import React, { useState } from "react";
import DropDown from "../global/fields/DropDown";
import Toggle from "../global/fields/Toggle";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CourseForm = () => {
  const login_Details = useSelector(state => state?.loginData?.LoginDetails);
  const [productGroup, setProductGroup] = useState("");
  const [activeTab, setActiveTab] = useState("active");
  const [courseTags, setCourseTags] = useState([]);
  const [courseInfo, setCourseInfo] = useState({
    courseName: "",
    productGroupName: "",
    courseDescription: "",
  });
  let isActive = activeTab === "active" ? "1" : "0";
  const handleChange = event => {
    setCourseInfo({ ...courseInfo, [event.target.name]: event.target.value });
  };
  const regex = /.*[a-zA-Z]+.*/gm;
  const handleSubmit = event => {
    event.preventDefault();
    let flag = true;
    let isFormValidated = () => {
      if (!courseInfo.courseName.match(regex)) {
        flag = false;
        alert("Please Enter Course Name!");
        return;
      }
      if (productGroup === "") {
        flag = false;
        alert("Please Select Product Group!");
        return;
      }
      if (!courseInfo.courseDescription.match(regex)) {
        flag = false;
        alert("Please Enter Course Description!");
        return;
      }
      if (courseTags.length === 0) {
        flag = false;
        alert("Please Select Course Tags!");
        return;
      }
    };
    isFormValidated();
    if (flag) refetch();
  };
  //function for adding the course =========>
  const fetch_addCourseData = async () => {
    return apirequest({
      url: `${endpoint.add_course.addr}`,
      method: "post",
      data: {
        courseName: courseInfo.courseName,
        courseDescription: courseInfo.courseDescription,
        productGroupName: productGroup,
        isActive: isActive,
        createdBy: login_Details.userImsPin,
        tagName: courseTags,
      },
    });
  };
  // Query For adding course ==========>
  const { refetch } = useQuery(
    `${endpoint.add_course.key}`,
    fetch_addCourseData,
    {
      enabled: false,
      onSuccess: data => {
        alert(data.data.message);
        window.location.reload(false);
      },
      onError: err => {
        alert(err.data.message);
      },
      retry: 1,
    },
  );
  // Query for tags data,eg: ["CAT", "XAT"]==========>
  const { data: tagsData} = useQuery(
    `${endpoint.tags_data.key}`,
    () =>
      apirequest({
        url: `${endpoint.tags_data.addr}`,
      }),
  );

  // Query for product group names eg :UG , PG   ======>
  const { data: product_groupName, isSuccess: productGroupStatus } = useQuery(
    endpoint.product_group.key,
    () =>
      apirequest({
        url: `${endpoint.product_group.addr}`,
      }),
  );
  return (
    <div className="pb-[5rem]">
      <div className="courseTable__body md:ml-[6%] md:mr-[12%] mt-4 shadow-outer py-12 px-8 rounded-xl bg-white pb-[2%]">
        <form
          className="flex flex-col justify-start font-ibm"
          onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
              Course Name{" "}
            </label>
            <input
              className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
              type="text"
              name="courseName"
              placeholder="Type course name"
              onChange={handleChange}
              required
              autoComplete="off"
            />
          </div>
          <div className="flex mb-4">
            <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
              Product Group
            </label>
            <DropDown
              placeholder={"Search or Create new Group"}
              options={productGroupStatus ? product_groupName.data.data : []}
              type={"singleSelect"}
              width={"w-[22rem]"}
              setFieldValue={setProductGroup}
              readonly={false}
            />
          </div>
          <div className="flex mb-4">
            <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
              Course Description{" "}
            </label>
            <textarea
              className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none resize-none w-7/12 focus:placeholder-transparent placeholder-bold font-semibold"
              rows="4"
              cols="50"
              name="courseDescription"
              onChange={handleChange}
              placeholder="Enter a short description about the course"
              required
              autoComplete="off"
            />
          </div>
          <div className="flex mb-4">
            <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
              Course Tags{" "}
            </label>
            <DropDown
              placeholder={"Search exisiting or Create new tags"}
              // options={tagsData?.data.data ?? []}
              options={tagsData?.data.data }
              setUpdatedOptions={setCourseTags}
              type={"multiselect"}
              width={"w-[20rem]"}
              courseTags={courseTags}
            />
          </div>
          <Toggle
            activeTab={activeTab}
            title={"This Course is"}
            setActiveTab={setActiveTab}
          />
        </form>
      </div>
      <div className="flex items-center justify-center mt-7 pr-20">
        <button
          type="submit"
          onClick={handleSubmit}
          className="bg-primary-200 text-white font-ibm px-12 rounded-xl hover:opacity-75 shadow-outer py-[10px] text-base font-medium mr-10 cursor-pointer">
          Save
        </button>
        <Link to="/Courseware/Courses" >
          <button className="text-black boxShadow-forButton font-ibm px-10 rounded-xl hover:opacity-75 shadow-outer text-base font-medium py-[10px] cursor-pointer">
            Exit
          </button>
        </Link>
      </div>
    </div>
  );
};

export default CourseForm;
