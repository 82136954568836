import React from "react";
import Listing from "./listing";

const SchoolListing = () => {
  return (
    <div className="shadow-outer rounded-b-2xl">
      <Listing />
    </div>
  );
};

export default SchoolListing;
