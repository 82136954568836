import React from "react";
import { useSelector } from "react-redux";
import Header from "../../header/Header";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import AddEditEvent from "./AddEditEvent";

const EditEventForSchool = () => {
  const data = useSelector(state=>state.eventSchoolDetails.eventSchoolData);
  console.log(data ,"data for ADDEditEventForSchool");
  return (
    <div>
      <Header/>
      <BreadCrumb />
      <AddEditEvent eventsdata= {data} requestType ="Update" />
    </div>
  );
};

export default EditEventForSchool;
