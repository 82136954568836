import React from "react";
import BreadCrumb from "../global/breadcrumbs/BreadCrumb";
import Header from "../header/Header";
import CourseForm from "./CourseForm";
function AddCourse() {
  return (
    <div>
      <Header />
      <div className="mt-6 ml-16">
        <BreadCrumb />
      </div>
      <CourseForm />
    </div>
  );
}

export default AddCourse;