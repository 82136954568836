import React from "react";
import AddTierModal from "./AddTierModal";
import propTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectSelectedTier } from "../../../redux/features/learningPath/learningPathSlice";

const TriggerAddTier = () => {
  return (
    <div className="font-ibm bg-primary-200 text-white font-normal px-6 rounded-xl hover:opacity-75 shadow-outer text-lg py-[9px] cursor-pointer">
      Add Tier
    </div>
  );
};

function AddTierFunc({ assetPath, parentId, parentData, handleEditClick }) {
  
  const selectedTier = useSelector(selectSelectedTier);
  const hasSubtier = selectedTier?.children?.some(
    child => child.assetPath === "0",
  );
  let isLastHeader = (selectedTier?.isHeader === "1" && assetPath === 1 ) || false;
  let disabled = isLastHeader || (hasSubtier && assetPath === "1");
  let message = isLastHeader ? "Cannot add assets to last Tier" : hasSubtier ? "Cannot add assets if there are subTiers" : "";

  return (
    <AddTierModal
      assetPath={assetPath}
      Trigger={TriggerAddTier}
      parentId={parentId} 
      parentData={parentData}
      handleEditClick={handleEditClick}
      disable={disabled}
      message={message}
    />
  );
}

AddTierFunc.propTypes = {
  assetPath: propTypes.string,
  parentId: propTypes.string,
  parentData: propTypes.object,
  handleEditClick: propTypes.func,
  disable: propTypes.bool,
  message: propTypes.string,
};
export default AddTierFunc;
