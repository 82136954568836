import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blogIdsequence: "",
};

export const postsequencingSlice = createSlice({
  name: "BlogIdSequence",
  initialState,
  reducers: {
    getBlogIdDetails: (state, action) => {
      state.blogIdsequence = action.payload;
    },
  },
});

export const { getBlogIdDetails } = postsequencingSlice.actions;

export default postsequencingSlice.reducer;
