import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  advertiseMentdata: {},
};

const access_Management_Slice = createSlice({
  name: "advertiseMentdata",
  initialState,
  reducers: {
    setadvertiseMentdata: (state, action) => {
      state.advertiseMentdata = action.payload;
    },
  },
});

export const { setadvertiseMentdata } = access_Management_Slice.actions;

export default access_Management_Slice.reducer;