import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blogName: "",
  blgName: "",
  blgId: "",
  blogDetails: "",
};

export const blogsSlice = createSlice({
  name: "blogsData",
  initialState,
  reducers: {
    getBlogDetails: (state, action) => {
      state.blogDetails = action.payload;
      state.blogName = action.payload.courseNames;
      state.blgName = action.payload.blogName;
      state.blgId = action.payload.blogId;
    },
  },
});

export const { getBlogDetails } = blogsSlice.actions;

export const selectedCourseName = state => state.blogsData.blogName;
export const selectedBlogName = state => state.blogsData.blgName;
export const selectedBlogId = state => state.blogsData.blgId;

export default blogsSlice.reducer;
