import React, { useState } from "react";
import Header from "../header/Header";
import BreadCrumb from "../global/breadcrumbs/BreadCrumb";
import DropDown from "../global/fields/CourseDropDownId";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const UpdateExam = () => {
  let history = useHistory();
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);

  const ExamData = useSelector(state => state.slideshowData.slideShowDetails);
  const [examName, setExamName] = useState(ExamData.examName);
  const [courseTags, setCourseTags] = useState([]);
  const [showLabel, setShowLabel] = useState(ExamData.courseNames ? ExamData.courseNames.map(a => a.courseName) : [],);
  const [courses, setCourses] = useState(ExamData.courseNames ? ExamData.courseNames: [],);
  const [courseId, setCourseId] = useState(
    ExamData.courseNames ? ExamData.courseNames.map(a => a.courseId) : [],
  );
  const [showLabelwithId,setShowLabelwithId]=useState(ExamData.courseNames ? ExamData.courseNames: [],);

  const { data: getCourseData,isSuccess } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method : "post",
      data : {
        "adminimspin":login_Details.userImsPin
      }
    }));

  const fetchAddExam = () => {
    return apirequest({
      url: `${endpoint.update_exams.addr}`,
      method: "put",
      data: {
        examName: examName,
        courseId: showLabelwithId.map(x=>x.courseId),
        isActive: "1",
        modifiedBy: login_Details.userImsPin,
        examId: ExamData.examId,
      },
    });
  };

  //API request to update exam data
  const { refetch: addProductExamRefetch } = useQuery(
    endpoint.update_exams.key,
    fetchAddExam,
    {
      enabled: false,
      onSuccess :(res)=>alert(res.data.message),
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );

  const onsave = e => {
    if(showLabelwithId.length===0){
      alert("Please Select Course");
    }
    else{
      addProductExamRefetch();
    }
    e.preventDefault();
  };

  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />
      <div className="mt-2 mx-12  px-8 py-2  ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
        >
          <div className="h-full space-y-6  px-12 py-6 relative">
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Exam Name
                <input
                  required
                  type="text"
                  name="blogName"
                  className="border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg focus:outline-none"
                  value={examName}
                  onChange={e => setExamName(e.target.value)}
                />
              </label>
            </div>
            <div className="flex py-5 space-x-14">
              <label className=" font-normal text-lg font-ibm ">Course</label>
              {isSuccess &&
              <DropDown
                placeholder={"Search Course(s)"}
                options={getCourseData?.data?.data?.courseList}
                setUpdatedOptions={setCourseTags}
                type={"multiselect"}
                width={"w-[22rem]"}
                courseTags={courseTags}
                showLabel={showLabel}
                setShowLabel={setShowLabel}
                value={courses}
                setValue={setCourses}
                courseId={courseId}
                setCourseId={setCourseId}
                showLabelwithId={showLabelwithId}
                setShowLabelwithId={setShowLabelwithId}
              />}
            </div>
          </div>
        </form>
        <div className=" space-x-6 flex justify-center mt-2">
          <button className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500" onClick={onsave}>
            SAVE
          </button>
          <button onClick={history.goBack} className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
            EXIT
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateExam;
