import React from "react";
import MapAssetModal from "../../components/course/learningPath/MapAssetModal";
import propTypes from "prop-types";

const Trigger = () => {
  return (
    <div
      className="font-ibm bg-primary-200 text-white font-normal cursor-pointer px-6 rounded-xl hover:opacity-75 shadow-outer text-lg py-[9px] ml-4">
      Map Asset
    </div>
  );
};
const MapAssetModalTrigger = ({ setLearningPath, setDisableSave }) => {
  return (
    <MapAssetModal
      Trigger={Trigger}
      setLearningPath={setLearningPath}
      setDisableSave={setDisableSave}
    />
  );
};

MapAssetModalTrigger.propTypes = {
  setLearningPath: propTypes.object,
  setDisableSave: propTypes.func,
};

export default MapAssetModalTrigger;
