import React from "react";
import PropTypes from "prop-types";

function ToggleYesNo({
  width = 180,
  title,
  setToggleState,
  toggleState,
  mg = "",
  disable,
  type,
  giveGap,
  isLabel = true
}) {
  const handleToggleState = () => {
    if (!disable) {
      setToggleState(true, type);
    }
  };
  const handleToggleSecState = () => {
    setToggleState(false, type);
  };

  return (
    <div className="flex items-center">
      {isLabel && <p
        style={{ width: `${width}px`, marginRight: `${giveGap ? "28px" : ""}` }}
        className={`text-lg font-normal font-ibm ${mg}`}>
        {title}
      </p>}
      <div className="flex bg-secondary-200 rounded-2xl">
        <p
          onClick={handleToggleState}
          className={`py-3 px-6 rounded-2xl cursor-pointer ${
            toggleState === true
              ? "bg-white shadow-outer font-ibm text-lg mb-0"
              : "font-ibm text-lg mb-0"
          } `}>
          Yes
        </p>
        <p
          onClick={() => handleToggleSecState(toggleState)}
          className={`py-3 px-6 rounded-2xl cursor-pointer ${
            toggleState === false
              ? "bg-white shadow-outer font-ibm text-lg mb-0"
              : "font-ibm text-lg mb-0"
          } `}>
          No
        </p>
      </div>
    </div>
  );
}

ToggleYesNo.propTypes = {
  title: PropTypes.string,
  width: PropTypes.number,
  setToggleState: PropTypes.func,
  toggleState: PropTypes.bool,
  giveGap: PropTypes.bool,
  mg: PropTypes.any,
  disable: PropTypes.bool,
  isLabel: PropTypes.bool,
  type: PropTypes.string,
};

export default ToggleYesNo;
