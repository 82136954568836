import React, { useState } from "react";
import Header from "../../../components/header/Header";
import { Link } from "react-router-dom";
import SlideshowTable from "../slideshow/SlideshowTable";
import TablePagination from "../../../components/table/TablePagination";
// import ArrowUp from "../../../assets/icons/arrow-up.svg";
import TableSearch from "../../table/TableSearch";
import DocumentPlusIcon from "../../../assets/icons/docplus.svg";
import Loader from "../../../components/global/loader/Loader";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { globalFilter } from "../../global/globalFilter/GlobalFilter";
import FilterFunctionlity from "../../filterFunctionality/FilterFunctionlity";

const SlideShowListing = () => {
  const column = [
    { col: "1", width: "10%" },
    { col: "2", width: "45%" },
    { col: "3", width: "40%" },
    { col: "4", width: "5%" },
  ];

  const modulesData = globalFilter({name : "Home Page", subModuleName : "Banner Management"});

  const [getSlideShowData , setSlideShowData] = useState([""]);
  const [currentPage, setCurrentPage] = useState(1);
  const [courseId, setCourseID] = useState([]);

  // API's for SlideShow Listing
  const fetchSlideshowListing = () => {
    return apirequest({
      url: `${endpoint.slideshow_data.addr}`,
      method: "post",
      data: {
        // adminimspin:adminimspin,
        adminimspin:modulesData.imsPin,
        courseId : ""
      }
    });
  };

  const {data : slideShow_DefaultData , error,isSuccess :is_slideshow_success,status } = useQuery(
    endpoint.slideshow_data.key,
    fetchSlideshowListing,{
      refetchOnMount : true ,
      onSuccess : (res)=>setSlideShowData(res)
    }
  );

  const { data: getCourseData } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method : "post",
      data : {
        "adminimspin":modulesData.imsPin,      
        "courseId" : ""
      }
    }),{
    select : res=>res.data.data.courseList
  });

  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;

  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };

  return (
    <div id="courses">
      <Header />{" "}
      {status === "loading" ? (
        <Loader />
      ) : !is_slideshow_success && error?.status !== 404 ? (
        <span>{error?.message}</span>
      ) : (
        <div className="courses">
          <div className="courseTable mt-9">
            <div className="courseTable__head flex justify-between md:mt-[4%] md-mb-[5%] md:mx-9 pl-[2%]">
              <FilterFunctionlity 
                filterText = " Course"
                setFunction = {setSlideShowData}
                dropdownData = {getCourseData}
                // endpointKey = {endpoint.variant_get_data.key}
                url = {endpoint.slideshow_data.addr}
                payload = {courseId}
                setPayload = {setCourseID}
                setCurrentPage = {setCurrentPage}
                payloadData = {{adminimspin :modulesData.imsPin , courseId : courseId} }
                filterId = "courseId"
                filterValue= "courseName"
              />

              <div className="ml-4 flex">
                { is_slideshow_success &&
                <TableSearch 
                  defaultData={slideShow_DefaultData}
                  setDefaultData ={setSlideShowData}
                  dataKey = "slideShowName"
                  dataForFilter={slideShow_DefaultData?.data.data.slideShowList}
                  name = "Home Page"
                  subModuleName= "Banner Management"
                  setCurrentPage={setCurrentPage}
                  childModuleName = ""
                  placeholder="Search by SlideShow"
                />
                
                }
                {(is_slideshow_success && getSlideShowData?.data?.data?.global_add_access==="1" || error?.data.message ==="No Record found") &&
                <Link to="/HomePage/BannerManagement/add-slide-show">
                  <button className="ml-6 bg-primary-200 text-white font-medium px-6 rounded-xl hover:opacity-75 shadow-outer font-ibm w-40 h-[56px]">
                    Add SlideShow
                  </button>
                </Link>}
                <Link to="/HomePage/BannerManagement/SlideShow-Sequence">
                  <button className="ml-6 bg-secondary-100 text-ibm font-medium px-6 rounded-xl hover:opacity-75 shadow-outer font-ibm  h-[56px]">
                    SlideShow Sequence
                  </button>
                </Link>
              </div>
            </div>
            <div className="courseTable__body md:ml-[4%] md:mr-[34px] mt-4 shadow-outer pl-[2%] pr-[5%] rounded-xl bg-white pb-[2%]">
              { is_slideshow_success &&
              <SlideshowTable
                icon={DocumentPlusIcon}
                column={column}
                currentData={getSlideShowData?.data?.data?.slideShowList.slice(
                  indexOfFirstItem,
                  indexOfLastItem,
                )}
                type={"course"}
                tags={true}
              />}
            </div>
            <div className="courseTable__footer mx-auto mb-7">
              {is_slideshow_success &&
              <TablePagination
                data={getSlideShowData?.data?.data?.slideShowList}
                dataPerPage={dataPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
              />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlideShowListing;
