import React, { useState } from "react";
import Header from "../../header/Header";
import Toggle from "../../global/fields/Toggle";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import DropDown from "../../global/fields/CourseDropDown";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import WhiteSpace from "../../global/regEx/WhiteSpaceValidator";


const EditBlog = () => {
  
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  let history = useHistory()
;
  const blogDetails = useSelector(state => state.blogsData.blogDetails);
  const blogId = useSelector(state => state.blogsData.blgId);
  const blogName = useSelector(state => state.blogsData.blgName);
  const [showLabel, setShowLabel] = useState(
    blogDetails.courseNames ? blogDetails.courseNames : [],
  );
  const [courseTags, setCourseTags] = useState([]);

  const [activeTab, setActiveTab] = useState(
    blogDetails.isActive == "0" ? "inactive" : "active",
  );
  let isActive = activeTab === "active" ? "1" : "0";
  const [courses, setCourses] = useState(
    blogDetails.courseNames ? blogDetails.courseNames : "",
  );
  const [value, setValue] = useState({
    blogName: blogDetails.blogName,
    blogUrl: blogDetails.blogUrl,
  });

  //API request to get courses data
  const { data: getCourseData,isSuccess } = useQuery(endpoint.course_data.key, ()=>apirequest({
    url: `${endpoint.course_data.addr}`,
    method : "post",
    data :{
      "adminimspin":login_Details.userImsPin
    }
  }),{
    refetchOnMount : true 
  });

  // API to edit blog

  const fetchUpdateBlog = () => {
    return apirequest({
      url: `${endpoint.update_blogs.addr}`,
      method: "put",
      data: {
        requestType: "update",
        blogName: value.blogName,
        blogUrl: value.blogUrl,
        isActive: isActive,
        modifiedBy: login_Details.userImsPin,
        courseName: courseTags,
        blogId: blogId,
      },
    });
  };

  const { refetch: UpdateBlogRefetch } = useQuery(
    [endpoint.add_blogs.key, blogName],
    fetchUpdateBlog,
    {
      enabled: false,
      refetchOnMount: false,
      onSuccess: (data)=> {
        alert(data.data.message);
      },
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );
  const HandleChange = e => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  const onsave = e => {
    e.preventDefault();
    if(WhiteSpace(value.blogName)===true){
      alert("Please enter a Valid Blog Name");
    }
    else if(WhiteSpace(value.blogUrl)===true){
      alert("Please enter Blog URL");
    }
    // to make course selection mandatory
    else if(showLabel.length===0){
      alert("Please Select Course");
    }
    else{
      UpdateBlogRefetch();
    }
  };

  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />
      <div className="mt-2 mx-12  px-8 py-2  ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
        >
          <div className="h-full space-y-6  px-12 py-6 relative">
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Blog Name
                <input
                  required
                  type="text"
                  name="blogName"
                  className="border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg focus:outline-none"
                  // placeholder="enter blog name"
                  value={value.blogName}
                  onChange={HandleChange}
                />
              </label>
            </div>
            <div>
              <label className=" font-normal text-lg font-ibm py-5">
                Blog Url
                <input
                  required
                  name="blogUrl"
                  className="focus:outline-none border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg"
                  // placeholder="enter blog Url"
                  value={value.blogUrl}
                  onChange={HandleChange}
                />
              </label>
            </div>
            {/* <input type="submit" value="Submit" /> */}
            <div className="flex py-5 space-x-14">
              <label className=" font-normal text-lg font-ibm ">Courses</label> 
              {isSuccess &&
              <DropDown
                placeholder={"Search Course(s)"}
                options={getCourseData?.data?.data?.courseList}
                setUpdatedOptions={setCourseTags}
                type={"multiselect"}
                width={"w-[22rem]"}
                courseTags={courseTags}
                showLabel={showLabel}
                setShowLabel={setShowLabel}
                value={courses}
                setValue={setCourses}
                forCourses ={true}
              />}
            </div>
            <Toggle
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              title="This Blog is"
            />
          </div>
        </form>
        <div className=" space-x-6 flex justify-center mt-2">
          <button className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500" onClick={onsave}>
            SAVE
          </button>
          <button onClick={history.goBack} className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
            EXIT
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditBlog;
