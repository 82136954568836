import React from "react";
import propTypes from "prop-types";

function GreyPill({tagName}) {
  return (
    <div className="mr-2 bg-gray-100 px-3 py-2 rounded-2xl text-sm capitalize">
      {tagName}
    </div>
  );
}

GreyPill.propTypes = {
  tagName: propTypes.string,
};

export default GreyPill;
