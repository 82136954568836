import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  mentorDetails:"",
};
export const mentorSlice = createSlice({
  name: "mentorData",
  initialState,
  reducers: {
    getmentorDetails: (state, action) => {
      state.mentorDetails=action.payload;
    },
  },
});
export const { getmentorDetails } = mentorSlice.actions;
export default mentorSlice.reducer;
