import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";
import ArrowDown from "../../../assets/icons/arrow-down.svg";
import ArrowUp from "../../../assets/icons/arrow-up.svg";
import { useDispatch } from "react-redux";
import { getIdDetails } from "../../../redux/features/blogs/sequencingSlice";
import { getPostIdDetails } from "../../../redux/features/blogs/postweekSlice";
function DropDown({
  placeholder,
  options,
  setUpdatedOptions,
  type,
  width,
  // setFieldValue,
  readonly,
  showLabel,
  setShowLabel,
  value,
  setValue,
  padding,
  showLabelwithId,
  setShowLabelwithId,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] =
    useState(false); /* State to  show and hide dropdown box*/
  const [searchResult, setSearchResult] =
    useState(options); /* To store Array of search result and array init*/
  const [optionLength, setOptionLength] = useState(searchResult?.length);
  const [valueCourse, setValueCourse]=useState("");
  /* handler to find if searched key is present in array*/
  const handleInput = e => {
    const searchedWrd = e.target.value;
    setValueCourse(e.target.value);
    setValue(e.target.value);
    const newFilter = options.filter(value => {
      if (type == "singleSelect") {
        return (
          value.postName ||
          value.stateName ||
          value.courseName ||
          value.countryName || value.cityName ||
          value.productGroupName
            .toLowerCase()
            .includes(searchedWrd.toLowerCase())
        );
      } else {
        return value.courseName
          .toLowerCase()
          .includes(searchedWrd.toLowerCase());
      }
    });
    setOpen(true);
    /* If not search not present in else append li saying not present */
    if (newFilter.length != 0) {
      setSearchResult(newFilter);
      setOptionLength(searchResult.length);
    } else {
      setSearchResult(options);
      setOptionLength(newFilter.length);
    }
  };
  const handleClick = () => {
    setOpen(!open);
    if (readonly === true) {
      setOpen(false);
    }
  };
  const outSideClickHandler = () => {
    setOpen(false);
  };

  /* to show labels*/
  const handleLabel = label => {
    setValue(label);
    if (!showLabelwithId.includes(label)) {
      setShowLabelwithId(oldlabel => oldlabel.concat(label));
      setUpdatedOptions(oldlabel => oldlabel.concat(label));
    }
  };

  const removeLabel = remLab => {
    setShowLabel(
      showLabel.filter(function (f) {
        return f !== remLab;
      }),
    );
  };
  const removeLabelwithId = remLab => {
    setShowLabelwithId(
      showLabelwithId.filter(function (f) {
        return f !== remLab;
      }),
    );
    
  };

  const renderOption = liData => {
    
    if (type == "multiselect") {
      if (optionLength != 0) {
        return liData?.map(item => (
          <li
            key={item.id}
            className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize"
            onClick={() => {
              handleLabel(item);
              // handleCourseId(item.courseId);
            }}>
            {item.courseName}
          </li>
        ));
      } 

    } else if (type == "singleSelect") {
      if (optionLength != 0) {
        return liData.map(item => (
          <li
            key={item.postId || item.courseId}
            className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize"
            onClick={() => {
              setValue(
                item.postName ||
                  item.courseName ||
                  item.countryName ||
                  item.stateName || item.cityName,
              );
              item.courseId || item.stateName ||item.cityName
                ? dispatch(getIdDetails(item))
                : "";
              item.postId ? dispatch(getPostIdDetails(item.postId)) : "";
            }}>
            {item.postName ||
              item.courseName ||
              item.countryName ||
              item.stateName ||item.cityName}
          </li>
        ));
      } else {
        return (
          <li className="font-ibm text-sm text-black font-medium opacity-60 py-1 px-4 capitalize">
            Product Group Not Present
          </li>
        );
      }
    }
  };
  useEffect(() => {
    if (type === "singleSelect" && setValue) {
      setValue(value); //setting values so that onsubmitting we can pass the values tht set to frontend
    } else {
      if (setUpdatedOptions) {
        setUpdatedOptions(showLabel); //to send set option values to fontend
      }
    }
  }, [value, showLabel]);
  useEffect(() => {
    setSearchResult(options);
  }, [options]);

  return (
    <div className="w-[22rem] flex justify-between">
      <OutsideClickHandler onOutsideClick={outSideClickHandler}>
        {(type == "singleSelect" || type == "multiselect") && (
          <div className={`${width} relative top-0`}>
            <input
              className={`ml-5  bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-${
                padding ? 0 : 3
              } border-solid  px-2 focus:outline-none w-full cursor-pointer focus:placeholder-transparent font-semibold placeholder-bold`}
              type="text"
              placeholder={placeholder}
              value={valueCourse}
              onChange={handleInput}
              onClick={handleClick}
              readOnly={
                type === "singleSelect" && readonly === true ? true : false
              }
            />
            <span
              onClick={handleClick}
              className={`absolute opacity-70 -right-2 cursor-pointer top-${
                padding ? 0 : 3
              }`}>
              <img src={open === true ? ArrowUp : ArrowDown} />
            </span>
            {open === true && (
              <div className="ml-5">
                <ul
                  className={`h-36 pt-2 w-full border-0 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 shadow-lg absolute z-50 top-14 bg-white overflow-y-scroll scrollbar scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded  max-h-[10rem] ${
                    type == "multiselect" ? "mb-[2rem]" : "mb-5"
                  }`}>
                  {renderOption(searchResult)}
                </ul>
              </div>
            )}
          </div>
        )}
      </OutsideClickHandler>
      {type === "multiselect" && (
        <div className="ml-9 flex mt-2">
          {!showLabelwithId &&
          showLabel.map((lab, l) => (
            <div key={l} className="relative mr-1">
              <span className="bg-gray-100 text-sm text-black rounded-full px-3 pr-6 pt-3 pb-2 capitalize whitespace-nowrap">
                {lab}
              </span>
              <span
                key={l}
                className="absolute bottom-[23px] text-xs font-medium cursor-pointer right-2 text-gray-500"
                onClick={() => {
                  removeLabel(lab);
                }}>
                  &#10005;
              </span>
            </div>
          ))}
          {showLabelwithId &&
           showLabelwithId?.map((data, l) => (
             <div key={l} className="relative mr-1">
               <span className="bg-gray-100 text-sm text-black rounded-full px-3 pr-6 pt-3 pb-2 capitalize whitespace-nowrap">
                 {data.courseName}
               </span>
               <span
                 key={l}
                 className="absolute bottom-[23px] text-xs font-medium cursor-pointer right-2 text-gray-500"
                 onClick={() => {
                   removeLabelwithId(data);
                 }}>
                  &#10005;
               </span>
             </div>
           ))
          }
        </div>
      )}
    </div>
  );
}
DropDown.propTypes = {
  placeholder: propTypes.string,
  options: propTypes.array,
  setUpdatedOptions: propTypes.func,
  setShowLabel: propTypes.func,
  type: propTypes.string,
  width: propTypes.string,
  setFieldValue: propTypes.func,
  readonly: propTypes.bool,
  showLabel: propTypes.array,
  value: propTypes.any,
  setValue: propTypes.func,
  setValId: propTypes.func,
  blog_post: propTypes.func,
  fetchGetBlogPost: propTypes.func,
  padding: propTypes.number,
  courseId: propTypes.array,
  setCourseId: propTypes.func,
  setShowLabelwithId:propTypes.func,
  showLabelwithId:propTypes.array,
};
export default DropDown;
