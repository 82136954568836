import React from "react";
import Calendar from "../../../global/calendar/Calendar";
import propTypes from "prop-types";

import WithModal from "../..//../global/modal/WithModal/WithModal";

function CalendarModal({ closeModal, setDate }) {
  return (
    <div>
      <Calendar closeModal={closeModal} setSelectedDate={setDate} />
    </div>
  );
}

CalendarModal.propTypes = {
  closeModal: propTypes.func,
  setDate: propTypes.func,
};

export default WithModal(CalendarModal);
