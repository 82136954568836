import config from "./config";
import axios from "axios";

console.log("======",config.staging);
/* optionaly add base url */
const client = axios.create({ baseURL: config.staging });
export const apirequest = ({ ...options }) => {
  client.defaults.headers.common.Authorization = "Bearer";

  const onSuccess = (response) => response;
  const onError = (error) => Promise.reject(error.response); 
  return client(options).then(onSuccess).catch(onError);
};
/* For base url from IMS Live*/
const imsLiveClient = axios.create({ baseURL: config.staging_imsLive });
export const apirequestIMSLIVE = ({ ...options }) => {
  imsLiveClient.defaults.headers.common.Authorization = "Bearer";

  const onSuccess = (response) => response;
  const onError = (error) => Promise.reject(error); 
  return imsLiveClient(options).then(onSuccess).catch(onError);
};
