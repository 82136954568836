import React, { useState } from "react";
import BreadCrumb from "../global/breadcrumbs/BreadCrumb";
import Header from "../header/Header";
import Toggle from "../global/fields/ToggleYesNo";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import WhiteSpace from "../global/regEx/WhiteSpaceValidator";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import DropDownSearchCommunication from "../../components/global/fields/CommunicationDropdown";

const AddBatch = () => {
  const BatchData = useSelector(state => state.slideshowData.slideShowDetails);
  const login_Details = useSelector(state => state?.loginData?.LoginDetails);
  const [activeTabForPublish, setActiveTabforPublish] = useState(BatchData.isActive==="1"?true:false);
  let history = useHistory();
  const [batchData, setBatchData] = useState({
    batchName: BatchData.batchName,
    batchCode: BatchData.batchCode,
  });
  // States for getting the unique items for course , centre=========>so on
  const [getCentreId , setGetCentreId] =useState(BatchData?.centres[0] === "All" ? ["All"] : BatchData?.centres.length != 0 ? BatchData?.centres.map((vl) => (vl.centreId)) : []);
  const [getCourseId, setGetCourseId] = useState(BatchData?.courses[0] === "All" ? ["All"] : BatchData?.courses.length != 0 ? BatchData?.courses.map((vl) => (vl.courseId)) : []);
  const [getVariantId, setGetVariantId] =  useState(BatchData?.variants[0] === "All" ? ["All"] : BatchData?.variants.length != 0 ? BatchData?.variants.map((vl) => (vl.variantId)) : []);
  const [getMentorId, setGetMentorId] = useState(BatchData?.variants[0] === "All" ? ["All"] : BatchData?.mentors.length != 0 ? BatchData?.mentors.map((vl) => (vl.mentorPin)) : []);

  // api for getting centre details ===>
  const { data: getCentreData  } = useQuery(`${endpoint.get_centers.key}`, () =>
    apirequest({
      url: `${endpoint.get_centers.addr}`,
    }),
  );
  // api for getting Courses details ===>
  const fetchGetCourse = () => {
    return apirequest({
      url: `${endpoint.get_courses.addr}`,
      method: "post",
      data: {
        centreId: getCentreId,
      } 
    });
  };

  const {data :course_data} = useQuery([endpoint.get_courses.key,getCentreId],fetchGetCourse,
    
    {
      refetchOnMount : true,
    },
  );
  // api for getting Variants details ===>
  const fetchGetVariants = () => {
    return apirequest({
      url: `${endpoint.get_variants.addr}`,
      method: "post",
      data: {
        courseId:getCourseId
      }
    });
  };
  const {data :variants_data,refetch:variantRefetch } = useQuery(
    [endpoint.get_variants.key,getCourseId],
    fetchGetVariants,
    {
      refetchOnMount : true,
    },
  ); 
    // api for getting mentors details ===>
  const fetchGetMentors = () => {
    return apirequest({
      url: `${endpoint.get_mentors.addr}`,
      method: "get",
    });
  };
  const {data :mentors_data,refetch:MentorRefetch } = useQuery(
    [endpoint.get_mentors.key,getCourseId],
    fetchGetMentors,
    {
      refetchOnMount : true,
    },
  ); 
  // api for updating batch
  const fetchUpdateBatch = () => {
    return apirequest({
      url: `${endpoint.update_batches.addr}`,
      method: "put",
      data: {
        batchName: batchData.batchName,
        batchCode:batchData.batchCode,
        isActive: activeTabForPublish?"1":"0",
        modifiedBy: login_Details.userImsPin,
        courseId:getCourseId,
        variantId: getVariantId,
        centreId: getCentreId,
        batchId: BatchData.batchId,
        mentorImsPin:getMentorId,
      }
    
    });
  };

  //API request to update batch data
  const { refetch: updateBatch } = useQuery(
    endpoint.update_batches.key,
    fetchUpdateBatch,
    {
      enabled: false,
      onSuccess :(res)=>{alert(res.data.message);},
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );

  const handleSubmit = event => {
    event.preventDefault();
    if(WhiteSpace(batchData.batchName) || WhiteSpace(batchData.batchCode)){
      alert("Required fields Cannot be Empty");
    }else{
      updateBatch();
    }
  };
  const handleChange = e => {
    setBatchData({
      ...batchData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="pb-9">
      <Header />
      <div className="mt-6 ml-2">
        <BreadCrumb />
      </div>
      <form>
        <div className="font-ibm flex justify-center mt-7">
          <div className="space-y-5 w-[93%] shadow-outer rounded-2xl p-10">
            <div className="flex space-x-5 mr-20">
              <div className="space-x-20 flex">
                <label className="font-normal text-lg leading-[40px]">
                Batch Name
                </label>
                <input
                  required
                  className="bg-white h-12 w-72 rounded-xl border-2 border-secondary-300 py-3 border-solid   focus:outline-none cursor-pointer focus:placeholder-transparent font-semibold placeholder-bold"
                  name="batchName"
                  value={batchData.batchName}
                  onChange={handleChange}
                />
              </div>
              <div className="flex space-x-8">
                <label className="font-normal text-lg mr-6 leading-[40px]">
                  Batch Code
                </label>
                <input
                  className="bg-white  h-12 w-72 rounded-xl  border-2 border-secondary-300 py-3 border-solid   focus:outline-none cursor-pointer focus:placeholder-transparent font-semibold placeholder-bold"
                  name="batchCode"
                  value={BatchData.batchCode}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex space-x-6 mr-20">
              <div className="space-x-[115px] flex">
                <label className="font-normal text-lg leading-[40px]">
                  Centers
                </label>
                <DropDownSearchCommunication
                  setId={setGetCentreId}
                  getId={getCentreId}
                  dropdownData={getCentreData?.data?.data ?? []}
                  filterText="Centre"
                  filterId="centreId"
                  filterValue="centreName"
                  val = {BatchData?.centres}
                />
              </div>
              <div className="flex  space-x-14">
                <label className="font-normal text-lg mr-6 leading-[40px]">
                  Courses
                </label>
                <DropDownSearchCommunication
                  setId={setGetCourseId}
                  getId={getCourseId}
                  dropdownData={course_data?.data?.data ?? []}
                  filterText="Course"
                  filterId="courseId"
                  filterValue="courseName"
                  refetch={variantRefetch}
                  val= {BatchData?.courses}
                />
              </div>
            </div>
            <div className="flex space-x-6 mr-20">
              <div className="space-x-[112px] flex">
                <label className="font-normal text-lg leading-[40px]">
                  Variants
                </label>
                <DropDownSearchCommunication
                  setId={setGetVariantId}
                  getId={getVariantId}
                  dropdownData={variants_data?.data?.data ?? []}
                  filterText="Variant"
                  filterId="variantId"
                  filterValue="variantName"
                  refetch={MentorRefetch}
                  val= {BatchData?.variants}
                />
              </div>
              <div className="space-x-8 flex">
                <label className="font-normal text-lg leading-[40px]">
                  Mentors name
                </label>
                <DropDownSearchCommunication
                  setId={setGetMentorId}
                  getId={getMentorId}
                  dropdownData={mentors_data?.data?.data ?? []}
                  filterText="Mentor"
                  filterId="mentorPin"
                  filterValue="mentorName"
                  refetch={false}
                  val= {BatchData?.mentors}
                />
              </div>
            </div>
            <div className="flex space-x-44 mr-[77px]">
              <Toggle
                title="Is Active"
                toggleState={activeTabForPublish}
                setToggleState={setActiveTabforPublish}
                mg="mr-[100px]"
              />
            </div>
          </div>
        </div>
      </form>
      <div className="flex justify-center mt-5 space-x-5 mb-5">
        <button
          className="px-10 py-3 bg-primary-200 text-white rounded-2xl shadow-outer"
          onClick={handleSubmit}>
          Save
        </button>
        <button
          className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer"
          onClick={history.goBack}>
          Exit
        </button>
      </div>
    </div>
  );
};

export default AddBatch;
