import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Drag from "../../../assets/icons/drag.svg";
import ArrowDown from "../../../assets/icons/arrow-down.svg";
import ArrowUp from "../../../assets/icons/arrow-up.svg";
import propTypes from "prop-types";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import { useDispatch } from "react-redux";
import {
  setLearningPathDetails,
  deleteTier,
  setDeletedTiers,
  setSelectedTier,
  setModule,
  setFolderOptions,
} from "../../../redux/features/learningPath/learningPathSlice";
import { setImportLearningPathData } from "../../../redux/features/learningPath/importLearningPathSlice";
import { useSelector } from "react-redux";
import AddTierFuncButton from "../../../components/course/navigationPath/TriggerAddTierFolder";
import { setSelectedLearningPathId } from "../../../redux/features/learningPath/learningPathSlice";
import { setAssetTierObject } from "../../../redux/features/learningPath/learningPathSlice";
import EditTierFuncButton from "../../course/navigationPath/TriggerEditTierFolder";
import ImportPathFuncForTier from "../../course/navigationPath/TriggerImportBtnForTier";
// import OutsideClickHandler from "react-outside-click-handler";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1); //removed tht element from array
  result.splice(endIndex, 0, removed); // added tht removed element to place we drop
  return result;
};

const TreeDraggable = ({ data, count, depth, treeIndexes, setDisableSave }) => {
  const dispatch = useDispatch();
  const learningPathArray = useSelector(state => state.learningPathData.learningPathDetails);
  const selectedTierId = useSelector(state => state.learningPathData.selectedLearningPathId);
  // const [showFolderOptions, setShowFolderOptions] = useState("");
  const showFolderOptions = useSelector(state => state.learningPathData.folderOptions);
  let _parentIndex = treeIndexes;
  if (!Array.isArray(_parentIndex)) _parentIndex = [_parentIndex];

  const [items, setItems] = useState([...data]);
  useEffect(() => {
    setItems([...data]);
  }, [data]);

  /* Styling droppable */
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#00ABFB" : "lightgrey",
  });

  const updateDataToNode = (reorderedItems, depth) => {
    let _learningPathArray = JSON.parse(JSON.stringify(learningPathArray));
    let currentNode = _learningPathArray;
    if (depth > 1) {
      for (let arrayIndex = 1; arrayIndex < _parentIndex.length; arrayIndex++) {
        const currentIndex = _parentIndex[arrayIndex];
        if (arrayIndex == depth - 1) {
          if (arrayIndex == 1) {
            currentNode[currentIndex].children = reorderedItems;
          } else {
            currentNode.children[currentIndex].children = reorderedItems;
          }
          break;
        }
        if (arrayIndex == 1) {
          currentNode = currentNode[currentIndex];
        } else {
          currentNode = currentNode.children[currentIndex];
        }
      }
    } else {
      _learningPathArray = reorderedItems;
    }
    return _learningPathArray;
  };

  /* Function to reorder the array after drag and drop */
  const onDragEnd = res => {
    if (!res.destination || res.source?.index == res.destination?.index) return;
    setItems(items => {
      const reorderedItems = reorder(
        items,
        res.source.index,
        res.destination.index,
      );
      const _learningPathArray = updateDataToNode(reorderedItems, depth);
      dispatch(setLearningPathDetails(_learningPathArray));
      return reorderedItems;
    });
    setDisableSave(false);
  };

  return (
    <DragDropContext onDragEnd={(...e) => onDragEnd(...e, depth)}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            {items &&
              items?.map((item, index) => {
                if (item.assetPath === "0") {
                  return (
                    <TreeNode
                      item={item}
                      index={index}
                      treeIndexes={[..._parentIndex, index]}
                      key={index}
                      count={count}
                      depth={depth}
                      selectedTierId={selectedTierId}
                      setDisableSave={setDisableSave}
                      showFolderOptions={showFolderOptions}
                    />
                  );
                }
              })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

{
  /***************************************** TREENODE COMPONENT *****************************************/
}

const TreeNode = ({
  item,
  index,
  count,
  depth,
  treeIndexes,
  selectedTierId,
  setDisableSave,
  showFolderOptions,
}) => {
  const [childVisible, setChildVisiblity] = useState(false);
  const variantData = useSelector(
    state => state.variantsByCourseData.variantsByCourse,
  );
  const hasTier = item?.children.some(child => child.assetPath === "0");
  const hasChildObject = item?.children?.length > 0;
  const dispatch = useDispatch();

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    boxShadow: isDragging ? "0 0 .4rem #666" : "none",
    background: "white",
    ...draggableStyle,
  });

  /* To get LearningPath ID */
  const handleSetLearningPathId = item => {
    if (selectedTierId === item.learningPathId) {
      dispatch(setSelectedLearningPathId(null));
      dispatch(setAssetTierObject([]));
      dispatch(setSelectedTier(null));
    } else {
      dispatch(setSelectedLearningPathId(item.learningPathId));
      const children = item.children.length > 0 ? item.children : [];
      dispatch(setAssetTierObject(children));
      dispatch(setSelectedTier(item));
    }
  };

  const closeModal = () => {
    dispatch(setFolderOptions(null));
  };

  /* Copy Path Link Function  */
  function copyToClipboard(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }

  const getCopyLearningPathObject = obj => {
    dispatch(setImportLearningPathData(obj));
    copyToClipboard(
      variantData.variantName + " > " + JSON.parse(JSON.stringify(obj)).name,
    )
      .then(() => alert("Path Link Copied!"))
      .catch(() => alert("error"));
  };
  const handleEditClick = () => {
    setDisableSave(false);
  };

  function handleOptionsClick(item){
    dispatch(setFolderOptions(item.learningPathId));
    dispatch(setModule(item.type));

  }
  
  return (
    <>
      <Draggable
        key={item.learningPathId}
        draggableId={item.learningPathId}
        index={index}>
        {(provided, snapshot) => (
          <div
            className="px-5 py-1 bg-gray-400"
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}>
            <div className="flex justify-between">
              <div className="flex">
                <img
                  className="mr-3 h-5 mt-2"
                  {...provided.dragHandleProps}
                  src={Drag}
                />
                {hasTier && hasChildObject && (
                  <div
                    className="rounded-full shadow-dark flex px-[6px] py-[4px] bg-white mr-3 mt-[2px]"
                    onClick={() => setChildVisiblity(v => !v)}>
                    <img
                      className="h-5 w-[17px]"
                      src={childVisible === true ? ArrowUp : ArrowDown}
                    />
                  </div>
                )}
                <div
                  className={`contentFont mt-1 font-semibold  mr-[2rem] text-base cursor-pointer
                  } ${
                    selectedTierId === item.learningPathId
                      ? "text-primary-200"
                      : "black"
                  }`}
                  style={{ opacity: count }}
                  onClick={() => {
                    handleSetLearningPathId(item);
                  }}>
                  {item.name}
                </div>
              </div>
              <div className="relative inline-block cursor-pointer">
                <span
                  className={`mr-3 h-5 m-auto text-2xl font-bold ${
                    showFolderOptions === item.learningPathId
                      ? "text-black"
                      : "text-gray-300"
                  }`}
                  onClick={() => handleOptionsClick(item)}>
                  ⋯
                </span>
                {showFolderOptions === item.learningPathId && (
                  <div className="py-2 border-0 border-secondary-300 rounded-2xl px-4 pl-6 pr-3 border-solid shadow-lg absolute z-50 top-8 bg-white mb-5 -right-2">
                    <Cancel
                      className="cursor-pointer mr-0 float-right -mt-1"
                      onClick={closeModal}
                    />
                    <EditTierFuncButton
                      assetPath="0"
                      editing={true}
                      parentData={item}
                      handleEditClick={handleEditClick}
                    />
                    <div className="py-1 font-ibm">
                      <span
                        className="whitespace-nowrap text-black hover:text-black"
                        onClick={() => {
                          dispatch(deleteTier(item));
                          dispatch(setDeletedTiers(item));
                          alert("successfully deleted!");
                          setDisableSave(false);
                        }}>
                        Delete
                      </span>
                    </div>
                    {/* <div className="py-1 font-ibm"><span className="whitespace-nowrap text-black hover:text-black">Add Tier</span></div> */}
                    <AddTierFuncButton
                      assetPath="0"
                      parentId={item.learningPathId}
                      parentData={null}
                      handleEditClick={handleEditClick}
                    />
                    <div className="py-1 font-ibm">
                      <span
                        className="whitespace-nowrap text-black hover:text-black"
                        onClick={() => getCopyLearningPathObject(item)}>
                        Copy Path Link
                      </span>
                    </div>
                    <div className="py-1 font-ibm">
                      <ImportPathFuncForTier
                        learningPathId={item.learningPathId}
                        setDisableSave={setDisableSave}
                      />
                      {/* <span className="whitespace-nowrap text-black hover:text-black">
                        Import Path
                      </span> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {hasChildObject && childVisible && item.assetPath === "0" && (
              <TreeDraggable
                data={item?.children}
                index={index}
                treeIndexes={treeIndexes}
                count={count.toFixed(1) == 0.2 ? 1 : count.toFixed(1) - 0.2}
                depth={depth + 1}
                setDisableSave={setDisableSave}
              />
            )}
          </div>
        )}
      </Draggable>
    </>
  );
};
TreeNode.propTypes = {
  item: propTypes.object,
  index: propTypes.number,
  count: propTypes.number,
  depth: propTypes.number,
  treeIndexes: propTypes.array,
  selectedTierId: propTypes.string,
  assetTierObject: propTypes.object,
  setDisableSave: propTypes.func,
  showFolderOptions: propTypes.string,
  setShowFolderOptions: propTypes.func,
};
TreeDraggable.propTypes = {
  data: propTypes.array,
  count: propTypes.number,
  depth: propTypes.number,
  treeIndexes: propTypes.array,
  setDisableSave: propTypes.func,
};
export default TreeDraggable;
