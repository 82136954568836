import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import propTypes from "prop-types";
import ImageResize from "quill-image-resize-module-react";
import BlotFormatter from "quill-blot-formatter";
import katex from "katex";
import "katex/dist/katex.min.css";
import "react-quill/dist/quill.snow.css";
import "./ReactQuill.css";
window.katex = katex;
const Image = Quill.import("formats/image");

const ATTRIBUTES = [
  "alt",
  "height",
  "width",
  "class",
  "style",
];

class CustomImage extends Image {
  static formats(domNode) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      const copy = { ...formats };

      if (domNode.hasAttribute(attribute)) {
        copy[attribute] = domNode.getAttribute(attribute);
      }

      return copy;
    }, {});
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/blotFormatter", BlotFormatter);
Quill.register({"formats/image": CustomImage}, true);

function Editor({ value, setValue }) {

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      // [{ font: [] }],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],
      [{ color: [] }],
      ["formula"]
    ],
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
    blotFormatter: {},
  };

  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      value={value}
      onChange={setValue}
    />
  );
}

Editor.propTypes = {
  value: propTypes.string,
  setValue: propTypes.func,
};

export default Editor;
