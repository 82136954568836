import React from "react";
import propTypes from "prop-types";
import BrowseLPcontainer from "./browseOnImport/BrowseLPcontainer";
import { useDispatch } from "react-redux";
import { clearImportData } from "../../../redux/features/learningPath/importLearningPathSlice";

const TriggerImportBtnForTier = () => {
  return (
    <span className="whitespace-nowrap text-black hover:text-black">
      {" "}
      Import Path
    </span>
  );
};

function ImportPathFunc({ learningPathId, setDisableSave }) {
  const dispatch = useDispatch();
  function clearImportDataOnOpen() {
    dispatch(clearImportData());
  }
  return (
    <BrowseLPcontainer
      Trigger={TriggerImportBtnForTier}
      onOpenFunctions={[clearImportDataOnOpen]}
      learningPathId={learningPathId}
      setDisableSave={setDisableSave}
    />
  );
}
ImportPathFunc.propTypes = {
  learningPathId: propTypes.string,
  setDisableSave: propTypes.func,
};
export default ImportPathFunc;
