import React ,{useState} from "react";
import arrowDown from "../../../assets/icons/arrow-down.svg";
import DropDown from "../../../components/global/fields/DropDown";
import addbutton from "../../../assets/icons/addButton.svg";
import arrowUp from "../../../assets/icons/arrow-up.svg";
import MoveIcon from "../../../assets/icons/move.svg";
import propTypes from "prop-types";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config";
import {apirequest} from "../../../API/api";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import { useEffect } from "react";
import NestedItems from "./VariantData";
import { useSelector} from "react-redux";

const AccordionItems = ({ 
  accordionList , 
  index,
  setVariantSequencingArray,
  addVariantData,
  setAddVariantData, 
  setNewAccordion , 
  updateVariant,
  setUpdateVariant,
  variantSequencingArray,
  newAccordion}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [nestedItemslists, setNestedItemsLists] = useState(accordionList?.variantData);
  const [variantDataForNewCategory , setVariantDataForNewCategory] = useState([]);
  const variantParentId = useSelector(state=> state?.variantData?.variantObj.variantId);
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  const handleClick =() =>{
    let value = Math.random(0,1);
    setNestedItemsLists(nestedItemslists?.length>0 ? [{"value": value} , ...nestedItemslists] : [{"value": value}]);
  };
  useEffect(()=>{
    if((selectedTitle != "" && variantDataForNewCategory?.length>0)){
      setNewAccordion([{
        "variantParentId" : variantParentId,
        categoryId : selectedTitle ,
        "isActive":"1",
        createdBy: login_Details.userImsPin, 
        "variants_data" : variantDataForNewCategory}, ...newAccordion]);
    }
  },[ variantDataForNewCategory]);

  const { data: categoryData, isSuccess } = useQuery(
    endpoint.get_category.key,
    () =>
      apirequest({
        url: `${endpoint.get_category.addr}`,
      })
  );

  const reorder = (nestedItemslists , startIndex, endIndex) => {
    const result = Array.from(nestedItemslists);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (res) => {   
    if (!res.destination) {
      return;
    } else {
      let result = reorder(
        nestedItemslists,
        res.source.index,
        res.destination.index
      );
      setNestedItemsLists(result);
      let array= [];
      result?.map((data, index)=>{
        array.push({ "variantId" : data.variantId , "variantOrder" : index});
      });
      variantSequencingArray.filter((dragItem)=>{
        setVariantSequencingArray(() =>{
          if(dragItem.categoryId === accordionList.categoryId){
            return [{...dragItem , "variants_data" :array}];
          }
        });
      });
      
    }};

  return (
    <>
      <Draggable
        draggableId={accordionList.categoryId ?  accordionList.categoryId:accordionList.value}
        index={index}
        key={accordionList.categoryId ? accordionList.categoryId : accordionList.value}
      >
        {(provided) => (
          <section
            className="h-auto flex"
            ref={provided.innerRef} 
            key= {index}
            {...provided.draggableProps}
          >
            <div className="w-[5%] mt-2" >
              <img
                src={MoveIcon}
                {...provided.dragHandleProps}
                
                className="w-6 h-6 mt-4"
              />
            </div>
            <div
              className="h-auto shadow-outer w-[95%] rounded-2xl"
            >
              <header
                onClick={() => {
                  setIsAccordionOpen(!isAccordionOpen);
                  setVariantSequencingArray([{
                    "variantParentId" :variantParentId , 
                    "categoryId" :accordionList.categoryId,
                    "variants_data" :[]
                  }]);
                }}
                className={`flex cursor-pointer justify-between shadow-outer h-auto py-4  px-9 ${
                  isAccordionOpen ? "rounded-t-2xl" : "rounded-2xl"
                }`}
              >
                <p
                  className={`font-medium text-base ${
                    !accordionList.categoryName  
                      ? "text-gray-400"
                      : "text-black"
                  }`}
                >
                  {! accordionList.categoryName && selectedTitle ===""
                    ? "Add Category " :  accordionList.categoryName }
                </p>
                <img
                  src={isAccordionOpen ? arrowUp : arrowDown}
                  className="-mt-2"
                />
              </header>
              {isAccordionOpen && (
                <div className="m-5 ml-10 space-y-5">
                  <aside className="flex  space-x-14 items-center w-[100%]">
                    <span className="text-lg w-[35%]">Category Name</span>
                    {isSuccess && (
                      <DropDown
                        placeholder="Choose Category name "
                        width="w-[30rem]"
                        setFieldValue={setSelectedTitle}
                        options={categoryData.data.data}
                        valueReadProduct={accordionList.categoryName}
                        type="singleSelect"
                        forUserRoll={true}
                      />
                    )}
                  </aside>
                  <div className="shadow-outer rounded-2xl pb-5">
                    <div>
                      <header className="grid grid-cols-2 shadow-outer rounded-t-2xl px-6 pt-3 mb-3">
                        <p className="font-medium text-base ">Variant Name</p>
                        <p className="font-medium text-base ml-4">
                          Variant Description
                        </p>
                      </header>
                      <DragDropContext
                        onDragEnd={ onDragEnd}
                      >
                        <Droppable droppableId="nestedaccordions">
                          {(provided) => (
                            <div>
                              <div
                                className=""
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {nestedItemslists?.map((data, index) => (
                                  <NestedItems
                                    key={data.variantId ? data.variantId :data.value}
                                    index = {index}
                                    keyValue = {data.variantId}
                                    setVariantDataForNewCategory ={setVariantDataForNewCategory}
                                    nestedItemslist={data}
                                    setNestedItemsLists={setNestedItemsLists}
                                    setAddVariantData = {setAddVariantData}
                                    addVariantData = {addVariantData}
                                    variantDataForNewCategory={variantDataForNewCategory}
                                    categoryId={accordionList.categoryId}
                                    updateVariantData ={updateVariant}
                                    setUpdateVariantData ={setUpdateVariant}
                                    nestedItemslistsArray= {nestedItemslists}
                                  />
                                ))}
                              </div>
                              { provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                    <button className="flex items-center relative left-[950px] mt-5" onClick={handleClick}>
                      <img src={addbutton} className="" />
                      <span className="font-medium text-base">Add Variant</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
            {provided.placeholder}
          </section>
        )}
      </Draggable>
    </>
  );
};


AccordionItems.propTypes = {
  accordionList: propTypes.any,
  index: propTypes.any,
  isForNewAccordion:propTypes.bool,
  setVariantSequencingArray : propTypes.func,
  addVariantData: propTypes.array,
  setAddVariantData : propTypes.func,
  setNewAccordion : propTypes.func,
  newAccordion : propTypes.array,
  data : propTypes.array,
  updateVariant : propTypes.array,
  setUpdateVariant : propTypes.func,
  variantSequencingArray : propTypes.array,
  randomValue : propTypes.string
};

export default React.memo( AccordionItems);