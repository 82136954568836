import React, { useState } from "react";
import Header from "../header/Header";
import BreadCrumb from "../global/breadcrumbs/BreadCrumb";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const UpdateProductGroup = () => {

  let history = useHistory();
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);

  const ProductGroupData = useSelector(
    state => state.slideshowData.slideShowDetails,
  );
  const [productGroupName, setProductGroupName] = useState(
    ProductGroupData.productGroupName,
  );
  const productGroupId = ProductGroupData.productGroupId;

  const fetchAddProductGroup = () => {
    return apirequest({
      url: `${endpoint.edit_productGroup.addr}`,
      method: "put",
      data: {
        productGroupName: productGroupName,
        isActive: "1",
        modifiedBy: login_Details.userImsPin,
        productGroupId: productGroupId,
      },
    });
  };

  //API request to update product group data
  const { refetch: addProductGroupRefetch } = useQuery(
    endpoint.edit_productGroup.key,
    fetchAddProductGroup,
    {
      enabled: false,
      onSuccess :(res)=>alert(res.data.message),
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );

  const onsave = e => {
    e.preventDefault();
    productGroupName?.trim().length === 0 ? alert("Field Should Not be Empty") : addProductGroupRefetch();
  };

  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />
      <div className="mt-2 mx-12  px-8 py-2  ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
        >
          <div className="h-full space-y-6  px-12 py-6 relative">
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Product Group
                <input
                  required
                  type="text"
                  name="productGroupName"
                  className="border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg focus:outline-none"
                  value={productGroupName}
                  onChange={e => setProductGroupName(e.target.value)}
                />
              </label>
            </div>
          </div>    
        </form>
        <div className=" space-x-6 flex justify-center mt-2">
          <button className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500" onClick={onsave}>
            SAVE
          </button>
          <button onClick={history.goBack} className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
            EXIT
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateProductGroup;
