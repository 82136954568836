import React from "react";
import PropTypes from "prop-types";

function Toggle({ activeTab, title, setActiveTab, margin }) {
  return (
    <div className="flex items-center">
      <p className=" text-lg font-normal font-ibm">{title}</p>
      <div
        // className="flex bg-secondary-200 rounded-2xl ml-16 ml "
        className={`flex bg-secondary-200 rounded-2xl   ml-${margin} `}>
        <p
          onClick={() => setActiveTab("active")}
          className={`py-3 px-5 rounded-2xl cursor-pointer   ${
            activeTab === "active"
              ? "bg-white shadow-outer font-ibm text-lg mb-0"
              : "font-ibm text-lg mb-0"
          } `}>
          Active
        </p>
        <p
          onClick={() => setActiveTab("inactive")}
          className={`py-3 px-5 rounded-2xl cursor-pointer ${
            activeTab === "inactive"
              ? "bg-white shadow-outer font-ibm text-lg mb-0"
              : "font-ibm text-lg mb-0"
          } `}>
          Inactive
        </p>
      </div>
    </div>
  );
}

Toggle.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  title: PropTypes.string,
  margin: PropTypes.any,
};

export default Toggle;
