import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import Sequence from "./Sequencing";
import BreadCrumb from "../../../global/breadcrumbs/BreadCrumb";
import DropDown from "../../../global/fields/CourseDropDown";
import {apirequest} from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const NoticeBoardSequence = () => {

  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  let history = useHistory();
  const [value, setValue] = useState("");
  const [events, setEvents] = useState([]);
  const [updates, setUpdates] = useState([]);
  // to pass the ids to the dropdown
  const [courseid,setCourseid]=useState("");
  // api to get courses
  const { data: getCourseData,isSuccess } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method : "post",
      data : {
        "adminimspin":login_Details.userImsPin
      }
    }));
  // end
  // const courseid = useSelector(state => state.CourseId.courseId);
  //API request to course_notice
  const fetchNotice = () => {
    return apirequest({
      url: `${endpoint.get_course_notice.addr}`,
      method: "post",
      data: {
        courseId: courseid,
      },
    });
  };

  const { data: getNoticeData, refetch: RefetchNotice } = useQuery(
    endpoint.get_course_notice.key,
    fetchNotice,
    {
      enabled: false,
      onSuccess: res => {
        // to handle the condition if there are no data from API
        setEvents(res?.data?.data[0].Event);
        setUpdates(res?.data?.data[0].Update);
      },
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );
  useEffect(() => {
    setEvents([]);
    setUpdates([]);
    courseid === "" ? "" : RefetchNotice();
  }, [courseid]);
  // end

  // changed value of sequence to be sent in api for events
  const value_events = [];
  getNoticeData &&
  // error handling if no events found from API
    events?.map((item, index) => {
      value_events.push({ eventId: item.NoticeBoardId, sequenceOrder: index });
    });

  // api for event sequencing
  const EventSequence = () => {
    return apirequest({
      url: `${endpoint.notice_event_sequence.addr}`,
      method: "put",
      data: {
        courseId: courseid,
        data: value_events,
      },
    });
  };

  const { refetch: eventsequence } = useQuery(
    endpoint.notice_event_sequence.key,
    EventSequence,
    {
      enabled: false,
    },
  );
  // end

  // changed value of sequence to be sent in api for updates
  const value_update = [];
  getNoticeData &&
    // error handling if no updates found from API
    updates?.map((item, index) => {
      value_update.push({ updateId: item.NoticeBoardId, sequenceOrder: index });
    });

  // api for update sequencing
  const UpdateSequence = () => {
    return apirequest({
      url: `${endpoint.notice_update_sequence.addr}`,
      method: "put",
      data: {
        courseId: courseid,
        data: value_update,
      },
    });
  };

  const { refetch: updatesequence } = useQuery(
    endpoint.notice_update_sequence.key,
    UpdateSequence,
    {
      enabled: false,
      onSuccess :(res)=>alert(res.data.message),
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );
  // end

  const onsave = e => {
    e.preventDefault();
    eventsequence();
    updatesequence();
  };
  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />
      <div className="mt-2 mx-12  px-8 py-2  ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
          onSubmit={onsave}>
          <div className="h-full space-y-6  px-12 py-6 relative">
            <div className="flex mb-4">
              <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg">
                Courses
              </label>
              {isSuccess &&
              <DropDown
                placeholder={"Select a Course"}
                options={getCourseData?.data?.data?.courseList}
                type={"singleSelect"}
                width={"w-[22rem]"}
                readonly={false}
                value={value}
                setValue={setValue}
                // to pass the ids of courses 
                setValueId={setCourseid}
                isSequencing={true}
              />}
            </div>
            {/* <input type="submit" value="Submit" /> */}
            {getNoticeData && getNoticeData.data.data[0] && (
              <div className="flex">
                <Sequence users={events} setUsers={setEvents} type="Events" />
                <Sequence
                  users={updates}
                  setUsers={setUpdates}
                  type="Updates"
                />
              </div>
            )}
          </div>
          <div className=" space-x-6 absolute left-[40%] -bottom-16">
            <button
              className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              type="submit">
              SAVE
            </button>
            <button onClick={history.goBack} className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
              EXIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NoticeBoardSequence;
