import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  venueDetails: {
    venueName: "",
    venueAddress: "",
    isActive: "",
    centres: [],
  },
};

export const venueSlice = createSlice({
  name: "VenueData",
  initialState,
  reducers: {
    setVenueDetails: (state, action) => {
      state.venueDetails = action.payload;
    },
  },
});

export const { setVenueDetails } = venueSlice.actions;
export default venueSlice.reducer;
