import React from "react";
import Header from "../../components/header/Header";
import Loader from "../../components/global/loader/Loader";
import LearningPath from "./LearningPath";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { endpoint } from "../../API/config";
import { apirequest } from "../../API/api";
import { clearAssetTierObject, setLearningPathDetails } from "../../redux/features/learningPath/learningPathSlice";
import { useEffect } from "react";

function LearningPathContainer() {
  const courseId = useSelector(
    state => state.courseData.courseDetails.courseId.toString(),
  );
  const variantId = useSelector(
    state => state.variantsByCourseData.variantsByCourse.variantId,
  );
  const dispatch = useDispatch();

  const fetchLearningPathPayload = () => {
    return apirequest({
      url: `${endpoint.get_LearningPath_Data.addr}`,
      method: "post",
      data: {
        courseId,
        variantId,
      },
    });
  };

  const { isLoading } = useQuery(
    [endpoint.get_LearningPath_Data.key, courseId, variantId],
    fetchLearningPathPayload,
    {
      select: data => data.data.data,
      onSuccess: data => {
        dispatch(setLearningPathDetails(data));
      },
      onError: () => {
        dispatch(setLearningPathDetails([]));
      },
    },
  );

  useEffect(() => {
    dispatch(clearAssetTierObject());
  }, []);
  

  return (
    <div>
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <LearningPath courseId={courseId} variantId={variantId} />
      )}
    </div>
  );
}

export default LearningPathContainer;
