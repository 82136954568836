import React from "react";
import BreadCrumb from "../../../global/breadcrumbs/BreadCrumb";
import Toggle from "../../../global/Toggle";
import Header from "../../../header/Header";
import { useSelector } from "react-redux";
import { useState } from "react";
import { endpoint } from "../../../../API/config";
import { apirequest } from "../../../../API/api";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { Tab } from "@headlessui/react";
import TabPanel from "./TabPanel";
const schoolAdvertisementTabs = [
  {
    id: 1,
    name: "Home",
    value: "home",
  },
  {
    id: 2,
    name: "Selection Criteria",
    value: "selection criteria",
  },
  {
    id: 3,
    name: "Eligibility Criteria ",
    value: "eligibility criteria",
  },
  {
    id: 4,
    name: "Cutoffs",
    value: "cutoffs",
  },
  {
    id: 5,
    name: "Deadlines",
    value: "deadlines",
  },
];

const AdvertiseMentSlots = () => {
  const { adverticementsDetails } = useSelector(
    (state) => state.advertisementSlotDetails.advertiseMentdata
  );
  const schoolData = adverticementsDetails.schools;

  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);

  const history = useHistory();

  const [isActive, setIsActive] = useState(adverticementsDetails?.isActive);

  const [abovechannelfilter, setAboveChannelfilter] = useState(
    Object.keys(adverticementsDetails).length
      ? adverticementsDetails.abovechannelfilter
      : [""]
  );
  const [belowChannelfilter, setBelowchannelfilter] = useState(
    Object.keys(adverticementsDetails).length
      ? adverticementsDetails.belowchannelfilter
      : [""]
  );
  const [homeSmallBanner, setHomeSmallBanner] = useState(
    schoolData?.home?.smallbanner ? schoolData.home.smallbanner : [""]
  );
  const [homeBigBanner, setHomeBigBanner] = useState(
    schoolData?.home?.bigbanner ? schoolData.home.bigbanner : [""]
  );
  const [selectionCriteriaSmallBanner, setSelectionCriteriaSmallBanner] =
    useState(
      schoolData?.selectioncriteria?.smallbanner
        ? schoolData.selectioncriteria.smallbanner
        : [""]
    );
  const [selectionCriteriaBigBanner, setSelectionCriteriaBigBanner] = useState(
    schoolData?.selectioncriteria?.bigbanner
      ? schoolData.selectioncriteria.bigbanner
      : [""]
  );
  const [eligibilityCriteriaSmallBanner, setEligibilityCriteriaSmallBanner] =
    useState(
      schoolData?.selectioncriteria?.smallbanner
        ? schoolData.eligibilitycriteria.smallbanner
        : [""]
    );
  const [eligibilityCriteriaBigBanner, setEligibilityCriteriaBigBanner] =
    useState(
      schoolData?.eligibilitycriteria?.bigbanner
        ? schoolData.eligibilitycriteria.bigbanner
        : [""]
    );
  const [cutoffSmallBanner, setCutoffSmallBanner] = useState(
    schoolData?.cutoffs?.smallbanner ? schoolData.cutoffs.smallbanner : [""]
  );
  const [cutoffBigBanner, setCutoffBigBanner] = useState(
    schoolData?.cutoffs?.bigbanner ? schoolData.cutoffs.bigbanner : [""]
  );
  const [deadlinesSmallBanner, setDeadlinesSmallBanner] = useState(
    schoolData?.deadlines?.smallbanner ? schoolData.deadlines.smallbanner : [""]
  );
  const [deadlinesBigBanner, setDeadlinesBigBanner] = useState(
    schoolData?.deadlines?.bigbanner ? schoolData.deadlines.bigbanner : [""]
  );

  const handleAboveChannelFilterSlots = (array, setArray) => {
    setArray(() => [...array, "", "", "", ""]);
  };

  const handleChangeForAboveChannelFilter = (e, index, setArray) => {
    setArray((prevData) => {
      const value = prevData.slice();
      value[index] = e.target.value;
      return value;
    });
  };

  const { refetch } = useQuery(
    endpoint.advertisementAddEdit.key,
    () =>
      apirequest({
        method: "post",
        url: endpoint.advertisementAddEdit.addr,
        data: {
          adminimspin: login_Details.userImsPin,
          courseid: adverticementsDetails.courseId,
          abovechannelfilter,
          belowchannelfilter: belowChannelfilter,
          isActive: isActive || "1",
          schools: {
            home: {
              smallbanner: homeSmallBanner,
              bigbanner: homeBigBanner,
            },
            selectioncriteria: {
              smallbanner: selectionCriteriaSmallBanner,
              bigbanner: selectionCriteriaBigBanner,
            },
            eligibilitycriteria: {
              smallbanner: eligibilityCriteriaSmallBanner,
              bigbanner: eligibilityCriteriaBigBanner,
            },
            cutoffs: {
              smallbanner: cutoffSmallBanner,
              bigbanner: cutoffBigBanner,
            },
            deadlines: {
              smallbanner: deadlinesSmallBanner,
              bigbanner: deadlinesBigBanner,
            },
          },
        },
      }),
    {
      enabled: false,
      onSuccess: (res) => alert(res.data.message),
      onError: (err) => alert(err.data.message),
    }
  );

  // const handleSlotReturn = (index) => {
  //   let val = index + 1;
  //   if (val > 4) {
  //     return val % 4 === 0 ? 4 : val % 4;
  //   }
  //   return val;
  // };
  return (
    <div>
      <Header />
      <div className="font-ibm p-9">
        <BreadCrumb />
        {/* {Object.keys(adverticementsDetails).length ? */}
        <section className="space-y-7">
          <h1 className="font-normal text-2xl">Home</h1>
          <Toggle
            title="Status: "
            margin={10}
            activeTab={isActive === "0" ? "inactive" : "active"}
            setActiveTab={(value) => {
              setIsActive(value === "active" ? "1" : "0");
            }}
          />
          <h2 className="font-normal text-lg">Above Channel filters</h2>
          <section className="flex space-x-4">
            <div className="grid grid-cols-4 mb-3">
              {abovechannelfilter.map((item, index) => (
                <div key={item.id} className="flex space-x-4 items-center">
                  <p className="font-normal text-lg">slot {index + 1}</p>
                  <input
                    type="text"
                    placeholder="Id"
                    value={item}
                    onChange={(e) =>
                      handleChangeForAboveChannelFilter(
                        e,
                        index,
                        setAboveChannelfilter
                      )
                    }
                    className="bg-white mb-2 border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none resize-none w-5/12 focus:placeholder-transparent placeholder-bold font-semibold"
                  />
                </div>
              ))}
            </div>
            <p
              className="text-primary-200 underline cursor-pointer"
              onClick={() =>
                handleAboveChannelFilterSlots(
                  abovechannelfilter,
                  setAboveChannelfilter
                )
              }
            >
              Add Slots
            </p>
          </section>
          <h2 className="font-normal text-lg">Below Channel filters</h2>
          <section className="flex space-x-4">
            <div className="grid grid-cols-4">
              {belowChannelfilter.map((item, index) => (
                <div key={item.id} className="flex space-x-4 items-center">
                  <p className="font-normal text-lg">slot {index + 1}</p>
                  <input
                    type="text"
                    placeholder="Id"
                    value={item}
                    onChange={(e) =>
                      handleChangeForAboveChannelFilter(
                        e,
                        index,
                        setBelowchannelfilter
                      )
                    }
                    className="bg-white mb-2 border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none resize-none w-5/12 focus:placeholder-transparent placeholder-bold font-semibold"
                  />
                </div>
              ))}
            </div>
            <p
              onClick={() =>
                handleAboveChannelFilterSlots(
                  belowChannelfilter,
                  setBelowchannelfilter
                )
              }
              className="text-primary-200 underline cursor-pointer"
            >
              Add Slots
            </p>
          </section>
        </section>
        <div className=" mt-10 flex flex-col">
          <h1 className="font-normal text-2xl mb-5">Schools</h1>

          <Tab.Group>
            <Tab.List className="flex space-x-3 rounded-xl p-1">
              {schoolAdvertisementTabs.map((category) => (
                <Tab
                  key={category}
                  className={({ selected }) =>
                    `${
                      selected
                        ? "text-secondary-100 bg-primary-300 font-medium "
                        : "text-secondary-600 "
                    } font-ibm font-normal text-md shadow-outer rounded-2xl px-6 py-4`
                  }
                >
                  {category.name}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-2">
              {/* {Object.keys(schoolData).map((obj, idx) => ( */}
              <TabPanel
                smallBanner={homeSmallBanner}
                bigBanner={homeBigBanner}
                setSmallBanner={setHomeSmallBanner}
                setBigBanner={setHomeBigBanner}
              />
              <TabPanel
                smallBanner={selectionCriteriaSmallBanner}
                bigBanner={selectionCriteriaBigBanner}
                setSmallBanner={setSelectionCriteriaSmallBanner}
                setBigBanner={setSelectionCriteriaBigBanner}
              />
              <TabPanel
                smallBanner={eligibilityCriteriaSmallBanner}
                bigBanner={eligibilityCriteriaBigBanner}
                setSmallBanner={setEligibilityCriteriaSmallBanner}
                setBigBanner={setEligibilityCriteriaBigBanner}
              />

              <TabPanel
                smallBanner={cutoffSmallBanner}
                bigBanner={cutoffBigBanner}
                setSmallBanner={setCutoffSmallBanner}
                setBigBanner={setCutoffBigBanner}
              />
              <TabPanel
                smallBanner={deadlinesSmallBanner}
                bigBanner={deadlinesBigBanner}
                setSmallBanner={setDeadlinesSmallBanner}
                setBigBanner={setDeadlinesBigBanner}
              />
              {/* ))} */}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      <footer className="flex justify-center space-x-6">
        <button
          className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer"
          onClick={refetch}
        >
          Save
        </button>
        <button
          className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer"
          onClick={() => history.push("/AdvertiseMent")}
        >
          Exit
        </button>
      </footer>
    </div>
  );
};

export default AdvertiseMentSlots;
