import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { setaccess_management_UserData } from "../../redux/features/access_Management/access_ManagementSlice";
import BreadCrumb from "../global/breadcrumbs/BreadCrumb";
import Loader from "../global/loader/Loader";
import Header from "../header/Header";
import AccessManagementTable from "./AccessManagementTable";
import UserTemplate from "./UserTemplate";

const EditUser = () => {
  const [data, setData] = useState(
    useSelector(
      (state) => state?.accessManagementUserData.access_management_UserData
    )
  );
  const dispatch = useDispatch();
  const adminimspin = useSelector(
    (state) => state.loginData.LoginDetails.userImsPin
  );

  const {
    data: list_For_ACCESS_Management_Table,
    error,
    status,
  } = useQuery(endpoint.addUser_AccessManangement.key, () =>
    apirequest({
      url: `${endpoint.addUser_AccessManangement.addr}`,
      method: "Post",
      data: {
        adminImsPin: adminimspin,
      },
    })
  );

  let updatedProductModuleAccess = data?.productModuleAccess.map((addKey) => {
    return { ...addKey, isActive: "1" };
  });
  let updatedGroupDataAccess = data?.productGroupAccess.map((addKey) => {
    return { ...addKey, isActive: "1" };
  });
  let updatedCentreDataAccess = data?.centreAccess.map((addKey) => {
    return { ...addKey, isActive: "1" };
  });

  // alert(JSON.stringify(updatedCentreDataAccess));
  let history = useHistory();
  const [selectedavatar, setSelectedAvatar] = useState(data.userAvatarUrl);
  let [role_id, set_Role_ID] = useState(data.roleName);
  const [userName, setUSerName] = useState(data.userName);
  const [imsPin, setImsPin] = useState(data.userImspin);
  const [userEmailId, setUserEmailId] = useState(data.userEmailId);
  const [productModuledata, setProductModuleData] = useState(
    updatedProductModuleAccess
  );
  const [productGroupData, setProductGroupData] = useState(
    updatedGroupDataAccess
  );
  const [cityCentreData, setCityCentreData] = useState(updatedCentreDataAccess);

  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);

  let id =
    (data.roleName === "Super Admin" && "1") ||
    ("Admin" && "2") ||
    ("Editor" && "3");

  let payloadData = {
    adminimspin: adminimspin,
    userName: userName,
    imsPin: imsPin,
    emailId: userEmailId,
    avtarUrl: selectedavatar,
    isActive: "1",
    createdBy: login_Details.userImsPin,
    modifiedBy: login_Details.userImsPin,
    roleId: role_id === "" ? id : role_id,
    userId: data.userId,
    productModuleData: productModuledata,
    productGroupData: productGroupData,
    centreAccessData: cityCentreData,
  };

  const { mutate: getUserData } = useMutation({
    mutationKey: [endpoint.access_Management_User_Api.key],
    mutationFn: () =>
      apirequest({
        url: `${endpoint.access_Management_User_Api.addr}`,
        method: "POST",
        data: {
          adminImsPin: adminimspin,
        },
      }),
    onSuccess: (response) => {
      const selectedUser = response?.data?.data?.usersList.find(
        (user) => user?.userId === data?.userId
      );
      // const finalUser = {
      //   ...selectedUser,
      //   productModuleAccess,
      //   productGroupAccess: selectedUser.productGroupAccess,
      // };
      if (selectedUser) {
        const uniqueBooks = Array.from(
          new Set(selectedUser?.productGroupAccess.map(JSON.stringify))
        ).map(JSON.parse);

        console.log(uniqueBooks);
      }
      dispatch(setaccess_management_UserData(selectedUser));
      console.log(selectedUser);
      setData(selectedUser);
    },
  });

  const { refetch } = useQuery(
    endpoint.edit_User_UpdateApi.key,
    () =>
      apirequest({
        url: `${endpoint.edit_User_UpdateApi.addr}`,
        method: "put",
        data: payloadData,
      }),
    {
      enabled: false,
      onSuccess: (res) => {
        alert(res.data.message);
        getUserData();
      },
      onError: (err) => alert(err.message),
    }
  );

  return (
    <div>
      <Header />
      <BreadCrumb />
      <div className="shadow-outer m-5 rounded-2xl p-6 h-auto font-ibm">
        <UserTemplate
          userRollData={
            list_For_ACCESS_Management_Table?.data?.data[0].user_roles
          }
          setTickMark={setSelectedAvatar}
          tickMark={selectedavatar}
          setUSerName={setUSerName}
          userName={userName}
          setImsPin={setImsPin}
          imsPin={imsPin}
          setUserEmailId={setUserEmailId}
          userEmailId={userEmailId}
          set_Role_ID={set_Role_ID}
          rollName={data.roleName}
          forEditUser={true}
          userData={data}
        />
        <p className="font-medium text-lg mt-5">Access Management</p>
        {status === "loading" ? (
          <Loader />
        ) : status === "error" ? (
          <span>{error.message}</span>
        ) : (
          <section>
            <AccessManagementTable
              tableData={
                list_For_ACCESS_Management_Table?.data?.data?.[0].product_groups
              }
              forProductModules={false}
              newArr={productGroupData}
              setNewArr={setProductGroupData}
              forEditUser={true}
              title="Product Group"
            />
            <AccessManagementTable
              tableData={
                list_For_ACCESS_Management_Table?.data?.data?.[0]
                  .product_modules
              }
              forProductModules={true}
              newArr={productModuledata}
              setNewArr={setProductModuleData}
              forEditUser={true}
              title="Product Module"
            />
            <AccessManagementTable
              forCenter={true}
              tableData={
                list_For_ACCESS_Management_Table?.data?.data?.[0].city_centres
              }
              newArr={cityCentreData}
              setNewArr={setCityCentreData}
              title="Centre Module"
            />
          </section>
        )}
      </div>
      <div className="flex justify-center mt-5 space-x-5 mb-5">
        <button
          className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer"
          onClick={refetch}
        >
          Save
        </button>
        <button
          className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer"
          onClick={history.goBack}
        >
          Exit
        </button>
      </div>
    </div>
  );
};
export default EditUser;
