import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EventDetails: "",
};

export const eventSlice = createSlice({
  name: "eventsData",
  initialState,
  reducers: {
    getEventDetails: (state, action) => {
      state.EventDetails = action.payload;
    },
  },
});
export const { getEventDetails } = eventSlice.actions;
export default eventSlice.reducer;
