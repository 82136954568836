import React from "react";

import Header from "../../header/Header";
import CriteriaForm from "./CriteriaForm";
import { endpoint } from "../../../API/config";

const EligibilityCriteria = () => {  
  return (
    <div>
      <Header />
      <CriteriaForm 
        endPoints = {endpoint.addEligibilityCriterias}
        downloadEndpoints = {endpoint.downloadEligibilityCriteriaCSV}
      />
    </div>
  );
};

export default EligibilityCriteria;
