import React from "react";
import submitted from "../../assets/icons/submitted-no-bg.svg";
import SlotModal from "./SlotModal";
import propTypes from "prop-types";

const Trigger = () => {
  return (
    <button className="p-4 bg-primary-200 text-white rounded-xl">
      Add Slot
    </button>
  );
};

const EditTrigger = () => {
  return <img className="h-6 cursor-pointer" src={submitted} />;
};

const SlotModalTrigger = ({ isEdit, slotToEdit }) => {
  return (
    <SlotModal
      isEdit={isEdit}
      slotToEdit={slotToEdit}
      Trigger={isEdit ? EditTrigger : Trigger}
    />
  );
};

SlotModalTrigger.propTypes = {
  isEdit: propTypes.any,
  slotToEdit: propTypes.any,
};
export default SlotModalTrigger;
