import React, { useState } from "react";
import Header from "../../components/header/Header";
import TableSearch from "../../components/table/TableSearch";
import { Link } from "react-router-dom";
import CourseTable from "../../components/table/CourseTable";
import testIcon from "../../assets/icons/test.svg";
import TablePagination from "../../components/table/TablePagination";
import { useQuery } from "react-query";
import { endpoint } from "../../API/config";
import { apirequest } from "../../API/api";
import Loader from "../../components/global/loader/Loader";
import { globalFilter } from "../../components/global/globalFilter/GlobalFilter";
import FilterFunctionlity from "../../components/filterFunctionality/FilterFunctionlity";

const ContentRepository = () => {
  const column = [
    { col: "0", width: "92%" },
    { col: "1", width: "50%" },
    { col: "2", width: "" },
  ];

  const modulesData = globalFilter({
    name: "Courseware",
    subModuleName: "Content Repository",
  });

  const [contentRepoData, setContentRepoData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productGroupId ,setproductGroupId] = useState([]);

  // Query for fetching the data  ==========>
  const fetchSlideshowListing = () => {
    return apirequest({
      url: `${endpoint.content_repo.addr}`,
      method: "post",
      data: {
        // adminimspin:adminimspin,
        adminimspin: modulesData.imsPin,
      },
    });
  };

  const {
    data: contentRepository_DefaultData,
    status,
    isSuccess,
    error,
  } = useQuery(endpoint.content_repo.key, fetchSlideshowListing, {
    onSuccess: res => setContentRepoData(res),
    refetchOnMount: true,
  });

  const { data: getProductGroupData } = useQuery(`${endpoint.product_group.key}`, () =>
    apirequest({
      url: `${endpoint.product_group.addr}`,
    }),{
    select : res=>res.data.data
  });

  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  // const [filteredData,setFilteredData] = useState(contentRepoData.data.data);
  // const currentItems = filteredData.slice(indexOfFirstItem,indexOfLastItem);
  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };
  return (
    <>
      <Header />
      {status === "loading" ? (
        <Loader />
      ) : (!isSuccess && error?.status !== 404) ? (
        <span>{error?.message}</span>
      ) : (
        <div className="mt-10 mx-6">
          <div className="courseTable__head flex justify-between pt-5 px-5">
            <FilterFunctionlity 
              filterText = "Product Group Name"
              setFunction = {setContentRepoData}
              dropdownData = {getProductGroupData}
              url = {endpoint.content_repo.addr}
              payload = {productGroupId}
              setPayload = {setproductGroupId}
              setCurrentPage = {setCurrentPage}
              payloadData = {{adminimspin :modulesData.imsPin , productGroupId : productGroupId} }
              filterId = "productGroupId"
              filterValue = "productGroupName"
            />
            <div className="ml-4 flex">
              <TableSearch
                defaultData={contentRepository_DefaultData}
                setDefaultData={setContentRepoData}
                dataKey = "contentRepositoryName"
                dataForFilter= {contentRepository_DefaultData?.data?.data?.ContentRepositoryList}
                name ="Courseware"
                subModuleName= "Content Repository"
                setCurrentPage={setCurrentPage}
                childModuleName=""
                placeholder="Search by Repository"
              />
                
              <div className="ml-6 bg-primary-200 text-white font-normal px-6 rounded-xl hover:opacity-75 shadow-outer text-lg pt-[10px] mr-4 text-center">
                {/* Typo Error Fix */}
                <Link
                  className="text-white hover:text-white"
                  to="/Courseware/ContentRepository/addContentRepo">
                  Add Repository
                </Link>
              </div>
            </div>
          </div>
          <div className="courseTable__body md:ml-[4%] md:mr-[34px] mt-4 shadow-outer px-[2%] rounded-xl bg-white pb-[2%] pt-5">
            <CourseTable
              column={column}
              currentData={contentRepoData?.data?.data?.ContentRepositoryList.slice(
                indexOfFirstItem,
                indexOfLastItem,
              )}
              colIcon={testIcon}
              isforContentRepo={true}
            />
          </div>
          <div className="courseTable__footer mx-auto mb-7 ">
            <TablePagination
              data={contentRepoData?.data?.data?.ContentRepositoryList}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              paginate={paginate}
              dataPerPage={dataPerPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ContentRepository;
