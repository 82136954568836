import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  contentRepositoryId : ""
};

const contentRepoSlice = createSlice({
  name: "contentRepositoryData",
  initialState,
  reducers: {
    setContentRepositoryId: (state, action) => {
      state.contentRepositoryId = action.payload;
    }
  },
});

export const {setContentRepositoryId } = contentRepoSlice.actions;

export default contentRepoSlice.reducer;