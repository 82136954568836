
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client} from "@aws-sdk/client-s3";
import awsConfig from "./bucketConfig";
const cred = {accessKeyId:awsConfig.credential.accessKeyId,secretAccessKey:awsConfig.credential.secretAccessKey};
export const UploadFileToAWS = async ({ ...options }) => {
  try {
    const parallelUploads3 = new Upload({
      client:new S3Client({region:awsConfig.credential.bucketRegion,credentials:cred}),
      params: { Bucket:awsConfig.credential.bucketName, Key:options.path, Body:options.file },
      leavePartsOnError: false, // optional manually handle dropped parts

    });
    parallelUploads3.on("httpUploadProgress", (progress) => {
      if (progress.Key) {
        alert("Upload Successful!");
      }
    });
    await parallelUploads3.done();
    const res = await parallelUploads3.singleUploadResult.Location;
    return res;
  } catch (e) {
    if (e) {
      alert("Upload Unsuccessful. Please Try Again!");
    }
  }
};

