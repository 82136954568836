import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  postweeksequence: "",
};

export const BlogweeksequencingSlice = createSlice({
  name: "PostIdSequence",
  initialState,
  reducers: {
    getPostIdDetails: (state, action) => {
      state.postweeksequence = action.payload;
    },
  },
});

export const { getPostIdDetails } = BlogweeksequencingSlice.actions;

export default BlogweeksequencingSlice.reducer;
