import React from "react";
import FilterFunctionlity from "../../../filterFunctionality/FilterFunctionlity";
import TableSearch from "../../../table/TableSearch";
import CourseTable from "../../../table/CourseTable";
import testIcon from "../../../../assets/icons/test.svg";
import TablePagination from "../../../table/TablePagination";
import { useState } from "react";
import Header from "../../../header/Header";
import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config";
import { apirequest } from "../../../../API/api";
import Loader from "../../../global/loader/Loader";

const AdvertisementListing = () => {
  const column = [
    { col: "0", width: "45%" },
    { col: "1", width: "50%" },
    { col: "2", width: "" },
  ];

  const {data :advertisementData , isSuccess , isError , isLoading} = useQuery(endpoint.advertisementListingApi.key ,()=> apirequest({
    method: "post",
    url : endpoint.advertisementListingApi.addr,
    data :{
      "adminimspin":"IMS0000394697"
    }
  }),{
    retry : 1
  });

  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };
  return (
    <div>
      <Header />
      {isLoading && <Loader/>}
      {isSuccess && <div className="p-8">
        <header className="flex justify-between px-6 mb-5">
          <FilterFunctionlity  dropdownData={[]}/>
          <TableSearch />
        </header>
        <section>
          <CourseTable
            currentData={advertisementData?.data?.data?.courseList.filter(item=>item.isActive ==="1").slice(indexOfFirstItem, indexOfLastItem)}
            colIcon={testIcon}
            column={column}
          />
        </section>
        <footer>
          <TablePagination
            data={advertisementData?.data?.data?.courseList.filter(item=>item.isActive ==="1")}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            paginate={paginate}
            dataPerPage={dataPerPage}
          />
        </footer>
      </div>}
      {isError && <span className="text-base text-red-600 flex justify-center">No Record found</span>}
    </div>
  );
};

export default AdvertisementListing;
