import React from "react";
import propTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectSlug, setEditCourseTab } from "../../redux/features/courseware/courseSlice";
function SaveAndExitButtons({ handleLearningPathAPI, disableSave }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const slug = useSelector(selectSlug);
  function handleExit(){
    dispatch(setEditCourseTab(1));
    history.push(`/Courseware/Courses/edit-Course:${slug}`);
  }
  return (
    <div className="flex items-center justify-center mt-7 pr-20">
      <button
        type="submit"
        onClick={handleLearningPathAPI}
        disabled={disableSave}
        className={`${
          disableSave
            ? "bg-gray-300 text-black"
            : "bg-primary-200  hover:opacity-75"
        }  text-white font-ibm px-12 rounded-xl shadow-outer py-[10px] text-base font-medium mr-10 cursor-pointer`}>
        Save
      </button>
      <button onClick={handleExit} className="text-black boxShadow-forButton font-ibm px-10 rounded-xl hover:opacity-75 shadow-outer text-base font-medium py-[10px] cursor-pointer">
        Exit
      </button>
    </div>
  );
}

SaveAndExitButtons.propTypes = {
  handleLearningPathAPI: propTypes.func,
  disableSave: propTypes.bool,
};

export default SaveAndExitButtons;
