import React, { useEffect, useState } from "react";
import CheckBoxActive from "../../assets/icons/tickMark_withBg.svg";
import propTypes from "prop-types";

const CheckBoxItem = ({ item, newArr, setNewArr, id }) => {
  const arrayToObject = (newArr) => {
    const res = {};
    for (let i = 0; i < newArr?.length; i++) {
      if (id === "2" && "product_group_id" in item) {
        if (
          newArr[i]["courseId"] !== "None" &&
          newArr[i]["courseId"] !== undefined
        ) {
          const key = newArr[i]["courseId"];
          res[key] = newArr[i];
        } else {
          const key = newArr[i]["product_group_id"];
          res[key] = newArr[i];
        }
      }
      if (id === "2" && "centre_id" in item) {
        const key = newArr[i]["centre_id"];
        res[key] = newArr[i];
      }
      // else {
      //   // added id authentication based on id prop
      //   if(id === "1" && "product_group_id" in item){
      //     if (
      //       newArr[i]["courseId"] !== "None" &&
      //       newArr[i]["courseId"] !== undefined
      //     ) {
      //       const key = newArr[i]["courseId"];
      //       res[key] = newArr[i];
      //     } else {
      //       const key = newArr[i]["product_group_id"];
      //       res[key] = newArr[i];
      //     }
      //   }
      const key =
        id === "1"
          ? newArr[i]["courseId"] !== "None" &&
            newArr[i]["courseId"] !== undefined
            ? newArr[i]["courseId"]
            : newArr[i]["productGroupId"] ||
              newArr[i]["productModuleId"] ||
              newArr[i]["centre_id"]
          : newArr[i]["productModuleId"] || newArr[i]["centre_id"];
      res[key] = newArr[i];
      // }
    }
    return res;
  };
  const access = arrayToObject(newArr);
  let itemAccess =
    access[
      id === "2" && "product_group_id" in item
        ? "course_id" in item
          ? Number(item.course_id)
          : Number(item?.product_group_id)
        : id === "2" && "centre_id" in item
        ? item?.centre_id
        : Number(item?.product_group_id || item?.product_module_id)
    ];
  const [viewchecked, setViewChecked] = useState(
    itemAccess?.viewAccess === "1" ? true : false
  );
  const [editchecked, setEditChecked] = useState(
    itemAccess?.editAccess === "1" ? true : false
  );
  const [deleteChecked, setDeleteChecked] = useState(
    itemAccess?.deleteAccess === "1" ? true : false
  );

  let viewAccessStatus = viewchecked ? "1" : "0";
  let editAccessStatus = editchecked ? "1" : "0";
  let deleteAccessStatus = deleteChecked ? "1" : "0";

  const condition_For_ProductGroup = (val) => {
    const isProductKeyExist = val.some((element) => {
      if ("course_id" in item) {
        if (
          Number(element?.productGroupId) === Number(item?.product_group_id) &&
          String(element?.courseId) === String(item?.course_id)
        ) {
          return true;
        }
      } else {
        if (
          Number(element?.productGroupId) === Number(item?.product_group_id) &&
          String(element?.courseId) === "None"
        ) {
          return true;
        }
      }
      return false;
    });
    isProductKeyExist
      ? setNewArr(val)
      : setNewArr([
          ...val,
          {
            productGroupId: item?.product_group_id.toString(),
            courseId: "course_id" in item ? String(item?.course_id) : "None",
          },
        ]);
  };
  const conditiom_For_ProductModule = (val) => {
    const isKeyExist = val.some((element) => {
      if (Number(element.productModuleId) === Number(item.product_module_id)) {
        return true;
      }
      return false;
    });

    isKeyExist
      ? setNewArr(val)
      : setNewArr([...val, { productModuleId: item.product_module_id }]);
  };

  const else_condition_forProductGroup = (val) => {
    setNewArr([
      ...newArr,
      {
        productGroupId: val.product_group_id.toString(),
        courseId: "course_id" in val ? item?.course_id?.toString() : "None",
        viewAccess: viewAccessStatus,
        deleteAccess: deleteAccessStatus,
        editAccess: editAccessStatus,
      },
    ]);
  };
  const else_condition_for_ProductModule = (val) => {
    setNewArr([
      ...newArr,
      {
        productModuleId: val.product_module_id.toString(),
        viewAccess: viewAccessStatus,
        editAccess: editAccessStatus,
        deleteAccess: deleteAccessStatus,
      },
    ]);
  };

  // handling checkbox for view Access=======>
  const handleChangeView = (data) => {
    setViewChecked(!viewchecked);
    if (newArr?.length > 0) {
      if ("product_group_id" in data) {
        condition_For_ProductGroup(newArr);
      } else if ("product_module_id" in data) {
        conditiom_For_ProductModule(newArr);
      }
    } else {
      if ("product_group_id" in data) {
        else_condition_forProductGroup(data);
      } else if ("product_module_id" in data) {
        else_condition_for_ProductModule(data);
      }
    }
  };
  // handling checkbox for edit access =====>
  const handleChangeEdit = (data) => {
    setEditChecked(!editchecked);
    if (newArr?.length > 0) {
      if ("product_group_id" in data) {
        condition_For_ProductGroup(newArr);
      } else if ("product_module_id" in data) {
        conditiom_For_ProductModule(newArr);
      }
    } else {
      if ("product_group_id" in data) {
        else_condition_forProductGroup(data);
      } else if ("product_module_id" in data) {
        else_condition_for_ProductModule(data);
      }
    }
  };
  // handling checkbox for delete Access =======>
  const handleChangeDelete = (data) => {
    setDeleteChecked(!deleteChecked);
    if (newArr?.length > 0) {
      if ("product_group_id" in data) {
        condition_For_ProductGroup(newArr);
      } else if ("product_module_id" in data) {
        conditiom_For_ProductModule(newArr);
      }
    } else {
      if ("product_group_id" in data) {
        else_condition_forProductGroup(data);
      } else if ("product_module_id" in data) {
        else_condition_for_ProductModule(data);
      }
    }
  };

  useEffect(() => {
    setNewArr(
      newArr?.map((val) => {
        if ("productGroupId" in val) {
          if (
            !("course_id" in item) &&
            Number(val.productGroupId) === Number(item.product_group_id) &&
            val.courseId === "None"
          ) {
            return {
              ...val,
              viewAccess: viewAccessStatus,
              courseId: "course_id" in item ? item.course_id : "None",
              deleteAccess: deleteAccessStatus,
              editAccess: editAccessStatus,
              isActive: "1",
            };
          } else if (
            "course_id" in item &&
            val?.courseId?.toString() === item?.course_id?.toString()
          ) {
            return {
              ...val,
              viewAccess: viewAccessStatus,
              courseId: "course_id" in item ? item.course_id : "None",
              deleteAccess: deleteAccessStatus,
              editAccess: editAccessStatus,
              isActive: "1",
            };
          } else {
            return val;
          }
        } else if ("productModuleId" in val) {
          if (Number(val.productModuleId) === Number(item.product_module_id)) {
            return {
              ...val,
              viewAccess: viewAccessStatus,
              deleteAccess: deleteAccessStatus,
              editAccess: editAccessStatus,
              isActive: "1",
            };
          } else {
            return val;
          }
        }
      })
    );
    setNewArr((val) =>
      val.filter((item) => {
        return (
          item.viewAccess === "1" ||
          item.editAccess === "1" ||
          item.deleteAccess === "1"
        );
      })
    );
  }, [viewAccessStatus, deleteAccessStatus, editAccessStatus]);

  return (
    <div className="flex space-x-11">
      <div className="flex">
        <input
          type="checkbox"
          className=" bg-white appearance-none mt-[2px] ml-[2px] h-[29px] w-[29px] rounded-[8px] border-[2px] cursor-pointer relative"
          onClick={() => handleChangeView(item)}
        />
        {viewchecked && (
          <img
            src={CheckBoxActive}
            className="absolute cursor-pointer "
            onClick={() => {
              setViewChecked(false);
            }}
          />
        )}
      </div>
      <div className="flex">
        <input
          type="checkbox"
          className=" bg-white appearance-none mt-[2px] ml-[2px] h-[29px] w-[29px] rounded-[8px] border-[2px] cursor-pointer relative"
          onClick={() => handleChangeEdit(item)}
        />
        {editchecked && (
          <img
            src={CheckBoxActive}
            className="absolute cursor-pointer "
            onClick={() => {
              setEditChecked(false);
            }}
          />
        )}
      </div>
      <div className="flex">
        <input
          type="checkbox"
          className=" bg-white appearance-none mt-[2px] ml-[2px] h-[29px] w-[29px] rounded-[8px] border-[2px] cursor-pointer relative"
          onClick={() => handleChangeDelete(item)}
        />
        {deleteChecked && (
          <img
            src={CheckBoxActive}
            className="absolute cursor-pointer "
            onClick={() => {
              setDeleteChecked(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
CheckBoxItem.propTypes = {
  item: propTypes.object,
  id: propTypes.string,
  newArr: propTypes.array,
  setNewArr: propTypes.func,
};

export default CheckBoxItem;
