import React, { useState } from "react";
// import DropDown from "../../components/global/fields/DropDown";
import { Link } from "react-router-dom";
import TableByIcon from "../table/contentRepoTable/TableByIcon";
import TablePagination from "../../components/table/TablePagination";
import propTypes from "prop-types";
import Loader from "../../components/global/loader/Loader";
import ArrowUp from "../../assets/icons/arrow-up.svg";
import arrowDown from "../../../src/assets/icons/arrow-down.svg";
import { useQuery } from "react-query";
import { endpoint } from "../../API/config";
import { apirequest } from "../../API/api";
import TableSearch from "../table/TableSearch";
import OutsideClickHandler from "react-outside-click-handler";

const  ContentRepositoryComp = ({
  isforAddConentRepo,
  dataSource,
  contentRepoData,
  status,
  showAddAssetButton,
  selectedData,
  setSelectedData,
  setAssetData,
  contentRepoID,
  defaultAssetData
}) => {
  const width = [
    { col: 1, width: "88%" },
    { col: 2, width: "20%" },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [assetType, setAssetType] = useState([]);
  const [showDropdown, setShowDropDown] = useState(false);
  const dataPerPage = isforAddConentRepo ? 4 : 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };

  const AssetType = [
    { id: 1, assetType: "Video" },
    { id: 2, assetType: "Assessment" },
    { id: 3, assetType: "Pdf" },
    { id: 4, assetType: "HTML" },
  ];

  const handleChange = (e, item) => {
    const isChecked = e.target.checked;
    // let value = e.target.value;
    if (isChecked) {
      // setPayload([...payload, e.target.id]);
      setAssetType([...assetType, item.assetType]);
    } else {
      setAssetType(assetType.filter((val) => val != item.assetType));
    }
  };

  const { refetch } = useQuery(
    "Filter___Key",
    () =>
      apirequest({
        url: endpoint.getAssetsUsingContentRepository.addr,
        method: "post",
        data: {
          contentRepositoryId: contentRepoID,
          assetType :assetType
        },
      }),
    {
      enabled: false,
      onSuccess: (res) => {
        setAssetData(res);
        setCurrentPage(1);
      },
      onError :(err)=>alert(err.data.message)
    }
  );

  return (
    <>
      <div className="flex justify-between">
        {/* Added outside click handler for filter in Assets */}
        <OutsideClickHandler onOutsideClick={()=>setShowDropDown(false)}>
          <div className="flex space-x-3">
            <div className="flex cursor-pointer bg-secondary-100 text-lg text-black shadow-outer px-[20px] py-[10px] rounded-xl items-center">
              Filter by:{" "}
              <input
                className="focus:outline-none  w-3/6 rounded-lg"
                placeholder={"Asset Type"}
                readOnly
                value={assetType.toString()}
              />
              <img
                src={arrowDown ? arrowDown : ArrowUp}
                onClick={() => setShowDropDown(!showDropdown)}
                className="ml-4"
              />
            </div>
            <p
              type="submit"
              className="bg-primary-200 text-white font-ibm px-12 rounded-xl hover:opacity-75 shadow-outer py-[10px] text-base font-medium mr-10 cursor-pointer"
              onClick={refetch}
            >
              Save
            </p>
          </div>
        
          {showDropdown && (
            <ul className="h-auto ml-2 mt-1 pt-2 px-3 w-80 border-2 border-solid border-gray-200 rounded-lg shadow-lg absolute z-50 bg-white overflow-y-scroll scrollbar scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded  max-h-[10rem]">
              {AssetType.map((item) => (
                <div className="flex" key={item.id}>
                  <input
                    type="checkbox"
                    id={item.id}
                    value={item.assetType}
                    checked={assetType.includes(item.assetType)}
                    onChange={(e) => handleChange(e, item)}
                  />
                  <li
                    key={item.id}
                    // removed onclick handler to avoid duplicated
                    // onClick={() => setAssetType([...assetType, item.assetType])}
                    className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize"
                  >
                    {item.assetType}
                  </li>
                </div>
              ))}
            </ul>
          )}
        </OutsideClickHandler>
        <div className="flex">
          <TableSearch
            defaultData={defaultAssetData}
            setDefaultData={setAssetData}
            dataKey="assetName"
            dataForFilter={defaultAssetData?.data?.data}
            name="Courseware"
            subModuleName="Content Repository"
            setCurrentPage={setCurrentPage}
            childModuleName=""
            contentRepoID = {contentRepoID}
            placeholder="Search by Assets"
          />
          {!isforAddConentRepo && showAddAssetButton && (
            <div className="ml-4 flex">
              {/* <TableSearch  data = {dataSource}  setFilteredData={setFilteredData}/> */}
              <div className="ml-6 bg-primary-200 text-white font-normal px-6 rounded-xl hover:opacity-75 shadow-outer text-lg py-[7px] mr-5">
                <Link
                  className="text-white hover:text-white"
                  to={{
                    pathname: `/Courseware/ContentRepository/${localStorage.getItem(
                      "breadcrumbs"
                    )}/add-Asset`,
                    state: { contentRepoData },
                  }}
                >
                  Add Asset
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      {status === "loading" ? (
        <Loader />
      ) : (
        <div className="mr-6">
          <div className="courseTable__table px-6 mx-5 shadow-outer w-[100%] rounded-xl bg-white py-5">
            <TableByIcon
              column={width}
              currentData={
                dataSource != null
                  ? dataSource?.slice(indexOfFirstItem, indexOfLastItem)
                  : dataSource
              }
              isforAddConentRepo={isforAddConentRepo}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
            />
          </div>
        </div>
      )}
      {/* show pagination when required */}
      {(dataSource != null && dataSource?.length > 0) && (
        <div className="courseTable__footer mx-auto mb-7">
          <TablePagination
            data={dataSource}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            paginate={paginate}
            dataPerPage={dataPerPage}
          />
        </div>
      )}
    </>
  );
};
ContentRepositoryComp.propTypes = {
  isforAddConentRepo: propTypes.bool,
  dataSource: propTypes.array,
  contentRepoData: propTypes.any,
  status: propTypes.string,
  showAddAssetButton: propTypes.bool,
  selectedData: propTypes.array,
  setSelectedData: propTypes.func,
  setAssetData: propTypes.func,
  contentRepoID: propTypes.string,
  defaultAssetData : propTypes.object
};

export default ContentRepositoryComp;
