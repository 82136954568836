import React, { useState } from "react";
import BreadCrumb from "../../components/global/breadcrumbs/BreadCrumb";
import ShowMessage from "./ShowMessage";
import AddTierFunc from "../../components/course/navigationPath/TriggerAddTier";
import ImportPathFunc from "../../components/course/navigationPath/TriggerImportBtn";
import AddFolderTree from "../../components/course/navigationPath/draggableTree/AddFolderTree";
import AddAssetTree from "../../components/course/navigationPath/draggableTree/AddAssetTree";
import MapAssetModalTrigger from "./MapAssetModalTrigger";
import SaveAndExitButtons from "./SaveAndExitButtons";
import { useSelector } from "react-redux";
import { endpoint } from "../../API/config";
import { useQuery, useQueryClient } from "react-query";
import { apirequest } from "../../API/api";
import { useDispatch } from "react-redux";
import {
  clearDeletedTiers,
  setLearningPathDetails,
  setDeleteOperationPerformedToFalse,
} from "../../redux/features/learningPath/learningPathSlice";
import propTypes from "prop-types";

const LearningPath = ({ courseId, variantId }) => {
  const card = "shadow-forButton rounded-xl bg-white py-6";
  const learningPathArray = useSelector(state => state.learningPathData.learningPathDetails);
  const login_Details = useSelector(state => state?.loginData?.LoginDetails);
  const dispatch = useDispatch();
  const [disableSave, setDisableSave] = useState(true);
  const selectedTierId = useSelector(state => state.learningPathData.selectedLearningPathId);
  const deletedTiers = useSelector(state => state.learningPathData.deletedTiers);
  const queryClient = useQueryClient();

  const addOrderToLearningPath = array => {
    let index = 0;
    let duplicate = [...array];
    let func = arr => {
      let queue = [...arr];
      while (queue.length > 0) {
        let current = queue.shift();
        current["order"] = index++;
        if (current.children && current.children.length > 0) {
          queue.push(...current.children);
        }
      }
    };
    func(duplicate);
    return duplicate;
  };

  const handleLearningPathAPI = () => {
    const praparedData = JSON.parse(JSON.stringify(learningPathArray));
    const orderedLearningPathArray = addOrderToLearningPath(praparedData);
    // post data api for learning path here
    saveLearningPathTiersFull();
    dispatch(setLearningPathDetails(orderedLearningPathArray));
    dispatch(clearDeletedTiers());
    /* Fetch Learning Path data */
    dispatch(setDeleteOperationPerformedToFalse());
    setDisableSave(true);
  };

  /* Add tier full list */
  const addTierList = async () => {
    return apirequest({
      url: `${endpoint.add_LearningPath_FullList.addr}`,
      method: "post",
      data: {
        adminimspin: login_Details.userImsPin,
        courseId,
        data: addOrderToLearningPath(
          JSON.parse(JSON.stringify(learningPathArray)),
        ),
        deleteTiers: deletedTiers,
      },
    });
  };

  const { refetch: saveLearningPathTiersFull } = useQuery(
    endpoint.add_LearningPath_FullList.key,
    addTierList,
    {
      enabled: false,
      onSuccess: data => {
        queryClient.invalidateQueries([
          endpoint.get_LearningPath_Data.key,
          courseId,
          variantId,
        ]);
        alert(data.data.data.message);
        window.location.reload();
      },
      retry: 1,
    },
  );

  const handleEditClick = () => {
    setDisableSave(false);
  };

  return (
    <div className="mx-10 pb-5">
      <div className="mt-6">
        <BreadCrumb type={"editCourse"} />
      </div>
      <h1 className="font-semibold mx-4 mt-3 mb-10">
        {localStorage.getItem("mappedVariant")} -{" "}
        {localStorage.getItem("LIMA code")}
      </h1>
      <div className="flex ml-6">
        <ImportPathFunc learningPathId="0" setDisableSave={setDisableSave} />
        <AddTierFunc
          assetPath="0"
          parentId=""
          parentData={null}
          handleEditClick={handleEditClick}
        />
      </div>

      {/* left side */}
      <div className="grid grid-cols-2 gap-5 mt-7 mb-10">
        <div className={card}>
          {learningPathArray.length > 0 ? (
            <AddFolderTree
              setDisableSave={setDisableSave}
              data={learningPathArray}
            />
          ) : (
            <ShowMessage folder={true} />
          )}
        </div>

        {/* right side */}
        <div className={card}>
          {selectedTierId != null && (
            <div className="flex justify-end px-10">
              <AddTierFunc
                assetPath="1"
                parentId={selectedTierId}
                parentData={null}
                handleEditClick={handleEditClick}
              />
              <MapAssetModalTrigger
                setLearningPath={null}
                setDisableSave={setDisableSave}
              />
            </div>
          )}
          {selectedTierId === null ? (
            <ShowMessage />
          ) : (
            <AddAssetTree
              setDisableSave={setDisableSave}
              parentId={selectedTierId}
            />
          )}
        </div>
      </div>
      {
        <SaveAndExitButtons
          handleLearningPathAPI={handleLearningPathAPI}
          disableSave={disableSave}
        />
      }
    </div>
  );
};

LearningPath.propTypes = {
  setDisableSave: propTypes.func,
  data: propTypes.array,
  courseId: propTypes.string,
  variantId: propTypes.string,
};

export default LearningPath;
