import React, { useState } from "react";
import Header from "../../../components/header/Header";
import { Link } from "react-router-dom";
import SlideshowTable from "../slideshow/SlideshowTable";
import Loader from "../../../components/global/loader/Loader";
import TablePagination from "../../../components/table/TablePagination";
import testIcon from "../../../assets/icons/calendar_active.svg";
// import ArrowUp from "../../../assets/icons/arrow-up.svg";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import TableSearch from "../../table/TableSearch";
import { globalFilter } from "../../global/globalFilter/GlobalFilter";
import FilterFunctionlity from "../../filterFunctionality/FilterFunctionlity";

const Courses = () => {

  const modulesData = globalFilter({name : "Home Page", subModuleName : "Calendar Management"});

  const[getEventsData , setGetEventsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [courseId, setCourseId] = useState([]);
  
  const column = [
    { col: "1", width: "10%" },
    { col: "2", width: "45%" },
    { col: "3", width: "40%" },
    { col: "4", width: "5%" },
  ];

  // API's for Events Listing
  const fetchSlideshowListing = () => {
    return apirequest({
      url: `${endpoint.get_calendarEvent_data.addr}`,
      method: "post",
      data: {
        adminimspin:modulesData.imsPin,
        courseId : ""
      } 
    });
  };
  
  const {data: calendar_Defaultdata , error,isSuccess :is_event_success,status } = useQuery(
    endpoint.get_calendarEvent_data.key,
    fetchSlideshowListing,{
      onSuccess :(res)=>setGetEventsData(res)
    }
  );

  const { data: getCourseData } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method : "post",
      data : {
        "adminimspin":modulesData.imsPin,      
        "courseId" : ""
      }
    }),{
    select : res=>res.data.data.courseList
  });

  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  // const [filteredData,setFilteredData] = useState(dataSource);
  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };
  return (
    <div id="courses">
      <Header />
      {status === "loading" ? (
        <Loader />
      ) : !is_event_success && error?.status !== 404 ? (
        <span>{error?.message}</span>
      ) : (
        <div className="courses">
          <div className="courseTable mt-9">
            <div className="courseTable__head flex justify-between  md:mt-[4%] md-mb-[5%] md:mx-9 pl-[2%]">
              
              <FilterFunctionlity 
                filterText = " Course"
                setFunction = {setGetEventsData}
                dropdownData = {getCourseData}
                url = {endpoint.get_calendarEvent_data.addr}
                payload = {courseId}
                setPayload = {setCourseId}
                setCurrentPage = {setCurrentPage}
                payloadData = {{adminimspin :modulesData.imsPin , courseId : courseId} }
                filterId = "courseId"
                filterValue="courseName"
              />
              <div className="flex justify-end space-x-20">
                <TableSearch 
                  defaultData ={calendar_Defaultdata}
                  setDefaultData ={setGetEventsData}
                  dataKey = "calendarEventName"
                  dataForFilter={calendar_Defaultdata?.data.data.calendarList}
                  name = "Home Page"
                  subModuleName= "Calendar Management"
                  setCurrentPage={setCurrentPage}
                  childModuleName=""
                  placeholder="Search by Event"
                />
              
                <div className="ml-4 flex">
                  {(is_event_success && getEventsData?.data?.data?.global_add_access==="1"|| error?.data.message ==="No Record found")&&
                  <Link
                    to="/HomePage/CalendarManagement/add_event">
                    <button className="ml-6 bg-primary-200 text-white font-medium px-6 rounded-xl hover:opacity-75 shadow-outer font-ibm w-40 h-[56px]">
                      Add Event
                    </button>
                   
                  </Link>}
                </div>
              </div>
            </div>
            <div className="courseTable__body md:ml-[4%] md:mr-[34px] mt-4 shadow-outer pt-3 pl-[2%] pr-[3%] rounded-xl bg-white pb-[2%]">
              {is_event_success &&
              <SlideshowTable
                icon={testIcon}
                column={column}
                currentData={getEventsData?.data?.data?.calendarList.slice(
                  indexOfFirstItem,
                  indexOfLastItem,
                )}
                tags={true}
              />}
            </div>
            <div className="courseTable__footer mx-auto mb-7">
              {is_event_success &&
              <TablePagination
                data={getEventsData?.data?.data?.calendarList}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
                dataPerPage={dataPerPage}
              />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Courses;
