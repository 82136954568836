import React from "react";
import avatar_One from "../../assets/icons/male-avtar3.svg";
import avatar_Two from "../../assets/icons/female-avtar3.svg";
import avatar_Three from "../../assets/icons/avatar-Three.svg";
import avatar_four from "../../assets/icons/avatar-four.svg";
import avatar_Five from "../../assets/icons/avatar-Five.svg";
import avatar_Six from "../../assets/icons/avatar-Six.svg";
import tickMarkimg from "../../assets/icons/tick-circle-active.svg";
import propTypes from "prop-types";

const UserTemplate = ({
  setTickMark,
  tickMark,
  setUSerName,
  setImsPin,
  setUserEmailId,
  userName,
  imsPin,
  userEmailId,
}) => {
  const imgData = [
    { id: 1, image: avatar_One },
    { id: 2, image: avatar_Two },
    { id: 3, image: avatar_Three },
    { id: 4, image: avatar_four },
    { id: 5, image: avatar_Five },
    { id: 6, image: avatar_Six },
  ];
  const handleSelectedImage = (data) => {
    setTickMark(data);
    if (tickMark === data) {
      setTickMark("");
    }
  };

  return (
    <div>
      <section className="flex  ">
        <aside className="space-y-5 w-7/12">
          <div className="space-x-16">
            <label className="font-normal text-lg mr-1">Name </label>
            <input
              value={userName}
              placeholder="Enter Name"
              onChange={(e) => setUSerName(e.target.value)}
              className="bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-8/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
            />
          </div>
          <div className="space-x-16">
            <label className="font-normal text-lg">IMS ID</label>
            <input
              value={imsPin}
              placeholder="Enter IMS ID"
              onChange={(e) => setImsPin(e.target.value)}
              className="bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-8/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
            />
          </div>
          <div className="space-x-14">
            <label className="font-normal text-lg ">Email Id</label>
            <input
              type={"email"}
              value={userEmailId}
              placeholder="Enter Email Id"
              onChange={(e) => setUserEmailId(e.target.value)}
              className="bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-8/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
            />
          </div>
          {/* <div className="flex space-x-8 align-middle">
            <label className="font-normal text-lg mt-3">User Roll</label>
            <Dropdown placeholder="Enter User Roll" options={userRollData} type="singleSelect" width={"w-[29rem]"}  setFieldValue={set_Role_ID} readonly={false} valueReadProduct={rollName} forUserRoll ={true}/>
          </div> */}
        </aside>
        <aside className="w-5/12 flex space-x-7">
          <p className="font-normal text-lg">Select your Avatar</p>
          <div className=" grid grid-cols-3 gap-2 h-10">
            {imgData.map((item) => (
              <div key={item.id}>
                <img
                  key={item.id}
                  className="cursor-pointer"
                  onClick={() => handleSelectedImage(item.image)}
                  src={item.image}
                />
                {tickMark === item.image && (
                  <img src={tickMarkimg} className="mt-[-85px] ml-16" />
                )}
              </div>
            ))}
          </div>
        </aside>
      </section>
    </div>
  );
};

UserTemplate.propTypes = {
  userRollData: propTypes.array,
  setTickMark: propTypes.func,
  tickMark: propTypes.string,
  set_Role_ID: propTypes.func,
  setUserEmailId: propTypes.func,
  setImsPin: propTypes.func,
  setUSerName: propTypes.func,
  userData: propTypes.object,
  forEditUser: propTypes.bool,
  userName: propTypes.string,
  imsPin: propTypes.string,
  userEmailId: propTypes.string,
  rollName: propTypes.string,
};

export default UserTemplate;
