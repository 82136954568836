import React, { useState } from "react";
import Header from "../../components/header/Header";
import { Link } from "react-router-dom";
import SlideshowTable from "../../components/home/slideshow/SlideshowTable";
import TablePagination from "../../components/table/TablePagination";
import TableSearch from "../../components/table/TableSearch";
import DocumentPlusIcon from "../../assets/icons/document.svg";
import BreadCrumb from "../../components/global/breadcrumbs/BreadCrumb";
import Loader from "../../components/global/loader/Loader";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import { globalFilter } from "../../components/global/globalFilter/GlobalFilter";

const ExamListing = () => {

  const modulesData = globalFilter({name : "Courseware", subModuleName : "Master" , childName : "Exams"});

  const [examData , setExamData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  
  const column = [
    { col: "1", width: "5%" },
    { col: "2", width: "40%" },
    { col: "3", width: "" },
    { col: "4", width: "5%" },
  ];

  // API's for Exams Listing
  const ExamListing = () => {
    return apirequest({
      url: `${endpoint.get_exams.addr}`,
      method: "post",
      data: {
        adminimspin:modulesData.imsPin,
      }});
  };

  const {data : examsDefaultData, error,isSuccess :is_exam_success,status } = useQuery(
    endpoint.get_exams.key,
    ExamListing,{
      onSuccess :(res)=>setExamData(res),
      refetchOnMount : true
    });

  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };

  return (
    <div id="courses">
      <Header />
      {status === "loading" ? (
        <Loader />
      ) : !is_exam_success && error?.status !== 404 ? (
        <span>{error?.message}</span>
      ) : (
        <div className="courses">
          <div className="courseTable mt-9">
            <div className="courseTable__head flex justify-between  md:mt-[2%] md-mb-[5%] md:mx-9 pl-[2%]">
              <div className="flex">
                <BreadCrumb />
              </div>

              <div className="ml-4 flex">
                { is_exam_success &&
                <TableSearch 
                  defaultData = {examsDefaultData}
                  setDefaultData ={setExamData}
                  dataKey = "examName"
                  dataForFilter={examsDefaultData?.data.data.examList}
                  name ="Courseware"
                  subModuleName="Master"
                  setCurrentPage={setCurrentPage}
                  childName = "Exams"
                  childModuleName=""
                  placeholder={"Search by Exam"}
                />
                }
                {(examData?.data?.data?.global_add_access==="1" || error?.data.message ==="No Record found")&&
                <Link to="/courseware/master/exam">
                  <button className="ml-6 bg-primary-200 text-white font-medium px-6 rounded-xl hover:opacity-75 shadow-outer font-ibm w-40 h-[56px]">
                    Add New Exam
                  </button>
                </Link>}
              </div>
            </div>
            <div className="courseTable__body md:ml-[4%] md:mr-[34px] mt-4 shadow-outer pl-[2%] pr-[5%] rounded-xl bg-white pb-[2%]">
              {is_exam_success &&
              <SlideshowTable
                icon={DocumentPlusIcon}
                column={column}
                currentData={examData?.data?.data?.examList?.slice(
                  indexOfFirstItem,
                  indexOfLastItem,
                )}
                type={"course"}
              />}
            </div>
            <div className="courseTable__footer mx-auto mb-7">
              {is_exam_success &&
              <TablePagination
                data={examData?.data?.data?.examList}
                dataPerPage={dataPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
              />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamListing;
