import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  LoginDetails: "",
};

export const loginSlice = createSlice({
  name: "LoginData",
  initialState,
  reducers: {
    getLoginDetails: (state, action) => {
      state.LoginDetails = action.payload;
    },
  },
});

export const { getLoginDetails } = loginSlice.actions;
export default loginSlice.reducer;
