import React from "react";
import ToggleYesNo from "../global/fields/ToggleYesNo";
import CalendarModalTrigger from "../home/blogs/calendar/CalendarModalTrigger";
import TimeDropDown from "../home/blogs/Dropdown";
import propTypes from "prop-types";
// import moment from "moment";
// import { apirequest } from "../../API/api";
// import { endpoint } from "../../API/config";
// import { useQuery } from "react-query";

function Basic({
  assessmentType,
  isAdaptive,
  setIsAdaptive,
  predictedCatPercentile,
  setPredictedCatPercentile,
  cerebryTopicId,
  setcerebryTopicId,
  isProctored,
  setIsProctored,
  releaseDate,
  setReleaseDate,
  // startDate,
  // setStartDate,
  endDate,
  setEndDate,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  startSecond,
  setStartSecond,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  endSecond,
  setEndSecond,
  // setAssessmentId,
  slotStartDate,
  setSlotStartDate,
  slotEndDate,
  setSlotEndDate,
}) {

  function convertDateFormat(inputDate) {
    // Split the input date string into an array using "-"
    if(!inputDate || inputDate === "DD : MM : YYYY")
      return "DD : MM : YYYY";
    const dateParts = inputDate.split("-");

    // Rearrange the elements in the desired order (DD-MM-YYYY)
    const day = dateParts[2];
    const month = dateParts[1];
    const year = dateParts[0];

    // Join the elements back together using "-"
    const outputDate = `${day}-${month}-${year}`;

    return outputDate;
  }

  return (
    <div>
      {/* Adaptive practice */}
      <div className="flex mb-8 justify-between ">
        <ToggleYesNo
          title="Adaptive Practice"
          setToggleState={setIsAdaptive}
          toggleState={isAdaptive}
        />
        <div className="mr-32">
          <ToggleYesNo
            title="Show Predicted CAT &#10; percentile for performance"
            setToggleState={setPredictedCatPercentile}
            toggleState={predictedCatPercentile}
            width={250}
            giveGap={true}
          />
        </div>
      </div>

      {/* Cerebry Topic id */}
      {isAdaptive && !isAdaptive && (
        <div className="flex mb-8">
          <label className="inline-block w-[180px] text-left whitespace-nowrap font-normal font-ibm text-lg mt-3 mr-7 ">
            Cerebry Topic ID
          </label>
          <input
            value={cerebryTopicId}
            className="bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent"
            placeholder="Enter the Cerebry Topic ID here"
            name="cerebryTopicId"
            onChange={e => setcerebryTopicId(e.target.value)}
            autoComplete="off"
            disabled={assessmentType === "Benchmarking"}
          />
        </div>
      )}

      {/* Proctored Toggle */}
      <div className="flex mb-8">
        <ToggleYesNo
          title="Proctored"
          setToggleState={setIsProctored}
          toggleState={isProctored}
        />
      </div>

      {/* Release Date */}
      <div className="flex mb-6">
        <label className="w-[180px] font-normal text-lg font-ibm ">
          Release Date
        </label>
        <div className="relative w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 h-10">
          {releaseDate !== "DD : MM : YYYY" && (
            <span
              className="absolute bottom-[5px] text-xs font-medium cursor-pointer left-1/2 text-gray-500"
              onClick={() => setReleaseDate("DD : MM : YYYY")}>
              &#10005;
            </span>
          )}
          <p
            className={`w-full outline-none boder border-gray-200 ${
              releaseDate
                ? "text-base text-secondary-400  font-normal"
                : "text-sm  text-primary-700"
            }`}>
            {releaseDate && convertDateFormat(releaseDate)}
          </p>
          <CalendarModalTrigger setDate={setReleaseDate} />
        </div>
      </div>

      {isProctored && (
        <>
          {/* End Date */}
          <div className="flex ">
            <label className=" font-normal text-lg font-ibm w-[180px] mb-9 ">
              End Date
            </label>
            <div className="relative w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 h-10">
              {endDate !== "DD : MM : YYYY" && (
                <span
                  className="absolute bottom-[5px] text-xs font-medium cursor-pointer left-1/2 text-gray-500"
                  onClick={() => setEndDate("DD : MM : YYYY")}>
                  &#10005;
                </span>
              )}
              <p
                className={`w-full outline-none boder border-gray-200 ${
                  endDate
                    ? "text-base text-secondary-400  font-normal"
                    : "text-sm  text-primary-700"
                }`}>
                {endDate && convertDateFormat(endDate)}
              </p>
              <CalendarModalTrigger setDate={setEndDate} />
            </div>
          </div>

          {/* Slot Booking */}
          <div className=" font-normal text-lg font-ibm mb-8 ">
            Slot Booking
          </div>
          {/* Start Date */}
          <div className="flex mb-6">
            <div className="flex">
              <label className=" font-normal text-lg font-ibm w-[180px] ">
                Start Date
              </label>
              <div className="relative w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 h-10">
                {slotStartDate !== "DD : MM : YYYY" && (
                  <span
                    className="absolute bottom-[5px] text-xs font-medium cursor-pointer left-1/2 text-gray-500"
                    onClick={() => setSlotStartDate("DD : MM : YYYY")}>
                    &#10005;
                  </span>
                )}
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    slotStartDate
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"
                  }`}>
                  {slotStartDate && convertDateFormat(slotStartDate)}
                </p>
                <CalendarModalTrigger setDate={setSlotStartDate} />
              </div>
            </div>
            <div className="flex items-center">
              <div className="ml-32">
                <TimeDropDown
                  minOffset={0}
                  maxOffset={23}
                  text={"Hrs"}
                  selectedValue={+startHour}
                  setSelectedValue={setStartHour}
                />
              </div>
              <div className="ml-2">
                <TimeDropDown
                  minOffset={0}
                  maxOffset={59}
                  text={"Mins"}
                  selectedValue={+startMinute}
                  setSelectedValue={setStartMinute}
                />
              </div>
              <div className="ml-2">
                <TimeDropDown
                  minOffset={0}
                  maxOffset={59}
                  text={"Sec"}
                  selectedValue={+startSecond}
                  setSelectedValue={setStartSecond}
                />
              </div>
            </div>
          </div>
          {/* End Date */}
          <div className="flex">
            <div className="flex ">
              <label className=" font-normal text-lg font-ibm w-[180px] ">
                End Date
              </label>
              <div className="relative w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 h-10">
                {slotEndDate !== "DD : MM : YYYY" && (
                  <span
                    className="absolute bottom-[5px] text-xs font-medium cursor-pointer left-1/2 text-gray-500"
                    onClick={() => setSlotEndDate("DD : MM : YYYY")}>
                    &#10005;
                  </span>
                )}
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    slotEndDate
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"
                  }`}>
                  {slotEndDate && convertDateFormat(slotEndDate)}
                </p>
                <CalendarModalTrigger setDate={setSlotEndDate} />
              </div>
            </div>
            <div className="flex items-center ">
              <div className="ml-32">
                <TimeDropDown
                  minOffset={0}
                  maxOffset={23}
                  text={"Hrs"}
                  selectedValue={+endHour}
                  setSelectedValue={setEndHour}
                />
              </div>
              <div className="ml-2">
                <TimeDropDown
                  minOffset={0}
                  maxOffset={59}
                  text={"Mins"}
                  selectedValue={+endMinute}
                  setSelectedValue={setEndMinute}
                />
              </div>
              <div className="ml-2">
                <TimeDropDown
                  minOffset={0}
                  maxOffset={59}
                  text={"Sec"}
                  selectedValue={+endSecond}
                  setSelectedValue={setEndSecond}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

Basic.propTypes = {
  assessmentType: propTypes.string,
  isAdaptive: propTypes.bool,
  setIsAdaptive: propTypes.func,
  predictedCatPercentile: propTypes.bool,
  setPredictedCatPercentile: propTypes.func,
  cerebryTopicId: propTypes.string,
  setcerebryTopicId: propTypes.func,
  isProctored: propTypes.bool,
  setIsProctored: propTypes.func,
  releaseDate: propTypes.string,
  setReleaseDate: propTypes.func,
  startDate: propTypes.string,
  setStartDate: propTypes.func,
  endDate: propTypes.string,
  setEndDate: propTypes.func,
  slotStartDate: propTypes.string,
  setSlotStartDate: propTypes.func,
  slotEndDate: propTypes.string,
  setSlotEndDate: propTypes.func,
  startHour: propTypes.number,
  setStartHour: propTypes.func,
  startMinute: propTypes.number,
  setStartMinute: propTypes.func,
  startSecond: propTypes.number,
  setStartSecond: propTypes.func,
  endHour: propTypes.number,
  setEndHour: propTypes.func,
  endMinute: propTypes.number,
  setEndMinute: propTypes.func,
  endSecond: propTypes.number,
  setEndSecond: propTypes.func,
  setAssessmentId: propTypes.func,
};

export default Basic;
