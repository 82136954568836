import { useQuery } from "react-query";
import { apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";

export const  useFullTextSearchCustomCall=({payloadData ,enabled,setFilteredata, setCurrentPage})=> {
  return useQuery(endpoint.fullTextSearchApi.key ,()=>apirequest({
    url : `${endpoint.fullTextSearchApi.addr}`,
    method: "post",
    data : payloadData
  }) ,{
    enabled : enabled,
    onSuccess : (res)=>{
      setFilteredata(res);
      setCurrentPage(1);
    },
    onError : err => alert(err.data.message),
    retry : 1
  });
};