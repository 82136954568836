import React, { useState, useEffect } from "react";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import WithModal from "../../global/modal/WithModal/WithModal";
import propTypes from "prop-types";
import SimpleDropDown from "../../global/SimpleDropDown/DropDown";
import DropDown2 from "./DropDown";
import ModalButton from "../../global/modal/ModalTemplate/ModalButton";
import ToggleYesNo from "../../global/fields/ToggleYesNo";
import Toggle from "../../global/fields/Toggle";
import { apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addTier,
  editTier,
  tempEditAssets,
  tempSetAssets,
} from "../../../redux/features/learningPath/learningPathSlice";
import { UploadFileToAWS } from "../../../AWS/uploadFile";
import Loader from "../../global/loader/Loader";

const content = {
  id: 1,
  title: "New Tier",
};

// const mg = "mr-[40px]";

function AddTierModal({
  closeModal,
  assetPath,
  parentId,
  parentData,
  editing,
  handleEditClick,
}) {

  const dispatch = useDispatch();
  const login_Details = useSelector(state => state?.loginData?.LoginDetails);
  const courseDetails = useSelector(state => state?.courseData?.courseDetails);
  const variantByCourseDetails = useSelector(state => state.variantsByCourseData.variantsByCourse);
  const deletedTiers = useSelector(state => state.learningPathData.deletedTiers);
  const module = useSelector(state => state.learningPathData.module);
  const [tierFormDetails, setTierFormDetails] = useState({
    assetPath,
    courseId: courseDetails.courseId.toString() || "",
    children: parentData?.children || [],
    createdBy: login_Details.userImsPin,
    examId: parentData?.examId || "",
    examName: parentData?.examName || "",
    isActive: parentData?.isActive || "0",
    isChannel: parentData?.isChannel || "0",
    isExam: parentData?.isExam || "0",
    isHeader: parentData?.isHeader || "0",
    learningPathId: parentData?.learningPathId || "",
    modifiedBy: login_Details.userImsPin,
    name: parentData?.name || "",
    parentId: parentData?.parentId || parentId,
    type: module || parentData?.type || "",
    url: parentData?.url || "",
    variantId: variantByCourseDetails.variantId || "",
    isThumbnail: parentData?.isThumbnail || "0",
    thumbnailLink: parentData?.thumbnailLink || "",
    isLearningUnitClassFlow: parentData?.isLearningUnitClassFlow || "0"
  });
  const [addTierAPIFlag, setAddTierAPIFlag] = useState(false);
  const [formErrors, setFormErrors] = useState({
    nameError: false,
    hyperlinkError: false,
    lastTierError: false,
    examError: false,
  });
  const regex = /.*[a-zA-Z]+.*/gm;
  const urlRegex =
    /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;

  const handleChange = e => {
    if (e.target.value < 1) {
      setFormErrors({
        ...formErrors,
        nameError: true,
      });
    } else if (!e.target.value.match(regex)) {
      setFormErrors({
        ...formErrors,
        nameError: true,
      });
    } else {
      setFormErrors({
        ...formErrors,
        nameError: false,
      });
    }
    setTierFormDetails({
      ...tierFormDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleHyperLinkChange = e => {
    if (e.target.value < 1) {
      setFormErrors({
        ...formErrors,
        hyperlinkError: true,
      });
    } else if (!e.target.value.match(urlRegex)) {
      setFormErrors({
        ...formErrors,
        hyperlinkError: true,
      });
    } else {
      setFormErrors({
        ...formErrors,
        hyperlinkError: false,
      });
    }
    setTierFormDetails({
      ...tierFormDetails,
      type: "Hyperlink",
      url: e.target.value,
    });
  };

  const setToggleChannelState = (state, type) => {
    if (type === "isChannel") {
      setTierFormDetails({
        ...tierFormDetails,
        isChannel: state === true ? "1" : "0",
      });
    } else if (type === "isExam") {
      setTierFormDetails({
        ...tierFormDetails,
        isExam: state === true ? "1" : "0",
      });
    } else if (type === "isThumbnail") {
      setTierFormDetails({
        ...tierFormDetails,
        isThumbnail: state === true ? "1" : "0",
      });
    } else if (type === "isLearningUnitClassFlow"){
      setTierFormDetails({
        ...tierFormDetails,
        isLearningUnitClassFlow: state === true ? "1" : "0",
      });
    }
    else {
      setTierFormDetails({
        ...tierFormDetails,
        isHeader: state === true ? "1" : "0",
      });
    }
  };

  const setActiveTab = state => {
    let isActive;
    if (state === "active") {
      isActive = "1";
    } else {
      isActive = "0";
    }
    setTierFormDetails({
      ...tierFormDetails,
      isActive,
    });
  };

  function changeHeaderType(type) {
    setTierFormDetails({ ...tierFormDetails, type });
    setFormErrors({
      ...formErrors,
      lastTierError: false,
    });
  }

  /*Click Save Button */
  const handleClick = () => {
    if (!tierFormDetails.name.match(regex)) {
      setAddTierAPIFlag(false);
      setFormErrors({
        ...formErrors,
        nameError: true,
      });
    } else if (tierFormDetails.type === "" && parentId === "") {
      setAddTierAPIFlag(false);
      setFormErrors({
        ...formErrors,
        lastTierError: true,
      });
    } else if (tierFormDetails.type === "Hyperlink") {
      if (!tierFormDetails.url.match(urlRegex)) {
        setAddTierAPIFlag(false);
        setFormErrors({
          ...formErrors,
          hyperlinkError: true,
        });
      } else {
        setAddTierAPIFlag(true);
      }
    } else if (
      tierFormDetails.isExam === "1" &&
      tierFormDetails.examId === ""
    ) {
      setAddTierAPIFlag(false);
      setFormErrors({
        ...formErrors,
        examError: true,
      });
    } else {
      setAddTierAPIFlag(true);
    }
    handleEditClick();
  };

  useEffect(() => {
    if (tierFormDetails.isExam === "0") {
      setTierFormDetails({ ...tierFormDetails, examId: "", examName: "" });
    }
  }, [tierFormDetails.isExam]);

  useEffect(() => {
    if (addTierAPIFlag) {
      if (editing) {
        fetchEditTierAPI();
      } else {
        const unsavedTier = deletedTiers.findIndex(
          tier => tier.name === tierFormDetails.name,
        );
        if (unsavedTier === -1) fetchAddTierAPI();
        else {
          alert(" Conflict! Save changes and try again.");
          closeModal();
        }
      }
    }
  }, [addTierAPIFlag]);

  /* Add Tier API */
  const fetch_addTierPayload = async () => {
    const payload = Object.assign({}, tierFormDetails);
    payload.name = payload.name.trim();
    delete payload.children;
    delete payload.modifiedBy;
    delete payload.learningPathId;
    return apirequest({
      url: `${endpoint.add_LearningPath_Tier.addr}`,
      method: "post",
      data: payload,
    });
  };
  
  const { refetch: fetchAddTierAPI } = useQuery(
    `${endpoint.add_LearningPath_Tier.key}`,
    fetch_addTierPayload,
    {
      enabled: false,
      onSuccess: data => {
        if (assetPath === "1") {
          let obj = {
            selectedData: [data.data.data[0]],
            addingToTier: false,
          };
          dispatch(tempSetAssets(obj));
        }
        dispatch(addTier(data.data.data[0]));
        closeModal();
        alert(data.data.message);
      },
      onError: err => {
        alert(err.data.message);
      },
      retry: 1,
    },
  );

  /*  Edit Tier API */
  const fetch_editTierPayload = async () => {
    return apirequest({
      url: `${endpoint.edit_LearningPath_Tier.addr}`,
      method: "put",
      data: {
        ...tierFormDetails,
      },
    });
  };
  const { refetch: fetchEditTierAPI } = useQuery(
    `${endpoint.edit_LearningPath_Tier.key}`,
    fetch_editTierPayload,
    {
      enabled: false,
      onSuccess: data => {
        if (assetPath === "1") {
          dispatch(tempEditAssets(tierFormDetails));
        }
        dispatch(editTier(tierFormDetails));
        closeModal();
        alert(data.data.message);
      },
      onError: err => {
        alert(err.data.message);
      },
      retry: 1,
    },
  );

  /* Exam API call */
  const fetch_get_exams_payload = () => {
    return apirequest({
      url: `${endpoint.get_exams.addr}`,
      method: "post",
      data: {
        // adminimspin:adminimspin,
        adminimspin: login_Details.userImsPin,
      },
    });
  };
  const { data: getSlideShowData, isSuccess: is_get_exam_success } = useQuery(
    endpoint.get_exams.key,
    fetch_get_exams_payload,
  );

  /* Header module api call */
  function fetchHeaderModules() {
    return apirequest({
      url: `${endpoint.getLearningPathModules.addr}`,
      method: "get",
    });
  }
  const {
    data: headerModuleData,
    isSuccess: isHeaderModulesSuccess,
    isLoading,
  } = useQuery(endpoint.getLearningPathModules.key, fetchHeaderModules, {
    select: data => data.data.data,
  });

  function handleBrowse(event) {
    event.preventDefault();
    setTierFormDetails({
      ...tierFormDetails,
      thumbnailLink: event.target?.files[0].name,
    });
    // setBrowseLink(event.target?.files[0].name);
    const fileDate = new Date()
      .toLocaleString({ hour12: true })
      .toString()
      .split("/");
    if (event.target?.files[0]) {
      const val = UploadFileToAWS({
        key: "addSlideShowUpload",
        file: event.target?.files[0],
        path:
          "Asset/AddSlideShow/" +
          fileDate[2].toString().split(",")[0] +
          "/" +
          event.target?.files[0].name.toString().split(".")[0] +
          "_" +
          login_Details.userImsPin +
          "_" +
          fileDate[2].toString().split(/[, ]+/)[1] +
          "." +
          event.target?.files[0].name.toString().split(".")[1],
      });
      {
        val.then(item =>
          setTierFormDetails({
            ...tierFormDetails,
            thumbnailLink: item,
          }),
        );
      }
    } else {
      alert("No files available!");
    }
  }

  return (
    <div
      className={`flex flex-col ${
        isLoading ? " h-[607px] " : "py-3 px-8"
      }  w-[805px] h-auto rounded-2xl `}>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex justify-between pl-[18rem]">
            <p className="font-ibm text-lg text-secondary-600 font-medium text-center">
              {parentData === null ? content.title : "Edit Tier"}
            </p>
            <Cancel className="cursor-pointer" onClick={closeModal} />
          </div>
          <div className="flex flex-col justify-start font-ibm w-full">
            <div className="flex items-center w-full mb-5 ">
              <label className="inline-block text-left whitespace-nowrap font-normal font-ibm text-lg w-56 ">
                Title{" "}
              </label>
              <div className="flex-1">
                <input
                  className={`w-9/12 border-2  ${
                    formErrors.nameError
                      ? "border-red-300"
                      : "border-secondary-300 font-semibold text-XL "
                  } rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none placeholder-bold `}
                  type="text"
                  placeholder="Enter Title"
                  required
                  name="name"
                  onChange={handleChange}
                  autoComplete="off"
                  value={tierFormDetails.name}
                />
                {formErrors.nameError && (
                  <p className=" text-xs text-red-400 mt-1 ml-2 ">
                    * Enter a valid title{" "}
                  </p>
                )}
              </div>
            </div>
            <div className="flex mb-5 items-center ">
              {parentId === "" && isHeaderModulesSuccess && (
                <>
                  <p className="text-lg w-56 ">Choose Module</p>
                  {isHeaderModulesSuccess && (
                    <div className="w-[48%]">
                      <SimpleDropDown
                        value={tierFormDetails.type}
                        error={formErrors.lastTierError}>
                        <SimpleDropDown.Options>
                          {headerModuleData.map(option => (
                            <SimpleDropDown.Option
                              key={option.moduleId}
                              option={option.moduleName}
                              setValue={changeHeaderType}
                            />
                          ))}
                        </SimpleDropDown.Options>
                      </SimpleDropDown>
                    </div>
                  )}
                </>
              )}
            </div>
            {tierFormDetails.type === "Hyperlink" && (
              <div className="mb-5 w-[48%] ">
                <input
                  className={` w-full border-2 ${
                    formErrors.hyperlinkError
                      ? "border-red-300"
                      : "border-secondary-300 font-semibold text-XL "
                  } rounded-2xl pl-4 pr-5 py-3 border-solid  px-2  focus:outline-none placeholder-bold font-semibold text-XL focus:placeholder-transparent`}
                  type="text"
                  placeholder="Enter hyperlink URL"
                  required
                  name="url"
                  value={tierFormDetails.url}
                  onChange={handleHyperLinkChange}
                />
                {formErrors.hyperlinkError && (
                  <p className=" text-xs text-red-400 mt-1 ml-2 ">
                    * Enter a valid URL{" "}
                  </p>
                )}
              </div>
            )}
            <div className="flex justify-start items-start mb-5">
              <ToggleYesNo
                title={"Is this an Exam name?"}
                setToggleState={setToggleChannelState}
                toggleState={tierFormDetails.isExam === "1"}
                type={"isExam"}
                width={224}
              />
              <div className="w-[48%]">
                {tierFormDetails.isExam == "1" && is_get_exam_success && (
                  <DropDown2
                    placeholder={"Select Exam Name"}
                    options={getSlideShowData?.data?.data?.examList}
                    type={"singleSelect"}
                    title={tierFormDetails.examName}
                    setExamValue={setTierFormDetails}
                    error={formErrors.examError}
                    setError={setFormErrors}
                  />
                )}
              </div>
            </div>
            <div className=" flex mb-5">
              <ToggleYesNo
                title={"Does this have a thumbnail?"}
                setToggleState={setToggleChannelState}
                toggleState={tierFormDetails.isThumbnail === "1"}
                type={"isThumbnail"}
                width={224}
              />
              {tierFormDetails.isThumbnail === "1" && (
                <>
                  <input
                    required
                    type="text"
                    name="url"
                    className="focus:outline-none border-2 border-secondary-300 px-2 py-3 rounded-lg "
                    placeholder="Paste the image URL"
                    value={tierFormDetails.thumbnailLink}
                    readOnly={true}
                  />
                  <label className=" bg-primary-200 flex items-center justify-center px-10 py-3 ml-2 rounded-xl text-white text-sm font-semibold cursor-pointer font-ibm">
                    <input
                      className="hidden "
                      type="file"
                      multiple
                      onChange={handleBrowse}
                      accept=".webp"
                    />
                    Browse
                  </label>
                </>
              )}
            </div>
            <div className="flex justify-start items-center mb-5">
              <ToggleYesNo
                title={"Is this last tier in Header?"}
                setToggleState={setToggleChannelState}
                toggleState={tierFormDetails.isHeader === "1"}
                type={"isHeader"}
                width={224}
              />
              {parentId !== "" && isHeaderModulesSuccess && tierFormDetails.isHeader === "1" && (
                <div className="w-[48%]">
                  <SimpleDropDown
                    value={tierFormDetails.type}
                    error={formErrors.lastTierError}>
                    <SimpleDropDown.Options>
                      {headerModuleData.map(option => (
                        <SimpleDropDown.Option
                          key={option.moduleId}
                          option={option.moduleName}
                          setValue={changeHeaderType}
                        />
                      ))}
                    </SimpleDropDown.Options>
                  </SimpleDropDown>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-start mb-5">
            <ToggleYesNo
              title={"Is this Channel tier?"}
              setToggleState={setToggleChannelState}
              toggleState={tierFormDetails.isChannel === "1"}
              type={"isChannel"}
              width={224}
            />
          </div>
          <div className="flex justify-start mb-5">
            <ToggleYesNo
              title={"Class flow ?"}
              setToggleState={setToggleChannelState}
              toggleState={tierFormDetails.isLearningUnitClassFlow === "1"}
              type={"isLearningUnitClassFlow"}
              width={224}
            />
          </div>
          <div className="flex justify-start mb-5">
            <Toggle
              activeTab={
                tierFormDetails.isActive === "1" ? "active" : "inactive"
              }
              title={"This Tier is"}
              setActiveTab={setActiveTab}
            />
          </div>
          <div className="flex justify-center">
            <div className="w-32">
              <ModalButton
                modalButtonValue="Save"
                modalButtonFunctions={[handleClick]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

AddTierModal.propTypes = {
  closeModal: propTypes.func,
  assetPath: propTypes.string,
  parentId: propTypes.string,
  learningPathAssetId: propTypes.string,
  parentData: propTypes.object,
  editing: propTypes.bool,
  handleEditClick: propTypes.func,
  learningPathData: propTypes.array,
  setLearningPathData: propTypes.func,
};

export default WithModal(AddTierModal);
