import React from "react";
import propTypes from "prop-types";
import BrowseLPcontainer from "./browseOnImport/BrowseLPcontainer";
import { useDispatch } from "react-redux";
import { clearImportData } from "../../../redux/features/learningPath/importLearningPathSlice";

const TriggerImportBtn = () => {
  return (
    <div className="font-ibm text-black font-normal px-6 rounded-xl hover:opacity-75 shadow-forButton text-lg py-[9px] mr-5 cursor-pointer">
      Import Learning Path
    </div>
  );
};

function ImportPathFunc({ learningPathId, setDisableSave }) {
  const dispatch = useDispatch();
  function clearImportDataOnOpen(){
    dispatch(clearImportData());
  }
  return (
    <BrowseLPcontainer
      Trigger={TriggerImportBtn}
      onOpenFunctions={[clearImportDataOnOpen]}
      learningPathId={learningPathId}
      setDisableSave={setDisableSave}
    />
  );
}
ImportPathFunc.propTypes = {
  learningPathId: propTypes.string,
  setDisableSave: propTypes.func,
};
export default ImportPathFunc;
