import React from "react";
import propTypes from "prop-types";
import testIcon from "../../../assets/icons/test.svg";
import video from "../../../assets/icons/video.svg";
import quiz from "../../../assets/icons/quiz.svg";
import Tooltip from "../../global/tooltip/Tooltip";
import myTask from "../../../assets/icons/myTask-active.svg";
import { Link } from "react-router-dom";
import submitted from "../../../assets/icons/submitted.svg";
import { useDispatch } from "react-redux";
import checkBoxActive from "../../../assets/icons/checkbox-active.svg";
import checkBoxInactive from "../../../assets/icons/checkbox-inactive.svg";
import { setAssetData } from "../../../redux/features/editAssetSlice/editAssetSlice";
const TableByIcon = ({
  column,
  currentData,
  isforAddConentRepo,
  selectedData,
  setSelectedData,
}) => {
  return (
    <>
      <table className="border-separate w-full">
        <tbody className="table-spacing">
          {currentData != null &&
            currentData?.map((data) => (
              <MappedData
                key={data.assetId}
                id={data.assetId}
                data={data}
                isforAddConentRepo={isforAddConentRepo}
                column={column}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            ))}
          {(currentData === null || currentData?.length === 0) && (
            <tr>
              <td>
                <p className="py-20 px-10 text-center text-xl font-ibm opacity-80">
                  No Assets Found!
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

const MappedData = ({
  data,
  column,
  isforAddConentRepo,
  selectedData,
  setSelectedData,
}) => {
  const dispatch = useDispatch();
  const tooltipText = {
    navgPathText: "Map to Variants",
  };

  const handleImageIcon = (selectedAsset) => {
    const curr = (prev) => {
      const exist = prev.some(
        (asset) => asset.assetId === selectedAsset.assetId
      );
      if (exist) {
        return prev.filter(
          (prevItem) => prevItem.assetId !== selectedAsset.assetId
        );
      } else {
        return [
          ...prev,
          {
            assetId: selectedAsset.assetId,
            assetName: selectedAsset.assetName,
            assetType: selectedAsset.assetType,
            isActive: selectedAsset.isActive,
          },
        ];
      }
    };
    // changing current data compared to previous data
    setSelectedData((prev) => curr(prev));
  };
  
  return (
    // <div className="h-20">
    <div key={data.assetId} className="mb-10 flex justify-between">
      <div className=" flex " width={column[0]["width"]}>
        <div className="mr-4">
          {isforAddConentRepo && (
            <img
              src={
                selectedData.find((prev) => prev.assetId === data.assetId)
                  ? checkBoxActive
                  : checkBoxInactive
              }
              onClick={() => handleImageIcon(data)}
              className="cursor-pointer"
            />
          )}
        </div>
        <div className="flex">
          <img
            className={`h-6 mr-5 ${
              data?.assetType == "Assessment" ? "-ml-1" : ""
            }`}
            src={
              data?.assetType == "Video"
                ? video
                : data?.assetType == "Pdf" || data?.assetType == "HTML"
                ? quiz
                : testIcon
            }
          />
          <div className=" block">
            <p className="text-xl mb-3">{data?.assetName}</p>
            {data?.tagNames?.map((tag, i) => (
              <span
                className="mr-2 bg-gray-100 px-3 py-2 rounded-2xl text-sm capitalize"
                key={i}
              >
                {tag}
              </span>
            ))}
          </div>
        </div>
      </div>
      {!isforAddConentRepo && (
        <div className="align-top pt-3 pl-3" width={column[1]["width"]}>
          <div className="flex cursor-pointer items-center  space-x-10">
            <>
              <div className="rounded-full shadow-forButton flex p-[14px] bg-white">
                <img src={myTask} data-tip data-for="classFlow" />
                {
                  <Tooltip
                    multiline={false}
                    id="classFlow"
                    place="bottom"
                    text={tooltipText.navgPathText.toString()}
                    offset={{ bottom: 10, right: 10 }}
                  />
                }
              </div>
            </>
            <Link
              to={{
                pathname: `/Courseware/ContentRepository/${localStorage.getItem(
                  "breadcrumbs"
                )}/edit-Asset/${data?.assetId}`,
                state: { data: data },
              }}
            >
              <img
                className="h-6"
                src={submitted}
                onClick={() => {
                  dispatch(setAssetData(data));
                }}
              />
            </Link>
            {/* <div className="z-50 rounded-full shadow-forButton flex p-[9px] relative bg-white ">
            <img src={deleteinactive}/></div> */}
          </div>
        </div>
      )}
    </div>
  );
};

MappedData.propTypes = {
  data: propTypes.any,
  isforAddConentRepo: propTypes.bool,
  column: propTypes.any,
  index: propTypes.number,
  selectedData: propTypes.array,
  setSelectedData: propTypes.func,
  id: propTypes.string,
};

TableByIcon.propTypes = {
  column: propTypes.array, //for adding width for table
  currentData: propTypes.array, //for get data from parent component
  isforAddConentRepo: propTypes.bool, //for conditional rendering for add repo content modal,
  selectedData: propTypes.array,
  setSelectedData: propTypes.func,
};
export default React.memo(TableByIcon);
