import React, { useState } from "react";
import Header from "../../../components/header/Header";
import testIcon from "../../../assets/icons/test.svg";
import TablePagination from "../../../components/table/TablePagination";
import CourseTable from "../../../components/table/CourseTable";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import TableSearch from "../../../components/table/TableSearch";
import { useQuery } from "react-query";
import { apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";
import { globalFilter } from "../../../components/global/globalFilter/GlobalFilter";
import FilterFunctionlity from "../../../components/filterFunctionality/FilterFunctionlity";
import Loader from "../../../components/global/loader/Loader";

const Variant = () => {

  const [filterByActiveStatus, setFilterByActivestatus] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [courseId, setCourseId] = useState([]);

  const modulesData = globalFilter({name : "Variants", subModuleName : "Variant Management"});

  const column = [
    { col: "0", width: "40%" },
    { col: "1", width: "20%" },
    { col: "2", width: "25%" },
  ];
  const {
    data: variantDatas,
    error,
    isError,
    isFetching,
    refetch: variant_refetch,
  } = useQuery(
    endpoint.variant_get_data.key,
    () => {
      return apirequest({
        url: `${endpoint.variant_get_data.addr}`,
        method: "post",
        data: {
          adminimspin: modulesData.imsPin,
          courseId : ""
        },
      });
    },
    {
      onSuccess: (res) => {
        setFilterByActivestatus(res);
      },
      retry : 1,
    }
  );

  const { data: getCourseData } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method : "post",
      data : {
        "adminimspin":modulesData.imsPin,      
        "courseId" : ""
      }
    }),{
    select : res=>res.data.data.courseList
  });
  
  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;

  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };

  return (
    <div>
      <Header />
      {isError && error?.status !== 404 ? <span>{error?.message}</span> : isFetching  ? <Loader /> :  (
        <div>
          <div className="mt-7 mb-5 ml-10 mr-6 flex justify-between">
            <FilterFunctionlity 
              filterText = " Course"
              setFunction = {setFilterByActivestatus}
              dropdownData = {getCourseData}
              endpointKey = {endpoint.variant_get_data.key}
              url = {endpoint.variant_get_data.addr}
              payload = {courseId}
              setPayload = {setCourseId}
              setCurrentPage = {setCurrentPage}
              payloadData = {{adminimspin :modulesData.imsPin , courseId : courseId} }
              filterId = "courseId"
              filterValue="courseName"
            />
            <section className="flex space-x-10 mr-4">
              <TableSearch
                defaultData ={variantDatas}
                setDefaultData = {setFilterByActivestatus}
                dataKey = "variantName"
                dataForFilter = {variantDatas?.data.data.variantList}
                name = "Variants"
                subModuleName = "Variant Management"
                setCurrentPage = {setCurrentPage}
                childModuleName = ""
                placeholder="Search by Variant"
              />
              
              {/* </TableSearch> */}
              {(variantDatas?.data?.data?.global_add_access === "1" || (error?.data.message ==="No Record found")) && (
                <Link
                  className="text-white hover:text-white mt-1"
                  to="/Variants/VariantManagement/Variant-List/add-Variant"
                >
                  <div className="ml-6 bg-primary-200 text-white font-normal font-ibm px-6 pt-3 pb-2 rounded-xl hover:opacity-75 shadow-outer text-lg">
                    Add Variant
                  </div>
                </Link>
              )}
            </section>
          </div>
          <div className="m-9 shadow-outer rounded-2xl pb-2 ">
            <CourseTable
              isForVarientComp={true}
              currentData={filterByActiveStatus?.data?.data?.variantList?.slice(
                indexOfFirstItem,
                indexOfLastItem
              )}
              column={column}
              tableHead={true}
              colIcon={testIcon}
              refetch_Calls={variant_refetch}
              dataForSorting = {filterByActiveStatus}
              setFunction ={setFilterByActivestatus}
              defaultData = {variantDatas}
            />
          </div>
          <TablePagination
            data={filterByActiveStatus?.data?.data?.variantList}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            paginate={paginate}
            dataPerPage={dataPerPage}
          />
        </div>
      )}
    </div>
  );
};

Variant.propTypes = {
  isShadow: propTypes.bool,
};
export default Variant;
