import React from "react";
import { useRef } from "react";

const CriteriaForm = () => {
  const inputRef = useRef();

  return (
    <div>
      <div className="mb-4 pl-10 flex gap-10 pt-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Course Name*
        </label>
        <input
          className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-black"
          type="text"
          name="courseName"
          placeholder="CAT"
          //   onChange={handleChange}
          required
          autoComplete="off"
        />
      </div>
      <div className="mb-4 pl-10 flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Category*
        </label>
        <input
          className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-black"
          type="text"
          name="FirstCall"
          placeholder="Type the  value and press Enter"
          //   onChange={handleChange}
          required
          autoComplete="off"
        />
      </div>
      <div className="mb-4 pl-10 flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Exam*
        </label>
        <input
          className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-black"
          type="text"
          name="FinalCallParameters"
          placeholder=""
          //   onChange={handleChange}
          required
          autoComplete="off"
        />
      </div>
      <div className="mb-4 pl-10 flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Parameters to be displayed* <br />
        </label>
        <input
          className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-black"
          type="text"
          name="FirstCall"
          placeholder="Type the  value and press Enter"
          //   onChange={handleChange}
          required
          autoComplete="off"
        />
      </div>
      <div className="mb-4 pl-10 flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Upload CSV*
        </label>
        <div className="container justify-center">
          <div className="relative pl-5">
            <input
              className="ml-20 relative bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-9/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
              type="text"
              name="UploadCSV"
              placeholder="No File Selected"
              //   onChange={handleChange}
              required
              autoComplete="off"
            />
            <div className="absolute flex top-0 right-2">
              <button
                onClick={() => {
                  console.log(inputRef);
                  inputRef.current.click();
                }}
                className="super-btn px-8 py-3 bg-primary-300 text-white  h-[50px] rounded-2xl shadow-outer "
              >
                Choose File
              </button>
              <input ref={inputRef} type="file" hidden />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-5 space-x-5 pt-20">
        <button className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer cursor-pointer">
          Save
        </button>
        <button className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer cursor-pointer">
          Exit
        </button>
      </div>
    </div>
  );
};
export default CriteriaForm;
