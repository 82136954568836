import React, {useState } from "react";
import propTypes from "prop-types";
import calendar from "../../assets/icons/calendar-inactive.svg";
import submitted from "../../assets/icons/submitted-no-bg.svg";
// import deleteinactive from "../../assets/icons/delete-inactive.svg";
import classFlow from "../../assets/icons/classFlow.svg";
import learningPath from "../../assets/icons/learningPath.svg";
import { Link, useHistory } from "react-router-dom";
import Tooltip from "../global/tooltip/Tooltip";
import { useDispatch } from "react-redux";
import { setCourseDetails } from "../../redux/features/courseware/courseSlice";
import { setVariantsByCourseData } from "../../redux/features/variantsByCourse/variantsByCourseSlice";
import { setVariantObj } from "../../redux/features/variant/variantSlice";
import { setContentRepositoryId } from "../../redux/features/contentRepository/contentRepoSlice";
import user_image from "../../assets/icons/interactive-classes.svg";
import { setaccess_management_UserData } from "../../redux/features/access_Management/access_ManagementSlice";
import { setadvertiseMentdata } from "../../redux/features/advertisement/advertisementSlice";
// import { endpoint } from "../../API/config";
// import { apirequest } from "../../API/api";
// import { useQuery } from "react-query";

const CourseTable = ({
  column,
  currentData,
  colIcon,
  tableHead,
  isforAddConentRepo,
  iconHeight,
  isForVarientComp,
  setContentRepoId, //to get the content repo id ,
  dataForSorting,  //data for sorting the data
  setFunction,  // function to set the sorted data
  defaultData //default data to set after sorting
}) => {
  const dispatch = useDispatch();

  let history = useHistory();

  const thClassName = "text-lg font-semibold font-ibm";
  // const [currentData,setcurrentData] = useState(currentData);
  const [order, setOrder] = useState("ASC");
  const tooltipText = {
    navgPathText: "Navigation Path",
  };
  const tooltip2Text = {
    classFlowText: "Class Flow",
  };
  const handleSort = (col) => {
    
    if(dataForSorting){
      console.log("sorting functionlity");
      if(order === "ASC"){
        let sorting_DATA = dataForSorting?.data?.data.variantList;
        setFunction({data:{data :{variantList:[...sorting_DATA].sort((a,b)=>a.limaCode - b.limaCode)}}});
        setOrder("DESC");
      }else{     
        setFunction(defaultData);
        setOrder("ASC");
      }
    }else{
      if (order == "ASC") {
        setOrder("DESC");
        currentData.sort((a, b) => (a[col] > b[col] ? 1 : -1));
      } else if (order == "DESC") {
        setOrder("ASC");
        currentData.sort((a, b) => (a[col] < b[col] ? 1 : -1));
      }
    }
  };
  /* To store Course Data and use it on Its detail Page */
  const setValuesToLocalStorage = (cData, type) => {
    if (type == "course") {
      let cName = cData.courseName
        ? cData.courseName
        : cData.contentRepositoryName;
      let cProduct =
        cData.productGroupName != "0" && " - " + cData.productGroupName;
      let result = cName?.concat(cProduct);
      localStorage.setItem("breadcrumbs", result);
    } else {
      localStorage.setItem("mappedVariant", cData?.variantName);
      localStorage.setItem("LIMA code", cData.limaCode);
    }
  };

  /* Delete operation using Delete API*/
  // var courseId = "";
  // const {refetch:delete_Refetch} =  useQuery(endpoint.delete_course.key,()=>{
  //   apirequest({
  //     url:`${endpoint.delete_course.addr}`,
  //     method: "PUT",
  //     data:{
  //       "requestType":"delete",
  //       "courseId": courseId}
  //   });
  // },{
  //   enabled:false,
  //   onSuccess:()=>{
  //     refetch_Calls();
  //   },
  //   onError : ()=>{
  //     refetch_Calls();
  //   }
  // });

  // Query to delete api for content repository===========>
  // const {refetch : deleteContentRepo} = useQuery(endpoint.delete_content_repo.key,()=>{
  //   apirequest({
  //     url : `${endpoint.delete_content_repo.addr}`,
  //     method: "PUT",
  //     data: {
  //       "requestType":"delete",
  //       "contentRepositoryId": courseId
  //     }
  //   });
  // },{
  //   enabled: false,
  // });

  /* End Delete operation*/
  // const handleDeleteCourse =(data)=>{
  //   courseId= data ;
  //   delete_Refetch();
  // };
  // const funcToDeleteContentRepoData =(id)=>{
  //   courseId = id;
  //   deleteContentRepo();
  // };

  // query for deleting the variant data
  // const{refetch :deleteVariant} = useQuery(endpoint.delete_variant_data.key, ()=>{
  //   apirequest({
  //     url: `${endpoint.delete_variant_data.addr}`,
  //     method: "PUT",
  //     data: {
  //       "requestType":"delete",
  //       "variantId": courseId
  //     }
  //   });
  // },{
  //   enabled : false,
  //   onError : ()=>refetch_Calls(),
  //   onSuccess: ()=>refetch_Calls()
  // });
  // const handleDeleteVariantFunction =(id)=>{
  //   courseId = id;
  //   deleteVariant();
  //   refetch_Calls();
  // };

  const onclickHandler = (data) => {
    if ("userId" in currentData[0]) {
      dispatch(setaccess_management_UserData(data));
      history.push("/My-Profile");
    }
  };
  // removed asset call from course table and this api will call from asset listing page 
  const handleClickForMapAssetModal = (id) => {
    setContentRepoId(id);
  };

  const  handleRoute  = (key)=>{
    history.push(`/Advertisement Management/:${key.courseName}`);
  };
  

  return (
    <>
      <table
        className={`border-separate w-full ${tableHead == true ? "" : ""}`}
      >
        {tableHead && (
          <thead className="shadow-outer w-full h-[4rem] top-0 z-50 text-left">
            {tableHead == true && (
              <tr className="w-full">
                <th
                  className={thClassName + " pl-5"}
                  width={column[0]["width"]}
                >
                  Variant name
                </th>
                <th className={thClassName} width={column[1]["width"]}>
                  LIMA Code
                  <span
                    className="ml-1 cursor-pointer font-medium relative"
                    onClick={() => handleSort("limaCode")}
                  >
                    <span className="absolute left-0 opacity-40">&#8593;</span>
                    <span className="absolute left-2 opacity-40">&#8595;</span>
                  </span>
                </th>
                <th className={thClassName + ""} width={column[2]["width"]}>
                  Courses
                </th>
                <th className={thClassName} width={column[0]["width"]}></th>
              </tr>
            )}
          </thead>
        )}
        <tbody className="table-spacing">
          {currentData?.length == 0 ? (
            <tr>
              <td
                colSpan={3}
                className="py-20 px-10 text-center text-xl font-ibm opacity-80"
              >
                No Data Found!
              </td>
            </tr>
          ) : (
            <>
              {currentData?.map((key,index) => (
                <tr
                  key={index}>
                  <td
                    className="align-top pt-4 pl-3"
                    width={column[0]["width"]}
                  >
                    <div
                      className={`flex ${
                        isforAddConentRepo && "cursor-pointer"
                      }`}
                      onClick={() =>
                        isforAddConentRepo &&
                        handleClickForMapAssetModal(key.contentRepositoryId)
                      }
                    >
                      <img
                        className={`${
                          tableHead == true
                            ? "h-7 mr-5"
                            : "userId" in key
                              ? "w-16 h-16 -mt-1"
                              : "h-5 mr-5"
                        }`}
                        style={iconHeight && { height: iconHeight }}
                        src={"userId" in currentData[0] ? user_image : colIcon}
                      />
                      <div
                        className={`align-top ${
                          "userId" in key && "cursor-pointer"
                        }`}
                        onClick={() => "userId" in key && onclickHandler(key)}
                      >
                        <p
                          className={
                            "text-xl mb-0 " +
                            (key.isActive == 1 ? "opacity-100" : "opacity-60")+(
                              key.newKey && "hover: cursor-pointer "
                            )
                          }
                          onClick={()=>key.newKey&& handleRoute(key)}
                        >
                          {key.courseName ||
                            key.userName ||
                            key.contentRepositoryName ||
                            key.categoryName ||
                            key.variantName ||
                            key.communicationName ||
                            key.calendarEventName}{" "}
                          {key?.productGroupName
                            ? key.productGroupName != "0" &&
                              "- " + key.productGroupName
                            : ""}
                        </p>
                        <div className="flex ml-3 mt-3">
                          <img src={calendar} />
                          <p
                            className={
                              "text-sm ml-2 mb-0 " +
                              (key.isActive == 1 ? "opacity-100" : "opacity-60")
                            }
                          >
                            {key.modifiedBy === null
                              ? "Published by Admin on"
                              : "Modified on"}{" "}
                            {key.modifiedBy === null ?key.publishTs || key.createdTs : key.modifiedTs  }
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                  {key.type && (
                    <td className="align-top pt-4" width={column[1]["width"]}>
                      <p
                        className={
                          "text-xl mb-0 " +
                          (key.isActive == 1 ? "opacity-100" : "opacity-60")
                        }
                      >
                        {key.type.trim()}
                      </p>
                    </td>
                  )}
                  {key.status && (
                    <td className="align-top pt-4" width={column[1]["width"]}>
                      <p
                        className={
                          "text-xl mb-0 " +
                          (key.isActive == 1 ? "opacity-100" : "opacity-60")
                        }
                      >
                        {key.status}
                      </p>
                    </td>
                  )}
                  {tableHead == true && (
                    <td className="align-top pt-4" width={column[1]["width"]}>
                      <p
                        className={
                          "text-xl mb-0 " +
                          (key.isActive == 1 ? "opacity-100" : "opacity-60")
                        }
                      >
                        {key.limaCode}
                      </p>
                    </td>
                  )}
                  {tableHead == true && (
                    <td className="align-top pt-4" width={column[2]["width"]}>
                      <p
                        className={
                          "text-xl mb-0 " +
                          (key.isActive == 1 ? "opacity-100" : "opacity-60")
                        }
                      >
                        {key.courseNames.join(",") || key.couses}
                      </p>
                    </td>
                  )}
                  {tableHead != true && "tagNames" in key === true && (
                    <td className="align-top pt-4" width={column[1]["width"]}>
                      {key.tagNames.map((tag, i) => (
                        <span
                          className="mr-2 bg-gray-100 px-3 py-2 rounded-2xl text-sm capitalize"
                          key={i}
                        >
                          {tag}
                        </span>
                      ))}
                    </td>
                  )}
                  {!tableHead && "userImspin" in key === true && (
                    <td width={column[1]["width"]} className="text-xl">
                      {key.userImspin}
                    </td>
                  )}
                  <td className="align-top pt-4" width={column[0]["width"]}>
                    <div className="flex cursor-pointer items-center">
                      {(key.type
                        ? key.type != "Email" && key.type != "WhatsApp"
                        : tableHead != true && !isforAddConentRepo) && (
                        <Link
                          className="mr-4"
                          to={{
                            pathname: key.courseName && !key.adverticementsDetails
                              ? `/Courseware/Courses/edit-Course:${
                                  key.courseName +
                                  (key.productGroupName != "0" &&
                                    "- " + key.productGroupName)
                                }`
                              : key.contentRepositoryName
                                ? "/Courseware/ContentRepository/editContentRepo"
                                : key.communicationName
                                  ? "/HomePage/CommunicationManagement/edit-Communication"
                                  : key.userName
                                    ? "/access_Management/Edit-User" : 
                                    key.adverticementsDetails ? `/Advertisement Management/${key.courseName}`
                                      : key.calendarEventName
                                        ? "/HomePage/CalendarManagement/Edit-Event"
                                        : `/Variants/CategoryManagement/category-List/edit-Category:${key.categoryName}`,
                            state: { data: key },
                          }}
                        >
                          {key.communicationName && <img src={submitted} />}
                          {key.categoryName && <img src= {submitted} />}
                          {!key.userName &&
                          !key.communicationName ? (
                            // done changes in access for content repo and course details
                              key.editAccess === "1" ? (
                                <img
                                  onClick={() => {
                                    setValuesToLocalStorage(key, "course");
                                    dispatch(
                                      setContentRepositoryId(
                                        key?.contentRepositoryId
                                      )
                                    );
                                    dispatch(setCourseDetails(key));
                                    dispatch(setadvertiseMentdata(key));
                                  }}
                                  className="h-6"
                                  src={submitted}
                                />
                              ) : null
                            ) : (
                              key.userName &&
                              key.editPermisison === "1" && (
                                <img
                                  onClick={() => {
                                    setValuesToLocalStorage(key, "course");
                                    dispatch(setaccess_management_UserData(key));
                                  }}
                                  className="h-6"
                                  src={submitted}
                                />
                              )
                            )}
                          {/* {key.userName && key.editPermisison === "1" && <img onClick={()=>{dispatch(setaccess_management_UserData(key));}} className="h-6" src={submitted}/>} */}
                        </Link>
                      )}
                      {tableHead == true && (
                        <>
                          <div
                            className={`ml-4 rounded-full shadow-forButton flex p-[9px] bg-white mr-4 ${
                              isForVarientComp && "hidden"
                            }`}
                          >
                            <Link
                              to={`/Courseware/Courses/edit-Course:${localStorage.getItem(
                                "breadcrumbs"
                              )}/navigationPath`}
                            >
                              <img
                                onClick={() => {
                                  setValuesToLocalStorage(key, "mapped"),
                                  dispatch(setVariantsByCourseData(key));
                                }}
                                src={classFlow}
                                data-tip
                                data-for="navgPath"
                              />
                            </Link>
                            {
                              <Tooltip
                                multiline={false}
                                id="navgPath"
                                place="bottom"
                                text={tooltipText.navgPathText.toString()}
                                offset={{ bottom: 10, right: 10 }}
                              />
                            }
                          </div>

                          <div
                            className={`mx-4 rounded-full shadow-forButton flex p-[9px]${
                              isForVarientComp ? "bg-none" : "bg-white mr-4"
                            }`}
                          >
                            {isForVarientComp ? (
                              <Link
                                to={{
                                  pathname:
                                    key.variantName &&
                                    `/Variants/VariantManagement/Variant-List/edit-Variant:${key.variantName}`,
                                  state: { data: key },
                                }}
                              >
                                {(
                                  key.editAccess==="1"
                                ) ? (
                                    <img
                                      className="h-6"
                                      src={submitted}
                                      onClick={() => {
                                        dispatch(setVariantObj(key));
                                      }}
                                    />
                                  ) : null}
                              </Link>
                            ) : (
                              <img
                                src={learningPath}
                                data-tip
                                data-for="classFlow"
                              />
                            )}
                            {
                              <Tooltip
                                multiline={false}
                                id="classFlow"
                                place="bottom"
                                text={tooltip2Text.classFlowText.toString()}
                                offset={{ bottom: 10, right: 10 }}
                              />
                            }
                          </div>
                        </>
                      )}
                      {
                        // !isforAddConentRepo &&
                        // <div className="z-50 ml-4 rounded-full shadow-forButton flex p-[9px] relative bg-white"
                        //   onClick={() => { isforContentRepo ? funcToDeleteContentRepoData (key.contentRepositoryId):
                        //     isForVarientComp ? handleDeleteVariantFunction(key.variantId) :
                        //       handleDeleteCourse(key.courseId);
                        //   }}><img src={deleteinactive}/>
                        // </div>
                      }
                    </div>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};
CourseTable.propTypes = {
  column: propTypes.array, //for adding width for table
  currentData: propTypes.array, //for get data from parent component
  colIcon: propTypes.string, //for icon in the first column
  tableHead: propTypes.bool, //for adding header on table
  setFilteredData: propTypes.func,
  isforAddConentRepo: propTypes.bool,
  setRenderChildPage: propTypes.func,
  refetch_Calls: propTypes.func,
  isforContentRepo: propTypes.bool,
  isForVarientComp: propTypes.bool,
  iconHeight: propTypes.string,
  setFunction: propTypes.func,
  setContentRepoId : propTypes.func,
  dataForSorting : propTypes.object,
  defaultData : propTypes.object
};
export default CourseTable;
