import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  variantsByCourse: {},
};

const variantsByCourseSlice = createSlice({
  name: "variantsByCourseData",
  initialState,
  reducers: {
    setVariantsByCourseData: (state, action) => {
      state.variantsByCourse = action.payload;
    },
  },
});

export const { setVariantsByCourseData } = variantsByCourseSlice.actions;

export default variantsByCourseSlice.reducer;
