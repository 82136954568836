import React, { useState } from "react";
import Header from "../header/Header";
import BreadCrumb from "../global/breadcrumbs/BreadCrumb";
import DropDown from "../global/fields/CourseDropDownId";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import WhiteSpace from "../global/regEx/WhiteSpaceValidator";

const AddReservationCategory = () => {

  let history = useHistory();
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  const [CategoryName, setCategoryName] = useState("");
  const [salesforceId, setSalesforceId] = useState("");
  const [courseTags, setCourseTags] = useState([]);
  const [showLabel, setShowLabel] = useState([]);
  const [courses, setCourses] = useState("");
  const [courseId, setCourseId] = useState([]);
  const [showLabelwithId,setShowLabelwithId]=useState([]);

  const { data: getCourseData } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method : "post",
      data : {
        "adminimspin":login_Details.userImsPin
      }
    }));

  const fetchAddCategory = () => {
    return apirequest({
      url: `${endpoint.add_categories.addr}`,
      method: "post",
      data: {
        reservationCategoryName: CategoryName,
        isActive: "1",
        createdBy: login_Details.userImsPin,
        courseId: showLabelwithId.map(x=>x.courseId),
        sfId:salesforceId,
      },
    });
  };

  //API request to add new category data
  const { refetch: addCategoryRefetch } = useQuery(
    endpoint.add_categories.key,
    fetchAddCategory,
    {
      enabled: false,
      onSuccess :(res)=>{alert(res.data.message),window.location.reload(false);},
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );

  const onsave = e => {
    if(WhiteSpace(CategoryName) || WhiteSpace(salesforceId)){
      alert("Please enter Valid Details");
    }
    else if(showLabelwithId.length===0){
      alert("Please Select Course");
    }
    else{
      addCategoryRefetch();
    }
    e.preventDefault();
  };

  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />
      <div className="mt-2 mx-12  px-8 py-2  ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
          onSubmit={onsave}>
          <div className="h-full space-y-6  px-12 py-6 relative">
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Reservation Category
                <input
                  required
                  type="text"
                  name="blogName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-2/5 rounded-lg focus:outline-none"
                  value={CategoryName}
                  onChange={e => setCategoryName(e.target.value)}
                />
              </label>
            </div>
            <div className="flex py-5 space-x-32">
              <label className=" font-normal text-lg font-ibm ">Course</label>
              {/* {isSuccess && */}
              <DropDown
                placeholder={"Search Course(s)"}
                options={getCourseData?.data?.data?.courseList}
                setUpdatedOptions={setCourseTags}
                type={"multiselect"}
                width={"w-[30rem]"}
                courseTags={courseTags}
                showLabel={showLabel}
                setShowLabel={setShowLabel}
                value={courses}
                setValue={setCourses}
                courseId={courseId}
                setCourseId={setCourseId}
                showLabelwithId={showLabelwithId}
                setShowLabelwithId={setShowLabelwithId}
              />
            </div>
            <div className="flex space-x-16 py-2">
              <label className=" font-normal text-lg font-ibm ">
              Sales Force ID
              </label>
              <input
                required
                type="text"
                name="salesForce"
                className="border-2 h-10 border-secondary-300 absolute left-48 w-2/5 rounded-lg focus:outline-none"
                value={salesforceId}
                onChange={e => setSalesforceId(e.target.value)}
              />
             
            </div>
          </div>
          <div className="flex space-x-6 absolute left-[40%] -bottom-16">
            <button className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
              SAVE
            </button>
            <p onClick={history.goBack} className=" p-3 text-lg font-ibm font-normal cursor-pointer  border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
              EXIT
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddReservationCategory;
