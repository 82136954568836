import React, { useState } from "react";
import { useQuery } from "react-query";
import { apirequest } from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import Browse from "./Browse";
import BrowseLearningPath from "./BrowseLearningPath";
import BackNCancelButton from "./BackNCancelButton";
import { useDispatch, useSelector } from "react-redux";
import WithModal from "../../../global/modal/WithModal/WithModal";
import ModalButton from "../../../global/modal/ModalTemplate/ModalButton";
import propTypes from "prop-types";
import { addTier } from "../../../../redux/features/learningPath/learningPathSlice";

function BrowseLPcontainer({ closeModal, learningPathId, setDisableSave }) {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [course, setCourse] = useState(null);
  const [variant, setVariant] = useState(null);
  const login_Details = useSelector(state => state.loginData.LoginDetails);
  const importLearningPathData = useSelector(
    state => state.importLearningPathData.importLearningPathDetails,
  );
  const courseIdForImportApi = useSelector(
    state => state.courseData.courseDetails.courseId,
  );
  const variantIdForImportApi = useSelector(
    state => state.variantsByCourseData.variantsByCourse.variantId,
  );
  const pathLink = useSelector(
    state => state.importLearningPathData.clipBoardData,
  );

  const dispatch = useDispatch();

  /* Import Learning Path API */
  const fetch_importPath_Payload = async () => {
    return apirequest({
      url: `${endpoint.import_Learning_Path.addr}`,
      method: "post",
      data: {
        courseId: courseIdForImportApi.toString(),
        variantId: variantIdForImportApi,
        learningPathId,
        isActive: "1",
        createdBy: login_Details.userImsPin,
        data: importLearningPathData,
      },
    });
  };
  const { refetch: fetchImportPathAPI } = useQuery(
    `${endpoint.import_Learning_Path.key}`,
    fetch_importPath_Payload,
    {
      enabled: false,
      onSuccess: data => {
        dispatch(addTier(data.data.data[0]));
        setDisableSave(false);
        alert(data.data.message);
        closeModal();
      },
      onError: err => {
        alert(err.data.message);
      },
      retry: 1,
    },
  );

  function handleClick() {
    if (pathLink === "") {
      alert("Please Copy or Browse Link!");
    } else {
      fetchImportPathAPI();
    }
  }

  /* Course API */
  const { refetch: fetchCourses, status: courseStatus } = useQuery(
    [`${endpoint.course_data.key}`],
    () =>
      apirequest({
        url: `${endpoint.course_data.addr}`,
        method: "Post",
        data: {
          adminimspin: login_Details.userImsPin,
        },
      }),
    {
      enabled: false,
      onSuccess: res => {
        setData({
          ...data,
          1: res.data.data.courseList,
        });
      },
      retry: 0,
      onError: err => {
        setCurrentScreen(currentScreen - 1);
        alert(err.response.data.message);
      },
    },
  );

  /* Variants data by courseId */
  const fetchVariantData = () => {
    return apirequest({
      url: `${endpoint.getCourse_VariantsData.addr}`,
      method: "post",
      data: {
        courseId: course.courseId,
      },
    });
  };

  const {status: variantStatus} = useQuery(
    [`${endpoint.getCourse_VariantsData.key}`, course],
    fetchVariantData,
    {
      onSuccess: res => {
        setData({
          ...data,
          2: res.data.data,
        });
      },
      onError: err => {
        // setCurrentScreen(currentScreen - 1);
        console.log("Err obj : ", err);
        setError(err.data.message);
      },
      retry: 0,
      enabled: !!course,
    },
  );

  /* Learning path api call */
  const fetchLearningPathPayload = () => {
    return apirequest({
      url: `${endpoint.get_LearningPath_Data.addr}`,
      method: "post",
      data: {
        courseId: course.courseId,
        variantId: variant.variantId,
      },
    });
  };

  const {status : learningPathStatus} = useQuery(
    [endpoint.get_LearningPath_Data.key, course, variant],
    fetchLearningPathPayload,
    {
      onSuccess: res => {
        setData({
          ...data,
          3: res.data.data,
        });
      },
      onError: err => {
        setError(err.data.message);
      },
      enabled: !!variant,
      retry: 0,
    },
  );

  function returnStatus(){
    if(currentScreen === 1)
      return courseStatus;
    if(currentScreen === 2)
      return variantStatus;
    if(currentScreen === 3)
      return learningPathStatus;
  }

  return (
    <div
      className={`flex flex-col w-[806px] py-4 px-8 shadow-outer ${
        currentScreen != 0
          ? "h-[400px] overflow-y-scroll scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded"
          : "h-[350px]"
      }`}>
      <BackNCancelButton
        closeModal={closeModal}
        currentScreen={currentScreen}
        setCurrentScreen={setCurrentScreen}
        setError={setError}
        setCourse={setCourse}
        setVariant={setVariant}
      />
      <div className="mb-16">
        {currentScreen == 0 ? (
          <BrowseLearningPath
            pathLink={pathLink}
            setCurrentScreen={setCurrentScreen}
            fetchCourses={fetchCourses}
          />
        ) : (
          <Browse
            currentScreen={currentScreen}
            setCurrentScreen={setCurrentScreen}
            data={data[currentScreen]}
            setData={setData}
            course={course}
            variant={variant}
            setCourse={setCourse}
            setVariant={setVariant}
            error={error}
            status={returnStatus()}
          />
        )}
      </div>

      {currentScreen === 0 && (
        <div className="mx-auto">
          <div className="w-32">
            <ModalButton
              modalButtonValue="Save"
              modalButtonFunctions={[handleClick]}
            />
          </div>
        </div>
      )}
    </div>
  );
}

BrowseLPcontainer.propTypes = {
  closeModal: propTypes.func,
  learningPathId: propTypes.string,
  setDisableSave: propTypes.func,
};

export default WithModal(BrowseLPcontainer);
