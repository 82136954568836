import React, { useState } from "react";
import ToggleYesNo from "../../global/fields/ToggleYesNo";
import CalendarModalTrigger from "../../home/blogs/calendar/CalendarModalTrigger";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DropDown from "../../global/fields/DropDown";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config";
import { apirequest } from "../../../API/api";
import DropdownDuration from "../../../components/home/blogs/Dropdown";
import propTypes from "prop-types";
import SchoolDropdown from "../SchoolDropdown";
import { articlesApiCalls } from "../apiCalls/reusableApiCalls";
import { UploadFileToAWS } from "../../../AWS/uploadFile";
// import { schoolsApicalls } from "../apiCalls/reusableApiCalls";

const AddEditArticle = ({  eventsdata , requestType}) => {
  const history = useHistory();

  const {
    activeDate,
    duration,
    isPinned,
    tags: tagItems,
    schools,
    courses,
    thumbnailLink,
    ...restValues
  } = eventsdata;
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);

  const [addEventData, setAddEventdata] = useState(restValues);

  const [pinThisEvent, setPinThisevent] = useState(
    isPinned === "1" ? true : false
  );
  const [selectedCourse, setSelectCourse] = useState(courses);
  const [selectedDate, setSelectedDate] = useState(activeDate);
  const [tags, setTags] = useState(tagItems);

  const [thumbnailLinks , setThumbnailLink] = useState(thumbnailLink);

  const timevalue = duration.split(":");
  const [hour, setHour] = useState(
    Array.from(String(timevalue[0]), Number)[0] === 0
      ? Array.from(String(timevalue[0]), Number)[1]
      : timevalue[0]
  );
  const [minutes, setMinutes] = useState(timevalue[1].replace(/^0/, ""));
  const [seconds, setSeconds] = useState(timevalue[2].replace(/^0/, ""));
  const [selectedSchools, setSelectedSchools] = useState(schools);

  //courses school api for dropdown
  const { data: coursesData } = useQuery(endpoint.getSchoolCoursesApi.key, () =>
    apirequest({
      method: "post",
      url: endpoint.getSchoolCoursesApi.addr,
      data: {
        adminImsPin: login_Details.userImsPin,
      },
    })
  );

  // schools data for dropdown
  const { data: SchoolsData } = useQuery(
    endpoint.getSchoolsForDropdown.key,
    () =>
      apirequest({
        method: "get",
        url: endpoint.getSchoolsForDropdown.addr,
      })
  );

  //Tags data for dropdown =======>

  const { data: tagsdata } = useQuery(endpoint.tags_data.key, () =>
    apirequest({
      method: "get",
      url: endpoint.tags_data.addr,
    })
  );

  const handleBrowse = (event) => {
    event.preventDefault();
    setThumbnailLink(event.target?.files[0].name);   //setting the selected CSV file 
    const fileDate = new Date()
      .toLocaleString({ hour12: true })
      .toString()
      .split("/");

    if (event.target?.files[0]) {
      const val = UploadFileToAWS({
        key: "addSlideShowUpload",
        file: event.target?.files[0],
        path:
          `School/${requestType}Event` +
          fileDate[2].toString().split(",")[0] +
          "/" +
          event.target?.files[0].name.toString().split(".")[0] +
          "_" +
          login_Details.userImsPin +
          "_" +
          fileDate[2].toString().split(/[, ]+/)[1] +
          "." +
          event.target?.files[0].name.toString().split(".")[1],
      });
      {
        val.then((item) => setThumbnailLink(item));
      }
    } else {
      alert("No files available!");
    }
  };

  let payloadData = {
    ...addEventData,
    activeDate: selectedDate,
    isPinned: pinThisEvent ? "1" : "0",
    createdBy: login_Details.userImsPin,
    requestType: requestType,
    isActive: "1",
    duration: `${hour}:${minutes}:${seconds}`,
    tagId: tags,
    courseId: selectedCourse.map(item=>item.courseId).flat(),
    modifiedBy: requestType === "Update" ? login_Details.userImsPin : "",
    schoolId: selectedSchools.map(item=>item.schoolId).flat(),
    thumbnailLink: thumbnailLinks,
  };

  const {refetch} = articlesApiCalls({payloadData});

  return (
    <div className="space-y-8">
      <div className="mb-4 pl-10 flex gap-10 pt-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Courses
        </label>
        <div className="ml-[64px]">
          <SchoolDropdown
            dropdownData={coursesData?.data?.data}
            setSelectedValue={setSelectCourse}
            selectedValue={selectedCourse}
            filterValue="courseName"
            filterId="courseId"
            placeholder="Selected Course(s)"
          />
        </div>
      </div>
      <div className="mb-4 pl-10 flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Title*
        </label>
        <input
          className="ml-16 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-black"
          type="text"
          name="articleName"
          placeholder=""
          value={addEventData.articleName}
          onChange={(e) =>
            setAddEventdata({
              ...addEventData,
              [e.target.name]: e.target.value,
            })
          }
          required
          autoComplete="off"
        />
      </div>
      <div className="flex ml-8   mb-2">
        <label className="font-normal text-lg ml-[8px] mt-2">Active Date</label>
        <div className="flex">
          <input
            placeholder="select End Date"
            value={selectedDate}
            readOnly
            required
            className="bg-white  ml-[176px] border-2 w-[100%] border-secondary-300 cursor-default rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"
          />
          <div className="relative top-4 right-8">
            <CalendarModalTrigger setDate={setSelectedDate} />
          </div>
        </div>
      </div>

      <div className="mb-4 pl-10 flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Article HTML Content*
        </label>
        <textarea
          className="ml-16 max-h-14  bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-black"
          type="text"
          name="articleHTMLContent"
          placeholder=""
          value={addEventData.articleHTMLContent}
          onChange={(e) =>
            setAddEventdata({
              ...addEventData,
              [e.target.name]: e.target.value,
            })
          }
          required
          autoComplete="off"
        />
      </div>
      <div className="mb-4 pl-10 flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Duration*
        </label>
        <div className="flex space-x-4 ml-16 mt-2">
          <div className="">
            <DropdownDuration
              minOffset={0}
              maxOffset={23}
              text={"Hrs"}
              selectedValue={hour}
              setSelectedValue={setHour}
            />
          </div>
          <div className="">
            <DropdownDuration
              minOffset={0}
              maxOffset={59}
              text={"Mins"}
              selectedValue={minutes}
              setSelectedValue={setMinutes}
            />
          </div>
          <div className="">
            <DropdownDuration
              minOffset={0}
              maxOffset={59}
              text={"Sec"}
              selectedValue={seconds}
              setSelectedValue={setSeconds}
            />
          </div>
        </div>
      </div>
      {/* <div className="mb-4 pl-10 flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Duration
        </label>
        <input
          className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-4/12 placeholder-bold font-semibold text-XL focus:placeholder-black"
          type="text"
          name="duration"
          value={addEventData.duration}
          placeholder=""
          onChange={(e) =>
            setAddEventdata({
              ...addEventData,
              [e.target.name]: e.target.value,
            })
          }
          required
          autoComplete="off"
        />
      </div> */}
      <div className="mb-4 pl-10 flex gap-10">
        <ToggleYesNo
          title="Pin this article?"
          toggleState={pinThisEvent}
          setToggleState={setPinThisevent}
          mg="mr-[100px]"
        />
      </div>
      <div className="mb-4 pl-10 flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Tags
        </label>
        <div className="ml-[57px]">
          <DropDown
            placeholder={"Search exisiting or Create new tags"}
            options={tagsdata?.data?.data}
            setUpdatedOptions={setTags}
            type={"multiselect"}
            width={"w-[22rem]"}
            courseTags={tags}
            valueReadCourseTag={tags}
          />
        </div>
      </div>
      <div className="mb-4 pl-10 flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Upload Thumbnail
        </label>
        <input
          className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-black"
          type="text"
          name="FirstCall"
          placeholder=""
          //   onChange={handleChange}
          required
          value={thumbnailLinks}
          autoComplete="off"
        />
        <label className=" bg-primary-200 px-10 ml-2 py-3 rounded-xl text-white text-sm font-semibold cursor-pointer font-ibm">
          <input
            className="hidden"
            type="file"
            multiple
            onChange={(e) =>handleBrowse(e)}
          />
          Browse
        </label>
      </div>

      {/* Related Schools */}
      <div className="mb-4 pl-10 flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Related Schools
        </label>
        <div className="ml-[77px]">
          <SchoolDropdown
            dropdownData={SchoolsData?.data.data}
            setSelectedValue={setSelectedSchools}
            filterId="schoolId"
            filterValue="schoolName"
            selectedValue={selectedSchools}
            placeholder="Select School(s)"
          />
        </div>
      </div>

      <div className="flex justify-center mt-5 space-x-5 pt-20">
        <button
          onClick={refetch}
          className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer cursor-pointer"
        >
          Save
        </button>
        <p
          onClick={() => history.push("/School/Articles")}
          className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer cursor-pointer"
        >
          Exit
        </p>
      </div>
    </div>
  );
};

AddEditArticle.propTypes = {
  eventsdata: propTypes.object,
  requestType: propTypes.bool,
};
export default AddEditArticle;
