import React from "react";
import Header from "../header/Header";
import Toggle from "../global/fields/Toggle";
import messageIcon from "../../assets/icons/message.svg";
import callIcon from "../../assets/icons/call.svg";
import DropDown from "../global/fields/CourseDropDown";
import { useState } from "react";
import {apirequest} from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import CentreDropdown from "../global/fields/CentreDropdown";
import { useSelector } from "react-redux";

const AddMentor = () => {
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  const NoticeDetails = useSelector(state => state.noticeData.noticeBoardDetails);
  const [activeTab, setActiveTab] = useState(NoticeDetails.isActive==="1"?"active":"inactive");
  const [courses, setCourses] = useState(NoticeDetails.courseNames ? NoticeDetails.courseNames : "",
  );
  const [showLabel, setShowLabel] = useState(
    NoticeDetails.courseNames ? NoticeDetails.courseNames : [],
  );
  const [courseTags, setCourseTags] = useState([]);
  //   for centres
  const [centres, setCentres] = useState(NoticeDetails.branchNames ? NoticeDetails.branchNames : "",);
  const [showLabelCentre, setShowLabelCentre] = useState(
    NoticeDetails.branchNames ? NoticeDetails.branchNames : [],
  );
  const [centreTags, setCentreTags] = useState([]);
  //API request to get the courses 
  const { data: getCourseData } = useQuery(endpoint.getCourses_mentor.key, ()=>apirequest({
    url : `${endpoint.getCourses_mentor.addr}`,
    method : "get",
  }),{
    refetchOnMount : true
  });
    //API request to get the courses 
  const { data: getCentresData } = useQuery(endpoint.getCentres_mentor.key, ()=>apirequest({
    url : `${endpoint.getCentres_mentor.addr}`,
    method : "get",
  }),{
    refetchOnMount : true
  });
  // API request to add or update mentor details
  const fetchUpdateMentor = () => {
    return apirequest({
      url: `${endpoint.add_Mentor.addr}`,
      method: "post",
      data:{
        "imsPin": NoticeDetails.mentorImsPin,
        "employeeContactNum": NoticeDetails.mentorContactNum,
        "employeeName": NoticeDetails.mentorName,
        "employeeEmail": NoticeDetails.mentorEmail,
        "isActive": activeTab==="active"?"1":"0",
        "createdBy": login_Details.userImsPin,
        "modifiedBy": login_Details.userImsPin,
        "courseName": courseTags,
        "branchName":centreTags,
      },
    
    });
  };

  const { refetch } = useQuery(
    [endpoint.add_Mentor.key],
    fetchUpdateMentor,
    {
      enabled: false,
      refetchOnMount: false,
      onSuccess: (data)=> {
        alert(data.data.message);
      },
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );
  const onSave=()=>{
    refetch();
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col relative">
        <div className="flex justify-between px-24 py-24">
          <p className="font-ibm text-2xl font-medium">{NoticeDetails.mentorName}</p>
          <div className="flex space-x-2">  
            <img className="" src={messageIcon} />
            <p className="mt-1">{NoticeDetails.mentorEmail}</p>
          </div>
          <div className="flex space-x-2">  
            <img className="" src={callIcon} />
            <p className="mt-1">{NoticeDetails.mentorContactNum}</p>
          </div>
          <div className="-mt-5">
            <Toggle
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              title=""
            />
          </div> 
        </div>
        <div className="flex flex-col space-y-8">
          <p className="px-24 font-ibm text-2xl font-medium">Course</p>
          <div className="px-20">
            <DropDown
              placeholder={"Search Course(s)"}
              options={getCourseData?.data?.data}
              setUpdatedOptions={setCourseTags}
              type={"multiselect"}
              width={"w-[22rem]"}
              courseTags={courseTags}
              showLabel={showLabel}
              setShowLabel={setShowLabel}
              value={courses}
              setValue={setCourses}
              forCourses ={true}
            />
          </div>
         
        </div>
        <div className="flex flex-col space-y-8 py-6 ">
          <p className="px-24 font-ibm text-2xl font-medium">Centres</p>
          <div className="px-20">
            <CentreDropdown
              placeholder={"Search Course(s)"}
              options={getCentresData?.data?.data}
              setUpdatedOptions={setCentreTags}
              type={"multiselect"}
              width={"w-[22rem]"}
              courseTags={centreTags}
              showLabel={showLabelCentre}
              setShowLabel={setShowLabelCentre}
              value={centres}
              setValue={setCentres}
              forCourses ={true}
            />
          </div>
         
        </div>
        <div className="absolute right-6 bottom-0 pt-10">
          <button className=" w-40 px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500" onClick={onSave}>
          SAVE
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMentor;