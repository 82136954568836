import React, { useState } from "react";
import propTypes from "prop-types";
import ArrowUp from "../../../../src/assets/icons/arrow-up.svg";
import arrowDown from "../../../../src/assets/icons/arrow-down.svg";
import OutsideClickHandler from "react-outside-click-handler";
import { useEffect } from "react";
// import { set } from "immer/dist/internal";
// import { useQuery } from "react-query";
// import { apirequest } from "../../../API/api";

const CommunicationDropdown = ({
  filterText,
  getId,
  dropdownData,
  setId,
  filterId,
  filterValue,
  val,
  
}) => {
  const [showDropdown, setShowDropDown] = useState(false);
  const [filteredData, setFilteredData] = useState(dropdownData);
  const [selectedValue, setSelectedValue] = useState([]);
  const [value, setValue] = useState([]);

  useEffect(() => {
    setFilteredData(dropdownData);
    // new Promise( (resolve)=>
    val[0]=== "All" ? setSelectedValue(dropdownData) :
      setSelectedValue(val.filter(val=>dropdownData?.some(item=>{
        if(item[filterValue] ===val[filterValue])return val;
        else setSelectedValue([]);
      })));
    // ); 
    
  }, [JSON.stringify(dropdownData)]);
  

  useEffect(() => {
    if(selectedValue?.length > 0){
      if(selectedValue?.length === dropdownData?.length){
        setValue("All Options");
        setId(["All"]);
      }else {setId(selectedValue.map(id=>id[filterId])); setValue(selectedValue.map(val=>val[filterValue]));}
    }else {setValue([]); setId([]);}
  }, [JSON.stringify(selectedValue)]);

  const handleFilter = (e) => {
    let searchValue = e.target.value;
    setValue (e.target.value) ;

    let spliitedValue = searchValue.split(",");
    searchValue.length > 0 ? setShowDropDown(true) : null;
    const filterData = dropdownData?.filter((item) => {
      return spliitedValue.some((data) => {
        if (item?.[filterValue].toLowerCase().includes(data.toLowerCase())) {
          return item;
        }
      });
    });
    setFilteredData(filterData);
  };

  const handleChange =  (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      //   setId([...getId, e.target.id]);
      setSelectedValue([...selectedValue, item]) ;
       
      // value.length===0 ?setValue [e.target.value] : setValue([...value , e.target.value]);
    } else {
      const removeItem = e.target.id;
      //   setId(getId.filter((val) => val != removeItem));
      setSelectedValue(selectedValue.filter((val) => val[filterId] != removeItem));
      setFilteredData(dropdownData);
    }
    // await updateInputValue();
  };
  
  const handleDeselect = (e, item) => {
    setId(getId.filter((val) => val != item[filterId]));
    setSelectedValue(selectedValue.filter((val) => val != item));
    setFilteredData(dropdownData);
  };

  const handleResetAll =(event)=>{
    let isChecked = event.target.checked;
    if(isChecked)  {
      setId([]);
      setSelectedValue([]);
      setFilteredData(dropdownData);
    } 
  };
  const handleSelectAll = (event)=>{
    let isChecked = event.target.checked;
    if(isChecked){
      setSelectedValue(dropdownData);
      setId(["All"]);
    }
  };


  const ResetAllComponent =()=>{
    return(
      <div className="flex">
        <input
          type="checkbox"
          onChange={(e)=>handleResetAll(e)}
        />
        <li className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize">
          Reset All
        </li>   
      </div>
    );
  };
  
  return (
    <OutsideClickHandler onOutsideClick={() => setShowDropDown(false)}>
      <div className="flex space-x-5">
        <div className="cursor-pointer border-2 border-secondary-300 rounded-2xl w-72 bg-secondary-100 text-lg text-black shadow-outer px-2 py-3 flex items-center">
          <input
            className="focus:outline-none  w-5/6 rounded-lg"
            placeholder={filterText}
            value={value}
            onChange={handleFilter}
          />
          <img
            src={!showDropdown ? arrowDown : ArrowUp}
            onClick={() => setShowDropDown(!showDropdown)}
          />
          
        </div>
      </div>
      {showDropdown && (
        // <div>
        <ul className="h-auto pt-2 px-3 w-72 border-2 border-solid border-gray-200 rounded-lg shadow-lg absolute z-50 bg-white overflow-y-scroll scrollbar-thin scrollbar-thumb-secondary-700 scrollbar-thumb-rounded  max-h-[10rem]">
          <section
            className={
              selectedValue?.length > 0
                ? "border-b-2 border-secondary-300"
                : null
            }
          >
            {selectedValue?.length >5 ? <ResetAllComponent /> : null}
            {getId.length > 0 &&
              selectedValue?.map((item) => (
                <div className="flex " key={item[filterId]}>
                  <input
                    type="checkbox"
                    checked={true}
                    value={item[filterValue]}
                    onChange={(e) => handleDeselect(e, item)}
                  />
                  <li className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize">
                    {item[filterValue]}
                  </li>
                </div>
              ))}
          </section>
          
          {filteredData?.length > 0 ? (
            <>
              <section>
                <div className="flex">
                  <input
                    type="checkbox"
                    onChange={(e) => handleSelectAll(e)}
                  />
                  <li className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize">Select All</li>                  
                </div>
                <ResetAllComponent />
              </section>

              {filteredData?.map((item) => (
                <div key={item[filterId]}>
                  <div className="flex">
                    <input
                      type="checkbox"
                      id={item[filterId]}
                      value={item[filterValue]}
                      checked={selectedValue.map(id=>id[filterId]).includes(item[filterId])}
                      onChange={(e) => handleChange(e, item)}
                    />

                    <li className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize">
                      {item[filterValue]}
                    </li>
                  </div>
                </div>
              ))}
            </>
          ) : (
            "No Record Found"
          )}
        </ul>
        // </div>
        // </OutsideClickHandler>
      )}
    </OutsideClickHandler>
  );
};

CommunicationDropdown.propTypes = {
  filterText: propTypes.string,
  dropdownData: propTypes.array,
  getId: propTypes.array,
  setId: propTypes.func,
  filterId: propTypes.string,
  filterValue: propTypes.string,
  filterFunctionalityForCity: propTypes.func,
  callRefetch: propTypes.bool,
  val : propTypes.array
};

export default CommunicationDropdown;
