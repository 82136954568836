import React from "react";
import { UploadFileToAWS } from "../../../../AWS/uploadFile";
import propTypes from "prop-types";
import { useSelector } from "react-redux";

function UploadFile({ link, setLink, label, objectKey, template, setCsvPath }) {
  const inputRef = React.useRef();
  const login_Details = useSelector(state => state?.loginData?.LoginDetails);
  function handleBrowse(event) {
    event.preventDefault();
    setLink(event.target?.files[0]?.name, objectKey);
    const fileDate = new Date()
      .toLocaleString({ hour12: true })
      .toString()
      .split("/");
    if (event.target?.files[0]) {
      const val = UploadFileToAWS({
        key: "addTemplate",
        file: event.target?.files[0],
        path:
          "Asset/Schools/" +
          fileDate[2].toString().split(",")[0] +
          "/" +
          event.target?.files[0].name.toString().split(".")[0] +
          "_" +
          login_Details.userImsPin +
          "_" +
          fileDate[2].toString().split(/[, ]+/)[1] +
          "." +
          event.target?.files[0].name.toString().split(".")[1],
      });
      {
        val.then(item => {
          setLink(item, objectKey);
          if (objectKey === "csvPath") {
            setCsvPath(item);
          }
        });
      }
    } else {
      alert("No files available!");
    }
  }
  return (
    <div className="mb-4  pt-5 flex items-center gap-10 ">
      <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
        {label}
      </label>
      <div className="ml-20 w-7/12 flex border-2 border-secondary-300 rounded-xl px-1 py-1 items-center justify-center ">
        { link && link.slice(0, 71) + "..."}
        <button
          onClick={() => {
            inputRef.current.click();
          }}
          className="super-btn px-8 py-3 ml-auto bg-primary-300 text-white flex items-center h-[36px] rounded-2xl shadow-outer cursor-pointer">
          Browse
        </button>
        <input ref={inputRef} type="file" hidden onChange={handleBrowse} />
      </div>
      {template && (
        <p
          className="underline text-primary-200 cursor-pointer  "
          onClick={() =>
            window.open(
              "https://staging.myimsv3.imsindia.com:5053/myims/cms/school/generateCutoffCSV",
            )
          }>
          Template
        </p>
      )}
    </div>
  );
}

UploadFile.propTypes = {
  label: propTypes.string,
  link: propTypes.string,
  objectKey: propTypes.string,
  setLink: propTypes.func,
  setCsvPath: propTypes.func,
  template: propTypes.bool,
};

export default UploadFile;
