import React from "react";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import calendar from "../../../assets/icons/calendar-inactive.svg";
import submitted from "../../../assets/icons/submitted.svg";
import { useDispatch } from "react-redux";
import { getnoticeDetails } from "../../../redux/features/notice/noticeSlice";

const CourseTable = ({ column, currentData, colIcon, tableHead }) => {
  const thClassName = "text-lg font-semibold font-ibm";
  const dispatch = useDispatch();

  return (
    <>
      <table
        className={`border-separate w-full ${tableHead == true ? "" : ""}`}>
        {tableHead && (
          <thead className="shadow-outer w-full h-[4rem] top-0 z-50 text-left">
            {tableHead == true && (
              <tr className="w-full">
                <th
                  className={thClassName + " pl-8"}
                  width={column[0]["width"]}>
                  Title
                </th>
                <th className={thClassName} width={column[5]["width"]}></th>
                <th className={thClassName} width={column[3]["width"]}>
                  Type
                </th>
                <th
                  className={thClassName + " pl-3"}
                  width={column[3]["width"]}>
                  Status
                </th>
                <th
                  className={thClassName + " pr-10"}
                  width={column[3]["width"]}></th>
              </tr>
            )}
          </thead>
        )}
        <tbody className="table-spacing">
          {currentData?.length == 0 ? (
            <tr>
              <td>
                <p className="py-20 px-10 text-center text-xl font-ibm opacity-80">
                  No Data Found!
                </p>
              </td>
            </tr>
          ) : (
            <>
              {currentData?.map((key, index) => (
                <tr key={index}>
                  <td
                    className="align-top pt-4 pl-8 "
                    width={column[0]["width"]}>
                    <div className="flex">
                      <img
                        className={`${
                          tableHead == true ? "h-7 mr-5" : "h-5 mr-5"
                        }`}
                        src={colIcon}
                      />
                      <div className="align-top">
                        <p
                          className={
                            "text-xl mb-0 " +
                            (key.isActive == 1 ? "opacity-100" : "opacity-60")
                          }>
                          {key.noticeBoardName}
                        </p>
                        <div className="flex ml-3 mt-3">
                          <img src={calendar} />
                          <p
                            className={
                              "text-sm ml-2 mb-0 " +
                              (key.isActive == 1 ? "opacity-100" : "opacity-60")
                            }>
                            {key?.modifiedTs === "None" ? 
                              <span>Published by {key.createdBy} on {key.createdTs}</span> 
                              : <span>Modified by {key.modifiedBy} on {key?.modifiedTs}</span>}
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="align-top pt-4 " width={column[1]["width"]}>
                    <p
                      className={
                        "text-xl mb-0 " +
                        (key.isActive == 1
                          ? "opacity-100 ml-24"
                          : "opacity-60 ml-24")
                      }>
                      {key.courseNames.join(",")}
                    </p>
                  </td>
                  {tableHead == true && (
                    <td className="align-top pt-4" width={column[4]["width"]}>
                      <p
                        className={
                          "text-xl mb-0 " +
                          (key.isActive == 1 ? "opacity-100" : "opacity-60")
                        }>
                        {key.type}
                      </p>
                    </td>
                  )}
                  {tableHead == true && (
                    <td className="align-top pt-4 " width={column[1]["width"]}>
                      <p
                        className={
                          "text-xl mb-0 " +
                          (key.isActive == 1 ? "opacity-100" : "opacity-60")
                        }>
                        {key.publishStatus}
                      </p>
                    </td>
                  )}
                  <td width={column[1]["width"]} className="align-top">
                    {/* {key.publishStatus == "Scheduled" && ( */}  
                    <div key={index} className="flex cursor-pointer items-center space-x-5">
                      {/* made changes for acccess */}
                      {key.editAccess==="1" &&(

                        <Link key={index} to={`/HomePage/NoticeBoardManagement/update-notice:${key.noticeBoardName}`}>
                          
                          <img
                            className="h-7"
                            src={submitted}
                            onClick={() => {
                              dispatch(getnoticeDetails(key));
                            }}></img>
                        </Link>
                      )}
                    </div>
                    {/* }  */}
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};
CourseTable.propTypes = {
  column: propTypes.array, //for adding width for table
  currentData: propTypes.array, //for get data from parent component
  colIcon: propTypes.string, //for icon in the first column
  tableHead: propTypes.bool, //for adding header on table
  setFilteredData: propTypes.func,
  isforAddConentRepo: propTypes.bool,
  setRenderChildPage: propTypes.func,
  refetch: propTypes.func,
};
export default CourseTable;
