import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import BreadCrumb from "../global/breadcrumbs/BreadCrumb";
import Loader from "../global/loader/Loader";
import Header from "../header/Header";
import AccessManagementTable from "./AccessManagementTable";
import UserTemplate from "./UserTemplate";
// import TreeNode from "./TreeNode";

const AddUser = () => {
  const adminimspin = useSelector(
    (state) => state.loginData.LoginDetails.userImsPin
  );

  // query to fetch the data for Access Management table list===>
  const {
    data: list_For_ACCESS_Management_Table,
    error,
    status,
  } = useQuery(endpoint.addUser_AccessManangement.key, () =>
    apirequest({
      url: `${endpoint.addUser_AccessManangement.addr}`,
      method: "Post",
      data: {
        adminImsPin: adminimspin,
      },
    })
  );

  const [productModuledata, setProductModuleData] = useState([]);
  const [cityCentreData, setCityCentreData] = useState([]);
  const [productGroupData, setProductGroupData] = useState([]);
  // State to capture User Roll =====>
  const [selectedavatar, setSelectedAvatar] = useState("");
  const [role_id, set_Role_ID] = useState("");
  const [userName, setUSerName] = useState("");
  const [imsPin, setImsPin] = useState("");
  const [userEmailId, setUserEmailId] = useState("");

  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);

  let history = useHistory();

  const { refetch } = useQuery(
    endpoint.add_User_PostAPi.key,
    () =>
      apirequest({
        url: `${endpoint.add_User_PostAPi.addr}`,
        method: "post",
        data: {
          adminimspin: adminimspin,
          userName: userName,
          imsPin: imsPin,
          emailId: userEmailId,
          avtarUrl: selectedavatar,
          createdBy: login_Details.userImsPin,
          isActive: "1",
          roleId: role_id,
          productModuleData: productModuledata,
          productGroupData: productGroupData,
          centreAccessData: cityCentreData,
        },
      }),
    {
      enabled: false,
      onSuccess: (res) => {
        alert(res.data.message);
      },
      onError: (err) => alert(err?.data.message), // add missed key "data" for onError Callback
    }
  );
  // Validation for Add user form
  const validateObj = (obj) => {
    let result = Object.keys(obj).filter((item) => {
      if (obj[item] === "") {
        return item;
      }
    });
    if (result.length > 0) {
      alert(`Please Enter Valid ${result.toString()}`);
    }
  };

  const handleClick = () => {
    let obj = { userName, userEmailId, selectedavatar, imsPin };
    Object.keys(obj).forEach((k) => (obj[k] = obj[k].trim()));
    if (Object.values(obj).includes("")) {
      validateObj(obj);
    } else {
      refetch();
    }
  };
  return (
    <div>
      <Header />
      <BreadCrumb />
      <div className="shadow-outer m-5 rounded-2xl p-6 h-auto font-ibm">
        <UserTemplate
          userRollData={
            list_For_ACCESS_Management_Table?.data?.data[0].user_roles
          }
          setTickMark={setSelectedAvatar}
          tickMark={selectedavatar}
          setUSerName={setUSerName}
          setImsPin={setImsPin}
          setUserEmailId={setUserEmailId}
          set_Role_ID={set_Role_ID}
        />
        <p className="font-medium text-lg mt-5">Access Management</p>
        {status === "loading" ? (
          <Loader />
        ) : status === "error" ? (
          <span>{error.message}</span>
        ) : (
          <section>
            <AccessManagementTable
              tableData={
                list_For_ACCESS_Management_Table?.data?.data?.[0].product_groups
              }
              forProductModules={false}
              newArr={productGroupData}
              setNewArr={setProductGroupData}
              title="Product Group"
            />
            <AccessManagementTable
              tableData={
                list_For_ACCESS_Management_Table?.data?.data?.[0]
                  .product_modules
              }
              forProductModules={true}
              newArr={productModuledata}
              setNewArr={setProductModuleData}
              title="Product Module"
            />
            <AccessManagementTable
              forCenter={true}
              tableData={
                list_For_ACCESS_Management_Table?.data?.data?.[0].city_centres
              }
              newArr={cityCentreData}
              setNewArr={setCityCentreData}
              title="Centre Module"
            />
            {/* {list_For_ACCESS_Management_Table?.data?.data?.[0].product_groups.map((index)=>(
              <TreeNode 
                key={index}
                newArr ={productGroupData}
                setNewArr ={setProductGroupData}
              />
            ))}
            {list_For_ACCESS_Management_Table?.data?.data?.[0].product_modules.map((index)=>(
              <TreeNode
                
              />
            ))
            } */}
          </section>
        )}
      </div>
      <div className="flex justify-center mt-5 space-x-5 mb-5">
        <button
          className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer"
          onClick={handleClick}
        >
          Save
        </button>
        <button
          className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer"
          onClick={history.goBack}
        >
          Exit
        </button>
      </div>
    </div>
  );
};
export default AddUser;
