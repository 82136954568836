import React, { useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import Toggle from "../../components/global/Toggle";
import BreadCrumb from "../../components/global/breadcrumbs/BreadCrumb";
import SearchCentreDropDown from "../../components/global/fields/SearchCentreDropDown";
import Header from "../../components/header/Header";

const EditVenue = () => {
  let history = useHistory();
  // const queryClient = useQueryClient();
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  const venueDetails = useSelector((state) => state?.VenueData.venueDetails);
  const [venue, setVenue] = useState(() => ({
    ...venueDetails,
    createdBy: login_Details.userImsPin,
  }));
  const [selectedCentre, setSelectedCentre] = useState([
    ...venueDetails.centres,
  ]);

  const addVenue = (data) => {
    return apirequest({
      url: `${endpoint.editVenue.addr}`,
      method: "put",
      data: data,
    });
  };
  const { mutate, isLoading } = useMutation({
    mutationKey: [endpoint.editVenue.key],
    mutationFn: addVenue,
    onSuccess: () => {
      alert("Venue updated successfully.");
      history.push("/venue-management");
      // queryClient.invalidateQueries({ queryKey: [endpoint.getVenues.key] });
    },
    onError: () => {
      alert("Failed to update venue.");
    },
  });

  const handleSubmit = () => {
    // if((variantName !="" && limaCode !="" &&  enddate != "" && selectedCourse != "" )){
    //   if(activeTabForPublish ==="schedule" ){ `${hour}:${minutes}:${seconds}`!= "00:00:00" ? refetch() :(setShowErrorMessage(true)); }
    //   else if(activeTabForPublish ==="now")(refetch() );
    // }else{
    //   setShowErrorMessage(true);
    // }

    if (!venue.venueName.trim()) {
      alert("Please enter Venue Name");
    } else if (!venue.venueAddress.trim()) {
      alert("Please Enter a Venue Address");
    } else if (selectedCentre.length <= 0) {
      alert("Please select Centres");
    } else {
      mutate({
        requestType: "update",
        venueId: venueDetails.venueId,
        venueName: venue.venueName,
        venueAddress: venue.venueAddress,
        isActive: venue.isActive,
        modifiedBy: login_Details.userImsPin,
        centres: [
          ...selectedCentre.map((centre) => centre.id || centre.centre_id),
        ],
      });
    }
  };

  return (
    <div className="pb-9">
      <Header />
      <div className="mt-6 ml-2">
        <BreadCrumb />
      </div>
      <div className="font-ibm flex justify-center mt-7">
        <form className="space-y-5 w-[93%] shadow-outer rounded-2xl p-10">
          <div className="space-x-[90px]">
            <label className="font-normal text-lg">Venue Name</label>
            <input
              placeholder="Enter Venue name"
              value={venue.venueName}
              required
              onChange={(e) =>
                setVenue((prev) => ({ ...prev, venueName: e.target.value }))
              }
              className="bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-XL focus:placeholder-transparent"
            />
          </div>
          <div className="space-x-[90px]">
            <label className="font-normal text-lg">Venue Address</label>
            <input
              placeholder="Enter Venue Address"
              value={venue.venueAddress}
              required
              onChange={(e) =>
                setVenue((prev) => ({ ...prev, venueAddress: e.target.value }))
              }
              className="bg-white border-2 w-6/12 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-XL focus:placeholder-transparent"
            />
          </div>
          <div className="flex space-x-[90px]">
            <label className="font-normal text-lg mt-2">Centres</label>
            <SearchCentreDropDown
              type="multiselect"
              options={selectedCentre}
              placeholder="Search Centre"
              width={"w-[22rem]"}
              setUpdatedOptions={setSelectedCentre}
            />
          </div>
          <Toggle
            title="This Venue is"
            activeTab={venue.isActive === "0" ? "inactive" : "active"}
            setActiveTab={(isActive) =>
              setVenue((prev) => ({
                ...prev,
                isActive: isActive === "active" ? "1" : "0",
              }))
            }
          />
        </form>
      </div>
      <div className="flex justify-center mt-5 space-x-5 mb-5">
        <button
          disabled={isLoading}
          className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer"
          onClick={() => handleSubmit()}
        >
          Save
        </button>
        <button
          className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer"
          onClick={history.goBack}
        >
          Exit
        </button>
      </div>
    </div>
  );
};

export default EditVenue;
