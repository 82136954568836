import React, { useState} from "react";
import Header from "../../../../components/header/Header";
import { Link } from "react-router-dom";
import PostTable from "./BlogTable";
import TablePagination from "../../../../components/table/TablePagination";
// import ArrowUp from "../../../../assets/icons/arrow-up.svg";
import TableSearch from "../../../table/TableSearch";
import documentIcon from "../../../../assets/icons/document.svg";
import {apirequest} from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import { useQuery } from "react-query";
import Loader from "../../../../components/global/loader/Loader";
import { getBlogDetails } from "../../../../redux/features/blogs/blogsSlice";
import { globalFilter } from "../../../global/globalFilter/GlobalFilter";
import FilterFunctionlity from "../../../filterFunctionality/FilterFunctionlity";

const Courses = () => {
  
  const modulesData = globalFilter({name : "Home Page", subModuleName : "Blog Management"});

  const [getBlogData , setBlogsData]= useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [courseId, setCourseId] = useState([]);

  const column = [
    { col: "1", width: "" },
    { col: "2", width: "40%" },
    { col: "3", width: "49%" },
    { col: "4", width: "5%" },
  ];
  const fetchAddBlog = () => {
    return apirequest({
      url: `${endpoint.blogs_data.addr}`,
      method: "post",
      data: {
        // adminimspin:adminimspin,
        adminimspin:modulesData.imsPin,
      } 
    });
  };

  const {data :  blogs_defaultdata,error,isSuccess :is_blog_success,status } = useQuery(
    endpoint.blogs_data.key,
    fetchAddBlog,
    {
      refetchOnMount : true,
      onSuccess : (res)=>setBlogsData(res),
    },
  );

  // API Call for course_data filter
  const { data: getCourseData } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method : "post",
      data : {
        "adminimspin":modulesData.imsPin,      
        "courseId" : ""
      }
    }),{
    select : res=>res.data.data.courseList
  });

  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };
  return (
    <div id="courses">
      <Header />{" "}
      {status === "loading" ? (
        <Loader />
      ) : !is_blog_success && error.status !== 404 ? (
        <span>{error?.message}</span>
      ):(
        <div className="courses">
          
          <div className="courseTable mt-9">
            <div className="courseTable__head flex justify-between  md:mt-[4%] md-mb-[5%] md:mx-9 pl-[2%]">
              {/* Filter Function */}
              <FilterFunctionlity 
                filterText = " Course"
                setFunction = {setBlogsData}
                dropdownData = {getCourseData}
                endpointKey = {endpoint.blogs_data.key}
                url = {endpoint.blogs_data.addr}
                payload = {courseId}
                setPayload = {setCourseId}
                setCurrentPage = {setCurrentPage}
                payloadData = {{adminimspin :modulesData.imsPin , courseId : courseId} }
                filterId = "courseId"
                filterValue="courseName"
              />

              <div className="ml-4 flex">
                <TableSearch
                  defaultData= {blogs_defaultdata}
                  setDefaultData = {setBlogsData}
                  dataKey = "blogName"
                  dataForFilter={blogs_defaultdata?.data.data.blogList}
                  name = "Home Page"
                  subModuleName= "Blog Management"
                  setCurrentPage={setCurrentPage}
                  childModuleName = "blogs"
                  placeholder="Search by Blogs"
                />
                {(is_blog_success && getBlogData?.data?.data?.global_add_access==="1"|| error?.data.message ==="No Record found") &&
                <Link to="/HomePage/BlogManagement/add_blogs">
                  <button className="ml-6 bg-primary-200 text-white font-medium px-6 rounded-xl hover:opacity-75 shadow-outer font-ibm w-40 h-[56px]">
                    Add Blog
                  </button>
                </Link>}
                <Link to="/HomePage/BlogManagement/blog_sequencing">
                  <button className="ml-6 bg-secondary-100 text-ibm font-medium px-6 rounded-xl hover:opacity-75 shadow-outer font-ibm w-40 h-[56px]">
                    Blog Sequence
                  </button>
                </Link>
              </div>
            </div>
            <div className="courseTable__body md:ml-[4%] md:mr-[34px] mt-4 shadow-outer pl-[2%] pr-[5%] rounded-xl bg-white pb-[2%]">
              { is_blog_success && 
              <PostTable
                icon={documentIcon}
                column={column}
                currentData={getBlogData?.data?.data?.blogList.slice(
                  indexOfFirstItem,
                  indexOfLastItem,
                )}
                reduxdata={getBlogDetails}
              />}
            </div>
            <div className="courseTable__footer mx-auto mb-7">
              {is_blog_success &&
              <TablePagination
                data={getBlogData?.data?.data?.blogList}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
                dataPerPage={dataPerPage}
              />}
            </div>
          </div>
          
        </div>
      )}
    </div>
  );
};

export default Courses;
