import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Courses from "./pages/courseware/Courses";
import Home from "./pages/Home";
import AddCourse from "./components/course/AddCourse";
import FormBlog from "./components/home/blogs/FormBlog";
import FormPost from "./components/home/blogs/FormPost";
import BlogListingPage from "./components/home/blogs/blogTable/BlogListing";
import PostListing from "./components/home/posts/PostListing";
import Blogsequencing from "./components/home/blogs/sequencing/BlogSequence";
import AddSlideShow from "./components/home/slideshow/AddSlideShow";

import EditCourse from "./components/course/EditCourse";
import LearningPath from "./pages/courseware/LearningPathContainer";
import ContentRepository from "./pages/contentRepository/ContentRepository";
import AddContentRepo from "./pages/contentRepository/AddContentRepo";
import AddAsset from "./components/createContentRepo/Addasset";
import EditAsset from "./components/createContentRepo/EditAsset";
import SlideShowListing from "./components/home/slideshow/SlideShowListing";
import SlideShowSequencing from "./components/home/slideshow/SlideShowSequencing";
import EditBlog from "./components/home/blogs/EditBlog";
import EditPost from "./components/home/posts/EditPost";
import { ReactQueryDevtools } from "react-query/devtools";
import Category from "./pages/variant/CategoryMngt";
import AddCategory from "./components/variants/category/AddCategory";
import AddVariant from "./components/variants/variant_comp/AddVariant";
import Variant from "./pages/variant/variant_comp/Variant";
import EditVariant from "./components/variants/variant_comp/EditVariant";
import EditCategory from "./components/variants/category/EditCategory";
import AddNotice from "./components/home/noticeboard/AddNotice";
import NoticeboardListing from "./components/home/noticeboard/NoticeboardListing";
import NoticeBoardSequence from "./components/home/noticeboard/sequencing/NoticeBoardSequence";
import UpdateNotice from "./components/home/noticeboard/UpdateNotice";
import EditSlideShow from "./components/home/slideshow/EditSlideShow";
import Communication from "./pages/communication/Communication";
import AddCommunication from "./components/communication/AddCommunication";
import EditCommunication from "./components/communication/EditCommunication";
import Landing from "./components/home/calendar_management/Landing";
import AddEvent from "./components/home/calendar_management/AddEvent";
import EditEvent from "./components/home/calendar_management/EditEvent";

import AccessManagement from "./pages/accessManagement/AccessManagement";
import AddUser from "./components/access_Management/AddUser";
import EditUser from "./components/access_Management/EditUser";
import MyProfile from "./components/access_Management/MyProfile";
import ProductGroup from "./pages/master/ProductGroups";
import AddProductGroup from "./components/master/AddProductGroup";
import UpdateProductGroup from "./components/master/UpdateProductGroup";
import Sections from "./pages/master/Sections";
import UpdateSection from "./components/master/UpdateSection";
import AddSection from "./components/master/AddSection";
import Exams from "./pages/master/Exams";
import AddExam from "./components/master/AddExam";
import UpdateExam from "./components/master/UpdateExam";
import Categories from "./pages/master/Categories";
import AddCategoryMaster from "./components/master/AddCategory";
import Centres from "./pages/master/Centres";
import AddCentre from "./components/master/AddCentre";
import UpdateCentre from "./components/master/UpdateCentre";
import UpdateCategoryMaster from "./components/master/UpdateCategory";
// import Login from "./components/login/Login";
import Batches from "./pages/master/Batches";
import AddBatch from "./components/master/AddBatch";
import UpdateBatch from "./components/master/UpdateBatch";
import BrowseLPcontainer from "./components/course/navigationPath/browseOnImport/BrowseLPcontainer";
import MentorListing from "./components/mentor_mapping/MentorListing";
import AddMentor from "./components/mentor_mapping/AddMentor";
import UpdateMentor from "./components/mentor_mapping/UpdateMentor";
import SchoolDetails from "./components/schools/schoolDetails/SchoolDetails";
import AddSchool from "./components/schools/schoolDetails/addSchool/AddSchool";
import SelectionCriteria from "./components/schools/selectionCriteria/SelectionCriteria";
import EligibilityCriteria from "./components/schools/eligibilityCriteria/EligibiltyCriteria";
import CutOffs from "./components/schools/cutOffs/CutOffs";
// import AddEvents from "./components/schools/Events/AddEvent";
import Deadlines from "./components/schools/deadlines/Deadlines";

import schoolModal from "./components/schools/SchoolModal";
import Articles from "./components/schools/Articles/Articles";
import SchoolListing from "./components/schools/SchoolListing";
import AdvertisementListing from "./components/home/advertisement/AdvertiseMentListing/AdvertisementListing";
import AdvertiseMentSlots from "./components/home/advertisement/AdvertiseMentListing/AdvertiseMentSlots";
import AddArticles from "./components/schools/Articles/AddArticle";
import Events from "./components/schools/Events/Events";
import AddEventforSchool from "./components/schools/Events/AddEvent";
import EditEventForSchool from "./components/schools/Events/EditEvent";
import EditArticles from "./components/schools/Articles/EditArticles";
import AddVenue from "./pages/venue/AddVenue";
import SlotManagementPage from "./pages/slotManagement";
import ManageSlots from "./pages/slotManagement/ManageSlots";
import VenueManagementPage from "./pages/venueManagement";
import EditVenue from "./pages/venue/EditVenue";

function App() {
  return (
    <Suspense fallback="Loading...">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/" component={Home} />*/}
          {/* {Blog Management} */}
          <Route
            exact
            path="/HomePage/BlogManagement"
            component={BlogListingPage}
          />
          <Route
            exact
            path="/HomePage/BlogManagement/add_blogs"
            component={FormBlog}
          />
          <Route
            exact
            path="/HomePage/BlogManagement/edit-blogs:slug"
            component={EditBlog}
          />
          <Route
            exact
            path="/HomePage/BlogManagement/blog_sequencing"
            component={Blogsequencing}
          />
          <Route
            exact
            path="/HomePage/BlogManagement/:id"
            component={PostListing}
          />
          <Route
            exact
            path="/HomePage/BlogManagement/PostManagement/AddPost"
            component={FormPost}
          />
          <Route
            exact
            path="/HomePage/BlogManagement/edit-posts/:slug"
            component={EditPost}
          />

          {/* CourseWare */}
          <Route exact path="/Courseware/Courses" component={Courses} />
          <Route
            exact
            path="/Courseware/Courses/add-Course"
            component={AddCourse}
          />
          <Route
            exact
            path="/Courseware/Courses/edit-Course:slug"
            component={EditCourse}
          />
          <Route
            exact
            path="/Courseware/Courses/edit-Course:slug/navigationPath"
            component={LearningPath}
          />
          {/* {Banner Management} */}
          <Route
            exact
            path="/HomePage/BannerManagement/add-slide-show"
            component={AddSlideShow}
          />
          <Route
            exact
            path="/HomePage/BannerManagement/update-slide-show:slug"
            component={EditSlideShow}
          />
          <Route
            exact
            path="/HomePage/BannerManagement"
            component={SlideShowListing}
          />
          <Route
            exact
            path="/HomePage/BannerManagement/SlideShow-Sequence"
            component={SlideShowSequencing}
          />
          {/* NoticeBoard Management */}
          <Route
            exact
            path="/HomePage/NoticeBoardManagement/new-notice"
            component={AddNotice}
          />
          <Route
            exact
            path="/HomePage/NoticeBoardManagement"
            component={NoticeboardListing}
          />
          <Route
            exact
            path="/HomePage/NoticeBoardManagement/noticeboard-sequence"
            component={NoticeBoardSequence}
          />
          <Route
            exact
            path="/HomePage/NoticeBoardManagement/update-notice:slug"
            component={UpdateNotice}
          />
          {/* Content Repository */}
          <Route
            exact
            path="/Courseware/ContentRepository"
            component={ContentRepository}
          />
          <Route
            exact
            path="/Courseware/ContentRepository/addContentRepo"
            component={AddContentRepo}
          />
          <Route
            exact
            path="/Courseware/ContentRepository/:slug/add-Asset"
            component={AddAsset}
          />
          <Route
            exact
            path="/Courseware/ContentRepository/:slug/edit-Asset/:assetId"
            component={EditAsset}
          />
          <Route
            exact
            path="/Courseware/ContentRepository/editContentRepo"
            component={AddContentRepo}
          />
          {/* Variants Management */}
          <Route exact path="/Variants/VariantManagement" component={Variant} />
          <Route
            exact
            path="/Variants/VariantManagement/Variant-List/add-Variant"
            component={AddVariant}
          />
          <Route
            exact
            path="/Variants/VariantManagement/Variant-List/edit-Variant:slug"
            component={EditVariant}
          />
          {/* {Category Management} */}
          <Route
            exact
            path="/Variants/CategoryManagement"
            component={Category}
          />
          <Route
            exact
            path="/Variants/CategoryManagement/category-List/add-Category"
            component={AddCategory}
          />
          <Route
            exact
            path="/Variants/CategoryManagement/category-List/edit-Category:slug"
            component={EditCategory}
          />
          {/* Communication Management */}
          <Route
            exact
            path="/HomePage/CommunicationManagement"
            component={Communication}
          />
          <Route
            exact
            path="/HomePage/CommunicationManagement/new-Communication"
            component={AddCommunication}
          />
          <Route
            exact
            path="/HomePage/CommunicationManagement/edit-Communication"
            component={EditCommunication}
          />
          {/* calendar Management */}
          <Route
            exact
            path="/HomePage/CalendarManagement"
            component={Landing}
          />
          <Route
            exact
            path="/HomePage/CalendarManagement/add_event"
            component={AddEvent}
          />
          <Route
            exact
            path="/HomePage/CalendarManagement/Edit-Event"
            component={EditEvent}
          />

          {/* Access Management */}
          <Route exact path="/access-Management" component={AccessManagement} />
          <Route exact path="/access_Management/Add-User" component={AddUser} />
          <Route
            exact
            path="/access_Management/Edit-User"
            component={EditUser}
          />
          {/* Mentor Mapping */}
          <Route exact path="/mentor-listing" component={MentorListing} />
          <Route exact path="/add-mentor" component={AddMentor} />
          <Route exact path="/update-mentor" component={UpdateMentor} />
          {/* Masters */}
          <Route
            exact
            path="/courseware/master/ProductGroups"
            component={ProductGroup}
          />
          <Route
            exact
            path="/courseware/master/product-group"
            component={AddProductGroup}
          />
          <Route
            exact
            path="/courseware/master/product-group/edit"
            component={UpdateProductGroup}
          />
          <Route
            exact
            path="/courseware/master/Sections"
            component={Sections}
          />
          <Route
            exact
            path="/courseware/master/section"
            component={AddSection}
          />
          <Route
            exact
            path="/courseware/master/section/edit"
            component={UpdateSection}
          />
          <Route exact path="/courseware/master/Exams" component={Exams} />
          <Route exact path="/courseware/master/exam" component={AddExam} />
          <Route
            exact
            path="/courseware/master/exam/edit"
            component={UpdateExam}
          />
          <Route
            exact
            path="/courseware/master/ReservationCategory"
            component={Categories}
          />
          <Route
            exact
            path="/courseware/master/category"
            component={AddCategoryMaster}
          />
          <Route
            exact
            path="/courseware/master/category/edit"
            component={UpdateCategoryMaster}
          />
          <Route exact path="/courseware/master/Centres" component={Centres} />
          <Route exact path="/courseware/master/centre" component={AddCentre} />
          <Route
            exact
            path="/courseware/master/centre/edit"
            component={UpdateCentre}
          />
          <Route exact path="/courseware/master/Batches" component={Batches} />
          <Route exact path="/courseware/master/batch" component={AddBatch} />
          <Route
            exact
            path="/courseware/master/batch/edit"
            component={UpdateBatch}
          />
          {/* My Profile */}
          <Route exact path="/My-Profile" component={MyProfile} />
          {/* {Campaign Form} */}
          <Route exact path="/Courseware/CampaignForm" component={Home} />
          {/* {Doubts Management} */}
          <Route exact path="/HomePage/DoubtsManagement" component={Home} />
          <Route exact path="/browselp" component={BrowseLPcontainer} />

          {/* Schools */}
          <Route
            exact
            path="/School/School Details"
            component={SchoolDetails}
          />
          <Route exact path="/School/Add School" component={AddSchool} />
          <Route exact path="/School/Edit School" component={AddSchool} />

          <Route
            exact
            path="/EligibilityCriteria/CAT"
            component={EligibilityCriteria}
          />
          <Route exact path="/Cut-Offs/CAT" component={CutOffs} />
          <Route exact path="/Deadlines/CAT" component={Deadlines} />

          {/* Schools Events */}
          <Route exact path="/School/Events" component={Events} />
          <Route exact path="/School/AddEvents" component={AddEventforSchool} />
          <Route
            exact
            path="/School/EditEvents"
            component={EditEventForSchool}
          />

          {/* Schools Articles */}
          <Route exact path="/School/Articles" component={Articles} />
          <Route exact path="/School/Add Article" component={AddArticles} />
          <Route exact path="/School/Edit Article" component={EditArticles} />

          {/* schools */}
          <Route
            exact
            path="/schools/SchoolListing"
            component={SchoolListing}
          />
          <Route exact path="/schools/schoolModal" component={schoolModal} />

          {/* Selection Criteria */}
          <Route
            exact
            path="/School/SelectionCriteria"
            component={SelectionCriteria}
          />
          <Route
            exact
            path="/School/EligibilityCriteria"
            component={EligibilityCriteria}
          />
          <Route exact path="/School/Deadline" component={Deadlines} />
          <Route exact path="/Advertisement" component={AdvertisementListing} />

          <Route exact path="/School/SchoolDetails" component={SchoolDetails} />
          <Route
            exact
            path="/Advertisement Management/:slug"
            component={AdvertiseMentSlots}
          />
          {/* slot booking changes */}
          <Route
            exact
            path="/slot-management/"
            component={SlotManagementPage}
          />
          <Route
            exact
            path="/venue-management/"
            component={VenueManagementPage}
          />
          <Route exact path="/add-venue" component={AddVenue} />
          <Route exact path="/edit-venue/:id" component={EditVenue} />
          <Route exact path="/manage-slots/:id" component={ManageSlots} />
        </Switch>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </Suspense>
  );
}

export default App;
