import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  noticeBoardName: "",
  noticeBoardId: "",
  noticeBoardDetails:"",
};

export const noticeSlice = createSlice({
  name: "noticeData",
  initialState,
  reducers: {
    getnoticeDetails: (state, action) => {
      state.noticeBoardName = action.payload.noticeBoardName;
      state.noticeBoardId = action.payload.noticeBoardId;
      state.noticeBoardDetails=action.payload;
    },
  },
});

export const { getnoticeDetails } = noticeSlice.actions;

export default noticeSlice.reducer;
