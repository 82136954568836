import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  PostName: "",
  PostId: "",
  PostDetails:"",
};

export const postsSlice = createSlice({
  name: "postsData",
  initialState,
  reducers: {
    getPostDetails: (state, action) => {
      state.PostName = action.payload.postName;
      state.PostId = action.payload.postId;
      state.PostDetails=action.payload;
    },
  },
});

export const { getPostDetails } = postsSlice.actions;

export const selectedPostName = state => state.postsData.PostName;
export const selectedPostId = state => state.postsData.PostId;

export default postsSlice.reducer;
