import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  articlesData: {},
};

const articles_Slice = createSlice({
  name: "articlesData",
  initialState,
  reducers: {
    setarticlesData: (state, action) => {
      state.articlesData = action.payload;
    },
  },
});

export const { setarticlesData } = articles_Slice.actions;

export default articles_Slice.reducer;