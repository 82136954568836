/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import BreadCrumb from "../global/breadcrumbs/BreadCrumb";
import Header from "../header/Header";
import DropDown from "../../components/global/fields/DropDown";
import ToggleSchedule from "../../components/home/slideshow/ToggleSchedule";
import CalendarModalTrigger from "../../components/home/blogs/calendar/CalendarModalTrigger";
import Toggle from "../../components/global/fields/Toggle";
import DropDownForDuration from "../../components/home/blogs/Dropdown";
import {apirequest,apirequestIMSLIVE} from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import { useEffect } from "react";
import  TextEditor from "../global/editor/TextEditor";
import {  useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import WhiteSpace from "../global/regEx/WhiteSpaceValidator";
import {UploadFileToAWS} from "../../AWS/uploadFile";
import { useSelector } from "react-redux";
import DropdownSearch from "../../components/global/fields/CommunicationDropdown";

const EditCommunication = () => {
  const history = useHistory();
  const location = useLocation();
  const dataValues = location?.state.data;
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);

  const [activeTabForPublish , setActiveTabforPublish] = useState(dataValues.status === "Published" ? "now" : "schedule");

  const [communicationType, setCommunicationType] = useState(null);
  const [startdate ,setStartDate] = useState(dataValues.startDate ? dataValues.startDate : "");
  const [enddate, setEndDate] = useState(dataValues.endDate ? dataValues.endDate === "None" ? "" : dataValues.endDate : "");
  const setTime = dataValues.startTime  ? dataValues.startTime.split(":") : "";
  const[hour, setHour] = useState(setTime != "" ? (Array.from(String(setTime[0]), Number)[0]===0?Array.from(String(setTime[0]), Number)[1]:setTime[0]) : "");
  const[minutes, setMinutes] = useState(setTime != "" ? (Array.from(String(setTime[1]), Number)[0]===0?Array.from(String(setTime[1]), Number)[1]:setTime[1]) : "");
  const[seconds , setSeconds] = useState(setTime != "" ? (Array.from(String(setTime[1]), Number)[0]===0?Array.from(String(setTime[2]), Number)[1]:setTime[2]) : "");
  const [browseLink, setBrowseLink] = useState(dataValues.link ?? "");

  const [variantStatus , setVariantStatus] = useState(dataValues.isActive ? (dataValues.isActive === "0") ? "inactive":"active":"active");
  const [communicationData , setCommunicationData] = useState(dataValues.communicationName ? dataValues : {"communicationName":"","description":"","startDate":"","startTime":"0:00:00","endDate":"","link":""}); 
  const [editorDescription, setEditorDescription] = useState("");
  var startTime = (activeTabForPublish != "now") ? (hour && minutes && seconds) ? (hour + ":" + minutes + ":" + seconds) : "0:00:00": "0:00:00";

  let isActive = variantStatus ==="active" ? "1" : "0";
  // const [postAddCommunication, setPostAddCommunication] = useState(false);
  // States for getting the unique items for city , centre=========>so on
  const [getStateCode,setGetStateCode] = useState(communicationData?.states[0] === "All" ? ["All"] : communicationData?.states.length != 0 ? communicationData?.states.map((vl) => (vl.stateId)) : []);
  const [getCityCode , setGetCityCode]= useState(communicationData?.cities[0] === "All" ? ["All"] : communicationData?.cities.length != 0 ? communicationData?.cities.map((vl) => (vl.cityId)) : []);
  const[getBranchId , setGetBranchId] = useState(communicationData?.branches[0] === "All" ? ["All"] : communicationData?.branches.length != 0 ? communicationData?.branches.map((vl) => (vl.branchId)) : []);
  const [getCourseId, setGetCourseId] = useState(communicationData?.courses[0] === "All" ? ["All"] : communicationData?.courses.length != 0 ? communicationData?.courses.map((vl) => (vl.courseId)) : []);
  const [getVariantId, setGetVariantId] = useState(communicationData?.variants[0] === "All" ? ["All"] : communicationData?.variants.length != 0 ? communicationData?.variants.map((vl) => (vl.variantId)) : []);
  const [getBatchId, setGetBatchIdd] = useState(communicationData?.batches[0] === "All" ? ["All"] : communicationData?.batches.length != 0 ? communicationData?.batches.map((vl) => (vl.batchId)) : []);

  const AssetType = [
    {id :1 , productGroupName : "Email"},
    {id :2 , productGroupName : "WhatsApp"},
    {id:3 , productGroupName : "Push Notification"},
    {id:4 , productGroupName : "Maintenance Message"}
  ];
  // api for getting state details ===>
  const {data : getStatesData } = useQuery(`${endpoint.getStatesData.key}`, ()=> apirequest({
    url  : `${endpoint.getStatesData.addr}` }),{
  });

  // api to call the cities Data ==========>
  const {data: citiesData} = useQuery([endpoint.getCitiesData.key ,getStateCode], ()=>apirequest({
    url  : `${endpoint.getCitiesData.addr}`,
    method :"POST",
    data: {
      stateId : getStateCode
    } 
  }),{
    enabled : (!!JSON.stringify(getStateCode)),
    select : (res)=>res.data.data
  });
  
  // api call for getting centers ======>
  const { data: centerData } = useQuery(
    [endpoint.get_Centers_For_addCommunication.key , getCityCode],
    () =>
      apirequest({
        url: `${endpoint.get_Centers_For_addCommunication.addr}`,
        method: "POST",
        data: {
          cityId:   getCityCode ,
        },
      }),
    {
      enabled : (!!JSON.stringify(getCityCode)),
      select: (res) => res.data.data,
      // onSuccess : ()=>filterFunctionalityForCity()
    }
  );
  // api for getting courses names =====>
  const { data: coursesData } = useQuery(
    [endpoint.get_Courses_For_addCommunication.key , getBranchId],
    () =>
      apirequest({
        url: `${endpoint.get_Courses_For_addCommunication.addr}`,
        method: "post",
        data: {
          branchId:  getBranchId ,
          imsPin : login_Details.userImsPin
        },
      }),
    {
      enabled : (!!JSON.stringify(getBranchId)),
      select: (res) => res.data.data,
    }
  );
  // api call for getting variants ======>
  const { data: variantsData} = useQuery(
    [endpoint.get_Variants_For_addCommunication.key , getCourseId],
    () =>
      apirequestIMSLIVE({
        url: `${endpoint.get_Variants_For_addCommunication.addr}`,
        method: "post",
        data: {
          courseId: getCourseId,
        },
      }),
    {
      enabled : (!!JSON.stringify(getCourseId)) ,
      select: (res) => res.data.data,
    }
  );

  // api call for getting Batches ======>
  const { data: batchesData } = useQuery(
    [endpoint.get_Batches_For_addCommunication.key , getVariantId],
    () =>
      apirequestIMSLIVE({
        url: `${endpoint.get_Batches_For_addCommunication.addr}`,
        method: "post",
        data: {
          variantId:  getVariantId ,
          centreId : getBranchId
        },
      }),
    {
      enabled : (!!JSON.stringify(getVariantId)),
      select: (res) => res.data.data,
    }
  );
  
  
  useEffect(()=>{
    if(activeTabForPublish != "now"){
      setCommunicationData({...communicationData , "startDate" : startdate,"startTime":startTime});
    }else{
      setCommunicationData({...communicationData , "startDate" : "","startTime":"0:00:00"});
    }  
  }, [startdate,activeTabForPublish,startTime]);
  useEffect(() => {
    if (editorDescription != "") {
      setCommunicationData({...communicationData , ["description"] : editorDescription});
    }
  }, [editorDescription]);

  const handleChange = (e) => {
    setCommunicationData({...communicationData , [e.target.name] : e.target.value});
  };

  // api call to post communicationData ======>
  const fetchEditCommunicationRequest = () => {
    return apirequest({
      url: endpoint.update_Communication.addr,
      method: "put",
      data : {
        "communicationName": communicationData?.communicationName,
        "type": communicationType,
        "description": communicationData?.description,
        "link": communicationData?.link,
        "startDate": communicationData?.startDate,
        "startTime": startTime != "" ? startTime : "0:00:00",
        "endDate": enddate ? enddate : "",
        "isActive": isActive,
        "modifiedBy": login_Details.userImsPin,
        "communicationId": dataValues.communicationId,
        "stateId": getStateCode,
        "cityId": getCityCode,
        "branchId": getBranchId,
        "courseId": getCourseId,
        "variantId": getVariantId,
        "batchId": getBatchId
      }
    });
  };
  const {refetch:fetchEditCommunication} = useQuery(`${endpoint.update_Communication.key}`,fetchEditCommunicationRequest,{
    enabled:false,
    onSuccess: (data)=> {
      alert(data.data.message);
    },
    onError:(err)=>{
      alert(err.data.message);
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (communicationData.communicationName === "" || communicationType === ""|| WhiteSpace(communicationData.communicationName)==true) {
      // setPostAddCommunication(false);
      alert("Communication Title, Type and Description are mandatory!");
    }else if (activeTabForPublish ==="schedule" && communicationData.startDate === "") {
      // setPostAddCommunication(false);
      alert("Please fill Start Date!");
    }else if((Number(startdate.replace(/-/g, "")))>Number(enddate.replace(/-/g, ""))){
      alert("please select End Date after Start Date");
    }
    else if(communicationType === "Email" || communicationType === "WhatsApp"){
      if (communicationData?.description === "" || WhiteSpace(communicationData?.description)==true) {
        // setPostAddCommunication(false);
        alert("Communication Body is mandatory!");
      }else{
        // setPostAddCommunication(true);
        fetchEditCommunication();
      }
    }else if(communicationType === "Push Notification"){
      if (communicationData?.description === "" || WhiteSpace(communicationData?.description)==true) {
        alert("Communication Body is mandatory!");
      }else{
        // setPostAddCommunication(true);
        fetchEditCommunication();
      }
    }else if(communicationType === "Maintenance Message"){
      if (communicationData?.description === "" || WhiteSpace(communicationData?.description)==true) {
        // setPostAddCommunication(false);
        alert("Communication Body is mandatory!");
      }
      if (communicationData?.link === "" || WhiteSpace(communicationData?.link)==true) {
        // setPostAddCommunication(false);
        alert("Redirect URL is mandatory!");
      }
      if (enddate === "") {
        // setPostAddCommunication(false);
        alert("Please fill End Date!");
      }
      if (communicationData?.description != "" && communicationData?.link != "" && enddate != "") {
        fetchEditCommunication();
      }
    }
  };

  const handleBrowse = (event) => {
    event.preventDefault();
    setBrowseLink(event.target?.files[0].name);
    const fileDate = new Date().toLocaleString({hour12: true }).toString().split("/");
    if (event.target?.files[0]) {
      const val = UploadFileToAWS({key:"editCommunicationUpload",file:event.target?.files[0],path:"Asset/EditCommunication/"+fileDate[2].toString().split(",")[0]+"/"+event.target?.files[0].name.toString().split(".")[0]+"_"+login_Details.userImsPin+"_"+fileDate[2].toString().split(/[, ]+/)[1]+"."+event.target?.files[0].name.toString().split(".")[1]}); 
      {val.then(item=>setCommunicationData({...communicationData , "link" : item}));}
    }else{
      alert("No files available!");
    }
  };

  return (
    <div className="pb-9">
      <Header />
      <div className="mt-6 ml-2">
        <BreadCrumb />
      </div>
      <form>
        <div className="font-ibm flex justify-center mt-7">
          <div className="space-y-5 w-[93%] shadow-outer rounded-2xl p-10" >
            <div className="space-x-8">
              <label className="font-normal text-lg">Communication Title</label>
              <input placeholder="Enter Communication title" className="bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent" 
                name="communicationName"
                value={communicationData?.communicationName}
                onChange={handleChange}
                autoComplete="off"/>
            </div>
            <div className="flex justify-between mr-20">
              <div className="space-x-[155px] flex">
                <label className="font-normal text-lg leading-[40px]">State</label>
                {/* <DropDownSearch name = "stateName" options={getStatesData && getStatesData.data.data.stateDetails} customise={customise} placeholder={"Search"} defaultOption={defaultOption} getId={getStateCode} setId={setGetStateCode} setFieldValue={communicationData.states}/> */}
                <DropdownSearch 
                  setId={setGetStateCode}
                  getId = {getStateCode}
                  dropdownData = {getStatesData?.data?.data.stateDetails ?? []}
                  filterText = "State"
                  filterId="stateId"
                  filterValue="stateName"
                  val = {dataValues?.states}
                />
              </div>
              <div className="flex flex-row space-x-5">
                <label className="font-normal text-lg mr-6 leading-[40px]">City</label>
                {/* <DropDownSearch name ="cityName" options={getStateCode && (getStateCode[0] != "All" ?  getStatesData?.data?.data?.cityDetails.filter(b => getStateCode?.some(a => a === b.stateId)):getStatesData?.data?.data?.cityDetails)} customise={customise} placeholder={"Search"} defaultOption={defaultOption} getId={getCityCode} setId={setGetCityCode} setFieldValue={communicationData.cities}/> */}
                <DropdownSearch
                  setId={setGetCityCode}
                  getId = {getCityCode}
                  dropdownData = {citiesData } 
                  filterText = "City"
                  filterId= "cityId"
                  filterValue="cityName"
                  val ={dataValues?.cities}                  
                />
              </div>
            </div>
            
            <div className="flex justify-between mr-20">
              <div className="space-x-[145px] flex">
                <label className="font-normal text-lg leading-[40px]">Center</label>
                <div className="flex flex-row align-middle">
                  {/* <DropDownSearch name = "branchName" options={centerData && centerData?.data.data} customise={customise} placeholder={"Search"} defaultOption={defaultOption} getId={getBranchId} setId={setGetBranchId} setFieldValue={communicationData.branches}/> */}
                  <DropdownSearch  
                    setId={setGetBranchId}
                    getId = {getBranchId}
                    dropdownData = {centerData ?? []}
                    filterText ="Center"
                    filterId="branchId"
                    filterValue="branchName"
                    val = {dataValues.branches}
                  />
                </div>
              </div>
              <div className="flex space-x-5">
                <label className="font-normal text-lg mt-2 leading-[40px]">Courses</label>
                {/* <DropDownSearch  name="courseName" options={coursesData && coursesData?.data.data } customise={customise} placeholder={"Search"} defaultOption={defaultOption} getId={getCourseId} setId={setGetCourseId} setFieldValue={communicationData.courses}/> */}
                <DropdownSearch
                  setId={setGetCourseId}
                  getId ={getCourseId}
                  dropdownData = {coursesData ??[]}
                  filterText="Courses"
                  filterId="courseId"
                  filterValue="courseName"
                  val = {dataValues.courses}
                />
              </div>
            </div>

            <div className="flex justify-between mr-[77px]">
              <div className="space-x-[143px] flex">
                <label className="font-normal text-lg leading-[40px]">Variant</label>
                <div className="flex flex-row align-middle">
                  {/* <DropDownSearch  name = "variantName" options={variantsData && variantsData?.data.data } customise={customise} placeholder={"Search"} defaultOption={defaultOption} getId={getVariantId} setId={setGetVariantId} setFieldValue={communicationData.variants}/> */}
                  <DropdownSearch
                    setId={setGetVariantId}
                    getId = {getVariantId}
                    dropdownData = {variantsData ??[]}
                    filterText="Variants"
                    filterValue="variantName"
                    filterId="variantId"
                    val = {dataValues?.variants}
                  />
                </div>
              </div>
              <div className="flex flex-row space-x-5">
                <label className="font-normal text-lg mr-6 leading-[40px]">Batch</label>
                {/* <DropDownSearch name = "batchName" options={batchesData && batchesData?.data.data } customise={customise} placeholder={"Search"} defaultOption={defaultOption} getId={getBatchId} setId={setGetBatchIdd} setFieldValue={communicationData.batches}/> */}
                <DropdownSearch 
                  setId={setGetBatchIdd}
                  getId = {getBatchId}
                  dropdownData = {batchesData ?? []}
                  filterText="Batches"
                  filterId="batchId"
                  filterValue="batchName"
                  val = {dataValues?.batches}
                />
              </div>
            </div>

          
            <div className="flex mb-4 space-x-6">
              <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">Communication Type </label>
              <DropDown placeholder={"Enter Communication type"} options={AssetType} setUpdatedOptions={setCommunicationType} type={"singleSelect"} width={"w-[22rem]"} setFieldValue={setCommunicationType} valueReadProduct={dataValues.type}/>            
            </div>

            {/* Push Notification form =====================================> */}

            {communicationType ==="Push Notification" && 
          <React.Fragment>
            <div className="flex mb-4 space-x-11">
              <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3"  >Communication Body</label>
              <textarea  className="ml-5 bg-white border-2 border-secondary-300 h-36 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent" placeholder="Enter Description " 
                name="description"
                onChange={handleChange}
                value={communicationData.description}
              />
            </div>
            <div className="flex mb-4 space-x-10">
              <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3"  >Redirect URL</label>
              <input  className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent" placeholder="Paste URL" 
                name="link"
                onChange={handleChange}
                value={communicationData.link}/>
            </div>
            <div className="flex space-x-16 mb-4">
              <ToggleSchedule title="Publish" activeTab={activeTabForPublish} setActiveTab={setActiveTabforPublish} margin="28"/>
              {activeTabForPublish ==="schedule" &&<div className="flex  space-x-5">
                <label className="font-normal text-lg mt-2">Start Date</label>
                <aside className="flex">
                  <input placeholder="select Start Date"
                    value={communicationData.startDate}
                    readOnly
                    className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"/>  
                  <div className="relative top-4 right-8"><CalendarModalTrigger setDate={setStartDate} /></div>
                </aside>
              </div>}
            </div>
            {activeTabForPublish ==="schedule" && 
              <div className="flex mb-4">
                <label className=" font-normal text-lg font-ibm -mr-4">
                  Start time
                </label>
                <div className="ml-28">
                  <DropDownForDuration minOffset={0} maxOffset={23} text={"Hrs"} selectedValue={hour} setSelectedValue={setHour}/>
                </div>
                <div className="ml-2">
                  <DropDownForDuration minOffset={0} maxOffset={59} text={"Mins"} selectedValue={minutes} setSelectedValue={setMinutes} />
                </div>
                <div className="ml-2">
                  <DropDownForDuration minOffset={0} maxOffset={59} text={"Sec"} selectedValue={seconds} setSelectedValue={setSeconds}/>
                </div>
              </div>
            }
            <div className="flex  space-x-[100px] mb-4">
              <label className="font-normal text-lg mt-2">End Date</label>
              <div className="flex">
                <input
                  placeholder="select End Date" 
                  value= {enddate}
                  readOnly
                  className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"/>  
                <div className="relative top-4 right-8"><CalendarModalTrigger setDate={setEndDate} /></div>
              </div>
            </div>
            <Toggle title="Status" activeTab={variantStatus}  setActiveTab={setVariantStatus} margin="16"/>
          </React.Fragment>
            }
            {/* Email form Field =================> */}
            {communicationType==="Email" && 
          <React.Fragment>
            <div className="flex mb-4 space-x-11">
              <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3"  >Communication Body</label>
              <TextEditor customStyle={"border-2 border-secondary-300 border-solid rounded-sm h-60 max-w-3xl"} setGetEditorValue={setEditorDescription} setFieldValue={communicationData.description}/>
            </div>
            <div className="flex mb-4 space-x-8">
              <label className="inline-block w-[11%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3"  >Thumbnail</label>
              <input 
                className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-6/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent" placeholder="Paste Thumbnail Link" 
                name="link"
                onChange={handleChange}
                value={browseLink}
                readOnly={true}/>
              <label className="bg-primary-200 px-10 ml-2 py-3 rounded-xl text-white text-sm font-semibold cursor-pointer font-ibm">
                <input className="hidden " type="file" multiple onChange={handleBrowse} accept=".webp"/>Browse
              </label>
            </div>
            <div className="flex space-x-16 mb-4">
              <ToggleSchedule title="Publish" activeTab={activeTabForPublish} setActiveTab={setActiveTabforPublish} margin="32"/>
              {activeTabForPublish ==="schedule" &&<div className="flex  space-x-5">
                <label className="font-normal text-lg mt-2">Start Date</label>
                <aside className="flex">
                  <input placeholder="Select Start Date"
                    value={communicationData?.startDate}
                    readOnly
                    className="bg-white border-2 w-[100%] cursor-pointer border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"/>  
                  <div className="relative top-4 right-8"><CalendarModalTrigger setDate={setStartDate} /></div>
                </aside>
              </div>}
            </div>
            {activeTabForPublish ==="schedule" && 
              <div className="flex mb-4 space-x-[126px]">
                <label className=" font-normal text-lg font-ibm -mr-4">
                  Start time
                </label>
                <div className="ml-28">
                  <DropDownForDuration minOffset={0} maxOffset={23} text={"Hrs"}  selectedValue={hour} setSelectedValue={setHour}/>
                </div>
                <div className="ml-2">
                  <DropDownForDuration minOffset={0} maxOffset={59} text={"Mins"} selectedValue={minutes} setSelectedValue={setMinutes} />
                </div>
                <div className="ml-2">
                  <DropDownForDuration minOffset={0} maxOffset={59} text={"Sec"} selectedValue={seconds} setSelectedValue={setSeconds}/>
                </div>
              </div>
            }
            <Toggle title="Status" activeTab={variantStatus}  setActiveTab={setVariantStatus} margin="20"/>
          </React.Fragment>
            }
            {/* Whats App form field ============================> */}
            {communicationType==="WhatsApp" &&
            <React.Fragment>
              <div className="flex mb-4 space-x-11">
                <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3"  >Communication Body</label>
                <textarea  className="ml-5 bg-white border-2 border-secondary-300 h-36 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent" placeholder="Enter Communication type" 
                  name="description"
                  onChange={handleChange}
                  value={communicationData.description}/>
              </div>
              <div className="flex mb-4 space-x-8">
                <label className="inline-block w-[11%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3"  >Thumbnail</label>
                <input  className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-6/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent" placeholder="Paste Thumbnail Link" 
                  name="link"
                  onChange={handleChange}
                  value={browseLink}
                  readOnly={true}/>
                <label className="bg-primary-200 px-10 ml-2 py-3 rounded-xl text-white text-sm font-semibold cursor-pointer font-ibm">
                  <input className="hidden " type="file" multiple onChange={handleBrowse}/>Browse
                </label>
              </div>
              <div className="flex space-x-16 mb-4">
                <ToggleSchedule title="Publish" activeTab={activeTabForPublish} setActiveTab={setActiveTabforPublish} margin="32"/>
                {activeTabForPublish ==="schedule" &&<div className="flex  space-x-5">
                  <label className="font-normal text-lg mt-2">Start Date</label>
                  <aside className="flex">
                    <input placeholder="Select Start Date"
                      value={communicationData.startDate}
                      readOnly
                      className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"/>  
                    <div className="relative top-4 right-8"><CalendarModalTrigger setDate={setStartDate} /></div>
                  </aside>
                </div>}
              </div>
              {activeTabForPublish ==="schedule" && 
                <div className="flex mb-4 space-x-[126px]">
                  <label className=" font-normal text-lg font-ibm -mr-4">
                    Start time
                  </label>
                  <div className="ml-28">
                    <DropDownForDuration minOffset={0} maxOffset={23} text={"Hrs"}  selectedValue={hour} setSelectedValue={setHour}/>
                  </div>
                  <div className="ml-2">
                    <DropDownForDuration minOffset={0} maxOffset={59} text={"Mins"} selectedValue={minutes} setSelectedValue={setMinutes} />
                  </div>
                  <div className="ml-2">
                    <DropDownForDuration minOffset={0} maxOffset={59} text={"Sec"} selectedValue={seconds} setSelectedValue={setSeconds}/>
                  </div>
                </div>
              }
              <Toggle title="Status" activeTab={variantStatus}  setActiveTab={setVariantStatus} margin="20"/>
            </React.Fragment>
            } 
            {/* {Maintanence message form field ===========================>} */}
            {communicationType==="Maintenance Message" &&
            <React.Fragment>
              <div className="flex mb-4 space-x-11">
                <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3"  >Communication Body</label>
                <textarea  className="ml-5 bg-white border-2 border-secondary-300 h-36 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent" placeholder="Enter Communication type" 
                  name="description"
                  onChange={handleChange}
                  value={communicationData.description}/>
              </div>
              <div className="flex mb-4 space-x-10">
                <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3"  >Redirect URL</label>
                <input  className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent" placeholder="Paste URL" 
                  name="link"
                  value={communicationData.link}
                  onChange={handleChange}/>
              </div>
              <div className="flex space-x-16 mb-4">
                <ToggleSchedule title="Publish" activeTab={activeTabForPublish} setActiveTab={setActiveTabforPublish} margin="32"/>
                {activeTabForPublish ==="schedule" &&<div className="flex  space-x-5">
                  <label className="font-normal text-lg mt-2">Start Date</label>
                  <aside className="flex">
                    <input placeholder="Select Start Date"
                      value={communicationData.startDate}
                      readOnly
                      className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"/>  
                    <div className="relative top-4 right-8"><CalendarModalTrigger setDate={setStartDate} /></div>
                  </aside>
                </div>}
              </div>
              {activeTabForPublish ==="schedule" && 
                <div className="flex mb-4 space-x-[126px]">
                  <label className=" font-normal text-lg font-ibm -mr-4">
                    Start time
                  </label>
                  <div className="ml-28">
                    <DropDownForDuration minOffset={0} maxOffset={23} text={"Hrs"}  selectedValue={hour} setSelectedValue={setHour}/>
                  </div>
                  <div className="ml-2">
                    <DropDownForDuration minOffset={0} maxOffset={59} text={"Mins"} selectedValue={minutes} setSelectedValue={setMinutes} />
                  </div>
                  <div className="ml-2">
                    <DropDownForDuration minOffset={0} maxOffset={59} text={"Sec"} selectedValue={seconds} setSelectedValue={setSeconds}/>
                  </div>
                </div>
              }
              <div className="flex  space-x-[115px] mb-4">
                <label className="font-normal text-lg mt-2">End Date</label>
                <div className="flex">
                  <input
                    placeholder="Select End Date" 
                    value= {enddate}
                    readOnly
                    className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"/>  
                  <div className="relative top-4 right-8"><CalendarModalTrigger setDate={setEndDate} /></div>
                </div>
              </div>
              <Toggle title="Status" activeTab={variantStatus}  setActiveTab={setVariantStatus} margin="20"/>
            </React.Fragment>
            }
          </div>
        </div>
      </form>
      <div className="flex justify-center mt-5 space-x-5 mb-5">
        <button className="px-10 py-3 bg-primary-200 text-white rounded-2xl shadow-outer" onClick={handleSubmit}>Save</button>
        <button className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer" onClick={history.goBack}>Exit</button>
      </div>
    </div>
  );
};

export default EditCommunication;