import React, { useState } from "react";
import Header from "../../components/header/Header";
// import TableFilter from "../../components/table/TableFilter";
import CourseTable from "../../components/table/CourseTable";
import TablePagination from "../../components/table/TablePagination";
import pdf from "../../assets/icons/pdf.svg";
import { Link } from "react-router-dom";
import TableSearch from "../../components/table/TableSearch";
import { useQuery } from "react-query";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import Loader from "../../components/global/loader/Loader";

const CategoryMngt = () => {

  const [activeCategoryData, setActiveCategoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const {data , error, isFetching , isSuccess} = useQuery(
    `${endpoint.get_category.key}`,
    () => apirequest({ url: `${endpoint.get_category.addr}` }),
    {
      onSuccess: (res) => setActiveCategoryData(res),
      retry : 1
    }
  );

  const column = [{ col: "0", width: "95%" }];

  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;

  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };

  return (
    <div>
      <Header />
      {isFetching ? (
        <Loader />
      ) : (!isSuccess && error?.status !== 404) ? (
        <span>{error?.message}</span>
      ) : (
        <div className="courses">
          <div className="courseTable mt-9">
            <div className="courseTable__head flex justify-between  md:mt-[4%] md-mb-[5%] md:mx-9 pl-[2%]">
              <div className="flex">
                {/* <TableFilter placeholder={"Filter by: Title or Tag"} options={option.data}/> */}
                {/* <DropDown placeholder={"Filter by: Title or Tag"} options={filteredData} type={"tableFilter"}  width={"w-[22rem]"}/>             */}
              </div>
              <div className="ml-4 flex">
                <TableSearch
                  setDefaultData = {setActiveCategoryData}
                  defaultData = {data}
                  dataKey ="categoryName"
                  name = "Variants"
                  subModuleName="Category Management"
                  dataForFilter={data?.data?.data}
                  setCurrentPage ={setCurrentPage}
                  childModuleName =  ""
                  placeholder="Search by Category"
                >
                </TableSearch>
                {/* {(data?.data?.data?.global_add_access==="1"|| error?.data.message ==="No Record found") && */}
                <div className="ml-6 bg-primary-200 text-white font-normal px-6 rounded-xl hover:opacity-75 shadow-outer text-lg py-[7px]">
                  <Link
                    className="text-white hover:text-white"
                    to="/Variants/CategoryManagement/category-List/add-Category"
                  >
                    Add Category
                  </Link>
                </div>
              </div>
            </div>
            <div className="courseTable__body md:ml-[4%] md:mr-[34px] mt-4 shadow-outer pt-3 pl-[2%] pr-[3%] rounded-xl bg-white pb-[2%]">
              <CourseTable
                column={column}
                currentData={activeCategoryData?.data?.data?.filter((item)=>item.isActive != "Is Deleted").slice(
                  indexOfFirstItem,
                  indexOfLastItem
                )}
                colIcon={pdf}
                iconHeight={"2rem"}
              />
            </div>
            <div className="courseTable__footer mx-auto mb-7">
              <TablePagination
                data={activeCategoryData?.data?.data?.filter((item)=>item.isActive != "Is Deleted")}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
                dataPerPage={dataPerPage}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryMngt;
