import React from "react";
import { Tab } from "@headlessui/react";
import propTypes from "prop-types";

const TabPanel = ({ smallBanner, bigBanner, setSmallBanner, setBigBanner }) => {
  const handleAboveChannelFilterSlots = (array, setArray) => {
    setArray(() => [...array, "", "", "", ""]);
  };
  const handleBigBannerSlots = (array, setArray) => {
    setArray(() => [...array, ""]);
  };

  const handleChangeForAboveChannelFilter = (e, index, setArray) => {
    setArray((prevData) => {
      const value = prevData.slice();
      value[index] = e.target.value;
      return value;
    });
  };
  // const handleSlotReturn = (index) => {
  //   let val = index + 1;
  //   if (val > 4) {
  //     return val % 4 === 0 ? 4 : val % 4;
  //   }
  //   return val;
  // };

  return (
    <Tab.Panel
    // key={idx}
    >
      <section className="space-y-7">
        {/*****************************  FOR SMALL BANNER   *********************************** */}
        <h2 className="font-normal text-lg">Small banner</h2>
        <section className="flex space-x-4">
          <div className="grid grid-cols-4 mb-3">
            {smallBanner?.map((banner, index) => (
              <div key={index} className="flex space-x-4 items-center">
                <p className="font-normal text-lg">slot {index + 1}</p>
                <input
                  type="text"
                  placeholder="Id"
                  value={banner}
                  onChange={(e) =>
                    handleChangeForAboveChannelFilter(e, index, setSmallBanner)
                  }
                  className="bg-white border-2 mb-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none resize-none w-5/12 focus:placeholder-transparent placeholder-bold font-semibold"
                />
              </div>
            ))}
          </div>
          <p
            className="text-primary-200 underline cursor-pointer"
            onClick={() =>
              handleAboveChannelFilterSlots(smallBanner, setSmallBanner)
            }
          >
            Add Slots
          </p>
        </section>

        {/***************************************        FOR BIG BANNER       ************************************************* */}
        <h2 className="font-normal text-lg">Big banner</h2>
        <section className="flex space-x-4">
          <div className="grid grid-cols-4 mb-3">
            {bigBanner?.map((banner, index) => (
              <div key={index} className="flex space-x-4 items-center">
                <p className="font-normal text-lg">slot {index + 1}</p>
                <input
                  type="text"
                  placeholder="Id"
                  value={banner}
                  onChange={(e) =>
                    handleChangeForAboveChannelFilter(e, index, setBigBanner)
                  }
                  className="bg-white mb-2 border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none resize-none w-5/12 focus:placeholder-transparent placeholder-bold font-semibold"
                />
              </div>
            ))}
          </div>
          {bigBanner?.length < 2 && (
            <p
              className="text-primary-200 underline cursor-pointer"
              onClick={() => handleBigBannerSlots(bigBanner, setBigBanner)}
            >
              Add Slots
            </p>
          )}
        </section>
      </section>
    </Tab.Panel>
  );
};
TabPanel.propTypes = {
  smallBanner: propTypes.array,
  bigBanner: propTypes.array,
  setBigBanner: propTypes.func,
  setSmallBanner: propTypes.func,
};
export default TabPanel;
