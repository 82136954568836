import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ArrowDown from "../../../assets/icons/arrow-down.svg";
import ArrowUp from "../../../assets/icons/arrow-up.svg";
import propTypes from "prop-types";

function DropDown({ title, placeholder, options, setExamValue, setError, error }) {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  function setValue(option) {
    setExamValue(prevValue => ({
      ...prevValue,
      examId: option.examId,
      examName: option.examName,
    }));
    if(setError){
      setError((prev)=>({
        ...prev,
        examError : false
      }));
    }
    setIsDropDownOpen(false);
  }
  return (
    <div className="w-[22rem] flex justify-between">
      <OutsideClickHandler onOutsideClick={() => setIsDropDownOpen(false)}>
        {
          <div className="w-[22rem] relative top-0">
            <p
              onClick={() => setIsDropDownOpen(!isDropDownOpen)}
              className={`ml-5 bg-white  rounded-2xl pl-4 pr-5 py-3 border-solid focus:outline-none px-2 placeholder-bold  w-full cursor-pointer focus:placeholder-transparent font-semibold placeholder-bold border-2 ${
                error
                  ? "border-red-300"
                  : "border-secondary-300 font-semibold text-XL "
              } `}>
              {title || placeholder}
            </p>
            <span
              onClick={() => setIsDropDownOpen(!isDropDownOpen)}
              className="absolute top-3 opacity-70 -right-2 cursor-pointer">
              <img src={isDropDownOpen ? ArrowUp : ArrowDown} />
            </span>
            {error && (
              <p className=" text-xs text-red-400 mt-1 ml-7 ">* Required</p>
            )}
            {isDropDownOpen && (
              <div className="ml-5">
                <ul className="h-auto pt-2 w-full border-0 border-secondary-300 mb-5 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 shadow-lg absolute z-[1000] top-14 bg-white overflow-y-scroll scrollbar scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded  max-h-[10rem]">
                  {options.map((option, index) => (
                    <li
                      key={index}
                      className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize"
                      onClick={() => setValue(option)}>
                      {option.examName}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        }
      </OutsideClickHandler>
    </div>
  );
}

DropDown.propTypes = {
  title: propTypes.string,
  placeholder: propTypes.string,
  options: propTypes.array,
  setExamValue: propTypes.func,
  setError: propTypes.func,
  error: propTypes.bool,
};

export default DropDown;
