import React from "react";
import PropTypes from "prop-types";

function Toggle({ activeTab, title, setActiveTab }) {
  return (
    <div className="flex items-center">
      <p className=" text-lg font-normal font-ibm">{title}</p>
      <div className="flex bg-secondary-200 rounded-2xl ml-10 ">
        <p
          onClick={() => setActiveTab("now")}
          className={`py-3 px-5 rounded-2xl cursor-pointer   ${
            activeTab === "now"
              ? "bg-white shadow-outer font-ibm text-lg mb-0"
              : "font-ibm text-lg mb-0"
          } `}>
          Now
        </p>
        <p
          onClick={() => setActiveTab("customise")}
          className={`py-3 px-5 rounded-2xl cursor-pointer ${
            activeTab === "customise"
              ? "bg-white shadow-outer font-ibm text-lg mb-0"
              : "font-ibm text-lg mb-0"
          } `}>
          Customise
        </p>
      </div>
    </div>
  );
}

Toggle.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  title: PropTypes.string,
};

export default Toggle;
