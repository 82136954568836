import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MoveIcon from "../../../../assets/icons/move.svg";
import React from "react";
import propTypes from "prop-types";

const Sequencing = ({ users, setUsers, type }) => {
  const handleDragEnd = e => {
    if (!e.destination) return;
    let tempData = Array.from(users);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setUsers(tempData);
  };

  return (
    <div className="flex w-full">
      <div className="shadow-outer w-4/5 mt-4  rounded-2xl">
        <label className=" ml-6 mt-8 font-semibold font-ibm text-lg">
          {type}
        </label>
        <DragDropContext onDragEnd={handleDragEnd}>
          <table className=" w-full ">
            <Droppable droppableId="droppable-1">
              {provider => (
                <tbody ref={provider.innerRef} {...provider.droppableProps}>
                  {users?.map((user, index) => (
                    <Draggable
                      key={user.NoticeBoadName || user.slideshowName}
                      draggableId={
                        user.NoticeBoardId?.toString() ||
                        user.slideshowId?.toString()
                      }
                      index={index}>
                      {provider => (
                        <tr
                          {...provider.draggableProps}
                          ref={provider.innerRef}>
                          <td
                            {...provider.dragHandleProps}
                            width="50px"
                            height="60px">
                            <img className="ml-4" src={MoveIcon} alt="move" />
                          </td>

                          <td>{user.NoticeBoadName || user.slideshowName}</td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provider.placeholder}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>
    </div>
  );
};
Sequencing.propTypes = {
  users: propTypes.array,
  setUsers: propTypes.func,
  type: propTypes.string,
};
export default Sequencing;
