import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  courseDetails: {},
  editCourseTab: 0,
  editVariantTab: 0,
  slug: "",
};

const courseSlice = createSlice({
  name: "courseData",
  initialState,
  reducers: {
    setCourseDetails: (state, action) => {
      state.courseDetails = action.payload;
    },
    setEditCourseTab: (state, action) => {
      state.editCourseTab = action.payload;
    },
    setEditVariantTab: (state, action) => { 
      state.editVariantTab = action.payload;
    },
    setSlug: (state, action) => {
      state.slug = action.payload;
    },
  },
});

export const { setCourseDetails, setEditCourseTab, setEditVariantTab, setSlug } = courseSlice.actions;

export const selectEditCourseTab = state => state.courseData.editCourseTab;

export const selectEditVariantTab = state => state.courseData.editVariantTab;

export const selectSlug = state => state.courseData.slug;

export default courseSlice.reducer;
