import React, { useState } from "react";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import Header from "../../header/Header";
import SchoolDropdown from "../SchoolDropdown";

import { UploadFileToAWS } from "../../../AWS/uploadFile";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config";
import { apirequest } from "../../../API/api";
// import ImportSelectionCriteriaModal from "./ImportSelectionCriteriaModal";

const SelectionCriteria = () => {
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails); //get the login details stored in redux while login
 
  // Api call for Courses data 
  const { data: coursesData } = useQuery(endpoint.getSchoolCoursesApi.key, () =>
    apirequest({
      method: "post",
      url: endpoint.getSchoolCoursesApi.addr,
      data: {
        adminImsPin: login_Details.userImsPin,
      },
    })
  );
  const [parametersObject, setParameterObject] = useState({}); //store the first call and final call parameters 
  const [selectedCourse, setSelectedCourse] = useState(""); //state to store the selected course Id
  const [firstCallCSVfile, setFirstCallCSV] = useState(""); //state to store the selected CSV file (first call)
  const [finalCallCSVFile, setFinalCallCSV] = useState("");   //state tp store the selected CSV file (final call)
  
  //function to call the S3 bucket function and setting the value
  const handleBrowse = (event, setBrowseLink, path) => {
    event.preventDefault();
    setBrowseLink(event.target?.files[0].name);   //setting the selected CSV file 
    const fileDate = new Date()
      .toLocaleString({ hour12: true })
      .toString()
      .split("/");

    if (event.target?.files[0]) {
      const val = UploadFileToAWS({
        key: "addSlideShowUpload",
        file: event.target?.files[0],
        path:
          path +
          fileDate[2].toString().split(",")[0] +
          "/" +
          event.target?.files[0].name.toString().split(".")[0] +
          "_" +
          login_Details.userImsPin +
          "_" +
          fileDate[2].toString().split(/[, ]+/)[1] +
          "." +
          event.target?.files[0].name.toString().split(".")[1],
      });
      {
        val.then((item) => setBrowseLink(item));
      }
    } else {
      alert("No files available!");
    }
  };
  
  const [CSVToDownload , setCSVtoDownload] = useState(null);
  const {refetch :  DownloadApi} = useQuery(endpoint.downloadSelectionCriteriaCSV.addr,()=> apirequest({
    method  : "POST" , 
    url :endpoint.downloadSelectionCriteriaCSV.addr,
    data : {
      course : selectedCourse
    }
  }), {
    enabled : false , 
    onSuccess : (res)=>{
      const fileContent = res[0][CSVToDownload];

      const a = document.createElement("a");
      a.href = fileContent;
      a.download = `${CSVToDownload}.csv`;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    select : (res)=>res.data.data,
    retry :1
  });

  const handleClick = (csvCall)=>{
    setCSVtoDownload(csvCall);
    selectedCourse !=""&& DownloadApi();
  };
   

  //Api call to add the selection criteria ========>
  const {refetch} = useQuery(endpoint.addSelectionCriteriaAPI.key,()=>apirequest({
    method : "Post",
    url : endpoint.addSelectionCriteriaAPI.addr,
    data : {
      ...parametersObject,
      firstCallCSV : firstCallCSVfile,
      finalCallCSV : finalCallCSVFile,
      createdBy : login_Details.userImsPin,
      course:selectedCourse
    }
  }),{
    enabled : false,
    onSuccess:(res)=>alert(res.data.message),
    onError : (err)=>alert(err.data.message)
  });
  // api call ends here
  return (
    <div>
      <Header />
      <div className="flex justify-between">
        <BreadCrumb />
      </div>

      <div>
        {/* {" "}
          <div className="grid grid-cols-2 gap-2 w-[500px] h-[50px] pt-2">
            <button
              className="px-8 py-3 bg-primary-300 text-white h-[50px] rounded-2xl shadow-outer "
              // onClick={handleClick}
            >
              Import Selection Criteria
            </button>
            <button
              className="px-8 py-3 bg-primary-300 text-white  h-[50px] rounded-2xl shadow-outer cursor-pointer"
              //   onClick={handleClick}
            >
              Download recent CSV
            </button>

      </div>  */}
        <div>
          <div className="mb-4 pl-10 flex gap-10 pt-5">
            <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
              Course Name
            </label>
            <div className="ml-[80px]">
              <SchoolDropdown
                dropdownData={coursesData?.data?.data}
                filterId="courseId"
                filterValue="courseName"
                selectedValue={selectedCourse}
                setSelectedValue={setSelectedCourse}
                forSingleSelect={true}
              />
            </div>
          </div>
          <div className="mb-4 pl-10 flex gap-10">
            <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
              Parameters to be displayed <br />
              (First Call)
            </label>
            <input
              className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL "
              type="text"
              name="firstCallParametersToBeDisplayed"
              placeholder="Type the  value and press Enter"
              onChange={(e) =>
                setParameterObject({
                  ...parametersObject,
                  [e.target.name]: e.target.value.split(","),
                })
              }
              required
              autoComplete="off"
            />
            <button
              className="px-3 bg-primary-300 text-white h-10  text-sm rounded-2xl  font-semibold  shadow-outer cursor-pointer"
              onClick = {()=>handleClick ("firstCallCSVPath")}
            >
              Download First Call CSV
            </button>
          </div>
          <div className="mb-4 pl-10 flex gap-10">
            <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
              Upload CSV (First Call)
            </label>
            <input
              className="ml-20 relative bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
              type="text"
              name="UploadCSV"
              placeholder="No File Selected"
              value={firstCallCSVfile}
              required
              autoComplete="off"
              readOnly
            />
            <label className=" px-3 bg-primary-300 text-white h-10  text-sm rounded-2xl flex text-center items-center font-semibold  shadow-outer cursor-pointer">
              <input
                className="hidden"
                type="file"
                multiple
                onChange={(e) =>
                  handleBrowse(
                    e,
                    setFirstCallCSV,
                    "School/SelectionCriteria/FirstCall"
                  )
                }
                accept=".csv"
              />
              Choose File
            </label>
          </div>
          {/* Parameters to be displayed(Final Call) */}
          <div className="mb-4 pl-10 flex gap-10">
            <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
              Parameters to be displayed <br />
              (Final Call)
            </label>
            <input
              className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL"
              type="text"
              name="finalCallParametersToBeDisplayed"
              placeholder="Type the  value and press Enter"
              onChange={(e) =>
                setParameterObject({
                  ...parametersObject,
                  [e.target.name]: e.target.value.split(","),
                })
              }
              required
              autoComplete="off"
            />
            <button
              className="px-3 bg-primary-300 text-white h-10  text-sm rounded-2xl  font-semibold  shadow-outer cursor-pointer"
              onClick = {()=>handleClick("finalCallCSVPath")}
            >
              Download Second Call CSV
            </button>
          </div>

          {/* Upload CSV (Final Call)     */}
          <div className="mb-4 pl-10 flex gap-10">
            <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
              Upload CSV (Final Call)
            </label>
            <input
              className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-black"
              type="text"
              name="FinalCallUploadCSV"
              placeholder="No File Selected"
              value={finalCallCSVFile}
              required
              autoComplete="off"
              readOnly
            />
            <label className=" px-3 bg-primary-300 text-white h-10 flex text-center items-center text-sm rounded-2xl  font-semibold  shadow-outer cursor-pointer">
              <input
                className="hidden"
                type="file"
                multiple
                onChange={(e) =>  //function calls to upload a csv file
                  handleBrowse(   
                    e,
                    setFinalCallCSV,
                    "School/SelectionCriteria/FinalCall"
                  )
                }
                accept=".csv"  //for Accepting only csv file
              />
              Choose File
            </label>
          </div>
          <div className="flex justify-center mt-5 space-x-5">
            <button onClick={refetch} className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer cursor-pointer">
              Save
            </button>
            {/* <button className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer cursor-pointer">
              Exit
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectionCriteria;
