import React, { useState } from "react";
import Header from "../../header/Header";
import Toggle from "../../global/fields/Toggle";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import DropDown from "../../global/fields/CourseDropDown";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import WhiteSpace from "../../global/regEx/WhiteSpaceValidator";

const FormBlog = () => {
  const [activeTab, setActiveTab] = useState("active");
  const [blogName, setBlogName] = useState("");
  const [blogUrl, setBlogUrl] = useState("");
  const [courses, setCourses] = useState("");
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  let history = useHistory();

  const [showLabel, setShowLabel] = useState(
    [],
  );
  const [courseTags, setCourseTags] = useState([]);

  //API request to get course data
  const { data: getCourseData } = useQuery(endpoint.course_data.key, ()=>apirequest({
    url : `${endpoint.course_data.addr}`,
    method : "post",
    data :{
      "adminimspin":login_Details.userImsPin
    }
  }),{
    refetchOnMount : true
  });

  const fetchAddBlog = () => {
    return apirequest({
      url: `${endpoint.add_blogs.addr}`,
      method: "post",
      data: {
        blogName: blogName,
        blogUrl: blogUrl,
        isActive: activeTab == "active" ? "1" : "0",
        createdBy: login_Details.userImsPin,
        courseName: courseTags,
      },
    });
  };
  //API request to add new blogs data
  const {refetch: addBlogRefetch } = useQuery(
    endpoint.add_blogs.key,
    fetchAddBlog,
    {
      enabled: false,
      onSuccess :(res)=>{alert(res.data.message),window.location.reload(false);},
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );

  const onsave = e => {
    e.preventDefault();
    if(WhiteSpace(blogName)===true){
      alert("Please enter a Valid Blog Name");
    }
    else if(WhiteSpace(blogUrl)===true){
      alert("Please enter Blog URL");
    }
    // to make course selection mandatory
    else if(showLabel.length===0){
      alert("Please Select Course");
    }    
    else{
      addBlogRefetch();
    }
  };

  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />
      <div className="mt-2 mx-12  px-8 py-2  ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
          onSubmit={onsave}>
          <div className="h-full space-y-6  px-12 py-6 relative">
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Blog Name
                <input
                  required
                  type="text"
                  name="blogName"
                  className="border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg focus:outline-none"
                  value={blogName}
                  onChange={e => setBlogName(e.target.value)}
                />
              </label>
            </div>
            <div>
              <label className=" font-normal text-lg font-ibm py-5">
                Blog Url
                <input
                  required
                  name="url"
                  className="focus:outline-none border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg"
                  value={blogUrl}
                  onChange={e => setBlogUrl(e.target.value)}
                />
              </label>
            </div>
            <div className="flex py-5 space-x-14">
              <label className=" font-normal text-lg font-ibm ">Courses</label>
              <DropDown
                placeholder={"Search Course(s)"}
                options={getCourseData?.data?.data?.courseList}
                setUpdatedOptions={setCourseTags}
                type={"multiselect"}
                width={"w-[22rem]"}
                courseTags={courseTags}
                showLabel={showLabel}
                setShowLabel={setShowLabel}
                value={courses}
                setValue={setCourses}
                forCourses ={true}
              />
            </div>
            <Toggle
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              title="This Blog is"
            />
          </div>
          <div className="flex space-x-6 absolute left-[40%] -bottom-16">
            <button className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
              SAVE
            </button>
            <p onClick={()=>history.goBack()} className=" p-3 text-lg font-ibm font-normal cursor-pointer border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
              EXIT
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormBlog;
