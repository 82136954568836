import React, { useState } from "react";
import WithModal from "../../global/modal/WithModal/WithModal";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import propTypes from "prop-types";
import addbutton from "../../../assets/icons/addButton.svg";
import deleteButton from "../../../assets/icons/cancel.svg";

const NewDescription = ({
  selectedVariantName,
  nestedItemslist,
  setTagDetails,
  setVariantDescription,
  closeModal,
  setPriceDetails,
  nestedItemslistsArray,
  forEditVariantId
}) => {
 
  const [data, setData] = useState(nestedItemslist?.description ?nestedItemslist?.description : [""]);
  const [tags, setTags] = useState(nestedItemslist?.tag ??[]);
  const [prize, setPrize] = useState(nestedItemslist?.price ?nestedItemslist?.price : "");

  const handleClick = () => {
    let arrayLength = data?.length;
    setData(arrayLength > 0 ? [...data, ""] : [""]);
    if (arrayLength === 0) {
      arrayLength = arrayLength + 1;
    }
  };
  const handleAddTags = () => {
    let arrayLength = tags?.length;
    setTags(arrayLength > 0 ? [...tags, ""] : [""]);
    if (arrayLength === 0) {
      arrayLength = arrayLength + 1;
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setData((newData) => {
      const value = newData?.slice();
      value[index] = e.target.value;
      return value;
    });
  };
  const handleTags = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setTags((newTags) => {
      const value = newTags.slice();
      value[index] = e.target.value;
      return value;
    });
  };
  let indexupdated = nestedItemslistsArray.findIndex(item=>item.variantId === forEditVariantId);

  const handleSubmit = () => {
    setVariantDescription(data);
    setTagDetails(tags);
    setPriceDetails(prize);
    // conditions added to show the updated value in modal of variants
    let index = nestedItemslistsArray.findIndex(item=>Object.keys(item).includes("value"));
    nestedItemslistsArray[index] = {
      tag:tags,
      price:prize,
      description:data,
      variantName : selectedVariantName,
      variantId : Math.random(0,1)
    };
    
    nestedItemslistsArray[indexupdated] = {
      tag:tags,
      price:prize,
      description:data,
      variantName : selectedVariantName,
      variantId : Math.random(0,1)
    };
  };
  const handleDeleteForTags = (removeableTag) => {
    setTags((value) =>
      value.filter((item) => {
        return item !== removeableTag;
      })
    );
  };

  return (
    <div className="w-[900px] p-20  space-y-5 ">
      <div className="flex justify-between">
        <p className="text-lg font-medium">
          Variant Name :{" "}
          <span className="underline">
            {nestedItemslist?.variantName
              ? nestedItemslist?.variantName
              : selectedVariantName}
          </span>{" "}
        </p>
        <div className="flex space-x-5">
          <button
            onClick={handleClick}
            className="px-10 py-3 bg-primary-300 text-white flex justify-center rounded-2xl shadow-outer"
          >
            Add description
          </button>
          <Cancel onClick={closeModal} className="cursor-pointer" />
        </div>
      </div>
      <div className="space-x-20">
        <label className="text-lg font-medium">Price : </label>
        <input
          placeholder="Please Enter amount of variant"
          value={prize}
          onChange={(e) => setPrize(e.target.value)}
          className="bg-white border-2  border-secondary-300 rounded-2xl pl-4 pr-5 py-2 w-[70%] border-solid  px-2 focus:outline-none placeholder-bold font-normal text-base focus:placeholder-transparent"
        />
      </div>
      <div className="space-x-5 flex">
        <label className="text-lg font-medium mr-16">Tags : </label>
        {tags?.map((item, index) => (
          <div className="flex " key={index}>
            <input
              placeholder="Enter a tags here"
              onChange={handleTags}
              value={item}
              id={index}
              key={index}
              className="bg-white border-2  border-secondary-300 rounded-2xl pl-4 pr-5 py-2 w-[138px] border-solid  px-2 focus:outline-none placeholder-bold font-normal text-base focus:placeholder-transparent"
            />
            <img
              src={deleteButton}
              className="w-10 h-10 cursor-pointer"
              onClick={() => handleDeleteForTags(item)}
            />
          </div>
        ))}
        <img src={addbutton} onClick={handleAddTags} />
      </div>
      {data?.map((item, index) => (
        <div key={index} className="flex space-x-3">
          <label className="mt-2 text-lg font-medium">
            Description {index + 1} :
          </label>
          <input
            placeholder="Enter description"
            id={index}
            value={item}
            onChange={handleChange}
            className="bg-white border-2  border-secondary-300 rounded-2xl pl-4 pr-5 py-2 w-[70%] border-solid  px-2 focus:outline-none placeholder-bold font-normal text-base focus:placeholder-transparent"
          />
        </div>
      ))}
      <div>
        <button
          className="px-10 py-3 bg-primary-300 text-white flex justify-center rounded-2xl shadow-outer"
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
};

NewDescription.propTypes = {
  closeModal: propTypes.func,
  nestedItemslist: propTypes.object,
  data: propTypes.array,
  setVariantDescription: propTypes.func,
  onCloseFunctions: propTypes.func,
  setTagDetails: propTypes.func,
  setPriceDetails: propTypes.func,
  selectedVariantName: propTypes.string,
  variantId: propTypes.string,
  setUpdateVariantData : propTypes.func,
  setVariantDataForNewCategory : propTypes.func,
  setAddVariantData : propTypes.func,
  categoryId : propTypes.string,
  updateVariantData :propTypes.array ,
  nestedItemslistsArray : propTypes.array,
  setNestedItemsLists : propTypes.func,
  variant_ID: propTypes.string,
  forEditVariantId : propTypes.string
};
export default WithModal(NewDescription);
