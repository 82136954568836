import React from "react";
import propTypes from "prop-types";

function CutOff({ cutOffCategories, setCutOffCategories }) {
  function handleChange(event, categoryId, sectionId) {
    const value = event.target.value;
    let duplicate = cutOffCategories;
    let categoryIndex = duplicate.findIndex(
      cat => cat.category_id === categoryId,
    );
    if (sectionId === -1) {
      duplicate[categoryIndex].overAll = value;
    } else {
      let sectionIndex = duplicate[categoryIndex].sections.findIndex(
        section => section.section_id === sectionId,
      );
      duplicate[categoryIndex].sections[sectionIndex].value = value;
    }
    setCutOffCategories([...duplicate]);
  }
  return (
    <div className=" grid grid-cols-4 gap-x-20 gap-y-14 ">
      {cutOffCategories?.map(category => (
        <div key={category.category_id}>
          <label className="inline-block text-left whitespace-nowrap font-normal font-ibm text-lg w-32 mr-5 mb-6 ">
            {category.reservationCategoryName}
          </label>
          <div className="flex items-center mb-3 ">
            <label className="inline-block text-left whitespace-nowrap font-normal font-ibm text-base w-16 mr-5 ">
              Overall
            </label>
            <input
              value={category.overAll}
              className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent"
              placeholder="Score"
              autoComplete="off"
              onChange={e => handleChange(e, category.category_id, -1)}
            />
          </div>
          {category?.sections?.map(section => (
            <div key={section.sectionId} className="flex items-center mb-3 ">
              <label className="inline-block text-left whitespace-nowrap font-normal font-ibm text-base w-16 mr-5 ">
                {section.name}
              </label>
              <input
                className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                placeholder="Score"
                value={section.value}
                autoComplete="off"
                onChange={e =>
                  handleChange(e, category.category_id, section.section_id)
                }
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

CutOff.propTypes = {
  cutOffCategories: propTypes.array,
  setCutOffCategories: propTypes.func,
};

export default CutOff;
