import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventSchoolData: {},
};

const event_School_Slice = createSlice({
  name: "eventSchoolData",
  initialState,
  reducers: {
    seteventSchoolData: (state, action) => {
      state.eventSchoolData = action.payload;
    },
  },
});

export const { seteventSchoolData } = event_School_Slice.actions;

export default event_School_Slice.reducer;