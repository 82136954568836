import React from "react";
import Dropdown from "../blogs/Dropdown";
import DropDown from "../../global/fields/CourseDropDown";
import CalendarModalTrigger from "../blogs/calendar/CalendarModalTrigger";
import propTypes from "prop-types";

const Recurring = ({
  date,
  setDate,
  type,
  setType,
  Endsvalue,
  setEndsValue,
  handleFilters,
  repeat,
  setRepeat,
  repeatValue,
  setRepeatValue,
  startDate,
  Checked, 
  setChecked
}) => {
  const options = [
    {
      courseId: "1",
      courseName: "day",
    },
    {
      courseId: "2",
      courseName: "Week",
    },
    {
      courseId: "3",
      courseName: "month",
    },
    {
      courseId: "4",
      courseName: "year",
    },
  ];
  
  const availableDates = [
    { id: "Sun", date: "S" },
    { id: "Mon", date: "M" },
    { id: "Tue", date: "T" },
    { id: "Wed", date: "W" },
    { id: "Thu", date: "T" },
    { id: "Fri", date: "F" },
    { id: "Sat", date: "S" },
  ];
  

  const handleToggle = value => {
    const currentIndex = Checked.indexOf(value);
    const newChecked = [...Checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    handleFilters(newChecked);
  };


  const onValueChange = event => {
    setType(event.target.value);
  };
  return (
    <div className="flex-col shadow-outer py-8 space-y-8 pl-16">
      <div className="flex space-x-5">
        <label className="font-normal text-lg font-ibm inline-block">
          Repeat Every
        </label>
        <div className="flex"></div>
        <Dropdown
          minOffset={1}
          maxOffset={24}
          text={""}
          selectedValue={repeat}
          setSelectedValue={setRepeat}
        />
        <DropDown
          placeholder={"select type"}
          options={options}
          type={"singleSelect"}
          width={"w-[10rem]"}
          readonly={false}
          value={repeatValue}
          setValue={setRepeatValue}
          padding={true}
        />
      </div>
      <div className="flex space-x-9">
        {/* to show the day selected in edit event */}
        {repeatValue==="Week" && 
        <>
          <label className="font-normal text-lg font-ibm">Repeat On</label>
          {availableDates.map((value, index) => (
            <div
              key={index}
              className={`w-[60px] h-12  shadow-outer items-center cursor-pointer  rounded-full ${
                Checked.indexOf(value.id) === -1 
                  ? " bg-secondary-200 "
                  : " bg-primary-200"
              }
              
          } `}>
              <div className="flex flex-col items-center cursor-pointer mt-2">
                <span
                  className="font-ibm font-medium text-base"
                  onClick={() => handleToggle(value.id)}>
                  {value.date}
                </span>
              </div>
            </div>
          ))}
        </>
        }
        {repeatValue==="month" && startDate &&
        <div>
          <p className="font-normal text-lg font-ibm inline-block">{`Monthly on day ${startDate.split("-")[2]}`}</p>
        </div> }
      </div>
      <div>
        <div>
          
        </div>
      </div>
      <div className="flex space-x-28">
        <label className="font-normal text-lg font-ibm">Ends</label>
        {/* <div className="radio"> */}
        <div className="grid grid-cols-1 space-y-5">
          <label className="font-normal text-lg font-ibm">
            <input
              type="radio"
              value="Never"
              checked={type === "Never"}
              onChange={onValueChange}
            />
            Never
          </label>
          <div className="flex">
            <label className="font-normal text-lg font-ibm ">
              <input
                type="radio"
                value="On"
                checked={type === "On"}
                onChange={onValueChange}
              />
              On
            </label>
            {type === "On" && (
              <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    date
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"
                  }`}>
                  {date && date}
                </p>
                <CalendarModalTrigger setDate={setDate} />
              </div>
            )}
          </div>
          <div className="flex">
            <label className="font-normal text-lg font-ibm ">
              <input
                type="radio"
                value="After"
                checked={type === "After"}
                onChange={onValueChange}
              />
              After
            </label>
            {type === "After" && (
              <>
                <Dropdown
                  minOffset={1}
                  maxOffset={24}
                  text={""}
                  selectedValue={Endsvalue}
                  setSelectedValue={setEndsValue}
                />
                <p className="font-normal text-lg font-ibm">Occurences</p>
              </>
            )}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};
Recurring.propTypes = {
  date: propTypes.string,
  setDate: propTypes.func,
  type: propTypes.string,
  setType: propTypes.func,
  Endsvalue: propTypes.number,
  setEndsValue: propTypes.func,
  repeat: propTypes.number,
  setRepeat: propTypes.func,
  handleFilters: propTypes.func,
  repeatValue: propTypes.string,
  setRepeatValue: propTypes.func,
  startDate:propTypes.string,
  Checked:propTypes.array,
  setChecked:propTypes.func,
};

export default Recurring;
