import React, { useState } from "react";
import Header from "../../components/header/Header";
import { Link } from "react-router-dom";
import SlideshowTable from "../../components/home/slideshow/SlideshowTable";
import TablePagination from "../../components/table/TablePagination";
import TableSearch from "../../components/table/TableSearch";
import DocumentPlusIcon from "../../assets/icons/document.svg";
import BreadCrumb from "../../components/global/breadcrumbs/BreadCrumb";
import Loader from "../../components/global/loader/Loader";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import { globalFilter } from "../../components/global/globalFilter/GlobalFilter";

const SectionListing = () => {
  
  const modulesData = globalFilter({name : "Courseware", subModuleName : "Master" , childName : "Sections"});

  const column = [
    { col: "1", width: "5%" },
    { col: "2", width: "91.5%" },
    { col: "3", width: "" },
    { col: "4", width: "20%" },
  ];


  const [sectionData , setSectionsdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // API's for section Listing
  const sectionListing = () => {
    return apirequest({
      url: `${endpoint.get_sections.addr}`,
      method: "post",
      data: {
        adminimspin:modulesData.imsPin,
      }
    
    });
  };

  const {data : sectionDefaultData , error,isSuccess :is_section_success,status } = useQuery(
    endpoint.get_sections.key,
    sectionListing,{
      onSuccess : (res)=>setSectionsdata(res)
    }
  );


  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };

  return (
    <div id="courses">
      <Header />
      {status === "loading" ? (
        <Loader />
      ) : !is_section_success && error?.status !== 404 ? (
        <span>{error?.message}</span>
      ) : (
        <div className="courses">
          <div className="courseTable mt-9">
            <div className="courseTable__head flex justify-between  md:mt-[2%] md-mb-[5%] md:mx-9 pl-[2%]">
              <div className="flex">
                <BreadCrumb />
              </div>

              <div className="ml-4 flex">
                {is_section_success &&
                <TableSearch
                  defaultData = {sectionDefaultData}
                  setDefaultData = {setSectionsdata}
                  dataKey = "sectionName"
                  dataForFilter={sectionDefaultData?.data.data.sectionlist}
                  name ="Courseware"
                  subModuleName="Master"
                  setCurrentPage={setCurrentPage}
                  childName = "Sections"
                  childModuleName=""
                  placeholder="Search by Sections"
                />
            
                }
                {(sectionData?.data?.data?.global_add_access==="1" || error?.data.message ==="No Record found") &&
                <Link to="/courseware/master/section">
                  <button className="ml-6 bg-primary-200 text-white font-medium px-6 rounded-xl hover:opacity-75 shadow-outer font-ibm w-40 h-[56px]">
                    Add New Section
                  </button>
                </Link>}
              </div>
            </div>
            <div className="courseTable__body md:ml-[4%] md:mr-[34px] mt-4 shadow-outer pl-[2%] pr-[5%] rounded-xl bg-white pb-[2%]">
              <SlideshowTable
                icon={DocumentPlusIcon}
                column={column}
                currentData={sectionData?.data?.data?.sectionlist.slice(
                  indexOfFirstItem,
                  indexOfLastItem,
                )}
                type={"course"}
              />
            </div>
            <div className="courseTable__footer mx-auto mb-7">
              <TablePagination
                data={sectionData?.data?.data?.sectionlist}
                dataPerPage={dataPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionListing;
