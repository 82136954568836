import React, { useEffect, useState } from "react";
import CheckBoxActive from "../../assets/icons/tickMark_withBg.svg";
import propTypes from "prop-types";

const CentreCheckBoxItem = ({ item, newArr, setNewArr, id }) => {
  //   alert(JSON.stringify(newArr));
  const arrayToObject = (newArr) => {
    const res = {};
    for (let i = 0; i < newArr?.length; i++) {
      if (id === "2" && "centre_id" in item) {
        const key = newArr[i]["centreId"];
        res[key] = newArr[i];
      }
      // added id authentication based on id prop
      const key = id === "1" ? newArr[i]["centreId"] : newArr[i]["centreId"];
      res[key] = newArr[i];
    }
    return res;
  };
  const access = arrayToObject(newArr);
  // alert(JSON.stringify(access));

  let itemAccess =
    access[id === "2" && "centre_id" in item ? item?.centre_id : ""];

  const [viewchecked, setViewChecked] = useState(
    itemAccess?.viewAccess === "1" ? true : false
  );
  const [editchecked, setEditChecked] = useState(
    itemAccess?.editAccess === "1" ? true : false
  );
  const [deleteChecked, setDeleteChecked] = useState(
    itemAccess?.deleteAccess === "1" ? true : false
  );

  let viewAccessStatus = viewchecked ? "1" : "0";
  let editAccessStatus = editchecked ? "1" : "0";
  let deleteAccessStatus = deleteChecked ? "1" : "0";
  //   const condition_For_ProductGroup = (val) => {
  //     const isProductKeyExist = val.some((element) => {
  //       if (
  //         element.productGroupId === item.product_group_id ||
  //         element.courseId === item.course_id
  //       ) {
  //         return true;
  //       }
  //       return false;
  //     });
  //     isProductKeyExist
  //       ? setNewArr(val)
  //       : setNewArr([
  //           ...val,
  //           {
  //             productGroupId: item.product_group_id.toString(),
  //             courseId: "course_id" in item ? item.course_id : "None",
  //           },
  //         ]);
  //   };
  const conditiom_For_centreModule = (val, data) => {
    const isKeyExist = val.some((element) => {
      if (element.centreId === item.centre_id) {
        return true;
      }
      return false;
    });
    isKeyExist
      ? setNewArr(val)
      : setNewArr([
          ...val,
          {
            centreId: data.centre_id,
          },
        ]);
  };

  const else_condition_for_centreModule = (data) => {
    setNewArr([
      ...newArr,
      {
        centreId: data.centre_id,
        viewAccess: viewAccessStatus,
        editAccess: editAccessStatus,
        deleteAccess: deleteAccessStatus,
      },
    ]);
  };
  // handling checkbox for view Access=======>
  const handleChangeView = (data) => {
    setViewChecked(!viewchecked);
    if (newArr?.length > 0) {
      if ("centre_id" in data) {
        conditiom_For_centreModule(newArr, data);
      }
    } else {
      if ("centre_id" in data) {
        else_condition_for_centreModule(data);
      }
    }
  };
  // handling checkbox for edit access =====>
  const handleChangeEdit = (data) => {
    setEditChecked(!editchecked);
    if (newArr?.length > 0) {
      if ("centre_id" in data) {
        conditiom_For_centreModule(newArr, data);
      }
    } else {
      if ("centre_id" in data) {
        else_condition_for_centreModule(data);
      }
    }
  };
  // handling checkbox for delete Access =======>
  const handleChangeDelete = (data) => {
    setDeleteChecked(!deleteChecked);
    if (newArr?.length > 0) {
      if ("centre_id" in data) {
        conditiom_For_centreModule(newArr, data);
      }
    } else {
      if ("centre_id" in data) {
        else_condition_for_centreModule(data);
      }
    }
  };
  useEffect(() => {
    setNewArr(
      newArr?.map((val) => {
        if ("centreId" in val) {
          if (Number(val.centreId) === Number(item?.centre_id)) {
            return {
              ...val,
              viewAccess: viewAccessStatus,
              deleteAccess: deleteAccessStatus,
              editAccess: editAccessStatus,
              isActive: "1",
            };
          } else {
            return val;
          }
        }
      })
    );
    setNewArr((val) =>
      val.filter((item) => {
        return (
          item?.viewAccess === "1" ||
          item?.editAccess === "1" ||
          item?.deleteAccess === "1"
        );
      })
    );
  }, [viewAccessStatus, deleteAccessStatus, editAccessStatus]);

  return (
    <div className="flex space-x-11">
      <div className="flex">
        <input
          type="checkbox"
          className=" bg-white appearance-none mt-[2px] ml-[2px] h-[29px] w-[29px] rounded-[8px] border-[2px] cursor-pointer relative"
          onClick={() => handleChangeView(item)}
        />
        {viewchecked && (
          <img
            src={CheckBoxActive}
            className="absolute cursor-pointer "
            onClick={() => {
              setViewChecked(false);
            }}
          />
        )}
      </div>
      <div className="flex">
        <input
          type="checkbox"
          className=" bg-white appearance-none mt-[2px] ml-[2px] h-[29px] w-[29px] rounded-[8px] border-[2px] cursor-pointer relative"
          onClick={() => handleChangeEdit(item)}
        />
        {editchecked && (
          <img
            src={CheckBoxActive}
            className="absolute cursor-pointer "
            onClick={() => {
              setEditChecked(false);
            }}
          />
        )}
      </div>
      <div className="flex">
        <input
          type="checkbox"
          className=" bg-white appearance-none mt-[2px] ml-[2px] h-[29px] w-[29px] rounded-[8px] border-[2px] cursor-pointer relative"
          onClick={() => handleChangeDelete(item)}
        />
        {deleteChecked && (
          <img
            src={CheckBoxActive}
            className="absolute cursor-pointer "
            onClick={() => {
              setDeleteChecked(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
CentreCheckBoxItem.propTypes = {
  item: propTypes.object,
  id: propTypes.string,
  newArr: propTypes.array,
  setNewArr: propTypes.func,
};

export default CentreCheckBoxItem;
