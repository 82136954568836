import React, { useState } from "react";
import Header from "../../header/Header";
import ToggleEvent from "./ToggleEvent";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import "../blogs/multiselect.css";
import Dropdown from "../blogs/Dropdown";
import propTypes from "prop-types";
import DropDown from "../../global/fields/CourseDropDown";
import ToggleSchedule from "../slideshow/ToggleSchedule";
import CalendarModalTrigger from "../blogs/calendar/CalendarModalTrigger";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import WhiteSpace from "../../global/regEx/WhiteSpaceValidator";

const UpdateNotice = () => {

  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  let history  = useHistory();
  const [courseTags, setCourseTags] = useState([]);
  const noticeReducers = {
    selectedNoticeid: useSelector(state => state.noticeData.noticeBoardId),
  };
  const NoticeDetails = useSelector(state => state.noticeData.noticeBoardDetails);
  let setTime = NoticeDetails?.startTime?.split(":");
  let endTime = NoticeDetails?.endTime?.split(":");
  const [showLabel, setShowLabel] = useState(
    NoticeDetails.courseNames ? NoticeDetails.courseNames : [],
  );
  const [Coursevalue, setCourseValue] = useState(
    NoticeDetails.courseNames ? NoticeDetails.courseNames : "",
  );
  const [value, setValue] = useState({
    Name: NoticeDetails.noticeBoardName,
    Subtext: NoticeDetails.description,
    RedirectUrl: NoticeDetails.link,
  });
  const [activeTabEvent, setActiveTabEvent] = useState(NoticeDetails.type==="Event"?"event":"update");
  const [activeTabPublish, setActiveTabPublish] = useState( NoticeDetails.publishStatus == "Scheduled" ? "schedule" : "now",);
  const [date, setDate] = useState(NoticeDetails.startDate);
  const [StartHour, setStartHour] = useState(setTime[0] ?? 0);
  const [StartMinute, setStartMinute] = useState(setTime[1].replace(/^0/, ""));
  const [StartSecond, setStartSecond] =useState(setTime[2].replace(/^0/, ""));
  const [EndHour, setEndHour] = useState(endTime[0] ?? 0);
  const [EndMinute, setEndMinute] = useState(endTime[1].replace(/^0/, ""));
  const [EndSecond, setEndSecond] =useState(endTime[2].replace(/^0/, ""));
  const [enddate, setEndDate] = useState(NoticeDetails.endDate);
  const [countdownDate, setCountdownDate] = useState(NoticeDetails.countDownStartDate);
  // const timeStart=StartHour+StartMinute+StartSecond;
  // const timeEnd=EndHour+EndMinute+EndSecond;
  //to get the current date
  // let today = new Date();
  // let dd = String(today.getDate()).padStart(2, "0");
  // let mm = String(today.getMonth() + 1).padStart(2, "0");
  // let yyyy = today.getFullYear();
  // today = yyyy + "-" + mm + "-" + dd ;
  // to get the current time
  // let todayDate = new Date();
  // const currentHour = todayDate.getHours();
  // const currentMinute = todayDate.getMinutes();
  // api to fetch courses

  const { data: getCourseData } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method : "post",
      data : {
        "adminimspin":login_Details.userImsPin
      }
    }),
  );

  const fetchUpdateNotice = () => {
    return apirequest({
      url: `${endpoint.update_notice.addr}`,
      method: "put",
      data: {
        requestType: "update",
        noticeBoardName: value.Name,
        type: activeTabEvent,
        link: value.RedirectUrl,
        courseName: showLabel,
        description: value.Subtext,
        startDate: activeTabPublish == "now" ? "" : date,
        startTime:
          activeTabPublish == "now"
            ? ""
            : `${StartHour}:${StartMinute}:${StartSecond}`,
        endDate: enddate,
        endTime: `${EndHour}:${EndMinute}:${EndSecond}`,
        countDownStartDate: countdownDate,
        isActive: "1",
        modifiedBy: login_Details.userImsPin,
        noticeBoardId: noticeReducers.selectedNoticeid,
      },
    });
  };

  //API request to update notice data
  const {refetch: UpdateNoticeRefetch } = useQuery(
    endpoint.update_notice.key,
    fetchUpdateNotice,
    {
      enabled: false,
      onSuccess :(res)=>alert(res.data.message),
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );
  const HandleChange = e => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  const onsave = e => {
    if(activeTabPublish==="schedule"){
      if(date==="Select Date" ){
        alert("Please select start date");
      }
      else if (enddate==="Select End Date" ){
        {
          alert("Please select end date");
        }
      }
      else if((Number(date.replace(/-/g, "")))>Number(enddate.replace(/-/g, ""))){
        alert("please select End Date after Start Date");
      }
      else if(WhiteSpace(value.Name)===true || WhiteSpace(value.RedirectUrl)===true || WhiteSpace(value.Subtext)===true){
        alert("Required fields can't be Blank spaces");
      }
      else if(countdownDate==="" || countdownDate==="Select Date"){
        alert("Please select CountDown Date");
      }
      // to show the alert message if start date is today and start time is before current system's timings
      // else if((Number(date.replace(/-/g, "")))===Number(today.replace(/-/g, ""))){
      //   if(Number(StartHour)<currentHour){
      //     alert("please select start time after current time");
      //   }
      //   else if(Number(StartHour)===currentHour){
      //     if(Number(StartMinute)<currentMinute)
      //       alert("please select start time after current time");
      //     else{
      //       UpdateNoticeRefetch();
      //     }
      //   }
      //   else{
      //     UpdateNoticeRefetch();
      //   }
      // }
      // to make course selection mandatory
      else if(showLabel.length===0){
        alert("Please Select Course");
      }
      else{
        UpdateNoticeRefetch();
      }
    }
    else if(enddate==="Select End Date"){
      alert("Please select end date");
    }
    else if(countdownDate==="" || countdownDate==="Select Date"){
      alert("Please select CountDown Date");
    }
    else if(WhiteSpace(value.Name)===true || WhiteSpace(value.RedirectUrl)===true || WhiteSpace(value.Subtext)===true){
      alert("Required fields can't be Blank spaces");
    }
    // to make course selection mandatory
    else if(showLabel.length===0){
      alert("Please Select Course");
    }
    else{
      UpdateNoticeRefetch();
    }
    e.preventDefault();
  };

  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb type={"editCourse"} />

      <div className="mt-2 mx-12 px-8 py-2 ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
        >
          <div className="h-full space-y-4  px-12 py-6 relative">
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Communication Title
                <input
                  required
                  type="text"
                  name="Name"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={value.Name}
                  onChange={HandleChange}
                />
              </label>
            </div>

            <div className="flex">
              <ToggleEvent
                activeTab={activeTabEvent}
                setActiveTab={setActiveTabEvent}
                title="This is an"
                margin={32}
              />
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Redirect URL
                <input
                  name="RedirectUrl"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  placeholder="Paste URL"
                  value={value.RedirectUrl != null ? value.RedirectUrl : ""}
                  onChange={HandleChange}
                />
              </label>
            </div>
            <div className="flex space-x-[123px]">
              <label className=" font-normal text-lg font-ibm ">Courses</label>
              <DropDown
                placeholder={"Search Course(s)"}
                options={getCourseData?.data?.data?.courseList}
                setUpdatedOptions={setCourseTags}
                type={"multiselect"}
                width={"w-[22rem]"}
                courseTags={courseTags}
                showLabel={showLabel}
                setShowLabel={setShowLabel}
                value={Coursevalue}
                setValue={setCourseValue}
                forCourses ={true}
              />
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Communication Subtext
                <input
                  required
                  type="text"
                  name="Subtext"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={value.Subtext}
                  onChange={HandleChange}
                />
              </label>
            </div>
            <div className="flex">
              <ToggleSchedule
                activeTab={activeTabPublish}
                setActiveTab={setActiveTabPublish}
                title="Publish"
                margin={32}
              />
              {activeTabPublish !== "now" && (
                <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                  <p
                    className={`w-full outline-none boder border-gray-200 ${
                      date
                        ? "text-base text-secondary-400  font-normal"
                        : "text-sm  text-primary-700"
                    }`}>
                    {date && date}
                  </p>
                  <CalendarModalTrigger setDate={setDate} />
                </div>
              )}
            </div>
            <div className="flex">
              {activeTabPublish !== "now" && (
                <>
                  <label className=" font-normal text-lg font-ibm ">
                    Start time
                  </label>
                  <div className="ml-32">
                    <Dropdown
                      minOffset={0}
                      maxOffset={23}
                      text={"Hrs"}
                      selectedValue={StartHour}
                      setSelectedValue={setStartHour}
                    />
                  </div>
                  <div className="ml-2">
                    <Dropdown
                      minOffset={0}
                      maxOffset={59}
                      text={"Mins"}
                      selectedValue={StartMinute}
                      setSelectedValue={setStartMinute}
                    />
                  </div>
                  <div className="ml-2">
                    <Dropdown
                      minOffset={0}
                      maxOffset={59}
                      text={"Sec"}
                      selectedValue={StartSecond}
                      setSelectedValue={setStartSecond}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="flex space-x-32">
              <label className=" font-normal text-lg font-ibm ">End Date</label>
              <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    enddate
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"
                  }`}>
                  {enddate && enddate}
                </p>
                <CalendarModalTrigger setDate={setEndDate} />
              </div>
            </div>
            <div className="flex">
              <label className=" font-normal text-lg font-ibm ">End time</label>
              <div className="ml-32">
                <Dropdown
                  minOffset={0}
                  maxOffset={23}
                  text={"Hrs"}
                  selectedValue={EndHour}
                  setSelectedValue={setEndHour}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={0}
                  maxOffset={59}
                  text={"Mins"}
                  selectedValue={EndMinute}
                  setSelectedValue={setEndMinute}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={0}
                  maxOffset={59}
                  text={"Sec"}
                  selectedValue={EndSecond}
                  setSelectedValue={setEndSecond}
                />
              </div>
            </div>
            <div className="flex space-x-8">
              <label className=" font-normal text-lg font-ibm ">
                Countdown starts on
              </label>
              <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    countdownDate
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"
                  }`}>
                  {countdownDate && countdownDate}
                </p>
                <CalendarModalTrigger setDate={setCountdownDate} />
              </div>
            </div>
          </div>
        </form>
        <div className=" space-x-6 flex justify-center mt-2">
          <button
            onClick={onsave}
            className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            type="submit">
            SAVE
          </button>
          <button onClick={history.goBack} className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
            EXIT
          </button>
        </div>
      </div>
    </div>
  );
};
UpdateNotice.propTypes = {
  closeModal: propTypes.func,
  setIsCalendarOpen: propTypes.func,
};

export default UpdateNotice;
