import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import ProfileDropDown from "../profile/ProfileDropdown";
import {getCurrentSubHeaderTab} from "../../redux/features/header/headerSlice";
import { useDispatch,useSelector } from "react-redux";


function SubHeaderTab({ buttonName,link,dropdownData }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedsubheader=useSelector(state=>state.HeaderData.currentSubHeaderTab);
  const handleOnClick = headerTabObject => {
    if(buttonName !== "Master"){
      history.push(`/${link}`);
    }
    
    dispatch(getCurrentSubHeaderTab(headerTabObject));
    // setCurrentSubHeaderTab(headerTabObject);
  };
  return (
    <>
      <div onClick={() => {handleOnClick(buttonName);}}
        className={`px-4 py-5 font-ibm  text-base text-secondary-600 cursor-pointer ${
          buttonName?.replace(/ /g, "")?.toLowerCase() === selectedsubheader?.toLowerCase().replace(/['"]+/g, "").replace(/ /g, "")
            ? "border-b-2 border-primary-200 font-semibold"
            : ""
}`}>        
        {/* <Link className="" to={`/${link}`}>
          {buttonName !== "Master" && buttonName}
        </Link> */}
        {buttonName !== "Master" && buttonName}
        {buttonName === "Master" && (
          <ProfileDropDown item={dropdownData} menuText={"Master"} position={true}/>
        )}
      </div>
    </>
  );
}

SubHeaderTab.propTypes = {
  buttonName: PropTypes.string,
  dropDownOptions: PropTypes.array,
  setCurrentDropDownOption: PropTypes.func,
  setSelectedSubHeaderData: PropTypes.func,
  currentHeaderTab: PropTypes.string,
  link: PropTypes.string,
  dropdownData:PropTypes.array,
};
export default SubHeaderTab;
