import React from "react";
import calendarIcon from "../../assets/icons/calendar-inactive.svg";
import propTypes from "prop-types";

function CourseTitle({ icon, isActive, title, modified, date, width }) {
  return (
    <div className="flex" style={{ flexBasis: width }} >
      { /* date */ }
      <img src={icon} alt="testIcon" className="h-5 mr-5" />
      <div>
        <p
          className={` text-xl ${isActive == 1 ? "opacity-100" : "opacity-60"} `}>
          {title}
        </p>
        {/* modified or created date tag */}
        <div className="flex items-center">
          <img src={calendarIcon} alt="calendar" />
          <p
            className={
              "text-sm ml-2 mb-0 " +
              (isActive == 1 ? "opacity-100" : "opacity-60")
            }>
            {modified ? "Modified on" : "Published by Admin on "}
            {date}
          </p>
        </div>
      </div>
    </div>
  );
}

CourseTitle.propTypes = {
  icon: propTypes.string,
  isActive: propTypes.string,
  title: propTypes.string,
  modified: propTypes.bool,
  date: propTypes.string,
  width: propTypes.string,
};

export default CourseTitle;
