import React from "react";
import Header from "../../header/Header";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import AddEditArticle from "./AddEditArticle";

const AddArticle = () => {
  let obj = {
    articleId: "",
    articleName: "",
    articleHTMLContent: "",
    activeDate: "",
    thumbnailLink: "",
    duration: "00:00:00",
    isPinned: "0",
    tags: [],
    schools: [],
    courses: [],
    isActive: "1",
    createdBy: "",
    createdTs: "",
    modifiedBy: "",
    modifiedTs: "",
  };
  return (
    <div>
      <Header />
      <BreadCrumb />
      <AddEditArticle requestType="Add" eventsdata={obj} />
    </div>
  );
};

export default AddArticle;
