import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  access_management_UserData: {},
};

const access_Management_Slice = createSlice({
  name: "access_management_UserData",
  initialState,
  reducers: {
    setaccess_management_UserData: (state, action) => {
      state.access_management_UserData = action.payload;
    },
  },
});

export const { setaccess_management_UserData } = access_Management_Slice.actions;

export default access_Management_Slice.reducer;