import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  uploadDetails: [],
};

const uploadAWSlice = createSlice({
  name: "uploadData",
  initialState,
  reducers: {
    getUploadDetails: (state, action) => {
      state.uploadDetails = action.payload;
    },
  },
});

export const { getUploadDetails } = uploadAWSlice.actions;

export default uploadAWSlice.reducer;