import React, { useState } from "react";
import categoryButton from "../../../assets/icons/cateogory-icon.svg";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config";
import {apirequest} from "../../../API/api";
import Loader from "../../global/loader/Loader";
import AccordionItems from "./AccordionItems";
import { useHistory } from "react-router-dom";
import { useSelector} from "react-redux";

const UpgradeVariant = () => {
  const[accordionList , setAccordionList] = useState([]); 
  const[newAccordion, setNewAccordion] = useState([]);
  const[variantSequencingArray , setVariantSequencingArray] = useState([{variants_data : []}]); // state for storing the sequencing of variant (nested in category)
  const [addVariantData , setAddVariantData] = useState ([]); //array to store the new Variant to the existing category
  const [updatedVariantData, setUpdatedVariantData] = useState([]);
  const [category_Sequencing_DATA , setCategorySequencingArray] = useState([]);

  let history = useHistory();
  const variantParentId = useSelector(state=> state?.variantData?.variantObj.variantId);

  // Api for the fetching the data for Upgrade Variant ========>
  const {
    status,
  } = useQuery(
    endpoint.upgrade_Variant_getApi.key,
    () =>
      apirequest({
        url: `${endpoint.upgrade_Variant_getApi.addr}`,
        method : "POST",
        data :{
          "variantParentId": variantParentId.toString()
        }
      }),
    {
      select: (res) => res.data.data,
      onSuccess:(res)=>{
        setAccordionList(res);
      }
    }
  );

  // Api for Posting the  new Category and Variant data  ====>
  const {refetch  : updateNewCategory_VariantData } =  useQuery(endpoint.add_New_Category.key, ()=>apirequest({
    url : `${endpoint.add_New_Category.addr}`,
    method : "POST", 
    data : newAccordion 
  }),{
    enabled : false,
    retry : 1,
    onSuccess : res=>alert(res.data.message),
    onError : err=>alert(err.data.message)
  });

  // Api call for posting the new Varaint to the existing category =====>
  const {refetch : addVariant_Refetch} = useQuery(endpoint.add_NewVariant.key ,()=>apirequest({
    url :`${endpoint.add_NewVariant.addr}`,
    method: "POST",
    data : addVariantData
  }),{
    enabled : false,
    retry : 1,
    // onsuccess of add variant api ,page is reloaded
    onSuccess : (res)=>{alert(res.data.message);window.location.reload(false);},
    onError :(err)=>alert(err.data.message)
  });

  const {refetch : updateVariantData_REfetchCall} = useQuery(endpoint.update_VariantData.key ,()=>apirequest({
    url :`${endpoint.update_VariantData.addr}`,
    method: "put",
    data : updatedVariantData,
  }),{
    enabled : false,
    retry : 1,
    onSuccess : res =>alert(res.data.message),
    onError : err =>alert(err.data.message)
  });

  // Function for creatting the new Category =======>
  const handleClick = () => {
    let value = Math.random();
    setAccordionList([{"value" :  value.toString()}, ...accordionList]);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (res) => {
    if (!res.destination) {
      return;
    } else {
      const result = reorder(
        accordionList,
        res.source.index,
        res.destination.index
      );
      setAccordionList(result);
      let array =[];
      result.map((data, index)=>{
        array.push({categoryId: data.categoryId, categoryOrder : index});
      });
      setCategorySequencingArray(array);
    }
  };
  // {categoryId: data.categoryId, categoryOrder : index}
  // Api call for Varient Drag and Drop =======>
  const {refetch : variant_Drag_Drop} = useQuery(endpoint.variant_Drag_Drop.key,()=>apirequest({
    url : `${endpoint.variant_Drag_Drop.addr}`, 
    method : "put",
    data : variantSequencingArray
  }),{
    enabled : false,
    retry : 1,
    onSuccess :err =>alert(err.data.message),
    onError : err =>alert(err.data.message)
  });
  
  const handleSave = ()=>{
    newAccordion.length >0 &&  updateNewCategory_VariantData();
    addVariantData.length>0 && addVariant_Refetch();
    updatedVariantData.length>0 && updateVariantData_REfetchCall();
    category_Sequencing_DATA.length >0 && categorySequenceReFetch();
    variantSequencingArray[0]["variants_data"]?.length >0 && variant_Drag_Drop();
  };

  // Api For Drag nd drop of Category ======>
  const {refetch:categorySequenceReFetch } =  useQuery([endpoint.category_Drag_DROP.key , category_Sequencing_DATA] , ()=>apirequest({
    url : `${endpoint.category_Drag_DROP.addr}`,
    method : "put",
    data:{
      "variantParentId" : variantParentId,
      "categoryData" : category_Sequencing_DATA
    }
  }),{
    enabled :false,
    retry : 1,
    onSuccess : (res)=>alert(res.data.message),
    onError :(err)=>alert(err.data.message)
  });
  return status === "loading " ? (
    <Loader />
  ) : (
    (
      <div className="">
        <button
          className="grid grid-cols-2 -space-x-8 float-right mr-8 -mt-12"
          onClick={handleClick}
        >
          <img src={categoryButton} />
          <span className="font-normal text-base">Add Category</span>
        </button>
        { accordionList?.length ===0? (
          <div className="shadow-outer rounded-2xl flex justify-center align-middle mx-7">
            <p className="py-32 text-lg">
              Add Upgrade Variants in Categories using the &quot;Add
              Category&quot; button
            </p>
          </div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="accordions">
              {(provided) => (
                <div>
                  <div
                    className="space-y-5"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {accordionList?.map((data , index) => (
                      <AccordionItems 
                        accordionList={data} 
                        data={accordionList} key={data.categoryId ?data.categoryId : data.value}
                        index= {index}   
                        setVariantSequencingArray={setVariantSequencingArray} 
                        variantSequencingArray={variantSequencingArray}
                        addVariantData ={addVariantData}
                        setAddVariantData = {setAddVariantData}  
                        setNewAccordion ={setNewAccordion}
                        variantData ={data.variantData}
                        newAccordion ={newAccordion}
                        updateVariant ={updatedVariantData}
                        setUpdateVariant ={setUpdatedVariantData}
                      />
                    ))}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <div className="flex justify-center mt-5 space-x-5">
          <button className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer" onClick={handleSave}>
            Save
          </button>
          <button className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer" onClick={history.goBack}>
            Exit
          </button>
        </div>
      </div>
    )
  );
};


export default UpgradeVariant;
