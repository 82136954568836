import React, { useState, useEffect } from "react";
import DropDownVariant from "../../global/fields/DropDownVariant";
// import TableSearch from "../../table/TableSearch";
import MappedVariantsTable from "../../table/MappedVariantsTable";
import TablePagination from "../../table/TablePagination";
import { apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import Loader from "../../../components/global/loader/Loader";
import { useSelector } from "react-redux";
import TableSearch from "../../table/TableSearch";  

const MapVarientTable = () => {
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  const [currentPage, setCurrentPage] = useState(1);

  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  // const [filteredData,setFilteredData] = useState(dataSource);
  // console.log(setFilteredData);
  // const currentItems = filteredData.slice(indexOfFirstItem,indexOfLastItem);
  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };
  const courseDetails = useSelector(state => state?.courseData?.courseDetails);
  const [getdropdownvalue, setGetdropdownvalue] = useState("");
  const [passVariantname, setPassVariantname] = useState(null);
  const [variant_data , setCourses_VariantsData] = useState([]);

  //get all variants  data API//=============>
  const { data: get_variants } = useQuery(
    endpoint.getAllVariantsData.key,
    () => {
      return apirequest({
        url: `${endpoint.getAllVariantsData.addr}`,
        method: "get",
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  );

  //map variant table
  const fetchVariantData = () => {
    return apirequest({
      url: `${endpoint.getCourse_VariantsData.addr}`,
      method: "post",
      data: {
        courseId: courseDetails.courseId.toString(),
      },
    });
  };

  const {data: course_Variants_DefaultData, refetch: refetch_varaint ,status:variant_status} = useQuery(
    `${endpoint.getCourse_VariantsData.key}`,
    fetchVariantData,
    {
      enabled: true,
      onSuccess :(res)=>setCourses_VariantsData(res)
    },
  );
  //end map table

  useEffect(() => {
    if (getdropdownvalue) {
      if (getdropdownvalue.includes(" · ")) {
        setPassVariantname(getdropdownvalue.split(" · ")[1]);
      } else {
        setPassVariantname(getdropdownvalue);
      }
    }
  }, [getdropdownvalue]);

  //map button //
  const fetchMapVariantData = () => {
    return apirequest({
      url: `${endpoint.addCourse_VariantMapping.addr}`,
      method: "post",
      data: {
        courseId: courseDetails.courseId,
        variantName: passVariantname,
        isActive: "1",
        createdBy: login_Details.userImsPin,
      },
    });
  };

  const { refetch: fetch_map_variant, status } = useQuery(
    `${endpoint.addCourse_VariantMapping.key}`,
    fetchMapVariantData,
    {
      enabled: false,
      onSuccess: res => {
        setGetdropdownvalue("");
        alert(res.data.message);
        refetch_varaint();
      },
      onError: err => {
        alert(err.data.message);
        setGetdropdownvalue("");
      },
      retry: 1,
    },
  );
  

  const handleMap = () => {
    if (getdropdownvalue) {
      const isFound = get_variants?.data?.data?.some(element => {
        if (element.variantName === passVariantname) {
          return true;
        } else {
          return false;
        }
      });
      if (isFound) {
        fetch_map_variant();
      } else {
        alert("Variants entered is not present!");
      }
    } else {
      alert("Please fill the field to map!");
    }
  };

  return (
    <>
      {status === "loading" ? (
        <Loader />
      ) : variant_status &&(
        <div>
          <div className="courseTable__body md:ml-[3%] md:mr-[4%] mt-4 shadow-outer rounded-xl bg-white px-14 py-8 pb-10">
            <div className="grid grid-cols-4 gap-1">
              <div className="col-span-3">
                <div className="flex mt-1 mb-3">
                  <h2 className="mr-8 pl-3 mt-1">Select Variant</h2>
                  <DropDownVariant
                    placeholder={"Enter Variant name or LIMA code."}
                    options={get_variants?.data.data }
                    width={"w-[38rem]"}
                    setGetdropdownvalue={setGetdropdownvalue}
                    setVariant_ID={setGetdropdownvalue}
                    fetch_map_variant={fetch_map_variant}
                  />
                </div>
                <p className="text-xs ml-3">
                  Note: The variant you want to map must be first created under
                  &apos;Variants&apos; module, only then the variant will be
                  visible in the dropdown.
                </p>
              </div>
              <div>
                <button
                  className="mx-auto ml-14 bg-primary-200 text-white font-normal px-4 rounded-xl hover:opacity-75 shadow-outer text-lg py-[10px] font-ibm w-24 text-center"
                  onClick={handleMap}>
                  Map
                </button>
              </div>
            </div>
          </div>

          <div className="mt-5 mb-5 mr-10 flex justify-end">
            <TableSearch
              setDefaultData = {setCourses_VariantsData}
              defaultData ={course_Variants_DefaultData}
              dataKey = "variantName"
              dataForFilter={get_variants?.data.data}
              name = "Courseware"
              subModuleName="Courses"
              setCurrentPage={setCurrentPage}
              courseId ={courseDetails.courseId.toString()}
              childModuleName ="Mapped Variants"
              placeholder="Search by Variant"
            />
          </div>

          <div className="courseTable__body md:ml-[3%] md:mr-[34px] mt-4 shadow-outer rounded-xl bg-white pb-[2%]">
            <MappedVariantsTable
              currentData={
                variant_data?.data?.data.slice(
                  indexOfFirstItem,
                  indexOfLastItem,
                )
                
              }
              variant_status={variant_status}
            />
          </div>
          {variant_data?.data?.data.length > 0 && (
            <div className="courseTable__footer mx-auto mb-7">
              <TablePagination
                data={variant_data?.data?.data}
                dataPerPage={dataPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MapVarientTable;
