import React from "react";

const Banner = () => {
  return (
    <div className="relative">
      {/* <img
        className="w-full rounded-3xl h-[85%]"
        src={Bannerimage}
        alt="banner"
      /> */}
    </div>
  );
};

export default Banner;
