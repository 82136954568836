const config = {
  staging: process.env.REACT_APP_ENV,
  staging_imsLive: process.env.REACT_APP_IMSLIVE,
  endpoint: {
    //Blogs API
    blogs_data: {
      addr: "cms/home/blogs/getBlogsData",
      key: "get_blogs",
    },
    add_blogs: {
      addr: "cms/home/blogs/addBlog",
      key: "add_blog",
    },
    update_blogs: {
      addr: "cms/home/blogs/updateBlog",
      key: "update_blog",
    },
    course_blog_post: {
      addr: "cms/home/blogs/getCourseBlogPost",
      key: "get_course_blog_post",
    },
    get_blog_post: {
      addr: "cms/home/blogs/getBlogPost",
      key: "get_blog_post",
    },
    blog_sequencing: {
      addr: "cms/home/blogs/blogSequence",
      key: "blog_sequence",
    },
    put_blog: {
      addr: "cms/home/blogs/addBlogOfTheWeek",
      key: "blog_week",
    },
    post_sequencing: {
      addr: "cms/home/blogs/posts/postSequence",
      key: "post_sequence",
    },

    // Posts API'S
    posts_data: {
      addr: "cms/home/blogs/posts/getPostsData",
      key: "get_posts",
    },
    add_posts: {
      addr: "cms/home/blogs/posts/addPost",
      key: "add_posts",
    },
    update_posts: {
      addr: "cms/home/blogs/posts/updatePost",
      key: "update_posts",
    },

    // Noticeboard API's
    noticeboard_data: {
      addr: "cms/home/noticeBoard/getNoticeBoardData",
      key: "get_noticeboard",
    },
    add_notice: {
      addr: "cms/home/noticeBoard/addNoticeBoard",
      key: "add_notice",
    },
    update_notice: {
      addr: "cms/home/noticeBoard/updateNoticeBoard",
      key: "update_notice",
    },
    get_course_notice: {
      addr: "cms/home/noticeboard/getCourseNoticeBoard",
      key: "course_notice",
    },
    notice_event_sequence: {
      addr: "cms/home/noticeboard/eventSequence",
      key: "event_sequence",
    },
    notice_update_sequence: {
      addr: "cms/home/noticeboard/updateSequence",
      key: "update_sequence",
    },

    // Slideshow API's
    slideshow_data: {
      addr: "cms/home/slideShow/getSlideShowData",
      key: "get_slideshowdata",
    },
    add_slideshow: {
      addr: "cms/home/slideShow/addSlideShow",
      key: "add_slideshow",
    },
    update_slideshow: {
      addr: "cms/home/slideShow/updateSlideShow",
      key: "update_slideshow",
    },
    get_course_slideshow: {
      addr: "cms/home/slideshow/getSlideShowUsingCourse",
      key: "course_slideShow",
    },
    slideShow_sequence: {
      addr: "cms/home/slideShow/slideshowSequence",
      key: "slideshow_sequence",
    },

    // Calendar Management API's
    get_calendarEvent_data: {
      addr: "cms/home/calendar/getCalendarEventData",
      key: "get_calendarData",
    },
    add_calendarEvent: {
      addr: "cms/home/calendar/addCalendarEvent",
      key: "add_calendarData",
    },
    update_calendarEvent: {
      addr: "cms/home/calendar/updateCalendarEvent",
      key: "update_calendarData",
    },

    // Courseware API's
    course_data: {
      addr: "cms/courseware/courses/getCoursesData",
      key: "get_courses",
    },
    add_course: {
      addr: "cms/courseware/courses/addCourse",
      key: "add_course",
    },
    update_course: {
      addr: "cms/courseware/courses/updateCourse",
      key: "update_course",
    },
    getCourse_VariantsData: {
      addr: "cms/courseware/courses/getCourseVariantsData",
      key: "getCourse_VariantsData",
    },
    addCourse_VariantMapping: {
      addr: "cms/courseware/courses/addCourseVariantMapping",
      key: "addCourse_VariantMapping",
    },
    downloadClassFlowCsv: {
      addr: "cms/courseware/courses/DownloadClassFlowCSV",
      key: "downloadClassFlowCsv",
    },

    // Tags api
    tags_data: {
      addr: "cms/courseware/courses/tags/getTagsData",
      key: "tags_data",
    },
    add_tag: {
      addr: "cms/courseware/courses/tags/addTag",
      key: "add_tag",
    },

    //duplicate API
    delete_course: {
      addr: "cms/courseware/courses/updateCourse",
      key: "delete_course",
    },
    //duplicate API
    product_group: {
      addr: "cms/courseware/master/productGroup/getProductGroupData",
      key: "get_productGroup",
    },

    //Contentrepo API
    content_repo: {
      addr: "cms/courseware/contentRepository/getContentRepositoryData",
      key: "content_Repo_data",
    },
    add_content_repo: {
      addr: "cms/courseware/contentRepository/addContentRepository",
      key: "add_content_data",
    },
    delete_content_repo: {
      addr: "/cms/courseware/contentRepository/updateContentRepository",
      key: "delete_content_data",
    },
    getAssessmentType: {
      addr: "cms/courseware/assets/getAssessmentType",
      key: "getAssessmentType",
    },
    getTestSectionsData: {
      addr: "cms/courseware/assets/getTestSectionsData",
      key: "getTestSectionsData",
    },
    getDetailedAnalyticsTemplate: {
      addr: "cms/courseware/assets/getDetailedAnalyticsTemplate",
      key: "getDetailedAnalyticsTemplate",
    },

    //Duplicate API
    update_content_repo: {
      addr: "cms/courseware/contentRepository/updateContentRepository",
      key: "update_content_repoData",
    },

    // Assets API
    getAssetsUsingContentRepository: {
      addr: "cms/courseware/assets/getAssetsUsingContentRepository",
      key: "getAssetsUsingContentRepository",
    },
    addAssetUsingContentRepository: {
      addr: "cms/courseware/assets/addAsset",
      key: "addAssetUsingContentRepository",
    },
    updateAsset_UsingContentRepository: {
      addr: "cms/courseware/assets/updateAsset",
      key: "updateAsset_UsingContentRepository",
    },

    //CMS - Variant
    variant_get_data: {
      addr: "/cms/variants/getVariantsData",
      key: "variant_get_data",
    },
    add_variant_data: {
      addr: "/cms/variants/addVariant",
      key: "add_variant_data",
    },
    delete_variant_data: {
      addr: "/cms/variants/updateVariant",
      key: "delete_variant_data",
    },
    // Duplicate API
    edit_variant_data: {
      addr: "/cms/variants/updateVariant",
      key: "edit_variant_data",
    },
    upgrade_Variant_getApi: {
      addr: "cms/variants/getCategoryVariantData",
      key: "upgrade_Variant_getApi",
    },
    add_New_Category: {
      addr: "cms/variants/addUpgradeVariant",
      key: "upgradeVariant_Post_Api",
    },
    update_VariantData: {
      addr: "/cms/variants/updateUpgradeVariant",
      key: "update_VariantData",
    },
    category_Drag_DROP: {
      addr: "cms/variants/updateCategorySequence",
      key: "category_Drag_DROP",
    },
    variant_Drag_Drop: {
      addr: "cms/variants/updateVariantSequence",
      key: "variant_Drag_Drop",
    },
    add_NewVariant: {
      addr: "/cms/variants/addVariantCategoryMapping",
      key: "addVariant_TO_Existing category",
    },

    //CMS - Category
    get_category: {
      addr: "cms/variants/category/getCategoryData",
      key: "get_category",
    },
    add_category: {
      addr: "cms/variants/category/addCategory",
      key: "add_category",
    },
    edit_category: {
      addr: "cms/variants/category/updateCategory",
      key: "edit_category",
    },

    //Communication API
    getStatesData: {
      addr: "cms/home/communication/getStatesData",
      key: "getStatesData",
    },
    getCitiesData: {
      addr: "/cms/home/communication/getCityData",
      key: "getCitiesCode",
    },
    getCommunicationData: {
      addr: "cms/home/communication/getCommunicationData",
      key: "getCommunicationData",
    },
    get_Centers_For_addCommunication: {
      addr: "cms/home/communication/getBranchDetails",
      key: "getCenterForAddCommunication",
    },
    get_Courses_For_addCommunication: {
      addr: "cms/home/communication/getcourse",
      key: "get_Courses_For_addCommunication",
    },
    get_Variants_For_addCommunication: {
      addr: "cms/home/communication/getVariants",
      key: "get_Variants_For_addCommunication",
    },
    get_Batches_For_addCommunication: {
      addr: "cms/home/communication/getBatchesData",
      key: "get_Batches_For_addCommunication",
    },
    post_addCommunication: {
      addr: "cms/home/communication/addCommunication",
      key: "post_addCommunication",
    },
    update_Communication: {
      addr: "cms/home/communication/updateCommunication",
      key: "update_Communication",
    },

    // Productgroup API
    get_productGroup: {
      addr: "cms/courseware/master/productGroup/getProductGroupData",
      key: "get_product_group",
    },
    add_productGroup: {
      addr: "cms/courseware/master/productGroup/addProductGroup",
      key: "add_product_group",
    },
    edit_productGroup: {
      addr: "cms/courseware/master/productGroup/updateProductGroup",
      key: "add_product_group",
    },

    // Sections API
    get_sections: {
      addr: "cms/courseware/master/sections/getSectionsData",
      key: "get_section",
    },
    add_section: {
      addr: "cms/courseware/master/sections/addSection",
      key: "add_section",
    },
    update_section: {
      addr: "cms/courseware/master/sections/updateSection",
      key: "update_section",
    },

    // Exams API
    get_exams: {
      addr: "cms/courseware/master/exams/getExamsData",
      key: "get_exams",
    },
    add_exams: {
      addr: "cms/courseware/master/exams/addExam",
      key: "add_exam",
    },
    update_exams: {
      addr: "cms/courseware/master/exams/updateExam",
      key: "update_exam",
    },

    // Reservation Category
    get_categories: {
      addr: "cms/courseware/master/reservationCategory/getReservationCategoryData",
      key: "get_categories",
    },
    add_categories: {
      addr: "cms/courseware/master/reservationCategory/addReservationCategory",
      key: "add_category",
    },
    update_categories: {
      addr: "cms/courseware/master/reservationCategory/updateReservationCategory",
      key: "update_category",
    },
    // Batches API
    get_batches: {
      addr: "cms/courseware/master/batches/getBatchesData",
      key: "get_batch",
    },
    add_batches: {
      addr: "cms/courseware/master/batches/addBatches",
      key: "add_batch",
    },
    update_batches: {
      addr: "cms/courseware/master/batches/updateBatches",
      key: "update_batch",
    },
    get_centers: {
      addr: "cms/courseware/master/batches/getCentres",
      key: "get_centers",
    },
    get_courses: {
      addr: "cms/courseware/master/batches/getCourses",
      key: "get_courses",
    },
    get_variants: {
      addr: "cms/courseware/master/batches/getVariants",
      key: "get_variants",
    },
    get_mentors: {
      addr: "cms/myPlan/mentor/getMentorsList",
      key: "get_mentors",
    },

    // Access Management Api
    addUser_AccessManangement: {
      addr: "/cms/home/accessmanagement/getAccessManagementModulesProductGroups",
      key: "addUser",
    },
    access_Management_User_Api: {
      addr: "/cms/home/accessmanagement/getAccessManagementUsersList",
      key: "access_Management_User_Api",
    },
    add_User_PostAPi: {
      addr: "/cms/home/accessmanagement/addUser",
      key: "access_Management_Post_Api",
    },
    edit_User_UpdateApi: {
      addr: "/cms/home/accessmanagement/updateUser",
      key: "access_Management_Update_Api",
    },

    // Centres API
    get_centres: {
      addr: "cms/courseware/master/centres/getCentresData",
      key: "get_centres",
    },
    add_centre: {
      addr: "cms/courseware/master/centres/addCentre",
      key: "add_centre",
    },
    update_centre: {
      addr: "cms/courseware/master/centres/updateCentre",
      key: "update_centre",
    },

    // Countries API
    get_country: {
      addr: "cms/courseware/master/countries/getCountriesData",
      key: "get_countries",
    },

    // Admin Signin
    login_admin: {
      addr: "cms/login/adminlogin/adminSignInCheck",
      key: "login_admin",
    },

    //Learning Path API's
    add_LearningPath_Tier: {
      addr: "cms/courseware/learningPath/addLearningPathTier",
      key: "add_LearningPath_Tier",
    },
    getLearningPathModules: {
      addr: "/cms/courseware/learningPath/getLearningPathModules",
      key: "learningPathModules",
    },
    edit_LearningPath_Tier: {
      addr: "/cms/courseware/learningPath/updateLearningPathTier",
      key: "edit_LearningPath_Tier",
    },
    get_LearningPath_Data: {
      addr: "cms/courseware/learningPath/getLearningPathData",
      key: "get_LearningPath_Data",
    },
    add_LearningPath_FullList: {
      addr: "cms/courseware/learningPath/addLearningPath",
      key: "add_LearningPath_FullList",
    },
    import_Learning_Path: {
      addr: "cms/courseware/learningPath/addImportLearningPath",
      key: "import_Learning_Path",
    },
    add_LearningPath_asset: {
      addr: "/cms/courseware/learningPath/addLearningPathAssetMapping",
      key: "addLPasset",
    },
    delete_LearningPath_asset: {
      addr: "/cms/courseware/learningPath/deleteLearningPathAssetMapping",
      key: "deleteLPasset",
    },

    // Full text search api
    fullTextSearchApi: {
      addr: "/cms/courseware/fullTextSearch",
      key: "full_text_search_Api",
    },

    // Get all Variant data for Mapped Variant page =======>
    getAllVariantsData: {
      addr: "/cms/variants/getAllVariantsData",
      key: "getAllVariantsData",
    },
    // Mentor MyPlan API'S
    get_mentorData: {
      addr: "/cms/myPlan/mentor/getMentorsData",
      key: "get_mentorData",
    },
    getCourses_mentor: {
      addr: "/cms/myPlan/mentor/getAllCourses",
      key: "getCourses_mentor",
    },
    getCentres_mentor: {
      addr: "/cms/myPlan/mentor/getAllCentres",
      key: "getCentres_mentor",
    },
    SearchMentorByNameOrPin: {
      addr: "/cms/myPlan/mentor/SearchMentorByNameOrPin",
      key: "SearchMentorByNameOrPin",
    },
    add_Mentor: {
      addr: "/cms/myPlan/mentor/AddUpdateMentor",
      key: "add_Mentor",
    },
    // Advertisement Api
    advertisementListingApi: {
      addr: "cms/home/adverticements/getAdverticementsDetails",
      key: "advertisement_listing",
    },
    advertisementAddEdit: {
      addr: "/cms/home/adverticements/addAdverticements",
      key: "advertisementAddEdit",
    },
    // Schools api
    addSchool: {
      addr: "/myims/cms/school/addSchool",
      key: "addSchool",
    },
    getSchoolUsingSchoolId: {
      addr: "/myims/cms/school/getUpdateSchool",
      key: "getSchool",
    },
    updateSchool: {
      addr: "/myims/cms/school/updateSchool",
      key: "updateSchool",
    },
    readCutOffCSV: {
      addr: "/myims/cms/school/readCutOffCSV",
      key: "readCutOffCSV",
    },
    // events api for schools====>
    addDeleteEditEvent: {
      addr: "/myims/cms/school/AddUpdateEvent",
      key: "addDeleteEditEvent",
    },
    getEventsDataforSchools: {
      addr: "/myims/cms/school/GetEventData",
      key: "getEventsDataforSchools",
    },
    // Articles api for Schools
    getArticlesForSchools: {
      addr: "/myims/cms/schools/getArticleData",
      key: "getArticlesForSchools",
    },

    // Schools courses API
    getSchoolCoursesApi: {
      addr: "/myims/cms/school/courseList",
      key: "getSchoolCoursesApi",
    },

    // schoolapi for dropdown
    getSchoolsForDropdown: {
      addr: "/myims/cms/school/schoolList",
      key: "getSchoolsForDropdown",
    },
    // Addeditapi for Articles
    addEditApiForArticles: {
      addr: "/myims/cms/schools/addUpdateArticle",
      key: "addEditApiForArticles",
    },
    //selection Criteria Api
    addSelectionCriteriaAPI: {
      addr: "/cms/schools/sectionCriteria/addSelectionCriterias",
      key: "addSelectionCriteriaAPI",
    },

    // getSchool listing api===>
    getSchoolsListing: {
      addr: "/myims/cms/school/getSchoolsData",
      key: "getSchoolsListing",
    },
    addEditDeleteSchool: {
      addr: "/myims/cms/school/updateSchool",
      key: "addEditDeleteSchool",
    },
    // My profile getuserdetails API

    getUserDetails: {
      addr: "/cms/home/accessmanagement/getUserAccessDetails",
      key: "getUserDetails",
    },
    downloadSelectionCriteriaCSV: {
      addr: "cms/school/selectionCriteria/downloadSelectionCriteriaCSV",
      key: "downloadSelectionCriteriaCSV",
    },
    addEligibilityCriterias: {
      addr: "/cms/schools/eligibilityCriteria/addEligibilityCriterias",
      key: "addEligibilityCriterias",
    },
    downloadEligibilityCriteriaCSV: {
      addr: "/cms/schools/eligibilityCriteria/DownloadEligibilityCriteriaCSV",
      key: "downloadEligibilityCriteriaCSV",
    },
    addDeadlines: {
      addr: "/cms/school/deadline/addDeadlines",
      key: "addDeadlines",
    },
    downloadDeadlinesCSV: {
      addr: "/cms/school/deadline/DownloadDeadlinesCSV",
      key: "DownloadDeadlinesCSV",
    },
    getProctoredTests: {
      addr: "/cms/courseware/assets/getProctoredAssessments",
      key: "proctored tests",
    },
    getSlots: {
      addr: "/cms/courseware/assets/slots",
      key: "slots",
    },
    getVenues: {
      addr: "/cms/courseware/venues/getVenueDetail",
      key: "venues",
    },
    getAllVenues: {
      addr: "/cms/courseware/venues/getVenues",
      key: "get all venues",
    },
    addVenue: {
      addr: "/cms/courseware/venues/addVenue",
      key: "add venue",
    },
    editVenue: {
      addr: "/cms/courseware/venues/editVenue",
      key: "edit venue",
    },
    addSlot: {
      addr: "/cms/courseware/assets/slots/addAssessmentSlot",
      key: "add slot",
    },
    editSlot: {
      addr: "/cms/courseware/assets/slots/editAssessmentSlot",
      key: "edit slot",
    },
    getCentres: {
      addr: "/cms/courseware/centre/searchByName",
      key: "centre by name",
    },
    downloadSlotReport: {
      addr: "/cms/courseware/assets/slots/slotBookingReport",
      key: "download report",
    },
    getAssetById: {
      addr: "/cms/courseware/assets",
      key: "get asset details",
    },
  },
};

module.exports = config;
