import React, { useState } from "react";
// import ToggleYesNo from "../global/fields/ToggleYesNo";
// import Toggle from "../global/fields/Toggle";
// import DropDown from "../global/fields/DropDown";
import DropDown from "../global/SimpleDropDown/DropDown";
// import DropDownForDuration from "../../components/home/blogs/Dropdown";
import { Tab } from "@headlessui/react";
import propTypes from "prop-types";
import Basic from "./Basic";
import CutOff from "./CutOff/CutOff";
import PercentileAndRank from "./PercentileAndRank/PercentileAndRank";
import DetailAnalytics from "./DetailAnalytics";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";

const TabContent = ({ children, isActive }) => {
  return (
    <div
      className={`px-6 py-5 bg-white shadow-outer rounded-xl ${
        isActive && "bg-primary-200 text-white "
      }`}>
      {children}
    </div>
  );
};

function Assessment({
  assessmentId,
  setAssessmentId,
  assessmentType,
  setAssessmentType,
  isAdaptive,
  setIsAdaptive,
  predictedCatPercentile,
  setPredictedCatPercentile,
  cerebryTopicId, 
  setcerebryTopicId,
  isProctored,
  setIsProctored,
  releaseDate,
  setReleaseDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  slotStartDate,
  setSlotStartDate,
  slotEndDate,
  setSlotEndDate,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  startSecond,
  setStartSecond,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  endSecond,
  setEndSecond,
  percentile,
  setPercentile,
  predictedPercentile,
  setPredictedPercentile,
  rank,
  setRank,
  notes,
  setNotes,
  detailAnalyticsTemplateLink,
  setDetailAnalyticsTemplateLink,
  cutOffCategories,
  setCutOffCategories,
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const fetchAssessmentType = () => {
    return apirequest({
      url: endpoint.getAssessmentType.addr,
      method: "GET",
    });
  };

  const { isSuccess, data } = useQuery(
    [endpoint.getAssessmentType.key],
    fetchAssessmentType,
    {
      enabled: true,
      select: res => res.data.data,
      onError: err => {
        alert(err.data.message);
      },
    },
  );

  return (
    <div>
      <div className="flex mb-4">
        <label className="inline-block w-[180px] text-left whitespace-nowrap font-normal font-ibm text-lg mt-3">
          Assessment ID
        </label>
        <input
          value={assessmentId}
          className="bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent"
          placeholder="Enter the Assessment ID here"
          name="assessmentId"
          onChange={e => setAssessmentId(e.target.value)}
          autoComplete="off"
        />
      </div>

      <div className="flex mb-4">
        <label className="inline-block w-[180px] text-left whitespace-nowrap font-normal font-ibm text-lg mt-3">
          Assessment Type
        </label>
        {isSuccess && (
          <div className="w-[22rem]">
            <DropDown
              value={assessmentType}
              initialValue={"Select Assessment Type"}>
              <DropDown.Options>
                {data.map(type => (
                  <DropDown.Option
                    initialValue={"Select Assessment Type"}
                    key={type.id}
                    option={type.assetTypeName}
                    setValue={setAssessmentType}
                  />
                ))}
              </DropDown.Options>
            </DropDown>
          </div>
        )}
      </div>
      <div>
        <Tab.Group
          as="div"
          className="mt-9"
          selectedIndex={selectedIndex}
          onChange={setSelectedIndex}>
          <Tab.List>
            <Tab className="mr-4">
              <TabContent isActive={selectedIndex === 0}>Basic</TabContent>
            </Tab>
            <Tab className="mr-4">
              <TabContent isActive={selectedIndex === 1}>Cut-off</TabContent>
            </Tab>
            <Tab className="mr-4">
              <TabContent isActive={selectedIndex === 2}>
                Percentile & Rank
              </TabContent>
            </Tab>
            <Tab className="mr-4">
              <TabContent isActive={selectedIndex === 3}>
                Detailed Analytics
              </TabContent>
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-14">
            <Tab.Panel>
              <Basic
                assessmentType={assessmentType}
                isAdaptive={isAdaptive}
                setIsAdaptive={setIsAdaptive}
                predictedCatPercentile={predictedCatPercentile}
                setPredictedCatPercentile={setPredictedCatPercentile}
                cerebryTopicId={cerebryTopicId}
                setcerebryTopicId={setcerebryTopicId}
                isProctored={isProctored}
                setIsProctored={setIsProctored}
                releaseDate={releaseDate}
                setReleaseDate={setReleaseDate}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                slotStartDate={slotStartDate}
                setSlotStartDate={setSlotStartDate}
                slotEndDate={slotEndDate}
                setSlotEndDate={setSlotEndDate}
                startHour={startHour}
                setStartHour={setStartHour}
                startMinute={startMinute}
                setStartMinute={setStartMinute}
                startSecond={startSecond}
                setStartSecond={setStartSecond}
                endHour={endHour}
                setEndHour={setEndHour}
                endMinute={endMinute}
                setEndMinute={setEndMinute}
                endSecond={endSecond}
                setEndSecond={setEndSecond}
                setAssessmentId={setAssessmentId}
              />
            </Tab.Panel>
            <Tab.Panel>
              <CutOff
                cutOffCategories={cutOffCategories}
                setCutOffCategories={setCutOffCategories}
              />
            </Tab.Panel>
            <Tab.Panel>
              <PercentileAndRank
                percentile={percentile}
                setPercentile={setPercentile}
                predictedPercentile={predictedPercentile}
                setPredictedPercentile={setPredictedPercentile}
                rank={rank}
                setRank={setRank}
                cutOffCategories={cutOffCategories}
              />
            </Tab.Panel>
            <Tab.Panel>
              <DetailAnalytics
                assessmentId={assessmentId}
                notes={notes}
                setNotes={setNotes}
                detailAnalyticsTemplateLink={detailAnalyticsTemplateLink}
                setDetailAnalyticsTemplateLink={setDetailAnalyticsTemplateLink}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

Assessment.propTypes = {
  assessmentId: propTypes.string,
  setAssessmentId: propTypes.func,
  assessmentType:propTypes.string,
  setAssessmentType: propTypes.func,
  setAssessmentInterface: propTypes.func,
  setNoOfQuestions: propTypes.func,
  setAssessmentHour: propTypes.func,
  setAssessmentMinutes: propTypes.func,
  setAssessmentSeconds: propTypes.func,
  setPassingThreshold: propTypes.func,
  setActiveTab: propTypes.func,
  setActiveTab2: propTypes.func,
  setActiveString: propTypes.func,
  activeTab: propTypes.bool,
  activeTab2: propTypes.bool,
  activeString: propTypes.string,
  assessmentHour: propTypes.number,
  assessmentMinutes: propTypes.number,
  assessmentSeconds: propTypes.number,
  isAdaptive: propTypes.bool,
  setIsAdaptive: propTypes.func,
  predictedCatPercentile: propTypes.bool,
  setPredictedCatPercentile: propTypes.func,
  cerebryTopicId: propTypes.string,
  setcerebryTopicId: propTypes.func,
  isProctored: propTypes.bool,
  setIsProctored: propTypes.func,
  releaseDate: propTypes.string,
  setReleaseDate: propTypes.func,
  startDate: propTypes.string,
  setStartDate: propTypes.func,
  endDate: propTypes.string,
  setEndDate: propTypes.func,
  slotStartDate: propTypes.string,
  setSlotStartDate: propTypes.func,
  slotEndDate: propTypes.string,
  setSlotEndDate: propTypes.func,
  startHour: propTypes.number,
  setStartHour: propTypes.func,
  startMinute: propTypes.number,
  setStartMinute: propTypes.func,
  startSecond: propTypes.number,
  setStartSecond: propTypes.func,
  endHour: propTypes.number,
  setEndHour: propTypes.func,
  endMinute: propTypes.number,
  setEndMinute: propTypes.func,
  endSecond: propTypes.number,
  setEndSecond: propTypes.func,
  percentile: propTypes.object,
  setPercentile: propTypes.func,
  predictedPercentile: propTypes.object,
  setPredictedPercentile: propTypes.func,
  notes: propTypes.string,
  setNotes: propTypes.func,
  rank: propTypes.string,
  setRank: propTypes.func,
  detailAnalyticsTemplateLink: propTypes.string,
  setDetailAnalyticsTemplateLink: propTypes.func,
  cutOffCategories: propTypes.array,
  setCutOffCategories: propTypes.func,
};

TabContent.propTypes = {
  children: propTypes.string,
  isActive: propTypes.bool,
};

export default Assessment;
