import React,{  }  from "react";
import Header from "../header/Header"; 
import profileImage from "../../assets/icons/profile_Image.svg"; 
import messageIcon from "../../assets/icons/message.svg";
import noticeBoardIcon from "../../assets/icons/noticePdg.svg";
import AccessManagementTable from "./AccessManagementTable";
import editIcon from "../../assets/icons/submitted.svg";
import { useSelector } from "react-redux";
import {useQuery} from "react-query";
import { endpoint } from "../../API/config";
import { apirequest } from "../../API/api";
import Loader from "../global/loader/Loader";
// import from "react";


const MyProfile = () => {
  // const data = useSelector(state=>state?.accessManagementUserData.access_management_UserData);
  const adminimspin = useSelector(state => state.loginData.LoginDetails.userImsPin);

  const {data } = useQuery(endpoint.getUserDetails.key , ()=>apirequest({
    method : "post",
    url : endpoint.getUserDetails.addr,
    data : {
      "adminImsPin" : adminimspin
    }
  }),{
    retry :1 ,
    refetchOnMount : true,
    select : (res)=>res.data.data.usersList[0]
  });

  const {data : list_For_ACCESS_Management_Table, status } = useQuery(endpoint.addUser_AccessManangement.key , ()=>apirequest({
    url: `${endpoint.addUser_AccessManangement.addr}`,
    method: "post",
    data : {
      "adminImsPin" : adminimspin
    }
  }));

  // Function which converts array to objects depending uppn the key ;
  const arrayToObject = arr => {
    const res = {};
    for(let i = 0; i < arr?.length; i++){
      const key = arr[i]["productModuleId"] || arr[i]["productGroupId"];
      res[key] = arr[i];
    }
    return res;
  };
  let array_TO_Object_For_ProductModules = arrayToObject(data?.productModuleAccess);
  let array_TO_Object_For_ProductGroup = arrayToObject(data?.productGroupAccess);
  return (
    <div className="font-ibm">
      <Header/>
      <div className="px-7">
        <div className="m-5 ">
          <span className="text-lg font-semibold -ml-3">My Profile</span>
        </div>
        <div className=" shadow-outer rounded-2xl p-5 flex justify-between">
          <aside className="flex space-x-5 ">

            <img src={ data?.userAvatarUrl !="" || null ? data?.userAvatarUrl : profileImage}  alt= "Profile Image"/>
            <aside className="space-y-2">
              <p className="text-lg font-semibold mt-3">{data?.userName}</p>
              <div className="flex space-x-2 items-center">  
                <img src={messageIcon} />
                <p className="">{data?.userEmailId}</p>
              </div>
              <div className="flex space-x-2 items-center">
                <img src={noticeBoardIcon} />
                <p className="">{data?.userImspin}</p>
              </div>
            </aside>
          </aside>
          <img src={editIcon} className="relative bottom-14 cursor-pointer"/>
         
        </div>
        {status==="loading" ? <Loader/> :status==="success" && <div className="shadow-outer p-5 mt-3 rounded-2xl">
          <span className="text-lg">You have access for</span>
          <AccessManagementTable 
            forProfileModule={true} 
            tableData={list_For_ACCESS_Management_Table?.data?.data?.[0].product_groups} 
            accessDetail= {array_TO_Object_For_ProductGroup} 
            title= "Product Courses"
          />
          <AccessManagementTable 
            forProfileModule={true} 
            tableData={list_For_ACCESS_Management_Table?.data?.data?.[0].product_modules} 
            accessDetail={array_TO_Object_For_ProductModules}
            title= "Product Module"
          />
        </div>}
      </div>
    </div>
  );
};
export default MyProfile ;