import React from "react";
import propTypes from "prop-types";
import submitted from "../../../assets/icons/add.svg";
import { Link } from "react-router-dom";
import calendar from "../../../assets/icons/calendar-inactive.svg";
import { useDispatch } from "react-redux";
import { getSlideshowDetails } from "../../../redux/features/slideshow/slideshowSlice";

function BlogTable({ column, currentData, icon, tags }) {
  const dispatch = useDispatch();

  return (
    <div>
      {currentData?.length == 0 && (
        <p className="py-20 px-10 text-center text-xl font-ibm opacity-80">
          No Data Found!
        </p>
      )}
      <table className="table-spacing border-separate">
        {currentData?.map((data, index) => (
          <tbody key={index}>
            <tr>
              <td width={column[0]["width"]} className="align-top ">
                <img className=" " src={icon} />
              </td>
              <td width={column[1]["width"]}>
                <div className="align-top">
                  <p
                    className={
                      "text-xl " +
                      (data.isActive == "1"
                        ? "opacity-100  text-ibm"
                        : "opacity-60 text-ibm")
                    }>
                    {data.slideShowName ||
                      data.productGroupName ||
                      data.sectionName ||
                      data.examName ||
                      data.reservationCategoryName || data.centreName ||data.calendarEventName ||data.batchName}
                  </p>
                  {data.createdBy && (
                    <div className="flex ml-3 mt-3">
                      <img src={calendar} className="mb-4" />
                      <p
                        className={
                          "text-sm ml-2 " +
                          (data.status == "1"
                            ? "opacity-100 text-ibm"
                            : "opacity-60 text-ibm")
                        }>
                        {data.modifiedBy
                          ? `Last Updated by ${data.modifiedBy} on ${data.modifiedTs}`
                          : `Published by ${data.createdBy} on ${data.createdTs}`}
                      </p>
                    </div>
                  )}
                </div>
              </td>
              {tags === true && (
                <td className="align-top" width={column[2]["width"]}>
                  <span className="mr-2 text-lg py-2 capitalize">
                    {data.courseNames?.join(",")}
                  </span>
                </td>
              )}
              <td width={column[3]["width"]} className="align-top ">
                {/* {data?.Access?.map((access, index) => ( */}
                <div key={index} className="flex cursor-pointer ">
                  {/* {access?.editAccess==="1" && */}
                  {data.sectionName || data.examName || data.reservationCategoryName || data.slideShowName || data.calendarEventName?
                    <>
                      {/* {data?.Access?.map((access, index) => ( */}
                      <Link key={index}
                        to={
                          data.productGroupName
                            ? "/courseware/master/product-group/edit"
                            : data.sectionName
                              ? "/courseware/master/section/edit"
                              : data.examName
                                ? "/courseware/master/exam/edit"
                                :data.reservationCategoryName
                                  ?"/courseware/master/category/edit":data.centreName?"/courseware/master/centre/edit":data.calendarEventName?"/HomePage/CalendarManagement/Edit-Event"
                                    : `/HomePage/BannerManagement/update-slide-show:${data.slideShowName}`
                        }>
                        {data.editAccess==="1" &&
                        <img
                          className="h-6"
                          src={submitted}
                          onClick={() => {
                            dispatch(getSlideshowDetails(data));
                          }}></img>}
                      </Link></>:
                    <Link
                      to={
                        data.productGroupName
                          ? "/courseware/master/product-group/edit":
                          data.batchName
                            ? "/courseware/master/batch/edit"
                            : data.sectionName
                              ? "/courseware/master/section/edit"
                              : data.examName
                                ? "/courseware/master/exam/edit"
                                :data.reservationCategoryName
                                  ?"/courseware/master/category/edit":data.centreName?"/courseware/master/centre/edit"
                                    : `/HomePage/BannerManagement/update-slide-show:${data.slideShowName}`
                      }>
                      <img
                        className="h-6"
                        src={submitted}
                        onClick={() => {
                          dispatch(getSlideshowDetails(data));
                        }}></img>
                    </Link>
                  }
                </div>
                {/* ))} */}
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}
BlogTable.propTypes = {
  column: propTypes.array,
  currentData: propTypes.array,
  type: propTypes.string,
  icon: propTypes.string,
  refetch: propTypes.func,
  tags: propTypes.bool,
};
export default BlogTable;
