import React from "react";
import propTypes from "prop-types";

function Input({ label, handleFormChange, objectKey, value, placeholder, type }) {
  return (
    <div className="mb-4 flex gap-10">
      <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
        {label}
      </label>
      <input
        value={value}
        className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
        type={type ?? "text"}
        name="size"
        placeholder={placeholder}
        onChange={e => handleFormChange(e, objectKey)}
        required
        autoComplete="off"
      />
    </div>
  );
}

Input.propTypes = {
  label: propTypes.string,
  objectKey: propTypes.string,
  placeholder: propTypes.string,
  handleFormChange: propTypes.func,
  value: propTypes.any,
  type: propTypes.string,
};
export default Input;
