import React, { useState } from "react";
import Header from "../../header/Header";
import TogglePublish from "../blogs/TogglePublish";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import "../blogs/multiselect.css";
import Dropdown from "../blogs/Dropdown";
import Toggle from "../../global/fields/Toggle";
import CalendarModalTrigger from "../blogs/calendar/CalendarModalTrigger";
import propTypes from "prop-types";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {UploadFileToAWS} from "../../../AWS/uploadFile";
import WhiteSpace from "../../global/regEx/WhiteSpaceValidator";

import {
  selectedPostName,
  selectedPostId,
} from "../../../redux/features/blogs/postsSlice";
import {
  selectedBlogId,
} from "../../../redux/features/blogs/blogsSlice";

const EditPost = () => {
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  let history = useHistory();
  const postReducers = {
    PostIdselected: useSelector(selectedPostId),
    PostNameselected: useSelector(selectedPostName),
    BlogIdselected: useSelector(selectedBlogId),
  };
  const PostDetails = useSelector(state => state.postsData.PostDetails);
  let setTime = PostDetails?.startTime?.split(":");
  let ReadTime = PostDetails?.readTime?.split(":");
  const [StartHour, setStartHour] = useState(setTime[0] ?? 0);
  const [StartMinute, setStartMinute] = useState(setTime[1].replace(/^0/, ""));
  const [StartSecond, setStartSecond] =useState(setTime[2].replace(/^0/, ""));
  const [Hour, setHour] = useState(ReadTime[0] ?? 0);
  const [Minute, setMinute] = useState(ReadTime[1].replace(/^0/, ""));
  const [Second, setSecond] =useState(ReadTime[2].replace(/^0/, ""));
  // the coursenames are fetched from API instead of fetching from blogListing page.
  const [courseReduction, setCourseReduction] = useState(PostDetails.courseNames
  );
  const [activeTab, setActiveTab] = useState(
    PostDetails.isActive == "0" ? "inactive" : "active",
  );
  let isActive = activeTab === "active" ? "1" : "0";
  const [activeTabPublish, setActiveTabPublish] = useState(
    PostDetails.publishStatus == "Published" ? "now" : "customise",
  );
  const [date, setDate] = useState(PostDetails.publishDate);

  const [value, setValue] = useState({
    Title: PostDetails.postName,
    BlogPostLink: PostDetails.blogPostLink,
  });
  const [browseLink, setBrowseLink] = useState(PostDetails.thumbnailLink);

  // const timeStart=StartHour+StartMinute+StartSecond;

  //to get the current date so that if publish date is today then in start time selection of past time can be restricted
  // let today = new Date();
  // let dd = String(today.getDate()).padStart(2, "0");
  // let mm = String(today.getMonth() + 1).padStart(2, "0");
  // let yyyy = today.getFullYear();
  // today = yyyy + "-" + mm + "-" + dd ;
  // to get the current time
  // let todayDate = new Date();
  // const currentHour = todayDate.getHours()  ;
  // const currentMinute = todayDate.getMinutes();

  const fetchAddPost = () => {
    return apirequest({
      url: `${endpoint.update_posts.addr}`,
      method: "put",
      data: {
        requestType: "update",
        postId: postReducers.PostIdselected,
        postName: value.Title,
        thumbnailLink: browseLink,
        blogPostLink: value.BlogPostLink,
        readTime: `${Hour}:${Minute}:${Second}`,
        publishDate: activeTabPublish == "now" ? "" : date,
        startTime: activeTabPublish == "now" ? "" : `${StartHour}:${StartMinute}:${StartSecond}`,
        blogId: postReducers.BlogIdselected,
        isActive: isActive,
        modifiedBy: login_Details.userImsPin,
        courseName: courseReduction,
      },
    });
  };
  //API request to edit posts data
  const {refetch: UpdatePostRefetch } = useQuery(
    endpoint.update_posts.key,
    fetchAddPost,
    {
      enabled: false,
      onSuccess :(res)=>alert(res.data.message),
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );
  const HandleChange = e => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  const onsave = e => {
    if(activeTabPublish==="customise"){
      if(date==="Select Date"){
        alert("Please select start date");
      }
      // to show the alert message if start date is today and start time is before current system's timings
      // else if((Number(date.replace(/-/g, "")))===Number(today.replace(/-/g, ""))){
      //   if(Number(StartHour)<currentHour){
      //     alert("please select start time after current time");
      //   }
      //   else if(Number(StartHour)===currentHour){
      //     if(Number(StartMinute)<currentMinute)
      //       alert("please select start time after current time");
      //     else{
      //       UpdatePostRefetch();
      //     }
      //   }
      //   else{
      //     UpdatePostRefetch();
      //   }
      // }
      else if(courseReduction.length===0){
        alert("At least One Course should be Present");
      }
      // to validate whitespaces
      else if(WhiteSpace(value.Title)){
        alert("Please Enter Valid Post Name");
      }
      // to validate if read time is not selected
      else if(Number(Hour)===0 ){
        if(Number(Minute)===0 && Number(Second)===0){
          alert("please select Read Time ");
        }
        else{
          UpdatePostRefetch();
        }  
      }
      else{
        UpdatePostRefetch();
      }
    }
    else if(Number(Hour)===0 ){
      if(Number(Minute)===0 && Number(Second)===0){
        alert("please select Read Time ");
      }
      else{
        UpdatePostRefetch();
      }
      
    }
    else if(courseReduction.length===0){
      alert("At least One Course should be Present");
    }
    else{
      UpdatePostRefetch();
    }
    e.preventDefault();
  };
  const handleBrowse = (event) => {
    event.preventDefault();
    setBrowseLink(event.target?.files[0].name);
    const fileDate = new Date().toLocaleString({hour12: true }).toString().split("/");
    if (event.target?.files[0]) {
      const val = UploadFileToAWS({key:"editPostUpload",file:event.target?.files[0],path:"Asset/EditPost/"+fileDate[2].toString().split(",")[0]+"/"+event.target?.files[0].name.toString().split(".")[0]+"_"+login_Details.userImsPin+"_"+fileDate[2].toString().split(/[, ]+/)[1]+"."+event.target?.files[0].name.toString().split(".")[1]}); 
      {val.then(item=>setBrowseLink(item));}
    }else{
      alert("No files available!");
    }
  };
  const removeLabel = (remLab) => {
    
    setCourseReduction(
      courseReduction.filter(function (f) {
        return f !== remLab;
      })
    );};
    
  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb type={"editCourse"} />

      <div className="mt-2 mx-12 px-8 py-2 ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
        >
          <div className="h-full space-y-6  px-12 py-6 relative">
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Title
                <input
                  required
                  type="text"
                  name="Title"
                  className="border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg focus:outline-none"
                  value={value.Title}
                  onChange={HandleChange}
                />
              </label>
            </div>
            <div className="flex ">
              <label className=" font-normal text-lg font-ibm py-5">
                Thumbnail Link
                <input
                  required
                  type="text"
                  name="ThumbnailLink"
                  className="focus:outline-none border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg"
                  placeholder="Paste thumbnail link"
                  value={browseLink}
                  readOnly={true}
                />
              </label>
              <label className="absolute right-36 top-[130px] bg-primary-200 px-10 ml-2 py-3 rounded-xl text-white text-sm font-semibold cursor-pointer font-ibm">
                <input className="hidden " type="file" multiple onChange={handleBrowse } accept=".webp"/>Browse
              </label>
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Blog Post Link
                <input
                  required
                  name="BlogPostLink"
                  className="focus:outline-none border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg"
                  value={value.BlogPostLink}
                  onChange={HandleChange}
                />
              </label>
            </div>
            <div className="flex">
              <label className=" font-normal text-lg font-ibm ">
                Read time
              </label>
              <div className="ml-16">
                <Dropdown
                  minOffset={0}
                  maxOffset={23}
                  text={"Hrs"}
                  selectedValue={Hour}
                  setSelectedValue={setHour}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={0}
                  maxOffset={59}
                  text={"Mins"}
                  selectedValue={Minute}
                  setSelectedValue={setMinute}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={0}
                  maxOffset={59}
                  text={"Sec"}
                  selectedValue={Second}
                  setSelectedValue={setSecond}
                />
              </div>
            </div>
            <div className="flex  space-x-14">
              <p className="font-normal text-lg font-ibm">Courses</p> 
              <div className="ml-14 flex mt-2 flex-wrap">
                {courseReduction.map((lab, i) => {
                  return (
                    <div
                      key={i.toString()}
                      itemID={i}
                      id={i}
                      className="relative mr-1 mb-5"
                    >
                      <span className="bg-gray-100 text-sm text-black rounded-full px-3 pr-6 pt-3 pb-2 capitalize whitespace-nowrap">
                        {lab}
                      </span>
                      <span
                        className="absolute bottom-[3px] text-xs font-medium cursor-pointer right-2 text-gray-500"
                        onClick={() => removeLabel(lab)}
                      >
                        &#10005;
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex">
              <TogglePublish
                activeTab={activeTabPublish}
                setActiveTab={setActiveTabPublish}
                title="Publish Date"
              />
              {activeTabPublish !== "now" && (
                <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                  <p
                    className={`w-full outline-none boder border-gray-200 ${
                      date
                        ? "text-base text-black  font-normal"
                        : "text-sm  text-primary-700"
                    }`}>
                    {date && date}
                  </p>
                  <CalendarModalTrigger setDate={setDate} />
                </div>
              )}
            </div>
            {activeTabPublish !== "now" && (
              <div className="flex">
                <label className=" font-normal text-lg font-ibm ">
                  Start time
                </label>
                <div className="ml-16">
                  <Dropdown
                    minOffset={0}
                    maxOffset={23}
                    text={"Hrs"}
                    selectedValue={StartHour}
                    setSelectedValue={setStartHour}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Mins"}
                    selectedValue={StartMinute}
                    setSelectedValue={setStartMinute}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Sec"}
                    selectedValue={StartSecond}
                    setSelectedValue={setStartSecond}
                  />
                </div>
              </div>
            )}
            <Toggle
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              title="This Blog Post is"
            />
          </div>
        </form>
        <div className="flex justify-center space-x-6 mt-2">
          <button
            className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            onClick={onsave}
            type="submit">
            SAVE
          </button>
          <button onClick={history.goBack} className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
            EXIT
          </button>
        </div>
      </div>
    </div>
  );
};
EditPost.propTypes = {
  closeModal: propTypes.func,
  setIsCalendarOpen: propTypes.func,
};

export default EditPost;
