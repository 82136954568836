import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  variantObj: {},
};

const variantSlice = createSlice({
  name: "variantObj",
  initialState,
  reducers: {
    setVariantObj: (state, action) => {
      state.variantObj = action.payload;
    },
  },
});

export const { setVariantObj } = variantSlice.actions;

export default variantSlice.reducer;