import React from "react";
import propTypes from "prop-types";
import testIcon from "../../../assets/icons/test-1.svg";
import calendar from "../../../assets/icons/calendar_inactive.svg";
import submitted from "../../../assets/icons/add.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPostDetails } from "../../../redux/features/blogs/postsSlice";

function CourseTable({ column, currentData }) {
  const dispatch = useDispatch();
  return (
    <div>
      {currentData?.length == 0 && (
        <p className="py-20 px-10 text-center text-xl font-ibm opacity-80">
          No Data Found!
        </p>
      )}
      <table className="table-spacing border-separate">
        {currentData?.map((key, index) => (
          <tbody key={index}>
            <tr key={index}>
              <td width={column[3]["width"]} className="align-top">
                <img className="h-8  mr-5" src={testIcon} />
              </td>
              <td width={column[1]["width"]}>
                <div className="align-top">
                  <p
                    className={
                      "text-xl " +
                      (key?.isActive == "1" ? "opacity-100" : "opacity-60")
                    }
                  >
                    {key?.postName}
                  </p>
                  <span className="flex ml-5 items-center">
                    <img src={calendar} className="h-5 mb-4" />
                    <p
                      className={
                        "text-sm ml-2 " +
                        (key?.isActive == "1" ? "opacity-100" : "opacity-60")
                      }
                    >
                      {key?.modifiedTs === "None" ? (
                        <span>Published on {key.createdTs}</span>
                      ) : (
                        <span>Modified on {key?.modifiedTs}</span>
                      )}
                    </p>
                  </span>
                </div>
              </td>
              <td
                className="align-top text-lg font-ibm"
                width={column[1]["width"]}
              >
                {key?.courseNames?.join(", ")}
              </td>
              <td width={column[3]["width"]} className="align-top">
                {/* {key?.Access.map((access, index) => ( */}
                <div
                  key={index}
                  className="flex cursor-pointer items-center space-x-5"
                >
                  {key?.editAccess === "1" && (
                    <Link
                      to={`/HomePage/BlogManagement/edit-posts/:${key?.postName}`}
                    >
                      <img
                        className="h-6"
                        src={submitted}
                        onClick={() => {
                          dispatch(getPostDetails(key));
                        }}
                      />
                    </Link>
                  )}
                </div>
                {/* ))} */}
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}
CourseTable.propTypes = {
  column: propTypes.array,
  currentData: propTypes.array,
  type: propTypes.string,
  refetch: propTypes.func,
  reduxdata: propTypes.func,
};
export default CourseTable;
