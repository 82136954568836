import React from "react";
import { Breadcrumbs } from "@material-ui/core";
import { useHistory, withRouter } from "react-router-dom";
import Arrow from "../../../assets/icons/arrow-right.svg";
import propTypes from "prop-types";
const BreadCrumb = () =>{
  let snippet = window.location.pathname.split(":");
  let pathSnippets = snippet[0]
    .split("/")
    .filter((index) => index);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join()}`;
    let history = useHistory();
    const goToPreviousPath = () => {
      history.goBack();
    };
    return (
      <div
        key={url}
        className="text-secondary-600 text-md font-ibm font-normal first-letter:capitalize cursor-pointer"
        onClick={goToPreviousPath}
      >
     
        {pathSnippets[index].replaceAll("%20", " ").replaceAll("-", " ").replaceAll("%22", " ").replaceAll("_", " ")}
        
      </div>
    );
  });
  const breadcrumbItems = [].concat(extraBreadcrumbItems);
  return (
    <div className="text-secondary-600 text-md font-ibm font-normal py-2 ml-10">
      <Breadcrumbs
        separator={<img src={Arrow} className="w-4 h-4 mr-2 object-contain" />}
      >
        {breadcrumbItems}
      </Breadcrumbs>
    </div>
  );
};
BreadCrumb.propTypes = {
  type:propTypes.string
};
export default withRouter(BreadCrumb);