import React from "react";
import { SpinnerCircular } from "spinners-react";
const Loader = () => {
  return (
    <div className="bg-white fixed w-full h-full top-0 bottom-0">
      <div className="fixed top-[50%] bottom-[50%] left-[50%] right-[50%] z-50">
        <SpinnerCircular size={50} thickness={100} speed={100} color="#00ABFB" secondaryColor="#f3f4f6" />
      </div>
    </div>
  );
};

export default Loader;