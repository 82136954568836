import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assetData: {},
};

const editAssetSlice = createSlice({
  name: "assetData",
  initialState,
  reducers: {
    setAssetData: (state, action) => {
      state.assetData = action.payload;
    },
  },
});

export const { setAssetData } = editAssetSlice.actions;

export default editAssetSlice.reducer;