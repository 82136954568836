import React, { useState } from "react";
import Header from "../../header/Header";
import TogglePublish from "./TogglePublish";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import Dropdown from "./Dropdown";
import Toggle from "../../global/fields/Toggle";
import CalendarModalTrigger from "../blogs/calendar/CalendarModalTrigger";
import propTypes from "prop-types";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {UploadFileToAWS} from "../../../AWS/uploadFile";
import { useHistory } from "react-router-dom";
import WhiteSpace from "../../global/regEx/WhiteSpaceValidator";

const FormBlog = () => {
  let history = useHistory();
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  const [activeTabPublish, setActiveTabPublish] = useState("now");
  const [activeTab, setActiveTab] = useState("active");
  const [PostName, setPostName] = useState("");
  const [blogPostLink, setBlogPostLink] = useState("");
  const [date, setDate] = useState("Select Date");
  const [Hour, setHour] = useState(0);
  const [Minute, setMinute] = useState(0);
  const [Second, setSecond] = useState(0);
  const [StartHour, setStartHour] = useState(0);
  const [StartMinute, setStartMinute] = useState(0);
  const [StartSecond, setStartSecond] = useState(0);
  const BlogIdselected = useSelector(state => state.blogsData.blgId);
  const courseNames = useSelector(state => state.blogsData.blogName);
  const [courseReduction, setCourseReduction] = useState(courseNames);
  const [browseLink, setBrowseLink] = useState("");
  //to get the current date so that if publish date is today then in start time selection of past time can be restricted
  // let today = new Date();
  // let dd = String(today.getDate()).padStart(2, "0");
  // let mm = String(today.getMonth() + 1).padStart(2, "0");
  // let yyyy = today.getFullYear();
  // today = yyyy + "-" + mm + "-" + dd ;
  // to get the current time
  //remove the condition as per requirement
  // let todayDate = new Date();
  // const currentHour = todayDate.getHours()  ;
  // const currentMinute = todayDate.getMinutes();

  console.log(activeTabPublish , "activeTabPublish");
  const fetchAddPost = () => {
    return apirequest({
      url: `${endpoint.add_posts.addr}`,
      method: "post",
      data: {
        postName: PostName,
        thumbnailLink: browseLink,
        blogPostLink: blogPostLink,
        readTime: `${Hour}:${Minute}:${Second}`,
        publishDate: activeTabPublish === "now" ? "" : date ,
        startTime: activeTabPublish === "now" ? ""  : `${StartHour}:${StartMinute}:${StartSecond}`,
        blogId: BlogIdselected,
        isActive: activeTab == "active" ? "1" : "0",
        createdBy: login_Details.userImsPin,
        courseName: courseReduction,
      },
    });
  };
  //API request to add new posts data
  const {  refetch: addBlogRefetch } = useQuery(
    endpoint.add_posts.key,
    fetchAddPost,
    {
      enabled: false,
      onSuccess :(res)=>{alert(res.data.message),window.location.reload(false);},
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );
  
  // const timeStart=StartHour+StartMinute+StartSecond;

  const onsave = e => {
    if(activeTabPublish==="customise"){
      if(date==="Select Date" ){
        alert("Please select start date");
      }
      //  course is mandatory 
      else if(courseReduction.length===0){
        alert("At least One Course should be Present");
      }
      // to validate if read time is not selected
      else if(Number(Hour)===0 ){
        if(Number(Minute)===0 && Number(Second)===0){
          alert("please select Read Time ");
        }
        else{
          addBlogRefetch();
        }
        
      }
      else{
        addBlogRefetch();
      }
    }
    else if(WhiteSpace(PostName)){
      alert("Please Enter Valid Post Name");
    }
    else if(courseReduction.length===0){
      alert("At least One Course should be Present");
    }
    else if(Number(Hour)===0 ){
      if(Number(Minute)===0 && Number(Second)===0){
        alert("please select Read Time ");
      }
      else{
        addBlogRefetch();
      } 
    }
    else{
      addBlogRefetch();
    }
    e.preventDefault();
  };
  const handleBrowse = (event) => {
    event.preventDefault();
    setBrowseLink(event.target?.files[0].name);
    const fileDate = new Date().toLocaleString({hour12: true }).toString().split("/");
    if (event.target?.files[0]) {
      const val = UploadFileToAWS({key:"addPostUpload",file:event.target?.files[0],path:"Asset/AddPost/"+fileDate[2].toString().split(",")[0]+"/"+event.target?.files[0].name.toString().split(".")[0]+"_"+login_Details.userImsPin+"_"+fileDate[2].toString().split(/[, ]+/)[1]+"."+event.target?.files[0].name.toString().split(".")[1]}); 
      {val.then(item=>setBrowseLink(item));}
    }else{
      alert("No files available!");
    }
  };
  const removeLabel = (remLab) => {
    setCourseReduction(
      courseReduction.filter(function (f) {
        return f !== remLab;
      })
    );};
  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />

      <div className="mt-2 mx-12  px-8 py-2 ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
          onSubmit={onsave}>
          <div className="h-full space-y-6  px-12 py-6 relative">
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Title
                <input
                  required
                  type="text"
                  name="PostName"
                  className="border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg focus:outline-none"
                  value={PostName}
                  onChange={e => setPostName(e.target.value)}
                />
              </label>
            </div>
            <div className="flex ">
              <label className=" font-normal text-lg font-ibm py-5">
                Thumbnail Link
                <input
                  required
                  type="text"
                  name="url"
                  className="focus:outline-none border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg"
                  placeholder="Paste thumbnail link"
                  value={browseLink}
                  readOnly={true}
                />
              </label>
              <label className="absolute right-36 top-[130px] bg-primary-200 px-10 ml-2 py-3 rounded-xl text-white text-sm font-semibold cursor-pointer font-ibm">
                <input className="hidden " type="file" multiple onChange={handleBrowse } accept=".webp"/>Browse
              </label>
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Blog Post Link
                <input
                  required
                  name="url"
                  className="focus:outline-none border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg"
                  value={blogPostLink}
                  onChange={e => setBlogPostLink(e.target.value)}
                />
              </label>
            </div>
            <div className="flex">
              <label className=" font-normal text-lg font-ibm ">
                Read time
              </label>
              <div className="ml-16">
                <Dropdown
                  minOffset={0}
                  maxOffset={23}
                  text={"Hrs"}
                  selectedValue={Hour}
                  setSelectedValue={setHour}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={0}
                  maxOffset={59}
                  text={"Mins"}
                  selectedValue={Minute}
                  setSelectedValue={setMinute}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={0}
                  maxOffset={59}
                  text={"Sec"}
                  selectedValue={Second}
                  setSelectedValue={setSecond}
                />
              </div>
            </div>
            <div className="flex  space-x-14">
              <p className="font-normal text-lg font-ibm">Courses</p> 
              <div className="ml-14 flex mt-2 flex-wrap">
                {courseReduction.map((lab, i) => {
                  return (
                    <div
                      key={i.toString()}
                      itemID={i}
                      id={i}
                      className="relative mr-1 mb-5"
                    >
                      <span className="bg-gray-100 text-sm text-black rounded-full px-3 pr-6 pt-3 pb-2 capitalize whitespace-nowrap">
                        {lab}
                      </span>
                      <span
                        className="absolute bottom-[3px] text-xs font-medium cursor-pointer right-2 text-gray-500"
                        onClick={() => removeLabel(lab)}
                      >
                        &#10005;
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex">
              <TogglePublish
                activeTab={activeTabPublish}
                setActiveTab={setActiveTabPublish}
                title="Publish Date"
              />
              {activeTabPublish !== "now" && (
                <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                  <p
                    className={`w-full outline-none boder border-gray-200 ${
                      date
                        ? "text-base text-secondary-400  font-normal"
                        : "text-sm  text-primary-700"
                    }`}>
                    {date && date}
                  </p>
                  <CalendarModalTrigger setDate={setDate} />
                </div>
              )}
            </div>
            {activeTabPublish !== "now" && (
              <div className="flex">
                <label className=" font-normal text-lg font-ibm ">
                  Start time
                </label>
                <div className="ml-16">
                  <Dropdown
                    // minOffset={((Number(today.replace(/-/g, "")))===Number(date.replace(/-/g, "")))?currentHour:0}
                    minOffset={0}
                    maxOffset={23}
                    text={"Hrs"}
                    selectedValue={StartHour}
                    setSelectedValue={setStartHour}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    // minOffset={((Number(today.replace(/-/g, "")))===Number(date.replace(/-/g, "")))?currentMinute:0}
                    minOffset={0}
                    maxOffset={59}
                    text={"Mins"}
                    selectedValue={StartMinute}
                    setSelectedValue={setStartMinute}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Sec"}
                    selectedValue={StartSecond}
                    setSelectedValue={setStartSecond}
                  />
                </div>
              </div> 
            )}
            <Toggle
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              title="This Blog Post is"
            />
          </div>
          <div className=" space-x-6 absolute left-[40%] -bottom-16">
            <button
              className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              type="submit">
              SAVE
            </button>
            <button onClick={history.goBack} className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
              EXIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
FormBlog.propTypes = {
  closeModal: propTypes.func,
  setIsCalendarOpen: propTypes.func,
};

export default FormBlog;
