import React from "react";
import Header from "../../../header/Header";
import BreadCrumb from "../../../global/breadcrumbs/BreadCrumb";
import UploadFile from "./uploadFile";
import Input from "./Input";
import tickActive from "../../../../assets/icons/tick-active.svg";
import tickInactive from "../../../../assets/icons/tick-inactive.svg";
import TabforAddSchool from "./TabforAddSchool";
import CutOffTable from "./CutOffTable";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { apirequest } from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import Tooltip from "../../../global/tooltip/Tooltip";

const AddSchool = () => {
  let history = useHistory();
  const [programsString, setProgramsString] = useState("");
  const [programs, setPrograms] = useState({});
  const [courses, setCourses] = useState({});
  const [selectedTab, setSelectedTab] = useState(null);
  const schoolId = useSelector(state => state.schools.schoolId);
  const isEditing = useSelector(state => state.schools.isEditing);

  const [disableSaveButton , setDisableSaveButton] = useState(false);
  const [formData, setFormData] = useState({
    schoolName: "",
    location: "",
    schoolImages: [],
    image: "",
    websiteUrl: "",
    programs: selectedTab,
    isActive: "1",
    createdBy: "IMS0000394697",
  });
  const [csvPath, setCsvPath] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const labelClasses =
    "inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black";

  const handleTab = item => {
    setSelectedTab(item);
  };

  {
    /***************************************************************************** ADD SCHOOL API  *****************************************************************************/
  }
  const addSchool = () => {
    return apirequest({
      url: `${endpoint.addSchool.addr}`,
      method: "post",
      data: {
        ...formData,
        programs: selectedTab,
        ...programs[selectedTab],
      },
    });
  };

  const { refetch: addSchoolApi } = useQuery(
    [endpoint.addSchool.key],
    () => addSchool(),
    {
      select: res => res.data,
      enabled: false,
      retry: false,
      onSuccess: res => {
        setDisableSaveButton(true);
        alert(res.message);
      },
      onError : err=>alert(err.data.message)
    },
  );

  {
    /********************************************************************* GET SCHOOL DETAILS ON EDIT API  ********************************************************************/
  }

  const getSchoolDetails = () => {
    return apirequest({
      url: `${endpoint.getSchoolUsingSchoolId.addr}`,
      method: "post",
      data: {
        adminimspin: "IMS0000394697",
        schoolId,
      },
    });
  };

  // const {data : editSchoolDetails} = 
  useQuery([endpoint.getSchoolUsingSchoolId.key, schoolId], getSchoolDetails, {
    select: res => res.data.data[0],
    enabled: isEditing,
    retry: false,
    onSuccess: data => {
      const schoolProgramData = data.schoolProgramData;
      setFormData({
        ...formData,
        schoolId: data.schoolId,
        schoolName: data.schoolName,
        location: data.location,
        schoolImages: data.schoolImages,
        image: data.image,
        websiteUrl: data.websiteUrl,
        programs: schoolProgramData[0].programs,
        isActive: data.isActive,
        createdBy: data.createdBy,
      });
      let programsData = {};
      setSelectedTab(schoolProgramData[0].programs);
      let localCourses = {};
      schoolProgramData.forEach(program => {
        programsData[program.programs] = {
          courses: program.courses.map(course => course.courseId),
          isSponsored: program.isSponsored,
          batchSize: program.batchSize,
          fees: program.fees,
          avgSalary: program.avgSalary,
          flagshipProgram: program.flagshipProgram,
          imsClassification: program.imsClassification,
          duration: program.duration,
          tags: program.tags,
          applicationLink: program.applicationLink,
          examAccepted: program.examAccepted,
          eligibilityCriteria: program.eligibilityCriteria,
          placementReportLink: program.placementReportLink,
          admissionProcedureLink: program.admissionProcedureLink,
        };
        setPrograms({...programs, ...programsData });
        localCourses = {
          ...localCourses,
          [program.programs]: [...program.courses],
        };
        setCourses({
          ...localCourses
        });
      });
      setCsvPath(data?.schoolCutOffData[0]?.csvPath ?? "");
      setCsvData(data?.schoolCutOffData[0]?.programsData);
    },
  });

  {
    /**********************************************************************************  EDIT SCHOOLS API  **********************************************************************************/
  }

  function modifyData(object) {
    object.modifiedBy = object.createdBy;
    delete object.createdBy;
    object.programs = selectedTab;
    object.csvPath = csvPath;
    return object;
  }

  const updateSchool = () => {
    return apirequest({
      url: `${endpoint.updateSchool.addr}`,
      method: "put",
      data: {
        ...modifyData(formData),
        ...programs[selectedTab],
        requestType: "Update",
      },
    });
  };

  const { refetch: updateSchoolApi } = useQuery(
    [endpoint.updateSchool.key],
    updateSchool,
    {
      enabled: false,
      retry: false,
      onSuccess: res => {
        setDisableSaveButton(true);
        alert(res.data.message);
        window.location.reload();
      },
      onError : (err)=>alert(err.data.message)
    },
  );

  {
    /**********************************************************************************  READ CUTOFF CSV  **********************************************************************************/
  }

  const readCutOffCSV = () => {
    return apirequest({
      url: `${endpoint.readCutOffCSV.addr}`,
      method: "post",
      data: {
        csvPath,
      },
    });
  };

  useQuery([endpoint.readCutOffCSV.key], readCutOffCSV, {
    select: res => res.data.data,
    enabled: !!csvPath,
    retry: false,
    onSuccess: data => {
      setCsvData(data);
    },
  });

  {
    /******************************************************************************************* X ********************************************************************************************/
  }

  function handleFormChange(e, key) {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  }

  function handlePrograms(e) {
    setProgramsString(e.target.value);
  }

  function handleEnter(e) {
    if (e.key === "Enter") {
      let objectStructure = {
        courses: [],
        isSponsored: "0",
        batchSize: "",
        fees: "",
        avgSalary: "",
        flagshipProgram: "0",
        imsClassification: "",
        duration: "",
        tags: [],
        applicationLink: "",
        examAccepted: [],
        eligibilityCriteria: "",
        placementReportLink: "",
        admissionProcedureLink: "",
        csvPath: "",
      };
      setPrograms({ ...programs, [programsString]: objectStructure });
      if (Object.keys(programs).length === 0) {
        setSelectedTab(programsString);
      }
      setProgramsString("");
      setDisableSaveButton(false);
    }
  }

  function handleDeleteProgram(program) {
    let duplicate = { ...programs };
    delete duplicate[program];
    setPrograms({ ...duplicate });
    setDisableSaveButton(false);
  }

  function addImages(e) {
    const links = e.target.value.split(",");
    const linksArray = links.map(link => {
      return {
        link,
        isThumbnail: "0",
      };
    });
    setFormData({
      ...formData,
      schoolImages: [...linksArray],
    });
  }

  function addImageToApi(image) {
    let dup = formData.schoolImages.map(img => {
      if (img.link === image.link) {
        img.isThumbnail = img.isThumbnail === "1" ? "0" : "1";
      }
      return img;
    });
    setFormData({
      ...formData,
      schoolImages: [...dup],
    });
  }

  function setLink(link, key) {
    setFormData({
      ...formData,
      [key]: link,
    });
  }

  function setProgramCourses(course) {
    let dup = programs;
    dup[selectedTab].courses = [...dup[selectedTab].courses, course.courseId];
    setPrograms({
      ...dup,
    });
    if (courses[selectedTab]) {
      setCourses({
        ...courses,
        [selectedTab]: [
          ...courses[selectedTab],
          {
            courseName: course.courseName,
            courseId: course.courseId,
          },
        ],
      });
    }else{
      setCourses({
        ...courses,
        [selectedTab]: [
          {
            courseName: course.courseName,
            courseId: course.courseId,
          },
        ],
      });
    }
  }

  function removeProgramCourses(option) {
    let dup = programs;
    dup[selectedTab].courses = dup[selectedTab].courses.filter(
      course => course !== option.courseId,
    );
    setPrograms({
      ...dup,
    });
    let filteredCourses = courses[selectedTab].filter(
      course => course.courseId !== option.courseId,
    );
    setCourses({
      ...courses,
      [selectedTab]: [...filteredCourses],
    });
  }

  function handleSave() {
    if (isEditing) {
      updateSchoolApi();
    } else {
      addSchoolApi();
    }
  }

  setTimeout(()=>!disableSaveButton && setDisableSaveButton(false),3000);

  return (
    <div>
      <Header />
      <div className="bg-white text-ibm px-12 pb-16 ">
        {/* <div className="grid grid-cols-4 gap-2 text-ibm"> */}
        <BreadCrumb />
        {/* </div> */}
        <div className="">
          <Input
            value={formData.schoolName}
            label="Name of the Institution"
            objectKey="schoolName"
            handleFormChange={handleFormChange}
            placeholder="Enter the name of the institution here"
          />
          <Input
            value={formData.location}
            label="Location"
            objectKey="location"
            handleFormChange={handleFormChange}
            placeholder="Enter location here"
          />
          <div className="mb-4 flex gap-10">
            <label className={labelClasses}>Add Images</label>
            <input
              placeholder="Separate the links by comma"
              value={
                formData.schoolImages.length > 0
                  ? formData.schoolImages.map(img => img?.link)
                  : ""
              }
              onChange={e => addImages(e)}
              className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"></input>
          </div>
          <div className="mb-4  flex gap-10">
            <label className={labelClasses}>Images Preview</label>
            <div className="ml-20 flex scrollbar-thin overflow-y-scroll pl-1 py-2">
              {formData.schoolImages.map(image => (
                <div
                  key={image?.link}
                  className="mr-5 h-50 w-50 border-solid border-secondary-300 rounded-2xl">
                  {image?.link.replace(/\s+/g, "") !== "" && (
                    <div className="relative">
                      <img
                        src={image?.link}
                        className="min-h-[163px] min-w-[288px] max-h-[163px] max-w-[288px] shadow-md"
                        alt=""
                      />
                      <img
                        src={
                          image?.isThumbnail === "1" ? tickActive : tickInactive
                        }
                        onClick={() => addImageToApi(image)}
                        className="absolute top-3 left-3 cursor-pointer "
                        alt=""
                        data-tip
                        data-for="thumbnail"
                      />
                      <Tooltip
                        multiline={false}
                        id="thumbnail"
                        place="bottom"
                        text="Select as thumbnail"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <UploadFile
            label="Upload Image"
            setLink={setLink}
            link={formData.image}
            objectKey={"image"}
          />
          <div className="mb-4  pt-5 flex gap-10 ">
            <label className={labelClasses}>Logo Preview</label>
            {formData.image && <img className="min-h-[163px] min-w-[288px] max-h-[163px] max-w-[288px] shadow-md ml-20" src={formData.image} />}
          </div>
          <Input
            value={formData.websiteUrl}
            label="Official Website"
            objectKey="websiteUrl"
            handleFormChange={handleFormChange}
            placeholder="Enter website URL here"
          />
          <div className="mb-4  flex gap-10">
            <label className={labelClasses}>Add Programs</label>
            <input
              value={programsString}
              className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
              type="text"
              name="programs"
              placeholder="Type the value and press Enter"
              required
              autoComplete="off"
              onChange={e => handlePrograms(e)}
              onKeyDown={e => handleEnter(e)}
            />
          </div>
          <div className="flex space-x-4 my-6">
            {Object.keys(programs)?.map(item => (
              <div
                key={item}
                className={`flex justify-center px-4 cursor-pointer relative ${
                  selectedTab === item
                    ? "flex border-primary-200 border-b-2"
                    : ""
                }`}>
                {" "}
                <span onClick={() => handleTab(item)} className="mb-3 ">
                  {item}
                </span>
                <p
                  onClick={() => handleDeleteProgram(item)}
                  className="absolute text-xs right-0 cursor-pointer">
                  x
                </p>
              </div>
            ))}
          </div>
          {/* Tabs for Add School */}
          {selectedTab &&  programs[selectedTab] && 
          Object.keys(programs[selectedTab]).length > 0 && (
            <TabforAddSchool
              programs={programs}
              selectedTab={selectedTab}
              setPrograms={setPrograms}
              courses={ courses[selectedTab]}
              setProgramCourses={setProgramCourses}
              removeProgramCourses={removeProgramCourses}
              csvPath={csvPath}
              setCsvPath={setCsvPath}
            />
          )}

          {/* Tabs ends here */}
          <label className={labelClasses + " mb-7"}>Cut-Offs Preview:</label>
          {csvData && <CutOffTable csvData={csvData} />}
          <div className="flex justify-center mt-5 space-x-5">
            <button
              disabled = {disableSaveButton}
              onClick={handleSave}
              className={ `px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer cursor-pointer ${disableSaveButton ? "bg-gray-600" : "bg-primary-300"}`}>
              Save
            </button>
            <button
              className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer cursor-pointer"
              onClick={history.goBack}>
              Exit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSchool;