import React from "react";
import ToggleYesNo from "../../../global/fields/ToggleYesNo";
import propTypes from "prop-types";
import Multiselect from "./Multiselect";
import Input from "./Input";
import { endpoint } from "../../../../API/config";
import { apirequest } from "../../../../API/api";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import UploadFile from "./uploadFile";

const TabforAddSchool = ({
  programs,
  setPrograms,
  selectedTab,
  setProgramCourses,
  removeProgramCourses,
  csvPath,
  setCsvPath,
  courses
}) => {
  const login_Details = useSelector(state => state?.loginData?.LoginDetails);

  function fetchCourses() {
    return apirequest({
      url: `${endpoint.course_data.addr}`,
      method: "post",
      data: {
        adminimspin: login_Details.userImsPin,
      },
    });
  }

  const { data: coursesData } = useQuery(
    [
      `${(endpoint.course_data.handleFormChange = { handleFormChange })}`,
      login_Details.userImsPin,
    ],
    fetchCourses,
    {
      select: res => res.data.data.courseList,
    },
  );

  function handleToggle(key) {
    setPrograms({
      ...programs,
      [selectedTab]: {
        ...programs[selectedTab],
        [key]: programs[selectedTab][key] === "1" ? "0" : "1",
      },
    });
  }

  function handleFormChange(e, key) {
    let value = e.target ? e.target.value : e;
    setPrograms({
      ...programs,
      [selectedTab]: {
        ...programs[selectedTab],
        [key]: (key == "fees" || key == "avgSalary") ? parseInt(value) : value,
      },
    });
  }

  function setMultipleValues(e, key) {
    let values = e.target.value.split(",");
    setPrograms({
      ...programs,
      [selectedTab]: {
        ...programs[selectedTab],
        [key]: values,
      },
    });
  }

  return (
    <div>
      <div className="mb-4  flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Course
        </label>
        <Multiselect
          options={coursesData}
          selectedOptions={courses}
          setOptions={setProgramCourses}
          removeOptions={removeProgramCourses}
        />
      </div>
      <div className="mb-4  flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap font-normal font-ibm text-lg mt-3 mr-[120px] gap-5 text-black">
          Sponsored
        </label>
        <ToggleYesNo
          isLabel={false}
          toggleState={programs[selectedTab].isSponsored === "1"}
          setToggleState={() => handleToggle("isSponsored")}
        />
      </div>
      <Input
        value={programs[selectedTab].batchSize}
        label="Batch Size"
        objectKey="batchSize"
        handleFormChange={handleFormChange}
        placeholder="Enter batch size here"
      />
      <Input
        value={programs[selectedTab].fees}
        label="Fees"
        objectKey="fees"
        handleFormChange={handleFormChange}
        placeholder="Enter fees here"
        type="number"
      />
      <Input
        value={programs[selectedTab].avgSalary}
        label="Average Salary"
        objectKey="avgSalary"
        handleFormChange={handleFormChange}
        placeholder="Enter Average Salary here"
        type="number"
      />
      <div className="mb-4  flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-[120px] font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Flagship Program?
        </label>
        <ToggleYesNo
          isLabel={false}
          toggleState={programs[selectedTab].flagshipProgram === "1"}
          setToggleState={() => handleToggle("flagshipProgram")}
        />
      </div>
      <Input
        value={programs[selectedTab].imsClassification}
        label="IMS Classification"
        objectKey="imsClassification"
        handleFormChange={handleFormChange}
        placeholder="Enter Classification"
      />
      <Input
        value={programs[selectedTab].duration}
        label="Duration"
        objectKey="duration"
        handleFormChange={handleFormChange}
        placeholder="Enter the duration"
      />
      <Input
        value={programs[selectedTab].tags}
        label="Tags"
        objectKey="tags"
        handleFormChange={setMultipleValues}
        placeholder="Full-time, Part-time, Executive etc"
      />
      <Input
        value={programs[selectedTab].applicationLink}
        label="Application link"
        objectKey="applicationLink"
        handleFormChange={handleFormChange}
        placeholder="Enter the application URL here"
      />
      <Input
        value={programs[selectedTab].examAccepted}
        label="Exams Accepted"
        objectKey={"examAccepted"}
        handleFormChange={setMultipleValues}
        placeholder="Separate the values by comma"
      />
      <div className="mb-4  flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Eligibility Criteria
        </label>
        <input
          value={programs[selectedTab].eligibilityCriteria}
          className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[725px] h-[178px] placeholder-bold font-semibold text-XL focus:placeholder-transparent"
          type="text"
          name="eligibilityCriteria"
          onChange={e => handleFormChange(e, "eligibilityCriteria")}
          required
          autoComplete="off"
        />
      </div>
      <UploadFile
        label="Placement Report Link"
        link={programs[selectedTab].placementReportLink}
        setLink={handleFormChange}
        objectKey="placementReportLink"
      />
      <UploadFile
        label="Admission Procedure Link"
        link={programs[selectedTab].admissionProcedureLink}
        setLink={handleFormChange}
        objectKey="admissionProcedureLink"
      />
      <UploadFile
        label="Cut Off Upload CSV"
        link={csvPath}
        setLink={handleFormChange}
        objectKey="csvPath"
        template={true}
        setCsvPath={setCsvPath}
      />
    </div>
  );
};

TabforAddSchool.propTypes = {
  programs: propTypes.object,
  selectedTab: propTypes.string,
  setPrograms: propTypes.func,
  setFormData: propTypes.object,
  setProgramCourses: propTypes.func,
  removeProgramCourses: propTypes.func,
  setLink: propTypes.func,
  setExamsAccepted: propTypes.func,
  csvPath: propTypes.string,
  setCsvPath: propTypes.func,
  courses: propTypes.array,
};

export default TabforAddSchool;
