import React, { useState } from "react";
import moment from "moment";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import propTypes from "prop-types";

function Calendar({ setIsCalendarOpen, setSelectedDate, closeModal }) {
  const today = moment().startOf("day");
  const [selectedDay, setSelectedDay] = useState(today);
  const [selectedMonth, setSelectedMonth] = useState(moment().startOf("month"));
  const daysInWeek = ["S", "M", "T", "W", "T", "F", "S"];

  const changeMonth = num => {
    setSelectedMonth(moment(selectedMonth.add(num, "months")));
  };

  const changeDay = day => {
    if (!day.isBefore(moment(), "day")) {
      setSelectedDay(day);
    }
    if (!day.isBefore(moment(), "day")) {
      const d = day.format("yyyy-MM-DD");
      setSelectedDate(d);
    }
  };

  const handleCancel = () => {
    setIsCalendarOpen && setIsCalendarOpen(false);
    closeModal && closeModal();
  };

  const addMonth = () => {
    changeMonth(1);
  };

  const subtractMonth = () => {
    changeMonth(-1);
  };

  const getDaysInMonth = () => {
    const totalDays = new Array();
    const totalLimit = 42;

    // Add days of current month
    for (let i = 0; i < selectedMonth.daysInMonth(); i++) {
      totalDays.push(moment(selectedMonth).add(i, "days"));
    }

    // Add days of previous month if first day of current month isn't Sunday
    if (moment(selectedMonth).day() !== 0) {
      const previousMonth = moment(selectedMonth) // returns 2021-12-01T00:00:00+05:30 (comment is based on code written in December 2021)
        .subtract(1, "months") // returns 2021-11-01T00:00:00+05:30
        .endOf("month") // returns 2021-11-30T23:59:59+05:30
        .startOf("day"); // returns 2021-11-30T00:00:00+05:30
      for (let i = 0; i < moment(selectedMonth).day(); i++) {
        // moment(selectedMonth).day() returns any number between 0-6 , where 0 = sunday
        totalDays.unshift(moment(previousMonth).subtract(i, "days"));
      }
    }

    // Add days of next month if total days is not enough
    if (totalDays.length < totalLimit) {
      const nextMonth = moment(selectedMonth).add(1, "months");
      const daysToFill = totalLimit - totalDays.length;
      for (let i = 0; i < daysToFill; i++) {
        totalDays.push(moment(nextMonth).add(i, "days"));
      }
    }

    return totalDays;
  };

  const getClassName = day => {
    if (day.diff(selectedDay, "days") === 0) {
      return "bg-primary-200 text-white";
    }
    else if (day.month() != selectedMonth.month() && day.isBefore(moment(), "day")) {
      return "bg-gray-300 cursor-not-allowed";
    }
    else if (day.isBefore(moment(), "day")) {
      return "bg-gray-300 cursor-not-allowed";
    }
    else if (day.month() != selectedMonth.month()){
      return "text-secondary-400";
    }
    
    else
    {
      return "";
    }
  };

  return (
    <div className="w-[396px] h-auto pt-7 pl-8 pr-[30px] pb-[30px] rounded-2xl shadow-outer text-[14px]">
      {/* header */}
      <div className="mb-9 flex justify-between">
        <div className="flex">
          <ArrowLeft
            onClick={subtractMonth}
            className="mr-[24px] cursor-pointer"
          />
          <p className="mr-[24px] text-[18px] font-medium ">
            {selectedMonth.format("MMMM YYYY")}
          </p>
          <ArrowRight onClick={addMonth} className="cursor-pointer" />
        </div>
        <Cancel className="cursor-pointer" onClick={handleCancel} />
      </div>

      {/* days in grid */}
      <div>
        {/* s m t w t f s grid */}
        <div className="grid grid-cols-7 justify-items-center">
          {daysInWeek.map((day, ind) => {
            return (
              <span
                className="h-[40px] w-[40px] bg-secondary-200 flex items-center justify-center rounded-full"
                key={day + ind}>
                {day}
              </span>
            );
          })}
        </div>

        {/* dates grid */}
        <div className="grid grid-cols-7 justify-items-center">
          {getDaysInMonth().map(day => {
            return (
              <span
                key={day}
                onClick={() => changeDay(day)}
                className={`h-[40px] w-[40px] cursor-pointer flex items-center justify-center rounded-xl ${getClassName(
                  day,
                )} `}>
                {day.date()}
              </span>
            );
          })}
        </div>
      </div>
      <div className="flex justify-end mr-[2px] text-primary-200 mt-7 text-sm font-medium">
        <p className="underline cursor-pointer" onClick={handleCancel}>
          Done
        </p>
      </div>
    </div>
  );
}

Calendar.propTypes = {
  setIsCalendarOpen: propTypes.func,
  setSelectedDate: propTypes.func,
  closeModal: propTypes.func,
};

export default Calendar;
