import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  learningPathDetails: [],
  deletedTiers: [],
  courseDataOnBrowse: [],
  variantDataOnBrowse: [],
  isDeleteOperationPerformed: false,
  selectedLearningPathId: null,
  selectedTier: null,
  assetTierObject: [],
  module: null,
  folderOptions: null
};

export const findParent = (arr, id) =>
  arr.reduce((found, child) => {
    if (found) return found;
    if (child.learningPathId === id) return child;
    if (child.children && child.children.length > 0)
      return findParent(child.children, id);
  }, null);

const learningPathSlice = createSlice({
  name: "learningPathData",
  initialState,
  reducers: {
    setLearningPathDetails: (state, action) => {
      state.learningPathDetails = action.payload;
    },
    addTier: (state, action) => {
      if (action.payload.parentId === "0") {
        state.learningPathDetails = [
          ...state.learningPathDetails,
          action.payload,
        ];
      } else {
        const duplicate = state.learningPathDetails;
        const parent = findParent(duplicate, action.payload.parentId);
        parent.children = [...parent?.children, action.payload];
        state.learningPathDetails = duplicate;
      }
    },
    addAssets: (state, action) => {
      const duplicate = state.learningPathDetails;
      let parent;
      if (action.payload.addingToTier) {
        parent = findParent(duplicate, action.payload.learningPathId);
      } else parent = findParent(duplicate, state.selectedLearningPathId);
      parent.children = [...parent?.children, ...action.payload.selectedData];
      state.learningPathDetails = duplicate;
    },
    setOnDragAsset: (state, action) => {
      const duplicate = state.learningPathDetails;
      const parent = findParent(duplicate, action.payload.parent_id);
      parent.children = [...action.payload.reorderedItems];
      state.learningPathDetails = duplicate;
    },
    editTier: (state, action) => {
      const duplicate = state.learningPathDetails;
      if (action.payload.parentId === "0") {
        const index = duplicate.findIndex(
          item => item.learningPathId === action.payload.learningPathId,
        );
        duplicate[index] = action.payload;
      } else {
        let parent = findParent(duplicate, action.payload.parentId);
        let index = parent.children.findIndex(
          child => child.learningPathId === action.payload.learningPathId,
        );
        parent.children[index] = action.payload;
      }
      state.learningPathDetails = duplicate;
    },
    deleteTier: (state, action) => {
      const duplicate = state.learningPathDetails;
      if (action.payload.parentId === "0") {
        const index = duplicate.findIndex(
          item => item.learningPathId === action.payload.learningPathId,
        );
        duplicate.splice(index, 1);
      } else {
        let parent = findParent(duplicate, action.payload.parentId);
        let index = parent.children.findIndex(
          child => child.learningPathId === action.payload.learningPathId,
        );
        parent.children.splice(index, 1);
        state.learningPathDetails = duplicate;
      }
      state.isDeleteOperationPerformed = true;
    },
    setDeletedTiers: (state, action) => {
      state.deletedTiers = [...state.deletedTiers, action.payload];
    },
    clearDeletedTiers: state => {
      state.deletedTiers = [];
    },
    setCourseDataOnBrowse: (state, action) => {
      state.courseDataOnBrowse = action.payload;
    },
    setDeleteOperationPerformedToFalse: state => {
      state.isDeleteOperationPerformed = false;
    },
    setSelectedLearningPathId: (state, action) => {
      state.selectedLearningPathId = action.payload;
    },
    setSelectedTier: (state, action) => {
      state.selectedTier = action.payload;
    },
    setAssetTierObject: (state, action) => {
      state.assetTierObject = [...action.payload];
    },
    clearAssetTierObject: state => {
      state.assetTierObject = [];
      state.selectedLearningPathId = null;
    },
    tempSetAssets: (state, action) => {
      if (action.payload.addingToTier) {
        let dup = state.assetTierObject;
        let parent = dup.find(
          tier => tier.learningPathId === action.payload.learningPathId,
        );
        parent.children = [...parent.children, ...action.payload.selectedData];
      } else {
        state.assetTierObject = [
          ...state.assetTierObject,
          ...action.payload.selectedData,
        ];
      }
    },
    tempEditAssets: (state, action) => {
      let tempAssets = state.assetTierObject;
      let index = tempAssets.findIndex(
        asset => asset.learningPathId === action.payload.learningPathId,
      );
      tempAssets[index] = action.payload;
      state.assetTierObject = tempAssets;
    },
    deleteAsset: (state, action) => {
      const duplicate = state.learningPathDetails;
      let parent = findParent(duplicate, action.payload.parent_id);
      const findChild = (child) => {
        if("learningPathId" in child){
          return child.learningPathId === action.payload.item.learningPathId;
        }else{
          return child.assetId === action.payload.item.assetId;
        }
      };
      let index = parent.children.findIndex(findChild);
      parent.children.splice(index, 1);
      state.learningPathDetails = duplicate;
    },
    setVariantDataOnBrowse: (state, action) => {
      state.variantDataOnBrowse = action.payload;
    },
    setModule: (state, action) => {
      state.module = action.payload;
    },
    setFolderOptions: (state, action) => {
      state.folderOptions = action.payload;
    },
  },
});

export const selectSelectedTier = state => state.learningPathData.selectedTier;

export const {
  setLearningPathDetails,
  addTier,
  addAssets,
  editTier,
  deleteTier,
  setDeletedTiers,
  clearDeletedTiers,
  setCourseDataOnBrowse,
  setVariantDataOnBrowse,
  setDeleteOperationPerformedToFalse,
  setSelectedLearningPathId,
  setSelectedTier,
  setAssetTierObject,
  clearAssetTierObject,
  tempSetAssets,
  tempEditAssets,
  setOnDragAsset,
  deleteAsset,
  setModule,
  setFolderOptions
} = learningPathSlice.actions;

export default learningPathSlice.reducer;
