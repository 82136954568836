import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courseId: "",
  stateCode: "",
  cityId:"",
};

export const sequencingSlice = createSlice({
  name: "CourseId",
  initialState,
  reducers: {
    getIdDetails: (state, action) => {
      state.courseId = action.payload.courseId;
      state.stateCode = action.payload.stateId;
      state.cityId=action.payload.cityId;
    },
  },
});

export const { getIdDetails } = sequencingSlice.actions;

export default sequencingSlice.reducer;
