import React, { useState } from "react";
import BreadCrumb from "../../../components/global/breadcrumbs/BreadCrumb";
import DropDown from "../../../components/global/fields/DropDown";
import Toggle from "../../../components/global/fields/Toggle";
import ToggleYesNo from "../../../components/global/fields/ToggleYesNo";
import Header from "../../../components/header/Header";
import ToggleSchedule from "../../../components/home/slideshow/ToggleSchedule";
// import calendarIcon from "../../assets/icons/calendar-inactive.svg";
import CalendarModalTrigger from "../../home/blogs/calendar/CalendarModalTrigger";
import DropdownDuration from "../../../components/home/blogs/Dropdown";
import { useQuery } from "react-query";
import { apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
// import WhiteSpace from "../../global/regEx/WhiteSpaceValidator";
// import { useDispatch } from "react-redux";
// import {setVariantObj} from "../../../redux/features/variant/variantSlice";

const AddVariant = () => {
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  const { data: coursesData } = useQuery(
    endpoint.course_data.key,
    () =>
      apirequest({
        url: `${endpoint.course_data.addr}`,
        method: "post",
        data: {
          adminimspin: login_Details.userImsPin,
        },
      }),
    {
      refetchOnMount: true,
    }
  );

  let history = useHistory();
  const [selectedCourse, setSelectCourse] = useState([]);
  const [activeTabForPublish, setActiveTabforPublish] = useState("now");
  const [variantStatus, setVariantStatus] = useState("active");
  const [isSession, setIsSession] = useState(true);
  const [limaCode, setLimaCode] = useState("");
  const [variantName, setVariantName] = useState("");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [myPlanSessions, setMyplanSessions] = useState("");
  const [isCentreProctoredAllowed, setIsCentreProctoredAllowed] =
    useState(false);

  const [hour, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [keyCode, setKeyCode] = useState("");

  let isActive = variantStatus === "active" ? "1" : "inactive" && "0";

  const limaCodeRegEx = /^[0-9]*$/;
  const onlyZero = /^[0]+$/;
  const regex = /.*[a-zA-Z]+.*/gm;

  const { refetch } = useQuery(
    endpoint.add_variant_data.addr,
    () =>
      apirequest({
        url: `${endpoint.add_variant_data.addr}`,
        method: "post",
        data: {
          variantName: variantName,
          limaCode: limaCode,
          isActive: isActive,
          meetamentorSlots: myPlanSessions,
          createdBy: login_Details.userImsPin,
          startDate: startdate,
          startTime: `${hour}:${minutes}:${seconds}`,
          endDate: enddate,
          courseName: selectedCourse,
          centreProctoredAllowed: isCentreProctoredAllowed ? "1" : "0",
        },
      }),
    {
      enabled: false,
      retry: 1,
      onSuccess: (res) => {
        alert(res.data.message), window.location.reload(false);
      },
      onError: (err) => alert(err.data.message),
    }
  );

  const handleSubmit = () => {
    // if((variantName !="" && limaCode !="" &&  enddate != "" && selectedCourse != "" )){
    //   if(activeTabForPublish ==="schedule" ){ `${hour}:${minutes}:${seconds}`!= "00:00:00" ? refetch() :(setShowErrorMessage(true)); }
    //   else if(activeTabForPublish ==="now")(refetch() );
    // }else{
    //   setShowErrorMessage(true);
    // }

    if (!variantName.match(regex)) {
      alert("Please enter a Valid Variant Name");
    } else if (!limaCode.match(limaCodeRegEx) || limaCode.match(onlyZero)) {
      alert("Please Enter a Valid Lima Code");
    } else if (selectedCourse.length <= 0) {
      alert("Please select Courses");
    } else if (
      Number(startdate.replace(/-/g, "")) > Number(enddate.replace(/-/g, ""))
    ) {
      alert("please select End Date after Start Date");
    } else if (isSession) {
      if (myPlanSessions === "") {
        alert("Enter Session Count !!!");
      } else {
        refetch();
      }
    } else refetch();
  };
  // e=>
  const handleKeyDown = (e) => {
    setKeyCode(e.keyCode);
  };

  const handleMyPlansession = (e) => {
    // setMyplanSessions(e.target.value);
    var regex = /^[-+]?[0-9]+$/;
    setMyplanSessions(() => {
      if (e.target.value.match(regex) || keyCode === 8) {
        return e.target.value;
      } else {
        return myPlanSessions;
      }
    });
  };

  const handleChangeForLimaCode = (e) => {
    var regex = /^[-+]?[0-9]+$/;
    setLimaCode(() => {
      if (e.target.value.match(regex) || keyCode === 8) {
        return e.target.value;
      } else {
        return limaCode;
      }
    });
  };
  // setLimaCode(e.target.value)
  return (
    <div className="pb-9">
      <Header />
      <div className="mt-6 ml-2">
        <BreadCrumb />
      </div>
      <div className="font-ibm flex justify-center mt-7">
        <form className="space-y-5 w-[93%] shadow-outer rounded-2xl p-10">
          <div className="space-x-16">
            <label className="font-normal text-lg">Variant Name</label>
            <input
              placeholder="Enter Variant name"
              value={variantName}
              required
              onChange={(e) => setVariantName(e.target.value)}
              className="bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
            />
          </div>
          <div className="space-x-[90px]">
            <label className="font-normal text-lg">Lima Code</label>
            <input
              placeholder="Enter Lima code"
              value={limaCode}
              required
              onKeyDown={handleKeyDown}
              onChange={(e) => handleChangeForLimaCode(e)}
              className="bg-white border-2 w-[31%] border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-XL focus:placeholder-transparent"
            />
          </div>
          <div className="flex space-x-[90px]">
            <label className="font-normal text-lg mt-2">Courses</label>
            <DropDown
              type="multiselect"
              options={coursesData?.data?.data?.courseList}
              placeholder="Select Course"
              width={"w-[22rem]"}
              setUpdatedOptions={setSelectCourse}
              forCourses={true}
            />
          </div>
          <div className="flex space-x-8">
            <ToggleSchedule
              title="Publish"
              activeTab={activeTabForPublish}
              setActiveTab={setActiveTabforPublish}
              margin="28"
            />
            {activeTabForPublish === "schedule" && (
              <div className="flex  space-x-5">
                <label className="font-normal text-lg mt-2">Start Date</label>
                <aside className="flex">
                  <input
                    placeholder="Select Start Date"
                    value={startdate}
                    readOnly
                    className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"
                  />
                  <div className="relative top-4 right-8">
                    <CalendarModalTrigger setDate={setStartDate} />
                  </div>
                </aside>
              </div>
            )}
          </div>
          {activeTabForPublish === "schedule" && (
            <div className="flex">
              <label className=" font-normal text-lg font-ibm -mr-4">
                Start time
              </label>
              <div className="ml-28">
                <DropdownDuration
                  minOffset={0}
                  maxOffset={23}
                  text={"Hrs"}
                  setSelectedValue={setHour}
                />
              </div>
              <div className="ml-2">
                <DropdownDuration
                  minOffset={0}
                  maxOffset={59}
                  text={"Mins"}
                  setSelectedValue={setMinutes}
                />
              </div>
              <div className="ml-2">
                <DropdownDuration
                  minOffset={0}
                  maxOffset={59}
                  text={"Sec"}
                  setSelectedValue={setSeconds}
                />
              </div>
            </div>
          )}
          <div className="flex  space-x-[100px]">
            <label className="font-normal text-lg mt-2">End Date</label>
            <div className="flex">
              <input
                placeholder="select End Date"
                value={enddate}
                readOnly
                required
                className="bg-white border-2 w-[100%] border-secondary-300 cursor-default rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"
              />
              <div className="relative top-4 right-8">
                <CalendarModalTrigger setDate={setEndDate} />
              </div>
            </div>
          </div>
          <Toggle
            title="This Variant is"
            activeTab={variantStatus}
            setActiveTab={setVariantStatus}
          />
          <ToggleYesNo
            title="Centre Proctored Allowed"
            toggleState={isCentreProctoredAllowed}
            setToggleState={setIsCentreProctoredAllowed}
          />
          <div className="flex space-x-10">
            <ToggleYesNo
              title="myPlan Sessions"
              mg="mr-[40px]"
              toggleState={isSession}
              setToggleState={setIsSession}
            />
            {isSession && (
              <input
                value={myPlanSessions}
                onKeyDown={handleKeyDown}
                placeholder="Set your sessions here"
                onChange={(e) => handleMyPlansession(e)}
                className="bg-white border-2 w-[25%] border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-XL focus:placeholder-transparent"
              />
            )}
          </div>
        </form>
      </div>
      <div className="flex justify-center mt-5 space-x-5 mb-5">
        <button
          className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer"
          onClick={() => handleSubmit()}
        >
          Save
        </button>
        <button
          className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer"
          onClick={history.goBack}
        >
          Exit
        </button>
      </div>
    </div>
  );
};
export default AddVariant;
