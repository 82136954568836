import React from "react";
import Header from "../../header/Header";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
// import AddEditArticle from "../Articles/AddEditArticle";
import AddEditEvent from "./AddEditEvent";

const AddEventforSchool = () => {
  const obj = {   //duplicate object for Add the event
    eventTitle: "",
    date: "",
    time: "00:00:00",
    duration: "",
    eventLink: "",
    type: "Meeting",
    isPinned: "0",
    tags: [],
    thumbnailLink: "",
    registrationUrl: "",
    schools: [],
    courses: [],
    createdBy: "",
    createdTs: "",
    modifiedBy: "",
    modifiedTs: "",
    isActive: "1",
    eventId :""
  };
  return (
    <div>
      <Header />
      <BreadCrumb />
      {/* Reusable component for Adding the event */}
      <AddEditEvent requestType="Add" eventsdata={obj}/> 
    </div> 
  );
};

export default AddEventforSchool;
