import React,{ useState} from "react";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import Header from "../../header/Header";
import Toggle from "../../global/fields/Toggle";
import {  useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import {  useQuery } from "react-query";
import { useSelector } from "react-redux";

const EditCategory = () => {
  const history = useHistory();
  const location = useLocation();
  const dataValues = location.state.data;
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  const [value, setValue] = useState({categoryName:dataValues.categoryName,categoryDescription:dataValues.description});
  const [activeTab,setActiveTab]=useState((dataValues.isActive == "0") ? "inactive" : "active");
  let isActive = activeTab ==="active" ? "1" : "0";
  const handleChange = (e) => {
    setValue({...value,[e.target.name]:e.target.value});
  };
  const fetchEditRequest = () => {
    return apirequest({
      url: endpoint.edit_category.addr,
      method: "put",
      data : {
        "requestType": "update",
        "categoryName": value.categoryName,
        "description": value.categoryDescription,
        "isActive": isActive,
        "modifiedBy": login_Details.userImsPin,
        "categoryId": dataValues.categoryId
      }    
    });
  };
  const {refetch:fetch_editCategory} = useQuery(`${endpoint.edit_category.key}`,fetchEditRequest,{
    enabled:false,
    onSuccess: (data) => {
      alert(data?.data?.message);
    },
    onError: (err) => {
      alert(err.data.message);
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    fetch_editCategory();
  };
  return (
    <div>
      <Header />
      <div className="mt-6 ml-16">
        <BreadCrumb type={"editCourse"}/>
      </div>
      <div className="pb-[5rem]">
        <div className="courseTable__body md:ml-[6%] md:mr-[12%] mt-4 shadow-outer py-12 px-8 rounded-xl bg-white pb-[2%]">
          <form className="flex flex-col justify-start font-ibm" id="editCategory" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="inline-block w-[10%] text-left whitespace-nowrap mr-14 font-normal font-ibm text-lg mt-3">Category Name </label>
              <input className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                type="text" 
                name= "categoryName"
                placeholder="Type category name"
                onChange={handleChange} 
                value={value.categoryName}
                required/>        
            </div>
            <div className="flex mb-4">
              <label className="inline-block w-[10%] text-left whitespace-nowrap mr-14 font-normal font-ibm text-lg mt-3">Category Description </label>
              <textarea className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none resize-none w-7/12 focus:placeholder-transparent placeholder-bold font-semibold" 
                rows="4" cols="50" 
                name="categoryDescription"
                onChange={handleChange}
                placeholder="Enter a short description about the Category" 
                value={value.categoryDescription}
                required/>        
            </div>
            <Toggle activeTab={activeTab} title={"This Course is"} setActiveTab={setActiveTab}/>
          </form>
        </div>
        <div className="flex items-center justify-center mt-7 pr-20">
          <button type="submit" form="editCategory" className="bg-primary-200 text-white font-ibm px-12 rounded-xl hover:opacity-75 shadow-outer py-[10px] text-base font-medium mr-10 cursor-pointer">Save</button>
          <button  className="text-black boxShadow-forButton font-ibm px-10 rounded-xl hover:opacity-75 shadow-outer text-base font-medium py-[10px] cursor-pointer" onClick={history.goBack}>Exit</button>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;