import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import CourseTable from "../../components/table/CourseTable";
import TablePagination from "../../components/table/TablePagination";
import { useQuery } from "react-query";
import { endpoint } from "../../API/config";
import { apirequest } from "../../API/api";
import Loader from "../../components/global/loader/Loader";
import { useSelector,useDispatch } from "react-redux";
import {getHeaderDetail,getSubheaderOption,getCurrentSubHeaderTab} from "../../redux/features/header/headerSlice";

const AccessManagement = () => {

  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);

  const dispatch = useDispatch();
  const column = [
    { col: "0", width: "45%" },
    { col: "1", width: "50%" },
    { col: "2", width: "" },
  ];
  const {
    data: user_Data,
    status,
    error,
  } = useQuery(endpoint.access_Management_User_Api.key, 
    () => apirequest({
      url: `${endpoint.access_Management_User_Api.addr}`,
      method: "POST",
      data: {
        adminImsPin: login_Details.userImsPin,
      },
    }),
    {
      select: (data) => data.data.data,
    }
  );

  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 5;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };
  useEffect(() => {
    dispatch(getCurrentSubHeaderTab(""));
    dispatch(getHeaderDetail(""));
    dispatch(getSubheaderOption(""));
  }, []);
  return (
    <div>
      <Header />
      {status === "loading" ? (
        <Loader />
      ) : status === "error" ? (
        <span>{error.message}</span>
      ) : (
        <div className=" m-9">
          <div className="flex justify-between">
            <span className="text-base font-medium ">Access Management</span>
            {user_Data.global_add_access === "1" ?
              <Link to="/access_Management/Add-User">
                <button className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer mt-7">
                  Add User
                </button>
              </Link> : 
              null }
          </div>
          <div className=" shadow-outer rounded-2xl pb-2 mt-5">
            <CourseTable
              column={column}
              currentData={user_Data.usersList.slice(
                indexOfFirstItem,
                indexOfLastItem,
              )}
              tableHead={false}
            />
          </div>
          <TablePagination
            data={user_Data.usersList}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            paginate={paginate}
            dataPerPage={dataPerPage}
          />
        </div>
      )}
    </div>
  );
};
export default AccessManagement;
