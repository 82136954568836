import React from "react";
import SubHeaderTab from "./SubHeaderTab";
import PropTypes from "prop-types";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import {useSelector} from "react-redux";

SwiperCore.use([Navigation]);

function SubHeader({
  
  setCurrentDropDownOption,
  setCurrentSubHeaderTab,
  setSelectedSubHeaderData,
  currentHeaderTab,
}) {
  const subHeaderOptions=useSelector(state=>state.HeaderData.subHeaderOptions);
  const handleLink = link => {
    const path=`${currentHeaderTab?.replace(/ /g, "")}/${link}`;
    return path;
  };
  return (
    <div className="flex justify-start px-14 shadow-outer ">
      {subHeaderOptions.filter(name => name.viewAccess!=="0" || name.editAccess!=="0" || name.deleteAccess!=="0").map(subHeader => (
        <SubHeaderTab
          key={subHeader.product_module_id}
          buttonName={subHeader.module_name}
          link={handleLink(subHeader.module_name.replace(/ /g, ""))}
          dropDownOptions={subHeader.dropDownOptions}
          setCurrentDropDownOption={setCurrentDropDownOption}
          setCurrentSubHeaderTab={setCurrentSubHeaderTab}
          setSelectedSubHeaderData={setSelectedSubHeaderData}
          currentHeaderTab={currentHeaderTab}
          dropdownData={subHeader.subModules}
        />
      ))}
    </div>
  );
}

SubHeader.propTypes = {
  subHeaderData: PropTypes.array,
  setCurrentDropDownOption: PropTypes.func,
  setCurrentSubHeaderTab: PropTypes.func,
  setSelectedSubHeaderData: PropTypes.func,
  currentHeaderTab: PropTypes.string,
};

export default SubHeader;
