import React,{useState,useEffect} from "react";
import DropDown from "../global/fields/DropDown";
import propTypes from "prop-types";
import submitted from "../../assets/icons/submitted.svg";
import { useQuery } from "react-query";
import { endpoint } from "../../API/config";
import {apirequest} from "../../API/api";
import {  useLocation } from "react-router-dom";
import WhiteSpace from "../global/regEx/WhiteSpaceValidator";
import { useSelector } from "react-redux";

const CreateContentRepo = ({contentRepFieldValue,setContentRepFieldValue,isSuccessMsg ,refetch_ContentRepo_data,dataValues,setShowAddAssetButton}) => {
  const [dropdownValue,setDropdownValue]= useState((dataValues?.productGroupName) ? dataValues.productGroupName : "");//state to get dropdown set values
  const [contentRepoValue,setContentRepoValue]= useState((dataValues) ? dataValues.contentRepositoryName: "");//state to get content repo field  set values
  const location = useLocation();
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);

  const handleChange =(e) => {
    setContentRepoValue(e.target.value);
  };
  const [readonly,setReadonly]= useState(location.pathname.split("/")[3] == "editContentRepo" ? true : false);//state to make input readonly
  const [showBlueBtn,setShowBlueBtn] = useState(location.pathname.split("/")[3] == "editContentRepo" ? false :true);//hide and show save and edit buttons
  const [assetBtn] = useState(location.pathname.split("/")[3] == "editContentRepo" ? true :false);
  const [editmode, setEditmode] = useState(false);
  /* Update Content Repo API*/
  const fetch_UpdateContent_Repo = () => {
    return apirequest({
      url:endpoint.update_content_repo.addr,
      method:"put",
      data: {
        requestType: "update",
        contentRepositoryName : contentRepoValue,
        productGroupName : dropdownValue,
        isActive : "1",
        modifiedBy : login_Details.userImsPin
        ,
        contentRepositoryId :dataValues?.contentRepositoryId.toString()
      }
    });
  };
  const {refetch:refetch_update_contentRepo} = useQuery(`${endpoint.update_content_repo.key}`,fetch_UpdateContent_Repo,{
    enabled:false,
    onSuccess:(data)=>{
      alert(data.data.message);
    },
    onError: (err) => alert(err)
  });
  /* End Update Content Repo API*/
  const handleSubmit = () => {
    if (editmode == false) {
      if (dropdownValue && contentRepoValue) {
        if(WhiteSpace(contentRepoValue)===true){
          alert("Please enter a Valid Content Repository");
        }
        else {
          refetch_ContentRepo_data();
          setReadonly(true);
          setShowBlueBtn(false);
        }

      }else{
        alert("Please fill both the fields!");
      }
    }
    if (editmode) {
      if (dropdownValue && contentRepoValue) {
        refetch_update_contentRepo();
        setReadonly(true);
        setShowBlueBtn(false);
      }else{
        alert("Please fill both the fields!");
      }
    }
    if (contentRepoValue != "" && dropdownValue !="") {
      localStorage.setItem("breadcrumbs", contentRepoValue);
    }
  };
  const editContentRepository = () => {
    // setShowComp(false); 
    setReadonly(false);
    setShowBlueBtn(true);
    setEditmode(true);
  };
  const renderButtonChange = () => {
    if (showBlueBtn) {
      return(
        <button type="submit" className="bg-primary-200 text-white font-ibm px-8 rounded-xl hover:opacity-75 shadow-outer py-[10px] text-base font-medium cursor-pointer ml-10 mr-5"
          onClick={()=> handleSubmit()}>Save</button> 
      );  
    }else{
      return(
        <div className="mr-4 cursor-pointer"><img className="h-9 ml-[3.11111rem] mr-12 mt-2" onClick={()=> editContentRepository()} src={submitted}/></div>
      );  
    }
  };
  // Query for product data ============>
  const {data :product_groupData ,isSuccess :product_groupStatus} = useQuery(endpoint.product_group.key,()=>apirequest({
    url: `${endpoint.product_group.addr}`
  }));
  useEffect(() => {
    setContentRepFieldValue({...contentRepFieldValue,inputValue:contentRepoValue,productValue:dropdownValue});
    // if (contentRepoValue && dropdownValue && location.pathname.split("/")[1] == "editContentRepo") {
    //   setShowBlueBtn(false);
    //   setReadonly(true);
    // }
  },[dropdownValue,contentRepoValue]);
  useEffect(() => {
    if (isSuccessMsg) {
      setReadonly(true);
      setShowBlueBtn(false);
      setShowAddAssetButton(true);
    }
  }, [isSuccessMsg]);
  useEffect(() => {
    if (assetBtn) {
      setShowAddAssetButton(true);
    }
  }, [assetBtn]);
  return (
    <div className="flex justify-between">
      <div className="">
        <input className="font-semibold text-xl ml-12 bg-white border-none pl-4 pr-5 py-3  px-2 focus:outline-none w-[25rem] cursor-pointer focus:placeholder-transparent capitalize" type="text" placeholder="Give your Repository a name" autoComplete="off"
          name="createRepository"
          onChange={(e)=>handleChange(e)}
          value={contentRepoValue}
          readOnly={readonly}
        />
      </div>
      <div className="flex justify-end mb-4">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">Product Group</label>
        <DropDown placeholder={"Search or Create new Group"} options={product_groupStatus ? product_groupData.data.data : []}  type={"singleSelect"} width={"w-[20rem]"} setFieldValue={setDropdownValue} readonly={readonly} valueReadProduct={dropdownValue}/>          
        {renderButtonChange()}
      </div>
    </div>
  );
};
CreateContentRepo.propTypes = {
  contentRepFieldValue:propTypes.object,
  setContentRepFieldValue:propTypes.func,
  showComp:propTypes.bool,
  setShowComp:propTypes.func,
  refetch_ContentRepo_data :propTypes.func,
  isSuccessMsg:propTypes.bool,
  dataValues:propTypes.any,
  setShowAddAssetButton:propTypes.func
};
export default CreateContentRepo;