import React from "react";
import propTypes from "prop-types";

function executeAllFunctions(functionsArray) {
  functionsArray && functionsArray.forEach(func=> func());
}

function ModalButton({ modalButtonValue, modalButtonFunctions }) {
  const handleClick =() => {
    executeAllFunctions(modalButtonFunctions);
  };
  return (
    <button
      onClick={handleClick}
      type="button"
      className="w-full flex-1 p-3 text-base font-ibm font-medium text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
    >
      {modalButtonValue}
    </button>
  );
}

ModalButton.propTypes = {
  modalButtonValue: propTypes.string,
  modalButtonFunctions: propTypes.array,
};

export default ModalButton;
