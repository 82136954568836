import React from "react";
import propTypes from "prop-types";
import submitted from "../../../../assets/icons/add.svg";
import { Link } from "react-router-dom";
import calendar from "../../../../assets/icons/calendar-inactive.svg";
import { useDispatch } from "react-redux";

function BlogTable({ column, currentData, icon, reduxdata }) {
  const dispatch = useDispatch();
  
  return (
    <div>
      {currentData?.length == 0 && (
        <p className="py-20 px-10 text-center text-xl font-ibm opacity-80">
          No Data Found!
        </p>
      )}
      <table className="table-spacing border-separate w-full">
        {currentData?.map((data, index) => (
          <tbody key={index}>
            <tr>
              <td width={column[3]["width"]}>
                <img className="h-8 mr-5" src={icon} />
              </td>
              <td width={column[1]["width"]}>
                <div className="align-top ">
                  <Link to={`/HomePage/BlogManagement/${data.blogName}`}>
                    <p
                      onClick={() => {
                        dispatch(reduxdata(data));
                      }}
                      className={
                        "text-xl " +
                        (data.isActive == "1"
                          ? "opacity-100 cursor-pointer text-ibm"
                          : "opacity-60 text-ibm")
                      }>
                      {data.blogName}
                    </p>
                    {data.modified && (
                      <div className="flex ml-3 mt-3">
                        <img src={calendar} className="mb-4" />
                        <p
                          className={
                            "text-sm ml-2 " +
                            (data.status == "1"
                              ? "opacity-100 text-ibm"
                              : "opacity-60 text-ibm")
                          }>
                          Published by {data.author} on {data.modified}{" "}
                          {data.time}
                        </p>
                      </div>
                    )}
                  </Link>
                </div>
              </td>
              <td className="align-top" width={column[2]["width"]}>
                <span className="mr-2 text-lg py-2 capitalize">
                  {data.courseNames.join(",")}
                </span>
              </td>
              <td width={column[3]["width"]} className="align-top">
                <div key={index} className="flex cursor-pointer items-center space-x-5">
                  {data.editAccess==="1" && 
                    <Link to={`/HomePage/BlogManagement/edit-blogs:${data.blogName}`}>
                      <img
                        className="h-7"
                        src={submitted}
                        onClick={() => {
                          dispatch(reduxdata(data));
                        }}></img>
                    </Link>}
                </div>
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}
BlogTable.propTypes = {
  column: propTypes.array,
  currentData: propTypes.array,
  type: propTypes.string,
  icon: propTypes.string,
  refetch: propTypes.func,
  reduxdata: propTypes.func,
};
export default BlogTable;
