import React from "react";
import TableTitle from "./TableTitle";
import myTaskIcon from "../../assets/icons/myTest.svg";
import courseSlice from "../../redux/features/courseware/courseSlice";
import { setVariantsByCourseData } from "../../redux/features/variantsByCourse/variantsByCourseSlice";
import classFlow from "../../assets/icons/classFlow.svg";
import learningPath from "../../assets/icons/learningPath.svg";
import Tooltip from "../global/tooltip/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import propTypes from "prop-types";
import { useState } from "react";
import Loader from "../global/loader/Loader";
import { endpoint } from "../../API/config";

function MappedVariantsTable({ currentData ,variant_status}) {
  const courseDetails = useSelector(state => state?.courseData?.courseDetails);
  const [order, setOrder] = useState("ASC");
  const route = courseDetails.courseName + " - " + courseDetails.productGroupName;
  const login_Details = useSelector(state => state?.loginData?.LoginDetails);
  const dispatch = useDispatch();

  const setValuesToLocalStorage = cData => {
    localStorage.setItem("mappedVariant", cData?.variantName);
    localStorage.setItem("LIMA code", cData.limaCode);
  };

  const handleSort = col => {
    if (order == "ASC") {
      setOrder("DESC");
      currentData.sort((a, b) => (a[col] > b[col] ? 1 : -1));
    } else if (order == "DESC") {
      setOrder("ASC");
      currentData.sort((a, b) => (a[col] < b[col] ? 1 : -1));
    }
  };

  function downloadClassFlowCsv(variantId){
    window.open(
      `${process.env.REACT_APP_ENV}/${endpoint.downloadClassFlowCsv.addr}/${variantId}/${login_Details.userImsPin}`,
    );
  }

  return (
    <div>
      {/* table header */}
      <div className="flex shadow-outer h-[4rem] px-7 items-center ">
        <p className=" font-semibold text-lg m-0 leading-none " style={{ flexBasis: "33%" }}>
          Variant Name
        </p>
        <p className=" font-semibold text-lg m-0 " style={{ flexBasis: "20%" }}>
          Lima Code
          <span
            className="ml-1 cursor-pointer font-medium relative"
            onClick={() => handleSort("limaCode")}>
            <span className="absolute left-0 opacity-40">&#8593;</span>
            <span className="absolute left-2 opacity-40">&#8595;</span>
          </span>
        </p>
        <p className=" font-semibold text-lg m-0 " style={{ flexBasis: "29%" }}>
          Courses
        </p>
      </div>
      {/* table body */}
      <div>
        {variant_status == "loading" ? <Loader/> : currentData?.length > 0 ? currentData?.map(item => (
          <div key={item.variantId} className="flex mb-4 items-start py-3 px-7">
            <TableTitle
              icon={myTaskIcon}
              isActive={item.isActive}
              title={item.variantName}
              modified={courseSlice.modifiedBy !== null}
              date={item.modifiedBy !== null ? item.modifiedTs : item.createdTs}
              width={"33%"}
            />
            <p className={`text-xl ${item.isActive === "1" ? "opacity-100" : "opacity-60"} `}
              style={{ flexBasis: "20%" }}>
              {item.limaCode}
            </p>
            <p className={`text-xl ${item.isActive === "1" ? "opacity-100" : "opacity-60"} `}
              style={{ flexBasis: "29%" }}>
              {item.courseNames.toString()}
            </p>
            <div className="ml-4 rounded-full shadow-forButton flex p-[9px] bg-white mr-4">
              <Link to={`/Courseware/Courses/edit-Course:${route}/navigationPath`}>
                <img
                  onClick={() => {
                    setValuesToLocalStorage(item),
                    dispatch(setVariantsByCourseData(item));
                  }}
                  src={classFlow}
                  data-tip
                  data-for="navgPath"
                />
              </Link>
              {
                <Tooltip
                  multiline={false}
                  id="navgPath"
                  place="bottom"
                  text={"Navigation Path"}
                  offset={{ bottom: 10, right: 10 }}
                />
              }
            </div>

            <div className="mx-4 rounded-full shadow-forButton flex p-[9px]">
              <img src={learningPath} data-tip data-for="classFlow" className="cursor-pointer" onClick={() => {
                downloadClassFlowCsv(item.variantId);
              }}  />
              {
                <Tooltip
                  multiline={false}
                  id="classFlow"
                  place="bottom"
                  text={"Class Flow"}
                  offset={{ bottom: 10, right: 10 }}
                />
              }
            </div>
          </div>
        ))
          :  <p className="text-xl text-center mt-9">No Data Found! </p>}
      </div>
    </div>
  );
}

MappedVariantsTable.propTypes = {
  currentData: propTypes.array,
  variant_status:propTypes.string
};

export default MappedVariantsTable;
