import React from "react";
import propTypes from "prop-types";
import AddTierModal from "./AddTierModal";

const TriggerEditTierFolder = () => {
  return (
    <div className="py-1 font-ibm"><span className="whitespace-nowrap text-black hover:text-black">Edit</span></div>
  );
};

function EditTierFuncButton({ assetPath, parentData, editing ,handleEditClick}) {
  return (
    <AddTierModal
      assetPath={assetPath}
      Trigger={TriggerEditTierFolder}
      parentData={parentData}
      editing={editing}
      handleEditClick={handleEditClick}
    />
  );
}
EditTierFuncButton.propTypes = {
  assetPath: propTypes.string,
  parentData: propTypes.object,
  editing: propTypes.bool,
  handleEditClick: propTypes.func,
};
export default EditTierFuncButton;