import React, { useState } from "react";
import Header from "../../components/header/Header";
import CourseTable from "../../components/table/CourseTable";
import TablePagination from "../../components/table/TablePagination";
import { Link } from "react-router-dom";
import testIcon from "../../assets/icons/quiz.svg";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import Loader from "../../components/global/loader/Loader";
import TableSearch from "../../components/table/TableSearch";
import ArrowUp from "../../assets/icons/arrow-up.svg";
import arrowDown from "../../../src/assets/icons/arrow-down.svg";
import OutsideClickHandler from "react-outside-click-handler";

const Communication = () => {
  // const modulesData = globalFilter({
  //   name: "Home Page",
  //   subModuleName: "Communication Management",
  // });
  const column = [
    { col: "0", width: "38%" },
    { col: "1", width: "19%" },
  ];
  
  const type = [
    {id : 1, communicationType : "Email"},
    {id : 2, communicationType : "WhatsApp"},
    {id : 3, communicationType : "Push Notification"},
    {id : 4, communicationType : "Maintenance Message"},
  ];

  const [getCommunicationData, setCommunicationData] = useState({});
  const [communicationType, setCommunicationType] = useState([]);
  const [showDropdown, setShowDropDown] = useState(false);

  const handleChange = (e, item) => {
    const isChecked = e.target.checked;
    // let value = e.target.value;
    if (isChecked) {
      // setPayload([...payload, e.target.id]);
      setCommunicationType([...communicationType, item.communicationType]);
    } else {
      setCommunicationType(communicationType.filter((val) => val != item.communicationType));
    }
  };
  const {
    data: communicationData,
    status,
    isRefetching,
  } = useQuery(
    `${endpoint.getCommunicationData.key}`,
    () =>
      apirequest({
        url: `${endpoint.getCommunicationData.addr}`,
        method : "post",
        data: {communicationType}
      }),
    {
      onSuccess: (res) => setCommunicationData(res),
      retry: 1,
      refetchOnMount: true,
    }
  );

  const { refetch } = useQuery(
    "Filter___Key",
    () =>
      apirequest({
        url: endpoint.getCommunicationData.addr,
        method: "post",
        data: {
          communicationType
        },
      }),
    {
      enabled: false,
      onSuccess: (res) => {
        setCommunicationData(res);
        setCurrentPage(1);
      },
      onError :(err)=>alert(err.data.message)
    }
  );

  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };
  return (
    <div>
      <Header />
      {status === "loading" || isRefetching ? (
        <Loader />
      ) : (
        <div className="courseTable mt-9">
          <div className="courseTable__head flex justify-between  md:mt-[4%] md-mb-[5%] md:mx-9 pl-[2%]">
            <div className="flex">
              <OutsideClickHandler
                onOutsideClick={() => setShowDropDown(false)}
              >
                <div className="flex space-x-3">
                  <div className="flex cursor-pointer bg-secondary-100 text-lg text-black shadow-outer px-[20px] py-[10px] rounded-xl items-center">
                    Filter by:{" "}
                    <input
                      className="focus:outline-none  w-3/6 rounded-lg"
                      placeholder={"Asset Type"}
                      readOnly
                      value={communicationType.toString()}
                    />
                    <img
                      src={arrowDown ? arrowDown : ArrowUp}
                      onClick={() => setShowDropDown(!showDropdown)}
                      className="ml-4"
                    />
                  </div>
                  <p
                    type="submit"
                    className="bg-primary-200 text-white font-ibm px-12 rounded-xl hover:opacity-75 shadow-outer py-[10px] text-base font-medium mr-10 cursor-pointer"
                    onClick={refetch}
                  >
                    Save
                  </p>
                </div>

                {showDropdown && (
                  <ul className="h-auto ml-2 mt-1 pt-2 px-3 w-80 border-2 border-solid border-gray-200 rounded-lg shadow-lg absolute z-50 bg-white overflow-y-scroll scrollbar scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded  max-h-[10rem]">
                    {type.map((item) => (
                      <div className="flex" key={item.id}>
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.assetType}
                          checked={communicationType.includes(item.communicationType)}
                          onChange={(e) => handleChange(e, item)}
                        />
                        <li
                          key={item.id}
                          // removed onclick handler to avoid duplicated
                          // onClick={() => setCommunicationType([...communicationType, item.communicationType])}
                          className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize"
                        >
                          {item.communicationType}
                        </li>
                      </div>
                    ))}
                  </ul>
                )}
              </OutsideClickHandler>
            </div>
            <div className="ml-4 flex">
              <TableSearch
                defaultData={communicationData}
                setDefaultData={setCommunicationData}
                dataKey="communicationName"
                dataForFilter={communicationData?.data.data}
                name="Home Page"
                subModuleName="Communication Management"
                setCurrentPage={setCurrentPage}
                childModuleName=""
                placeholder="Search by Communication name or Tag"
              />
              <div className="ml-6 bg-primary-200 text-white font-normal px-6 rounded-xl hover:opacity-75 shadow-outer text-lg py-[7px]">
                <Link
                  className="text-white hover:text-white"
                  to="/HomePage/CommunicationManagement/new-Communication"
                >
                  Add Communication
                </Link>
              </div>
            </div>
          </div>
          <div className="courseTable__body md:ml-[4%] md:mr-[34px] mt-4 shadow-outer pt-3 pl-[2%] pr-[3%] rounded-xl bg-white pb-[2%]">
            <CourseTable
              column={column}
              currentData={getCommunicationData?.data?.data?.slice(
                indexOfFirstItem,
                indexOfLastItem
              )}
              colIcon={testIcon}
              iconHeight={"2rem"}
              tableHead={false}
              isforAddConentRepo={false}
            />
          </div>
          <div className="courseTable__footer mx-auto mb-7">
            <TablePagination
              data={getCommunicationData?.data?.data}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              paginate={paginate}
              dataPerPage={dataPerPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Communication;
