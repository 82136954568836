import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  importLearningPathDetails: {},
  clipBoardData: ""
};

const importLearningPathSlice = createSlice({
  name: "importLearningPathData",
  initialState,
  reducers: {
    setImportLearningPathData: (state, action) => {
      state.importLearningPathDetails = action.payload;
    },
    setClipBoardData: (state, action) => {
      state.clipBoardData = action.payload;
    },
    clearImportData: (state) => {
      state.importLearningPathDetails = {};
      state.clipBoardData = "";
    }
  },
});

export const {setImportLearningPathData, setClipBoardData, clearImportData } = importLearningPathSlice.actions;

export default importLearningPathSlice.reducer;