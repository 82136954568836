import React, { useState } from "react";
import WithModal from "../../global/modal/WithModal/WithModal";
import CourseTable from "../../table/CourseTable";
import testIcon from "../../../assets/icons/test.svg";
import TablePagination from "../../table/TablePagination";
import ContentRepositoryComp from "../../createContentRepo/ContentRepositoryComp";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import propTypes from "prop-types";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import { apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { addAssets } from "../../../redux/features/learningPath/learningPathSlice";
import { tempSetAssets } from "../../../redux/features/learningPath/learningPathSlice";
import {} from "../../../redux/features/learningPath/learningPathSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../global/loader/Loader";

const MapAssetModal = ({
  closeModal,
  setDisableSave,
  learningPathId,
  addingToTier = false,
}) => {
  const [assetDetails, setAssetDetails] = useState([]);
  const variantId = useSelector(state => state.variantsByCourseData.variantsByCourse.variantId);
  const column = [
    { col: "0", width: "35%" },
    { col: "1", width: "55%" },
    { col: "2", width: "" },
  ];
  const lpID = learningPathId || useSelector(state => state.learningPathData.selectedLearningPathId);
  const login_Details = useSelector(state => state?.loginData?.LoginDetails);

  /*Get Repository API */
  const { data: getContentRepoData, status: contentStatus,isLoading } = useQuery(
    `${endpoint.content_repo.key}`,
    () =>
      apirequest({
        url: `${endpoint.content_repo.addr}`,
        method: "POST",
        data: {
          adminimspin: login_Details.userImsPin,
        },
      }),
  );
  /*Get Repository API */

  /*Map asset API */

  const mapAsset = async () => {
    return apirequest({
      url: `${endpoint.add_LearningPath_asset.addr}`,
      method: "POST",
      data: {
        variantId,
        learningPathId: lpID,
        assetData: selectedData,
        createdBy: login_Details.userImsPin,
      },
    });
  };

  const {refetch : mapAssetToLP} = useQuery(
    endpoint.add_LearningPath_asset.key,
    mapAsset,
    {
      enabled : false,
      onSuccess: res => {
        const addAsetObj = {
          selectedData: res.data.data.assetData,
          addingToTier,
          learningPathId : lpID,
        };
        dispatch(tempSetAssets(addAsetObj));
        dispatch(addAssets(addAsetObj));
        setDisableSave(false);
        closeModal();
      },
      onError: err => {
        alert(err.data.message);
        closeModal();
      }
    }
  );
  /*Map asset API */

  /*Pagination */
  const [selectedData, setSelectedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [repoId, setRepoId] = useState(""); // created state to store the content repo id
  const [showAsset, setShowAsset] = useState(false);
  const dataPerPage = 4;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };
  const dispatch = useDispatch();
  // Getting asset Data using content Repository id had called in course table ======>
  const handleSaveAssets = () => {
    mapAssetToLP();
  };

  // api to call the asset data using content Repo id ===========>
  const { data: default_AssetData, isFetching } = useQuery(
    [endpoint.getAssetsUsingContentRepository.key, repoId],
    () =>
      apirequest({
        url: `${endpoint.getAssetsUsingContentRepository.addr}`,
        method: "post",
        data: {
          contentRepositoryId: repoId.toString(),
        },
      }),
    {
      onSuccess: res => {
        setAssetDetails(res);
        setShowAsset(true);
      },
      onError: err => {
        alert(err.data.message);
        setRepoId("");
      },
      enabled: !!repoId,
      retry: 0,
    },
  );

  return (
    <>
      <div className="p-5 w-[64rem]">
        <div className="flex justify-between mb-8 pl-[27rem]">
          <h1 className="font-ibm text-lg font-medium">Map Asset</h1>
          <Cancel className="cursor-pointer mr-4" onClick={closeModal} />
        </div>
        {!showAsset ? (
          <>
            <BreadCrumb />
            {isLoading && <Loader />}
            {contentStatus === "success" && (
              <div className="rounded-2xl shadow-outer p-5">
                <CourseTable
                  currentData={getContentRepoData?.data?.data?.ContentRepositoryList?.slice(
                    indexOfFirstItem,
                    indexOfLastItem,
                  )}
                  column={column}
                  colIcon={testIcon}
                  dataForContentRepo={true}
                  isforAddConentRepo={true}
                  setAssetDetails={setAssetDetails}
                  setContentRepoId={setRepoId}
                />
              </div>
            )}
            <TablePagination
              data={getContentRepoData?.data.data.ContentRepositoryList}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              paginate={paginate}
              dataPerPage={dataPerPage}
            />
          </>
        ) : isFetching ? (
          <Loader />
        ) : (
          <>
            <p
              className="underline ml-6 cursor-pointer"
              onClick={() => {
                setShowAsset(false);
                setRepoId("");
              }}>
              Back
            </p>
            <ContentRepositoryComp
              isforAddConentRepo={true}
              dataSource={assetDetails?.data.data}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              contentRepoID={repoId} //sending repo id to fetch id based asset from api
              setAssetData={setAssetDetails}
              defaultAssetData={default_AssetData} //default data for filter
            />
          </>
        )}
        <div className="flex justify-center mt-5 space-x-5">
          <button
            className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer"
            onClick={handleSaveAssets}>
            Save
          </button>
          <button
            className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer"
            onClick={() => closeModal()}>
            Exit
          </button>
        </div>
      </div>
    </>
  );
};
MapAssetModal.propTypes = {
  closeModal: propTypes.func,
  setLearningPath: propTypes.object,
  setDisableSave: propTypes.func,
  learningPathId: propTypes.string,
  addingToTier: propTypes.bool,
};
export default WithModal(MapAssetModal);
