import React from "react";
import TableTitle from "./TableTitle";
import GreyPillsMap from "../global/Tags/GreyPillsMap";
import testIcon from "../../assets/icons/test.svg";
import submitted from "../../assets/icons/submitted-no-bg.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCourseDetails, setSlug } from "../../redux/features/courseware/courseSlice";
import propTypes from "prop-types";

function CoursesTable({ currentData }) {
  const dispatch = useDispatch();

  return (
    <>
      {currentData?.map(course => (
        <div key={course.courseId} className="flex mb-4 items-start py-3 px-7 ">
          <TableTitle
            icon={testIcon}
            isActive={course.isActive}
            title={course.courseName + " - " + course.productGroupName}
            modified={course.modifiedBy !== null}
            date={
              course.modifiedBy !== null ? course.modifiedTs : course.createdTs
            }
            width={"45%"}
          />
          <GreyPillsMap data={course.tagNames} />
          <Link
            to={`/Courseware/Courses/edit-Course:${
              course.courseName + course.productGroupName
            }`}
            className={`${
              course.editAccess === "0" ? "pointer-events-none" : ""
            }`}>
            {course.editAccess === "1" ? (
              <img
                onClick={() => {
                  dispatch(setCourseDetails(course));
                  dispatch(setSlug(course.courseName + course.productGroupName));
                }}
                className="h-6"
                src={submitted}
              />
            ) : null}
          </Link>
        </div>
      ))}
    </>
  );
}

CoursesTable.propTypes = {
  currentData: propTypes.array,
};

export default CoursesTable;
