import React, { useState } from "react";
import DropDownForVariant from "../../global/fields/DropDownVariant";
import propTypes from "prop-types";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config";
import { apirequest } from "../../../API/api";
import NewDescription from "./NewDescriptionModal";
import { Draggable } from "react-beautiful-dnd";
import MoveIcon from "../../../assets/icons/move.svg";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const NestedItems = ({
  variantDataForNewCategory,
  nestedItemslist,
  setVariantDataForNewCategory,
  index,
  addVariantData,
  setAddVariantData,
  updateVariantData,
  setUpdateVariantData,
  categoryId,
  setNestedItemsLists,
  nestedItemslistsArray,
}) => {
  const [selectedVariantName, setSelectedVariantName] = useState(
    nestedItemslist?.length > 0 ? nestedItemslist.variantName : ""
  );
  const [variant_ID, setVariant_ID] = useState("");
  const [variantDescrition, setVariantDescription] = useState(
    nestedItemslist?.description ? nestedItemslist?.description : []
  );
  const [tagDetails, setTagDetails] = useState([]);
  const [priceDetails, setPriceDetails] = useState("");
  const parentVariantID = useSelector(
    (state) => state?.variantData?.variantObj.variantId
  );
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  const { data: variantDatas } = useQuery(
    endpoint.variant_get_data.key,
    () => {
      return apirequest({
        url: `${endpoint.variant_get_data.addr}`,
        method: "post",
        data: {
          adminimspin: login_Details.userImsPin,
        },
      });
    },
    { refetchOnWindowFocus: true }
  );

  useEffect(() => {
    if (selectedVariantName !== "" && categoryId) {
      setAddVariantData([
        {
          variantParentId: parentVariantID,
          categoryId: categoryId,
          variantId: variant_ID,
          description: variantDescrition,
          tag: tagDetails,
          isActive: "1",
          price: priceDetails,
          createdBy: login_Details.userImsPin,
        },
        ...addVariantData,
      ]);
    }
  }, [(selectedVariantName, variantDescrition)]);

  useEffect(() => {
    if (variantDescrition != [] && priceDetails != "" && !nestedItemslist.variantId) {
      setVariantDataForNewCategory(
        [
          {
            variantId: variant_ID,
            description: variantDescrition,
            tag: tagDetails,
            price: priceDetails,
          },
        ],
        ...variantDataForNewCategory
      );
    }
  }, [variantDescrition, variant_ID, priceDetails]);

  useEffect(() => {
    // if variant id is not a random number then only update the data
    if (
      variantDescrition != [] &&
      priceDetails != "" &&
      nestedItemslist.variantId && Number.isInteger(Number(nestedItemslist.variantId))
    ) {
      setUpdateVariantData([
        {
          variantParentId: parentVariantID,
          categoryId: categoryId,
          variantId: nestedItemslist.variantId,
          description: variantDescrition,
          tag: tagDetails,
          price: priceDetails,
          isActive: "1",
          modifiedBy: login_Details.userImsPin,
        },
        ...updateVariantData,
      ]);
    }
  }, [priceDetails, variantDescrition, variant_ID]);

  const Trigger = () => {
    return (
      <button className="w-[20%] hover:underline text-blue-600 text-base">
        {" "}
        See More...
      </button>
    );
  };
  const NewDescriptionModal = () => {
    return (
      <NewDescription
        selectedVariantName={selectedVariantName || nestedItemslist.variantName}
        Trigger={Trigger}
        setPriceDetails={setPriceDetails}
        setTagDetails={setTagDetails}
        setVariantDescription={setVariantDescription}
        data={variantDescrition}
        nestedItemslist={nestedItemslist}
        setNestedItemsLists={setNestedItemsLists}
        nestedItemslistsArray={nestedItemslistsArray}
        variant_ID={variant_ID}
        forEditVariantId = {nestedItemslist.variantId}
      />
    );
  };

  return (
    <Draggable
      draggableId={`${nestedItemslist}${index}`}
      key={`${nestedItemslist}${index}`}
      index={index}
    >
      {(provided) => (
        <section
          className="flex mb-3 space-x-5 w-[100%]"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="flex w-[20%]">
            <img
              src={MoveIcon}
              className="w-6 h-6 mt-2 ml-2"
              ref={provided.innerRef}
              {...provided.dragHandleProps}
            />
            {
              <DropDownForVariant
                valueReadProduct={nestedItemslist?.variantName}
                width="w-[25rem]"
                placeholder="<Lima Code> <Course Name>"
                options={variantDatas?.data?.data?.variantList}
                isIconNotNeeded={false}
                forVariant={true}
                setVariant_ID={setVariant_ID}
                setGetdropdownvalue={setSelectedVariantName}
              />
            }
          </div>
          <div className="relative left-60 flex w-[80%] space-x-6">
            <input
              placeholder="Enter the content here "
              value={variantDescrition}
              onChange={() =>
                alert("Plz Click see more button to add the description")
              }
              className={
                "bg-white border-2 w-[60%] border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-XL focus:placeholder-transparent "
              }
            />
            <NewDescriptionModal />
          </div>
        </section>
      )}
    </Draggable>
  );
};
NestedItems.propTypes = {
  nestedItemslist: propTypes.any,
  index: propTypes.any,
  setNestedItemsLists: propTypes.func,
  addVariantData: propTypes.array,
  setAddVariantData: propTypes.func,
  categoryId: propTypes.string,
  setVariantDataForNewCategory: propTypes.func,
  variantDataForNewCategory: propTypes.array,
  updateVariantData: propTypes.array,
  setUpdateVariantData: propTypes.func,
  keyValue: propTypes.string,
  nestedItemslistsArray : propTypes.array,
};
export default NestedItems;
