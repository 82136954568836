import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import ArrowUp from "../../../src/assets/icons/arrow-up.svg";
import arrowDown from "../../../src/assets/icons/arrow-down.svg";
import OutsideClickHandler from "react-outside-click-handler";
import { useQuery } from "react-query";
import { apirequest } from "../../API/api";

const FilterFunctionlity = ({
  filterText,
  setFunction,
  payload,
  dropdownData,
  url,
  setPayload,
  payloadData,
  setCurrentPage,
  filterId,
  filterValue
}) => {
  const [showDropdown, setShowDropDown] = useState(false);
  const[filteredData ,setFilteredData] = useState(dropdownData);
  const [selectedValue, setSelectedValue] = useState([]);
  const [value , setValue] = useState("");

  const handleFilter =(e)=>{
    let searchValue = e.target.value;
    setValue(e.target.value);
    let spliitedValue =  searchValue.split(" ");
    searchValue.length>0 ? setShowDropDown(true) : null;
    const filterData = dropdownData?.filter((item)=>{
      return spliitedValue.some((data)=>{
        if(item?.[filterValue].toLowerCase().includes(data.toLowerCase())){
          return item;
        }
      });
    });
    setFilteredData(filterData);
  };
  
  const handleChange = (e , item) => {
    const isChecked = e.target.checked;
    // let value = e.target.value;
    if (isChecked) {
      setPayload([...payload, e.target.id]);
      setSelectedValue([...selectedValue , item]);
    } else {
      const removeItem = e.target.id;
      setPayload(payload.filter((val) => val != removeItem));
      setSelectedValue(selectedValue.filter((val)=> val!= item));
    }
  };

  const handleDeselect =(e, item)=>{
    setPayload(payload.filter(val=>val!= item[filterId]));
    setSelectedValue(selectedValue.filter((val)=>val!= item));
  };

  const { refetch } = useQuery(
    "Filter__Key",
    () =>
      apirequest({
        url: url,
        method: "post",
        data: payloadData,
      }),
    {
      enabled: false,
      onSuccess: (res) => {
        setFunction(res);
        setCurrentPage(1);
      },
      onError: (err) => alert(err.data.message),
      retry: 1,
    }
  );

  useEffect(()=>{
    setFilteredData(dropdownData);  // useEffect to Update the filter data when mounts ===========>
  },[dropdownData]);

  return (
    <OutsideClickHandler onOutsideClick={() => setShowDropDown(false)}>

      <div
        className="flex space-x-5 w-full"
      >
        <div className="cursor-pointer bg-secondary-100 text-lg text-black shadow-outer px-[5px] py-[10px] rounded-xl flex items-center">
          Filter by: <input
            className= "focus:outline-none ml-2 w-40 rounded-lg"
            placeholder={filterText} 
            value = {value}
            onChange = {handleFilter}
          />
          <img src={!showDropdown ? arrowDown : ArrowUp} className=""  onClick={() => setShowDropDown(!showDropdown)}/>
        </div>
        <p
          form="addAssetForm"
          type="submit"
          className="bg-primary-200 text-white font-ibm px-3 rounded-xl hover:opacity-75 shadow-outer py-[10px] text-base font-medium mr-10 cursor-pointer"
          onClick={refetch}
        >
          Save
        </p>
      </div>

      {showDropdown && (
        <div>
          <ul className="h-auto pt-2 px-3 w-80 border-2 border-solid border-gray-200 rounded-lg shadow-lg absolute z-50 bg-white overflow-y-scroll scrollbar-thin scrollbar-thumb-secondary-700 scrollbar-thumb-rounded  max-h-[10rem]">
            <section className= { selectedValue && selectedValue?.length>0 ? "border-b-2 border-secondary-300" : null}>   
              {payload?.length>0 && selectedValue?.map(item=>(
                <div className="flex " key = {item[filterId]}>
                  <input  
                    type ="checkbox"
                    checked ={true}
                    value ={item[filterValue]}
                    // id={}
                    onChange = {(e)=>handleDeselect(e , item)}
                  />
                  <li className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize">{item[filterValue]}</li>
                </div>
              ))}
            </section>
            {filteredData?.length>0 ?filteredData?.map((item) => (
              <div className="flex" key={item[filterId]}>
                <input
                  type="checkbox"
                  id={item[filterId]}
                  value={item[filterValue]}
                  checked={payload.includes(item[filterId])}
                  onChange={(e) => handleChange(e, item)}
                />
                <li className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize">
                  {item[filterValue]}
                </li>
              </div>
            )) : "No Record Found"}
          </ul>
        </div>
        // </OutsideClickHandler>
      )}
    </OutsideClickHandler>
  );
};

FilterFunctionlity.propTypes = {
  filterText: propTypes.string,
  setFunction: propTypes.func,
  dropdownData: propTypes.array,
  payloadData: propTypes.object,
  url: propTypes.string,
  payload: propTypes.array,
  setPayload: propTypes.func,
  setCurrentPage: propTypes.func,
  filterId : propTypes.string,
  filterValue : propTypes.string,
};

export default FilterFunctionlity;
