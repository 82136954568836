import React, { useState } from "react";
import Header from "../../header/Header";
import ToggleEvent from "./ToggleEvent";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import "../blogs/multiselect.css";
import Dropdown from "../blogs/Dropdown";
import propTypes from "prop-types";
import DropDown from "../../global/fields/CourseDropDown";
import ToggleSchedule from "../slideshow/ToggleSchedule";
import CalendarModalTrigger from "../blogs/calendar/CalendarModalTrigger";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import WhiteSpace from "../../global/regEx/WhiteSpaceValidator";

const AddNotice = () => {

  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  let history = useHistory();
  const [activeTabEvent, setActiveTabEvent] = useState("event");
  const [activeTabPublish, setActiveTabPublish] = useState("now");
  const [NoticeName, setNoticeName] = useState("");
  const [Subtext, setSubtext] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [courseTags, setCourseTags] = useState([]);
  const [date, setDate] = useState("Select Date");
  const [StartHour, setStartHour] = useState(0);
  const [StartMinute, setStartMinute] = useState(0);
  const [StartSecond, setStartSecond] = useState(0);
  const [EndHour, setEndHour] = useState(0);
  const [EndMinute, setEndMinute] = useState(0);
  const [EndSecond, setEndSecond] = useState(0);
  const [enddate, setEndDate] = useState("Select End Date");
  const [countdownDate, setCountdownDate] = useState("Select Date");
  // state to store selected course values and passed as prop
  const [showLabel, setShowLabel] = useState([]);
  const [value, setValue] = useState("");
  //to get the current date
  // let today = new Date();
  // let dd = String(today.getDate()).padStart(2, "0");
  // let mm = String(today.getMonth() + 1).padStart(2, "0");
  // let yyyy = today.getFullYear();
  // today = yyyy + "-" + mm + "-" + dd ;
  // to get the current time
  // let todayDate = new Date();
  // const currentHour = todayDate.getHours();
  // const currentMinute = todayDate.getMinutes();
  const { data: getCourseData } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method: "post",
      data : {
        "adminimspin":login_Details.userImsPin
      } 
    }),{
    refetchOnMount : true
  });

  const fetchAddNotice = () => {
    return apirequest({
      url: `${endpoint.add_notice.addr}`,
      method: "post",
      data: {
        noticeBoardName: NoticeName,
        type: activeTabEvent,
        link: redirectUrl,
        courseName: showLabel,
        description: Subtext,
        startDate: activeTabPublish == "now" ? "" : date,
        startTime:
          activeTabPublish == "now"
            ? ""
            : `${StartHour}:${StartMinute}:${StartSecond}`,
        endDate: enddate,
        endTime: `${EndHour}:${EndMinute}:${EndSecond}`,
        countDownStartDate: countdownDate==="Select Date" ? "":countdownDate,
        isActive: "1",
        createdBy: login_Details.userImsPin,
      },
    });
  };
  //API request to add new blogs data
  const { refetch: addNoticeRefetch } = useQuery(
    endpoint.add_notice.key,
    fetchAddNotice,
    {
      enabled: false,
      onSuccess :(res)=>{alert(res.data.message),window.location.reload(false);},
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );

  const onsave = e => {
    if(activeTabPublish==="schedule"){
      if(date==="Select Date"){
        alert("Please select start date");
      }
      else if (enddate==="Select End Date"){
        {
          alert("Please select end date");
        }
      }else if((Number(date.replace(/-/g, "")))>Number(enddate.replace(/-/g, ""))){
        alert("please select End Date after Start Date");
      }
      else if(WhiteSpace(NoticeName)===true || WhiteSpace(redirectUrl)===true || WhiteSpace(Subtext)===true){
        alert("Required fields can't be Blank spaces");
      }
      else if(countdownDate==="Select Date"){
        alert("Please select CountDown Date");
      }
      // to make course selection mandatory
      else if(showLabel.length===0){
        alert("Please Select Course");
      }// to show the alert message if start date is today and start time is before current system's timings
      // else if((Number(date.replace(/-/g, "")))===Number(today.replace(/-/g, ""))){
      //   if(Number(StartHour)<currentHour){
      //     alert("please select start time after current time");
      //   }
      //   else if(Number(StartHour)===currentHour){
      //     if(Number(StartMinute)<currentMinute)
      //       alert("please select start time after current time");
      //     else{
      //       addNoticeRefetch();
      //     }
      //   }
      //   else{
      //     addNoticeRefetch();
      //   }
      // }
      else{
        addNoticeRefetch();
      }
    }
    else if(enddate==="Select End Date"){
      alert("Please select end date ");
    }
    else if(WhiteSpace(NoticeName)===true || WhiteSpace(redirectUrl)===true || WhiteSpace(Subtext)===true){
      alert("Required fields can't be Blank spaces");
    }
    else if(countdownDate==="Select Date"){
      alert("Please select CountDown Date");
    }
    // to make course selection mandatory
    else if(showLabel.length===0){
      alert("Please Select Course");
    }
    else{
      addNoticeRefetch();
    }
    e.preventDefault();
  };

  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />

      <div className="mt-2 mx-12 px-8 py-2 ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
          onSubmit={onsave}>
          <div className="h-full space-y-4  px-12 py-6 relative w-[80%]">
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Communication Title
                <input
                  required
                  type="text"
                  name="blogName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={NoticeName}
                  onChange={e => setNoticeName(e.target.value)}
                />
              </label>
            </div>

            <div className="flex">
              <ToggleEvent
                activeTab={activeTabEvent}
                setActiveTab={setActiveTabEvent}
                title="This is an"
                margin={32}
              />
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Redirect URL
                <input
                  name="blogName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  placeholder="Paste URL"
                  value={redirectUrl}
                  onChange={e => setRedirectUrl(e.target.value)}
                />
              </label>
            </div>
            <div className="flex py-2 space-x-32">
              <label className=" font-normal text-lg font-ibm ">Courses</label>
              <DropDown
                placeholder={"Search Course(s)"}
                options={getCourseData?.data?.data?.courseList}
                setUpdatedOptions={setCourseTags}
                type={"multiselect"}
                width={"w-[22rem]"}
                courseTags={courseTags}
                showLabel={showLabel}
                setShowLabel={setShowLabel}
                value={value}
                setValue={setValue}
                forCourses={true}
              />
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Communication Subtext
                <input
                  required
                  type="text"
                  name="blogName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={Subtext}
                  onChange={e => setSubtext(e.target.value)}
                />
              </label>
            </div>
            <div className="flex">
              <ToggleSchedule
                activeTab={activeTabPublish}
                setActiveTab={setActiveTabPublish}
                title="Publish"
                margin={32}
              />
              {activeTabPublish !== "now" && (
                <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                  <p
                    className={`w-full outline-none boder border-gray-200 ${
                      date
                        ? "text-base text-secondary-400  font-normal"
                        : "text-sm  text-primary-700"
                    }`}>
                    {date && date}
                  </p>
                  <CalendarModalTrigger setDate={setDate} />
                </div>
              )}
            </div>
            <div className="flex">
              {activeTabPublish !== "now" && (
                <>
                  <label className=" font-normal text-lg font-ibm ">
                    Start time
                  </label>
                  <div className="ml-32">
                    <Dropdown
                      minOffset={0}
                      maxOffset={23}
                      text={"Hrs"}
                      selectedValue={StartHour}
                      setSelectedValue={setStartHour}
                    />
                  </div>
                  <div className="ml-2">
                    <Dropdown
                      minOffset={0}
                      maxOffset={59}
                      text={"Mins"}
                      selectedValue={StartMinute}
                      setSelectedValue={setStartMinute}
                    />
                  </div>
                  <div className="ml-2">
                    <Dropdown
                      minOffset={0}
                      maxOffset={59}
                      text={"Sec"}
                      selectedValue={StartSecond}
                      setSelectedValue={setStartSecond}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="flex space-x-32">
              <label className=" font-normal text-lg font-ibm ">End Date</label>
              <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    enddate
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"
                  }`}>
                  {enddate && enddate}
                </p>
                <CalendarModalTrigger setDate={setEndDate} />
              </div>
            </div>
            <div className="flex">
              <label className=" font-normal text-lg font-ibm ">End time</label>
              <div className="ml-32">
                <Dropdown
                  minOffset={0}
                  maxOffset={23}
                  text={"Hrs"}
                  selectedValue={EndHour}
                  setSelectedValue={setEndHour}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={0}
                  maxOffset={59}
                  text={"Mins"}
                  selectedValue={EndMinute}
                  setSelectedValue={setEndMinute}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={0}
                  maxOffset={59}
                  text={"Sec"}
                  selectedValue={EndSecond}
                  setSelectedValue={setEndSecond}
                />
              </div>
            </div>
            <div className="flex space-x-8">
              <label className=" font-normal text-lg font-ibm ">
                Countdown starts on
              </label>
              <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    countdownDate
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"
                  }`}>
                  {countdownDate && countdownDate}
                </p>
                <CalendarModalTrigger setDate={setCountdownDate} />
              </div>
            </div>
          </div>
          <div className=" space-x-6 absolute left-[40%] -bottom-16">
            <button
              className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              type="submit">
              SAVE
            </button>
            <button onClick={history.goBack} className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
              EXIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
AddNotice.propTypes = {
  closeModal: propTypes.func,
  setIsCalendarOpen: propTypes.func,
};

export default AddNotice;
