import React, { useState } from "react";
import Header from "../../header/Header";
import ToggleSchedule from "./ToggleSchedule";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import Dropdown from "../blogs/Dropdown";
import Toggle from "../../global/Toggle";
import CalendarModalTrigger from "../blogs/calendar/CalendarModalTrigger";
import propTypes from "prop-types";
import DropDown from "../../global/fields/CourseDropDown";
import VideoDropdown from "../../global/fields/DropDown";
import { apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { UploadFileToAWS } from "../../../AWS/uploadFile";
import { useSelector } from "react-redux";
import WhiteSpace from "../../global/regEx/WhiteSpaceValidator";

const options = [
  {
    productGroupId: "1",
    productGroupName: "Vimeo",
  },
  {
    productGroupId: "2",
    productGroupName: "YouTube",
  },
  {
    productGroupId: "3",
    productGroupName: "S3",
  },
];

const AddSlideShow = () => {
  let history = useHistory();
  const [activeTabPublish, setActiveTabPublish] = useState("now");
  const [activeTab, setActiveTab] = useState("active");
  const [SlideShowName, setSlideShowName] = useState("");
  const [hyperLink, setHyperLink] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [date, setDate] = useState("Select Date");
  const [enddate, setEndDate] = useState("Select End Date");
  const [type, setType] = useState("Image");
  const [courseTags, setCourseTags] = useState([]);
  const [StartHour, setStartHour] = useState(0);
  const [StartMinute, setStartMinute] = useState(0);
  const [StartSecond, setStartSecond] = useState(0);
  const [EndHour, setEndHour] = useState(0);
  const [EndMinute, setEndMinute] = useState(0);
  const [EndSecond, setEndSecond] = useState(0);
  // state to store selected course values and passed as prop
  const [showLabel, setShowLabel] = useState([]);
  const [value, setValue] = useState("");
  const [Videovalue, setVideoValue] = useState("");

  // const timeEnd=EndHour+EndMinute+EndSecond;
  const [browseLink, setBrowseLink] = useState("");

  // current date condition not required
  //to get the current date
  // let today = new Date();
  // let dd = String(today.getDate()).padStart(2, "0");
  // let mm = String(today.getMonth() + 1).padStart(2, "0");
  // let yyyy = today.getFullYear();
  // today = yyyy + "-" + mm + "-" + dd ;
  // // to get the current time
  // let todayDate = new Date();
  // const currentHour = todayDate.getHours()  ;
  // const currentMinute = todayDate.getMinutes();

  const onValueChange = (event) => {
    setType(event.target.value);
  };
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  // api to get courses data
  const { data: getCourseData } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method: "post",
      data: {
        adminimspin: login_Details.userImsPin,
      },
    })
  );
  // api to add slideshow

  const fetchAddSlideShow = () => {
    return apirequest({
      url: `${endpoint.add_slideshow.addr}`,
      method: "post",
      data: {
        slideShowName: SlideShowName,
        type: type,
        imageUrl: browseLink,
        hyperLink: hyperLink,
        videoType: type === "Video" ? Videovalue : "",
        videoLink: type === "Video" ? videoLink : "",
        isActive: activeTab == "active" ? "1" : "0",
        startDate: activeTabPublish == "now" ? "" : date,
        startTime:
          activeTabPublish == "now"
            ? ""
            : `${StartHour}:${StartMinute}:${StartSecond}`,
        endDate: enddate,
        endTime: `${EndHour}:${EndMinute}:${EndSecond}`,
        createdBy: login_Details.userImsPin,
        courseName: showLabel,
      },
    });
  };
  //API request to add new slideshow data
  const { refetch: addSlideShowRefetch } = useQuery(
    endpoint.add_slideshow.key,
    fetchAddSlideShow,
    {
      enabled: false,
      onSuccess: (res) => {
        alert(res.data.message), window.location.reload(false);
      },
      retry: 0,
      onError: (err) => {
        alert(err.data.message);
      },
    }
  );
  // let splitForlink = browseLink.split(".");
  // const validationText = (obj)=>{
  //   let result = Object.keys(obj).filter((item)=>{
  //     if(obj[item] === ""){
  //       return item ;
  //     }});
  //   if(result.length > 0){
  //     alert(`Please Enter valid ${result.toString()}`);
  //   }
  // };

  const onsave = (e) => {
    if (activeTabPublish === "schedule") {
      // if((Number(today.replace(/-/g, "")))>Number(date.replace(/-/g, ""))){
      //   alert("Publish Date can't be Past days");
      // }
      if (Number(date.replace(/-/g, "")) > Number(enddate.replace(/-/g, ""))) {
        alert("please select End Date after Start Date");
      } else if (date === "Select Date") {
        alert("Please select start date");
      }
      // else if(StartHour === "" || StartMinute==="" || StartSecond ===""){
      //   let obj = {StartHour , StartMinute , StartSecond};
      //   validationText(obj);
      // }
      // else if(EndHour  ==="" ||  EndMinute ==="" || EndSecond ===""){
      //   let obj = {EndHour , EndMinute , EndSecond};
      //   validationText(obj);
      // }
      else if (enddate === "Select End Date") {
        {
          alert("Please select end date ");
        }
      } else if (browseLink === "") {
        alert("Please select browse link to Upload Image");
      }
      // to show the alert message if start date is today and start time is before current system's timings
      // else if((Number(date.replace(/-/g, "")))===Number(today.replace(/-/g, ""))){
      //   if(Number(StartHour)<currentHour){
      //     alert("please select start time after current time");
      //   }
      //   else if(Number(StartHour)===currentHour){
      //     if(Number(StartMinute)<currentMinute)
      //       alert("please select start time after current time");
      //     else{
      //       addSlideShowRefetch();
      //     }
      //   }
      //   else{
      //     addSlideShowRefetch();
      //   }
      // }
      else if (WhiteSpace(SlideShowName) === true) {
        alert("Please enter a Valid Slideshow Name");
      }
      // to make course selection mandatory
      else if (showLabel.length === 0) {
        alert("Please Select Course");
      } else if (type === "Image") {
        // if(WhiteSpace(hyperLink)===true){
        //   alert("Please enter a Valid Hyperlink");
        // }
        // else
        addSlideShowRefetch();
      } else if (type === "Video") {
        if (WhiteSpace(videoLink) === true) {
          alert("Please enter a Valid videolink");
        } else addSlideShowRefetch();
      } else {
        addSlideShowRefetch();
      }
    }
    // else if(EndHour  ==="" ||  EndMinute ==="" || EndSecond ===""){
    //   let obj = {EndHour , EndMinute , EndSecond};
    //   validationText(obj);
    // }
    else if (browseLink === "") {
      alert("Please select browse link to Upload Image");
    } else if (enddate === "Select End Date") {
      alert("Please select end date ");
    } else if (WhiteSpace(SlideShowName) === true) {
      alert("Please enter a Valid Slideshow Name");
    }
    // to make course selection mandatory
    else if (showLabel.length === 0) {
      alert("Please Select Course");
    }
    // else if(browseLink !=""){
    //   if(splitForlink[splitForlink.length-1]=== "webp"){
    //     addSlideShowRefetch();
    //   }else{
    //     alert("Please select only webp format Images !!!");
    //   }
    // }
    else if (type === "Image") {
      // if (WhiteSpace(hyperLink) === true) {
      // alert("Please enter a Valid Hyperlink");
      // } else
      addSlideShowRefetch();
    } else if (type === "Video") {
      if (WhiteSpace(videoLink) === true) {
        alert("Please enter a Valid videolink");
      } else addSlideShowRefetch();
    } else {
      addSlideShowRefetch();
    }
    e.preventDefault();
  };
  const handleBrowse = (event) => {
    event.preventDefault();
    setBrowseLink(event.target?.files[0].name);
    // let spl =(event.target?.files[0].type.split("/"));
    const fileDate = new Date()
      .toLocaleString({ hour12: true })
      .toString()
      .split("/");
    // if(spl[spl.length-1] != "webp"){
    //   alert("Please select Only webp files");
    //   setBrowseLink("");
    // }
    if (event.target?.files[0]) {
      const val = UploadFileToAWS({
        key: "addSlideShowUpload",
        file: event.target?.files[0],
        path:
          "Asset/AddSlideShow/" +
          fileDate[2].toString().split(",")[0] +
          "/" +
          event.target?.files[0].name.toString().split(".")[0] +
          "_" +
          login_Details.userImsPin +
          "_" +
          fileDate[2].toString().split(/[, ]+/)[1] +
          "." +
          event.target?.files[0].name.toString().split(".")[1],
      });
      {
        val.then((item) => setBrowseLink(item));
      }
    } else {
      alert("No files available!");
    }
  };

  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />
      <div className="mt-2 mx-12  px-8 py-2 ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
          onSubmit={onsave}
        >
          <div className="h-full space-y-6  px-12 py-6 relative">
            <div className="flex  py-5 space-x-10">
              <label className=" font-normal text-lg font-ibm ">
                SlideShow Name
              </label>
              <input
                required
                type="text"
                name="blogName"
                className="border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg focus:outline-none"
                value={SlideShowName}
                onChange={(e) => setSlideShowName(e.target.value)}
              />
            </div>
            <div className="flex space-x-12">
              <label className="font-normal text-lg font-ibm">
                Slide Show Type
              </label>
              <div className="flex">
                <div className="radio">
                  <label className="font-normal text-lg font-ibm">
                    <input
                      type="radio"
                      value="Image"
                      checked={type === "Image"}
                      onChange={onValueChange}
                    />
                    Image
                  </label>
                </div>
                {/* Todo : Add Slideshow radio button */}
                {/* <div className="">
                  <label className="font-normal text-lg font-ibm">
                    <input
                      type="radio"
                      value="Video"
                      checked={type === "Video"}
                      onChange={onValueChange}
                    />
                    Video
                  </label>
                </div> */}
              </div>
            </div>
            {type === "Video" && (
              <div className="flex space-x-20 pt-6">
                <label className="font-normal text-lg font-ibm">
                  Video Type
                </label>
                <VideoDropdown
                  placeholder={"select video type"}
                  options={options}
                  setUpdatedOptions={setVideoValue}
                  type={"singleSelect"}
                  width={"w-[22rem]"}
                  setFieldValue={setVideoValue}
                />
              </div>
            )}

            <div className="flex space-x-10">
              {type === "Image" && (
                <>
                  <label className=" font-normal text-lg font-ibm py-5">
                    Upload Image
                  </label>
                  <input
                    required
                    type="text"
                    name="url"
                    className="focus:outline-none border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg top-48"
                    // Paste the image URL to Upload Image URL
                    placeholder=" Upload Image URL"
                    value={browseLink}
                    readOnly={true}
                  />
                  <label className="absolute right-36 top-44 bg-primary-200 px-10 ml-2 py-3 rounded-xl text-white text-sm font-semibold cursor-pointer font-ibm">
                    <input
                      className="hidden"
                      type="file"
                      multiple
                      onChange={handleBrowse}
                      accept=".webp"
                    />
                    Browse
                  </label>
                </>
              )}
            </div>
            <div className="flex  space-x-10 py-5">
              {type === "Image" && (
                <>
                  <label className=" font-normal text-lg font-ibm ">
                    Hyper Link <span className="text-gray-400">(optional)</span>
                  </label>
                  <input
                    type="text"
                    name="url"
                    className="focus:outline-none border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg"
                    value={hyperLink}
                    onChange={(e) => setHyperLink(e.target.value)}
                  />
                </>
              )}
              {type === "Video" && (
                <>
                  <label className=" font-normal text-lg font-ibm ">
                    Video Link
                  </label>
                  <input
                    type="text"
                    name="url"
                    className="focus:outline-none border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg"
                    value={videoLink}
                    onChange={(e) => setVideoLink(e.target.value)}
                  />
                </>
              )}
            </div>
            <div className="flex py-2 space-x-24">
              <label className=" font-normal text-lg font-ibm ">Courses</label>
              <DropDown
                placeholder={"Search Course(s)"}
                options={getCourseData?.data?.data?.courseList}
                setUpdatedOptions={setCourseTags}
                type={"multiselect"}
                width={"w-[22rem]"}
                courseTags={courseTags}
                showLabel={showLabel}
                setShowLabel={setShowLabel}
                value={value}
                setValue={setValue}
                forCourses={true}
              />
            </div>
            <div className="space-x-20 ml">
              <Toggle
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                margin={32}
                title="Status"
              />
            </div>

            <div className="flex">
              <ToggleSchedule
                activeTab={activeTabPublish}
                setActiveTab={setActiveTabPublish}
                title="Publish"
                margin={32}
              />
              {activeTabPublish !== "now" && (
                <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                  <p
                    className={`w-full outline-none boder border-gray-200 ${
                      date != "Select Date"
                        ? "text-lg font-medium"
                        : "text-sm  text-primary-700"
                    }`}
                  >
                    {date && date}
                  </p>
                  <CalendarModalTrigger setDate={setDate} />
                </div>
              )}
            </div>
            {activeTabPublish !== "now" && (
              <div className="flex">
                <label className=" font-normal text-lg font-ibm ">
                  Start time
                </label>
                <div className="ml-28">
                  <Dropdown
                    minOffset={0}
                    maxOffset={23}
                    text={"Hrs"}
                    selectedValue={StartHour}
                    setSelectedValue={setStartHour}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Mins"}
                    selectedValue={StartMinute}
                    setSelectedValue={setStartMinute}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Sec"}
                    selectedValue={StartSecond}
                    setSelectedValue={setStartSecond}
                  />
                </div>
              </div>
            )}
            <div className="flex space-x-28">
              <label className=" font-normal text-lg font-ibm ">End Date</label>
              <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    enddate != "Select End Date"
                      ? " font-medium text-lg  "
                      : "text-sm  text-primary-700"
                  }`}
                >
                  {enddate && enddate}
                </p>
                <CalendarModalTrigger setDate={setEndDate} />
              </div>
            </div>
            <div className="flex">
              <label className=" font-normal text-lg font-ibm ">End time</label>
              <div className="ml-28">
                <Dropdown
                  // minOffset={((Number(today.replace(/-/g, "")))===Number(enddate.replace(/-/g, "")))?currentHour:0}
                  minOffset={0}
                  maxOffset={23}
                  text={"Hrs"}
                  selectedValue={EndHour}
                  setSelectedValue={setEndHour}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  // minOffset={((Number(today.replace(/-/g, "")))===Number(enddate.replace(/-/g, "")))?currentMinute:0}
                  minOffset={0}
                  maxOffset={59}
                  text={"Mins"}
                  selectedValue={EndMinute}
                  setSelectedValue={setEndMinute}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={0}
                  maxOffset={59}
                  text={"Sec"}
                  selectedValue={EndSecond}
                  setSelectedValue={setEndSecond}
                />
              </div>
            </div>
          </div>
          <div className=" space-x-6 absolute left-[40%] -bottom-16">
            <button
              className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              type="submit"
            >
              SAVE
            </button>
            <button
              onClick={history.goBack}
              className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            >
              EXIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
AddSlideShow.propTypes = {
  closeModal: propTypes.func,
  setIsCalendarOpen: propTypes.func,
};

export default AddSlideShow;
