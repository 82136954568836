import React from "react";
import pdf from "../../assets/icons/pdf.svg";
import editButton from "../../assets/icons/submitted-no-bg.svg";
import deleteButton from "../../assets/icons/delete-inactive.svg";
import propTypes from "prop-types";
import CalendarIcon from "../../assets/icons/calendar-inactive.svg";
import { endpoint } from "../../API/config";
import { apirequest } from "../../API/api";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setIsEditing, setSchoolId } from "../../redux/features/schools/schoolsSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const SchoolModuleTables = ({
  name ,
  modifiedBy,
  modifiedTs,
  createdBy,
  createdTs,
  selectedItem ,
  //path  ====> send the path as a prop <======
}) => {
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  const dispatch = useDispatch();
  const history = useHistory();
  // Delete api will fail , because of insufficient details========>
  const {refetch} = useQuery(endpoint.addEditDeleteSchool.key , ()=>apirequest({
    method: "PUT",
    url : endpoint.addEditDeleteSchool.addr,
    data : {
      ...selectedItem,
      "isActive" : "0",
      "modifiedBy" : login_Details.userImsPin,
      "requestType" : "Delete",
      "programs" : "",
      "courses" : [],
      "isSponsored": "" ,
      "batchSize" : "",
      "fees" : "",
      "avgSalary" : "",
      "flagshipProgram" : "",
      "imsClassification" : "",
      "duration" : "",
      "tags" : [],
      "applicationLink" : "",
      "examAccepted" : "",
      "eligibilityCriteria" : "",
      "placementReportLink" : "",
      "admissionProcedureLink" : "",
      "csvPath" : ""
    }
  }),{
    enabled : false,
    onSuccess : (res)=>alert(res.data.message),
    onError : (res)=>res.data.message
  });

  const handleDelete = (itemToDelete)=>{
    console.log(itemToDelete , "selectted");
    refetch();
  };
  return (
    <div className="px-5 flex justify-between ">
      <section>
        <div className="flex space-x-3">
          <img src={pdf} />
          <div className="space-y-2">
            <p className="font-medium text-lg">{name}</p>
            <div className="flex space-x-5">
              <img src={CalendarIcon} />
              <p className="font-normal text-lg">
                {modifiedBy != ""
                  ? `Last Updated by ${modifiedBy} on ${modifiedTs}`
                  : `Created By ${createdBy} on ${createdTs}`}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="flex space-x-5">
        <img src={editButton} className="cursor-pointer" onClick={() => {
          dispatch(setSchoolId(selectedItem.schoolId));
          dispatch(setIsEditing(true));
          history.push("/school/edit school");
        }} />
        <img
          src={deleteButton}
          className="cursor-pointer"
          onClick={() => handleDelete(selectedItem)}
        />
      </section>
    </div>
  );
};

SchoolModuleTables.propTypes={ 
  name : propTypes.string,
  modifiedBy : propTypes.string,
  path : propTypes.string,
  modifiedTs : propTypes.string,
  createdBy : propTypes.string,
  createdTs : propTypes.string,
  selectedItem : propTypes.object
};


export default SchoolModuleTables;
