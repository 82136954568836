import React from "react";
import propTypes from "prop-types";
function BrowseLearningPath({  pathLink, setCurrentScreen, fetchCourses}) {

  function handleBrowse(){
    fetchCourses();
    setCurrentScreen(1);
  }
  return (
    <div className="flex flex-row  w-full  font-ibm ">
      <div className="mr-6">
        <label className="inline-block text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg">
          Path Link{" "}
        </label>
        <input
          className="ml-5 w-[29rem] border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2  focus:outline-none placeholder-bold font-semibold text-XL"
          type="text"
          placeholder="Paste the link to import learning path"
          autoFocus
          value={pathLink}
          readOnly
          required
        />
      </div>
      <div
        className="font-ibm bg-primary-200 text-white font-normal px-6 rounded-xl hover:opacity-75 shadow-outer text-lg py-[9px] cursor-pointer"
        onClick={()=>handleBrowse()}
      >
        Browse
      </div>
    </div>
  );
}

BrowseLearningPath.propTypes = {
  pathLink: propTypes.string,
  setCurrentScreen: propTypes.func,
  fetchCourses: propTypes.func,
};
export default BrowseLearningPath;
