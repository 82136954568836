import React from "react";
import propTypes from "prop-types";


function CutOffTable({csvData}) {
  const width = (100 / Object.keys(csvData[0]).length) + "%";
  return (
    <div className="w-full">
      {/*************** Table headers ***************/}
      <div className="px-6 py-6 flex rounded-t-xl shadow-outer w-full ">
        {Object.keys(csvData[0]).map(heading => (
          <p key={heading} style={{ width }} className="justify-self-start">
            {heading}
          </p>
        ))}
      </div>
      <div className="pt-12 shadow-outer rounded-b-xl">
        {csvData.map(cutOff => (
          <div key={cutOff.categoryId} className="flex px-6">
            {Object.keys(csvData[0]).map(column => (
              <p
                key={column}
                style={{ width }}
                className="justify-self-start mb-12">
                {cutOff[column]}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

CutOffTable.propTypes = {
  csvData: propTypes.array,
};

export default CutOffTable;
