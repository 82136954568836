import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import Sequence from "./Sequencing";
import BreadCrumb from "../../../global/breadcrumbs/BreadCrumb";
import DropDown from "../../../global/fields/CourseDropDown";
import {apirequest} from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const BlogSequence = () => {

  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  let history = useHistory();
  const [value, setValue] = useState("");
  const [valueBlogWeek, setValueBlogWeek] = useState("");
  const [blogdata, setBlogdata] = useState([]);
  const [postdata, setPostdata] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const value_posts = [];
  // const courseid = useSelector(state => state.CourseId.courseId);
  // const BlogId = useSelector(state => state.BlogIdSequence.blogIdsequence);
  const postid = useSelector(state => state.PostIdSequence.postweeksequence);
  // to pass the value of selected course to the dropdown
  const [valueId,setValueId]=useState("");
  const [BlogId,setBlogId]=useState("");
  // api to get courses
  const { data: getCourseData,isSuccess } = useQuery(endpoint.course_data.key, ()=>apirequest({
    url : `${endpoint.course_data.addr}`,
    method : "post",
    data :{
      "adminimspin":login_Details.userImsPin
    }
  }),{
    refetchOnMount : true
  });

  // end
  //API request to course_blog_post
  const fetchAddBlog = () => {
    return apirequest({
      url: `${endpoint.course_blog_post.addr}`,
      method: "post",
      data: {
        courseId: valueId,
      },
    });
  };

  const { data: addBlogData, refetch: addBlogRefetch } = useQuery(
    endpoint.course_blog_post.key,
    fetchAddBlog,
    {
      enabled: false,
      onSuccess: res => {
        setBlogdata(res.data.data[0].blogs);
        setPostdata(res.data.data[0].posts);
      },
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );
  useEffect(() => {
    valueId === "" ? "" : addBlogRefetch();
  }, [valueId]);
  // end

  // changed value of sequence to be sent in api for blogs
  const value_blogs = [];
  addBlogData &&
    blogdata?.map((item, index) => {
      value_blogs.push({ blogId: item.blogId, sequenceOrder: index });
    });

  // api for put blog sequence
  const PostBlogSequence = () => {
    return apirequest({
      url: `${endpoint.blog_sequencing.addr}`,
      method: "put",
      data: {
        courseId: valueId,
        data: value_blogs,
      },
    });
  };

  const { refetch: blogsequence } = useQuery(
    endpoint.blog_sequencing.key,
    PostBlogSequence,
    {
      enabled: false,
      onSuccess :(res)=>alert(res.data.message),
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );
  // end
  // api for put blogweek
  const BlogWeek = () => {
    return apirequest({
      url: `${endpoint.put_blog.addr}`,
      method: "put",
      data: {
        courseId: valueId,
        postId: postid,
      },
    });
  };

  const { refetch: blogweek } = useQuery(endpoint.put_blog.key, BlogWeek, {
    enabled: false,
  });
  // end

  // api to get blogpost
  const fetchBlogPost = () => {
    return apirequest({
      url: `${endpoint.get_blog_post.addr}`,
      method: "post",
      data: {
        blogId: BlogId,
      },
    });
  };

  //API request to course_blog_post
  const { data: addPostData, refetch: BlogPostRefetch } = useQuery(
    endpoint.get_blog_post.key,
    fetchBlogPost,
    {
      enabled: false,
      // need to set the state of selected course here on success of api ==========
      onSuccess: res => {
        setSelectedCourse(res.data.data);
      },
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );
  // changed value of sequence to be sent in api for posts

  addPostData &&
    selectedCourse?.map((item, index) => {
      value_posts.push({ postId: item.postId, sequenceOrder: index });
    });
  useEffect(() => {
    BlogId === "" ? "" : BlogPostRefetch();
    setSelectedCourse([]);
  }, [BlogId]);
  // api for put post sequence
  const PostSequence = () => {
    return apirequest({
      url: `${endpoint.post_sequencing.addr}`,
      method: "put",
      data: {
        blogId: BlogId,
        courseId: valueId,
        data: value_posts,
      },
    });
  };

  const { refetch: postsequence } = useQuery(
    endpoint.post_sequencing.key,
    PostSequence,
    {
      enabled: false,
    },
  );
  // end
  useEffect(() => {
    setBlogdata([]);
    setValueBlogWeek("");
    setPostdata([]);
  }, [valueId]);

  const onsave = e => {
    e.preventDefault();
    blogsequence();
    blogweek();
    postsequence();
  };
  // console.log({ productGroup });

  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />
      <div className="mt-2 mx-12  px-8 py-2  ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
          onSubmit={onsave}>
          <div className="h-full space-y-6  px-12 py-6 relative">
            <div className="flex mb-4">
              <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg">
                Courses
              </label>
              {isSuccess &&
              <DropDown
                placeholder={"Search or Create new Group"}
                options={getCourseData?.data?.data?.courseList}
                type={"singleSelect"}
                width={"w-[22rem]"}
                // setFieldValue={setProductGroup}
                readonly={false}
                value={value}
                setValue={setValue}
                blog_post={addBlogRefetch}
                fetchGetBlogPost={fetchAddBlog}
                // pass the setId function to the dropdown
                setValueId={setValueId}
                isSequencing={true}
              />}
            </div>
            <div className="flex mb-4">
              <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg">
                Blog of the week
              </label>
              {/* {addBlogData && addBlogData.data.data[0].posts && ( */}
              <DropDown
                placeholder={"Select Blog of the Week"}
                options={postdata}
                type={"singleSelect"}
                width={"w-[22rem]"}
                // setFieldValue={setProductGroup}
                readonly={false}
                value={valueBlogWeek}
                setValue={setValueBlogWeek}
              />
              {/* )} */}
            </div>
            <div className="">
              {addBlogData && addBlogData.data.data[0].blogs && (
                <Sequence
                  users={blogdata}
                  setUsers={setBlogdata}
                  selectedCourse={selectedCourse}
                  setSelectedCourse={setSelectedCourse}
                  value_posts={value_posts}
                  // BlogId={BlogId}
                  blogid={BlogId}
                  setBlogId={setBlogId}
                />
              )}
            </div>
          </div>
          <div className=" space-x-6 absolute left-[40%] -bottom-16">
            <button
              className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              type="submit">
              SAVE
            </button>
            <button onClick={history.goBack} className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
              EXIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BlogSequence;
