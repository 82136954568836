import React, { useReducer, createContext, useContext } from "react";
import PropTypes from "prop-types";
import ArrowUp from "../../../assets/icons/arrow-up.svg";
import ArrowDown from "../../../assets/icons/arrow-down.svg";
import OutsideClickHandler from "react-outside-click-handler";

const CustomContext = createContext();

function useCustomContext() {
  return useContext(CustomContext);
}

const initialState = {
  isOpen: false,
  value: "Select",
};

function reducer(state, action) {
  switch (action.type) {
  case "toggle":
    return { ...state, isOpen: !state.isOpen };
  case "close":
    return { ...state, isOpen: false };
  case "setValue":
    return { isOpen: false, value: action.value };
  default:
    break;
  }
}

const Options = ({ children }) => {
  const { state } = useCustomContext();
  if (!state.isOpen) {
    return null;
  }

  return (
    <div className=" h-auto pt-2 w-full border-0 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 shadow-lg absolute z-[1000] top-14 bg-white overflow-y-scroll scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded  max-h-[10rem]">
      {children}
    </div>
  );
};

const Option = ({ option, setValue }) => {
  const { dispatch } = useCustomContext();

  function handleClick() {
    setValue(option);
    dispatch({
      type: "setValue",
      value: option,
    });
  }

  return (
    <div
      onClick={handleClick}
      className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize flex">
      {option}
    </div>
  );
};

const SimpleDropDown = ({ children, value, initialValue, error }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const provideState = {
    state,
    dispatch,
  };

  const handleToggleDropDown = () => {
    dispatch({ type: "toggle" });
  };

  const closeDropDown = () => {
    dispatch({ type: "close" });
  };

  React.useEffect(() => {
    if (value || initialValue)
      dispatch({
        type: "setValue",
        value: value || initialValue,
      });
  }, []);

  return (
    <CustomContext.Provider value={provideState}>
      <OutsideClickHandler onOutsideClick={closeDropDown}>
        <div className="relative">
          <div
            onClick={handleToggleDropDown}
            className={`bg-white rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-full cursor-pointer border-2 ${
              error && "border-red-300"
            } flex justify-between ${
              state.value === initialValue ? "text-[#9ca3af]" : !error ? "border-secondary-300 font-semibold text-XL" : ""
            } `}>
            {state.value}
            <img src={state.isOpen ? ArrowUp : ArrowDown} alt="arrow" />
          </div>
          {error && (
            <p className=" text-xs text-red-400 mt-1 ml-2 ">* Required</p>
          )}
          {children}
        </div>
      </OutsideClickHandler>
    </CustomContext.Provider>
  );
};

SimpleDropDown.Options = Options;

SimpleDropDown.Option = Option;

SimpleDropDown.propTypes = {
  children: PropTypes.object,
  value: PropTypes.string,
  initialValue: PropTypes.string,
  error: PropTypes.bool,
};

Options.propTypes = {
  children: PropTypes.array,
};

Option.propTypes = {
  option: PropTypes.string,
  children: PropTypes.object,
  setValue: PropTypes.func,
};

export default SimpleDropDown;
