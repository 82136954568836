
import React from "react";
import BreadCrumb from "../global/breadcrumbs/BreadCrumb";
import Header from "../header/Header";
import CourseDetailForm from "./editCourse/CourseDetailForm";
import { useDispatch, useSelector } from "react-redux";
import MapVarientTable from "./mappedVarient/MapVarientTable";
import Tab from "../tab/Tab";
import { selectEditCourseTab, setEditCourseTab } from "../../redux/features/courseware/courseSlice";

const EditCourse = () => {

  const activeTab = useSelector(selectEditCourseTab);
  const dispatch = useDispatch();

  function handleTabChange(tab){
    dispatch(setEditCourseTab(tab));
  }

  const tabContent = [
    {
      title: ("Course Details"),
      content: (<CourseDetailForm/>)
    },
    {
      title: ("Mapped Variants"),
      content: (<MapVarientTable/>)
    }
  ];
  return (
    <div>
      <Header />
      <div className="mt-6 ml-16">
        <BreadCrumb type={"editCourse"}/>
      </div>
      <div className="text-left mt-5">
        <Tab handleTabChange={handleTabChange} activeTab={activeTab} >
          {tabContent.map((item,i) => (
            <Tab.TabPane key={`tab-${i}`} tab={item.title}>
              {item.content}
            </Tab.TabPane>
          ))}
        </Tab>
      </div>     
    </div>
  );
};
export default EditCourse;