import React ,{useState} from "react";
import Header from "../../header/Header";
import Tab from "../../tab/Tab";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import TablePagination from "../../table/TablePagination";

import TableSearch from "../../table/TableSearch";
import SchoolModuleTables from "../SchoolModuleTables";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config";
import { apirequest } from "../../../API/api";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../global/loader/Loader";
import { setIsEditing } from "../../../redux/features/schools/schoolsSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom";



const SchoolDetails = () => {

  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  

  const [schoolData , setSchoolData] = useState({});
  const {data : schoolDefaultData ,  isSuccess , isError , isLoading} = useQuery(endpoint.getSchoolsListing.key,()=>apirequest({
    method : "post",
    url : endpoint.getSchoolsListing.addr,
    data : {
      adminimspin : login_Details.userImsPin
    }
  }),{
    refetchOnMount :  true ,
    onSuccess : (res)=>setSchoolData(res)
  });

  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 3;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const dispatch = useDispatch();
  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };
  const history = useHistory();
  
  function handleAddSchool(){
    dispatch(setIsEditing(false));
    history.push("/School/Add School");
  }

  return (
    <div className="bg-white h-[744px] text-ibm">
      <div className="">
        <Header />
        {/* <SubHeader /> */}
        <Tab />
        <div className="flex justify-between text-ibm">
          <BreadCrumb />
          {/* <CourseCard /> */}
          <div className="flex justify-center space-x-5">
            {/* <button
              className="px-8 py-3 bg-primary-300 text-white h-[50px] rounded-2xl shadow-outer "
              // onClick={handleClick}
            >
              Import Schools
            </button> */}
            <p
              className="px-8 py-3 bg-primary-300 text-white  h-[50px] rounded-2xl shadow-outer cursor-pointer"
              onClick={handleAddSchool}
              to="/School/Add School"
            >
              Add School
            </p>
          </div>
          <div className=" col-span-4">
            <TableSearch
              defaultData={schoolDefaultData}
              setDefaultData={setSchoolData}
              setCurrentPage={setCurrentPage}
              dataKey= "schoolName"
              dataForFilter={schoolDefaultData?.data.data}
              name = "School"
              subModuleName="School Details"
              childModuleName=""
              placeholder="Search By School Name"
            />
            
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
      {isSuccess &&<div className="p-2 m-10  shadow-outer rounded-2xl space-y-10">
        {schoolData?.data?.data.filter(item=>item.isActive != "0").length>0 ? schoolData?.data?.data.filter(item=>item.isActive != "0").slice(indexOfFirstItem, indexOfLastItem).map(item=>(
          <SchoolModuleTables  
            key={item.schoolId}
            name ={item.schoolName}
            modifiedBy={item.modifiedBy}
            path= {"/editSchoolDetails"}
            modifiedTs = {item.modifiedTs}
            createdBy = {item.createdBy}
            createdTs = {item.createdTs}
            selectedItem = {item}
            schoolsData = {schoolData}
          />
        )) : <p className="text-base text-red-600 flex justify-center">No Record Found</p>}
      </div>}
      {isError && <span className="flex justify-center text-red-600">No Record Found</span>}
      <TablePagination 
        data ={schoolData?.data?.data}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        paginate={paginate}
        dataPerPage={dataPerPage}
      />
    </div>
  );
};

export default SchoolDetails;
