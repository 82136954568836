import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import propTypes from "prop-types";
import ArrowDown from "../../../assets/icons/arrow-down.svg";
import ArrowUp from "../../../assets/icons/arrow-up.svg";

const DropDownVariant = ({
  options,
  placeholder,
  width,
  setGetdropdownvalue,
  valueReadProduct,
  setVariant_ID,
}) => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false); /* State to  show and hide dropdown box*/
  const [searchResult, setSearchResult] = useState(options); /* To store Array of search result and array init*/

  useEffect(()=>{
    setSearchResult(options);
  },[JSON.stringify(options)]);

  const outSideClickHandler = () => {
    setOpen(false);
  };

  const handleInput = (e) => {
    const searchedWrd = e.target.value;
    setValue(e.target.value);

    const newFilter = options?.filter((value) => {
      if (value.variantName.toLowerCase().includes(searchedWrd.toLowerCase())) {
        return value;
      } else {
        if(value.limaCode)
          return value.limaCode.includes(searchedWrd.toLowerCase());
      }
    });
    setOpen(true);
    setSearchResult(newFilter);
    if (newFilter?.length == 0) {
      setSearchResult(null);
    }
  };

  return (
    <div className="w-[22rem] flex justify-between">
      <OutsideClickHandler onOutsideClick={outSideClickHandler}>
        <div className={`${width} relative top-0`}>
          <input
            className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-full cursor-pointer focus:placeholder-transparent font-semibold placeholder-bold"
            type="text"
            placeholder={placeholder}
            value={valueReadProduct ? valueReadProduct : value}
            onChange={handleInput}
            onFocus={() => setOpen(true)}
          />
          <span className="absolute top-3 opacity-70 -right-2 cursor-pointer" onClick={()=>setOpen(!open)}>
            <img src={open === true ? ArrowUp : ArrowDown} />
          </span>
          {open === true && (
            <div className="ml-5">
              <ul className="h-auto pt-2 w-full border-0 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 shadow-lg absolute z-50 top-14 bg-white overflow-y-scroll scrollbar scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded  max-h-[10rem] mb-5">
                {searchResult?.length > 0 ? (
                  searchResult?.map((item, index) => (
                    <li
                      key={index}
                      className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize"
                      onClick={() => {
                        setVariant_ID(item.variantId);
                        setValue(item.limaCode + " · " + item.variantName),
                        setGetdropdownvalue(
                          item.limaCode + " · " + item.variantName
                        );
                      }}
                    >
                      {item.limaCode + " · " + item.variantName}
                    </li>
                  ))
                ) : (
                  <span className="font-ibm text-sm text-black font-medium opacity-60 py-1 px-4 capitalize">
                    Variant Not Present
                  </span>
                )}
              </ul>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};
DropDownVariant.propTypes = {
  placeholder: propTypes.string,
  options: propTypes.array,
  setGetdropdownvalue: propTypes.func,
  setVariant_ID: propTypes.func,
  width: propTypes.string,
  valueReadProduct: propTypes.string,
  forVariant: propTypes.bool,
};
export default DropDownVariant;
