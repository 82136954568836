import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentHeaderTab: "",
  subHeaderOptions:"",
  currentSubHeaderTab:"",
};

export const headerSlice = createSlice({
  name: "HeaderData",
  initialState,
  reducers: {
    getHeaderDetail: (state, action) => {
      state.currentHeaderTab = action.payload;
    },
    getSubheaderOption: (state, action) => {
      state.subHeaderOptions=action.payload;
    },
    getCurrentSubHeaderTab: (state, action) =>{
      state.currentSubHeaderTab = action.payload;
    },
  },
});

export const { getHeaderDetail,getSubheaderOption,getCurrentSubHeaderTab } = headerSlice.actions;
export default headerSlice.reducer;
