import React from "react";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import Header from "../../header/Header";
import FilterFunctionlity from "../../filterFunctionality/FilterFunctionlity";
import TableSearch from "../../table/TableSearch";

import GreyPill from "../../global/Tags/GreyPill";
import TablePagination from "../../table/TablePagination";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config";
import { apirequest } from "../../../API/api";

import { useDispatch, useSelector } from "react-redux";

import editIcon from "../../../assets/icons/submitted-no-bg.svg";
import deleteIcon from "../../../assets/icons/delete-inactive.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setarticlesData } from "../../../redux/features/article/articleSlice";
import { articlesApiCalls } from "../apiCalls/reusableApiCalls";
import { useEffect } from "react";
import Loader from "../../global/loader/Loader";

const Articles = () => {
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  const [currentPage, setCurrentPage] = useState(1);

  const [articlesData, setArticlesdata] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    isSuccess,
    isError,
    data: articlesDefaultData,
    isLoading,
  } = useQuery(
    endpoint.getArticlesForSchools.key,
    () =>
      apirequest({
        method: "post",
        url: endpoint.getArticlesForSchools.addr,
        data: {
          adminimspin: login_Details.userImsPin,
        },
      }),
    {
      onSuccess: (res) => setArticlesdata(res),
      refetchOnMount: true,
    }
  );

  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;

  const [payloadData, setPayloadObj] = useState({});
  const { refetch } = articlesApiCalls({ payloadData });

  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };

  const handleClick = (item) => {
    dispatch(setarticlesData(item));
    history.push("/School/Edit Article");
  };

  const handleDelete = (item) => {

    const { tags, schools, courses, ...rest } = item;
    setPayloadObj({
      ...rest,
      requestType: "Delete",
      isActive: "0",
      createdBy: login_Details.userImsPin,
      articleId: item.articleId,
      tagId: tags,
      schoolId: schools,
      courseId: courses,
    });
  };

  useEffect(() => {
    Object.keys(payloadData).length ? refetch() : null;
  }, [payloadData]);
  return (
    <div className="">
      <Header />
      {/*even If the get api fails ,user can able to add the data using add button*/}
      <header className="flex justify-between">
        <BreadCrumb />
        <Link
          to="/School/Add Article"
          className="px-8 py-2 text-white bg-primary-300 rounded-2xl cursor-pointer shadow-outer"
        >
          Add
        </Link>
      </header>
      {isLoading && <Loader />}
      {isError && (
        <span className="flex justify-center text-red-700">
          No Record found
        </span>
      )}

      {isSuccess && (
        <div>
          <div className="p-5 space-y-5 font-ibm">
            
            <section className="flex justify-between">
              <FilterFunctionlity />
              <TableSearch
                defaultData={articlesDefaultData}
                setDefaultData={setArticlesdata}
                dataKey="articleName"
                dataForFilter={articlesData?.data?.data}
                name="School"
                subModuleName="Articles"
                setCurrentPage={setCurrentPage}
                childModuleName=""
                placeholder="Search by Articles"
              />
            </section>

            <section className="px-7">
              {articlesData?.data?.data
                .filter((item) => item.isActive != 0).length> 0 ? 
                articlesData?.data?.data.filter((item) => item.isActive != 0)
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((item) => (
                    <section
                      key={item.id}
                      className="flex items-center justify-between space-x-3 mb-3"
                    >
                      <div className="flex justify-start w-[80%] space-x-3">
                        <img src={item.thumbnailLink} className="w-28 h-24" />
                        <div className="space-y-4">
                          <p className="font-normal text-lg">
                            {item.articleName}
                          </p>
                          <div className="flex space-x-3">
                            <GreyPill tagName={item.activeDate} />
                            <GreyPill tagName={item.tags?.join(", ")} />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end space-x-4 items-center">
                        <img
                          className=" cursor-pointer"
                          src={editIcon}
                          onClick={() => handleClick(item)}
                        />
                        <img
                          className="cursor-pointer "
                          src={deleteIcon}
                          onClick={() => handleDelete(item)}
                        />
                      </div>
                    </section>
                  )) 
                : <p className="text-base text-red-600 flex justify-center">No Record Found</p>}
            </section>
          </div>
          <footer>
            <TablePagination
              data={articlesData?.data?.data}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              paginate={paginate}
              dataPerPage={dataPerPage}
            />
          </footer>
        </div>
      )}
    </div>
  );
};

export default Articles;
