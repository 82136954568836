import React from "react";
import Header from "../header/Header";
import Dropdown from "../global/SimpleDropDown/DropDown";

import MoveIcon from "../../../src/assets/icons/move.svg";
import GreyPill from "../global/Tags/GreyPill";

const data = [
  {
    id: 1,
    inst: "IIM Ahmedabad",
    instFullname: "Indian Institute of Management",
    classification: "CDC",
    studentNum: "250",
    feeAmnt: "25.30",
    fee: "Lakhs per Annum",
    avgAmnt: "250 L",
    avgPackage: "Avg. Package",
  },

  {
    id: 2,
    inst: "KIIT School of Management",
    instFullname: "Kalinga Institute of Technology",
    classification: "CDC",
    studentNum: "250",
    feeAmnt: "25.30",
    fee: "Lakhs per Annum",
    avgAmnt: "250 L",
    avgPackage: "Avg. Package",
  },
  {
    id: 3,
    inst: "IIM Ahmedabad",
    instFullname: "Indian Institute of Management",
    classification: "CDC",
    studentNum: "250",
    feeAmnt: "25.30",
    fee: "Lakhs per Annum",
    avgAmnt: "250 L",
    avgPackage: "Avg. Package",
  },
  {
    id: 4,
    inst: "NMIMS Mumbai",
    instFullname: "Narsee Monjee Institute of Management Studies",
    classification: "A++",
    studentNum: "250",
    feeAmnt: "25.30",
    fee: "Lakhs per Annum",
    avgAmnt: "250 L",
    avgPackage: "Avg. Package",
  },
  {
    id: 5,
    inst: "SIBM Pune",
    instFullname: " Symbiosis Institute of Business Management",
    classification: "A+",
    studentNum: "250",
    feeAmnt: "25.30",
    fee: "Lakhs per Annum",
    avgAmnt: "250 L",
    avgPackage: "Avg. Package",
  },
];

const listing = () => {
  return (
    <div className="">
      <Header />
      {/*  */}
      <div className="  p-10 mx-8 mt-10 -mb-14">
        <div className="grid grid-cols-3 gap-4">
          <div className="text-xl font-medium">
            Select Colleges to be listed
          </div>
          <div className="col-span-2">
            <Dropdown
              className="w-[725px] flex border-2 border-gray-200 rounded-xl font-medium text-lg px-4 py-2"
              value="All Selected (109)"
            />
          </div>
          <div className="text-lg font-medium">
            Select parameters to be listed
          </div>
          <div className="col-span-2">
            <Dropdown
              className=" w-[725px] flex border-2 border-gray-200 rounded-xl font-medium text-lg px-4 py-2"
              value="IMS Classification, Batch Size, Fee, Avg. Salary"
            />
          </div>
        </div>

        {/*header  */}
        <div className="flex flex-row gap-4 mt-8 justify-between items-center">
          <div className=" text-xl font-medium">
            Listing Preview(Drag to re-order)
          </div>
          <div className="text-xl font-medium ml-96 -mr-48 ">Order By:</div>
          <div className=" w-48 ">
            <Dropdown value="Batch Size" />
          </div>
        </div>

        {/* table-header */}

        <div className="grid grid-cols-7 mt-8 gap-2 text-lg font-medium  bg-white rounded-t-2xl shadow-outer p-5 ">
          <div className="col-span-2">School Name</div>
          <div>
            IMS Classification
            <div></div>
          </div>
          <div>Batch Size</div>
          <div>Fee </div>
          <div>Avg. Salary</div>
          <div></div>
        </div>

        {/* data */}

        {data.map((items) => (
          <div className="grid grid-cols-7 p-5 justify-center" key={items.id}>
            <div className=" col-span-2 w-[303px] h-[48px]">
              <div className="font-medium">
                {items.inst} -{" "}
                <span className="font-normal">{items.instFullname}</span>
              </div>
              <div className="w-[92px] h-[30px] flex justify-center items-center mt-1">
                <GreyPill tagName="Sponsored" />
              </div>
            </div>
            <div className="font-medium">{items.classification}</div>
            <div className="font-medium">
              {items.studentNum}
              <div className="text-sm">Students</div>
            </div>
            <div className="font-medium">
              {items.feeAmnt}
              <div className="text-sm">{items.fee}</div>
            </div>
            <div className="font-medium">
              {items.avgAmnt}
              <div className="text-sm">{items.avgPackage}</div>
            </div>
            <div className="flex justify-end">
              <img className="w-[24px] h-[24px]" src={MoveIcon} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default listing;


