import React from "react";
import AddTierModal from "./AddTierModal";
import propTypes from "prop-types";

const TriggerAddTierFolder = () => {
  return (
    <div className="py-1 font-ibm"><span className="whitespace-nowrap text-black hover:text-black">Add Tier</span></div>
  );
};

function AddTierFuncButton({assetPath,parentId,parentData,handleEditClick}) {
  return <AddTierModal assetPath={assetPath} Trigger={TriggerAddTierFolder} parentId={parentId} parentData={parentData} handleEditClick={handleEditClick}/>;
}
AddTierFuncButton.propTypes = {
  assetPath: propTypes.string,
  parentId: propTypes.string,
  parentData: propTypes.object,
  handleEditClick: propTypes.func,
};
export default AddTierFuncButton;