import React, { useState } from "react";
import propTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getLoginDetails } from "../../redux/features/login/loginSlice";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

const ProfileDropDown = ({ item, menuIcon = "", menuText = "",position }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedsubheader=useSelector(state=>state.HeaderData.currentSubHeaderTab);  //To get the current subheader <========== 

  let isIcon = true;
  const [open, setOpen] = useState(false);

  let onHoverProfile = () => {
    setOpen(!open);
  };
  const handleLink = link => {
    return `/courseware/master/${link?.replace(/ /g, "")}`;
  };

  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);

  let accessinfo = login_Details?.productModuleAccessData?.filter((data)=>{
    if(data.module_name === "Access Management"){
      return data;
    }
  });

  item = accessinfo[0].viewAccess === "0" ? item.filter((val)=> val.title !== "Access Management") : item;

  const handleClick = (id,path,module_name) => {
    console.log(id,"ID");
    if (id === 4) {
      localStorage.clear();
      Cookies.remove("loginInfo");
      dispatch(getLoginDetails([]));
      window.location.replace(path?path:handleLink(module_name));
    }
    else  history.push(path?path:handleLink(module_name));
  };
  return (
    <div className={`relative inline-block text-left ${position ? "" :"z-50"}`}>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <div>
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md bg-white text-sm font-medium text-gray-700  focus:outline-none"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true">
            {menuIcon && (
              <img
                className="min-h-10 min-w-10 rounded-md ml-2 cursor-pointer "
                src={menuIcon}
                alt="User Image"
                onClick={() => onHoverProfile()}
              />
            )}
            {menuText && (
              <p
                className={`font-ibm  text-lg focus:text-primary-100 hover:text-primary-100 ${selectedsubheader?.trim() ==="Master" ? "font-bold" : "font-normal"}`}
                onClick={() => onHoverProfile()}>
                {menuText}
              </p>
            )}
          </button>
        </div>

        {open && (
          <div
            style={{ boxShadow: " 0px 4px 10px rgba(33, 37, 41, 0.1)" }}
            className={`${position?"z-20":""} flex origin-top-right absolute right-0 mt-2 w-56 pl-3 py-3 rounded-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1">
            <div className="" role="none">
              {item.map(item => (
                <div key={item.id || item.product_module_id} className="flex flex-row ml-2">
                  {item.src && (
                    <div className="mt-3 items-center">
                      <img className="w-5 h-5" src={item.src} alt="logout" />
                    </div>
                  )}
                  <div
                    className={`ml-5 mt-2 mb-2 mr-5 text-ibm ${
                      isIcon ? "hover:text-secondary-400" : "hover:font-medium"
                    } items-center `}>
                    {/*<Link to={item.path?item.path:handleLink(item.module_name)}>
                      
                      {item.title || item.module_name}
                  </Link>*/}
                    <p onClick={()=>handleClick(item.id,item.path,item.module_name)}>{item.title || item.module_name}</p>

                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

ProfileDropDown.propTypes = {
  item: propTypes.array,
  menuIcon: propTypes.string,
  menuText: propTypes.string,
  position:propTypes.bool,
};

export default ProfileDropDown;
