import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist/";
import blogsReducer from "../features/blogs/blogsSlice";
import postsReducer from "../features/blogs/postsSlice";
import courseReducer from "../features/courseware/courseSlice";
import slideshowReducer from "../features/slideshow/slideshowSlice";
import noticeReducer from "../features/notice/noticeSlice";
import variantReducer from "../features/variant/variantSlice";
import sequencingReducer from "../features/blogs/sequencingSlice";
import postsequencingReducer from "../features/blogs/postsequencingSlice";
import BlogweeksequencingReducer from "../features/blogs/postweekSlice";
import EventsReducer from "../features/events/eventSlice";
import LoginReducer from "../features/login/loginSlice";
import UploadAWSReducer from "../features/uploadAWS/uploadAWSlice";
import AccessManagementReducer from "../features/access_Management/access_ManagementSlice";

import learningPathReducer from "../features/learningPath/learningPathSlice";
import variantsByCourseReducer from "../features/variantsByCourse/variantsByCourseSlice";
import contentRepositoryReducer from "../features/contentRepository/contentRepoSlice";
import importPathReducer from "../features/learningPath/importLearningPathSlice";
import HeaderReducer from "../features/header/headerSlice";
import VenueReducer from "../features/venue/venueSlice";
import EditAssetReducer from "../features/editAssetSlice/editAssetSlice";
import mentorReducer from "../features/mentor/mentorSlice";
import advertisementSlice from "../features/advertisement/advertisementSlice";
import articleSlice from "../features/article/articleSlice";
import schoolsSlice from "../features/schools/schoolsSlice";

import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import eventSchoolSlice from "../features/eventsForSchool/eventSchoolSlice";
const reducers = combineReducers({
  blogsData: blogsReducer,
  postsData: postsReducer,
  slideshowData: slideshowReducer,
  noticeData: noticeReducer,
  courseData: courseReducer,
  variantData: variantReducer,
  CourseId: sequencingReducer,
  BlogIdSequence: postsequencingReducer,
  PostIdSequence: BlogweeksequencingReducer,
  eventsData: EventsReducer,
  loginData: LoginReducer,
  uploadData: UploadAWSReducer,
  accessManagementUserData: AccessManagementReducer,
  learningPathData: learningPathReducer,
  variantsByCourseData: variantsByCourseReducer,
  contentRepositoryData: contentRepositoryReducer,
  importLearningPathData: importPathReducer,
  HeaderData: HeaderReducer,
  VenueData: VenueReducer,
  AssetData: EditAssetReducer,
  mentorData: mentorReducer,
  advertisementSlotDetails: advertisementSlice,
  articleDetails: articleSlice,
  eventSchoolDetails: eventSchoolSlice,
  schools: schoolsSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "courseData",
    "blogsData",
    "postsData",
    "slideshowData",
    "noticeData",
    "variantData",
    "uploadData",
    "accessManagementUserData",
    "loginData",
    "variantsByCourseData",
    "contentRepositoryData",
    "importLearningPathData",
    "HeaderData",
    "VenueData",
    "AssetData",
    "mentorData",
    "advertisementSlotDetails",
    "articleDetails",
    "eventSchoolDetails",
    "schools",
  ],
  blacklist: ["CourseId"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
