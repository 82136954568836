import React from "react";
import GreyPill from "./GreyPill";
import propTypes from "prop-types";

function GreyPillsMap({ data }) {
  return (
    <div className="flex" style={{flexBasis : "50%"}} >
      {data.map((tagName,ind) => (
        <GreyPill tagName={tagName} key={ind} />
      ))}
    </div>
  );
}

GreyPillsMap.propTypes = {
  data: propTypes.array,
};

export default GreyPillsMap;
