import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";
import ArrowDown from "../../../assets/icons/arrow-down.svg";
import ArrowUp from "../../../assets/icons/arrow-up.svg";
import { apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

function DropDown({
  placeholder,
  options, //removed default empty array since the data isn't showing properly
  setUpdatedOptions,
  type,
  width,
  setFieldValue,
  readonly,
  valueReadProduct,
  valueReadCourseTag,
  isIconNotNeeded,
  className,
  forUserRoll,
  forCourses,
}) {
  const [open, setOpen] =
    useState(false); /* State to  show and hide dropdown box*/
  const [value, setValue] = useState("");
  const [searchResult, setSearchResult] =
    useState(options); /* To store Array of search result and array init*/

  useEffect(() => {
    setSearchResult(options);
  }, [JSON.stringify(options)]);

  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  const [optionLength, setOptionLength] = useState(
    searchResult?.length
  ); /* state to store search result length if optionLength == 0 show li saying "result not found!"*/
  var [showLabel, setShowLabel] = useState(
    valueReadCourseTag ? valueReadCourseTag : []
  ); /*state to store clicked multiselected values */
  const [id, setId] = useState("");

  /* Post course tag API */
  const { refetch: fetchData_forTag } = useQuery(
    endpoint.add_tag.key,
    () => {
      apirequest({
        url: `${endpoint.add_tag.addr}`,
        method: "post",
        data: {
          tagName: value,
          isActive: "1",
          createdBy: login_Details.userImsPin,
        },
      });
    },
    {
      enabled: false,
    }
  );
  /* End Call */
  /* handler to find if searched key is present in array*/

  const handleInput = (e) => {
    const searchedWrd = e.target.value;
    setValue(e.target.value);
    var newFilter = options?.filter((value) => {
      if (type == "singleSelect") {
        return value?.productGroupName
          ? value?.productGroupName
              ?.toLowerCase()
              .includes(searchedWrd.toLowerCase())
          : value?.role_name
          ? value.role_name.toLowerCase()?.includes(searchedWrd.toLowerCase())
          : value.limacode
          ? value?.limacode?.includes(searchedWrd).toLowerCase()
          : value.examName.includes(searchedWrd).toLowerCase();
      } else {
        return (value.tagName || value.courseName)
          .toLowerCase()
          .includes(searchedWrd.toLowerCase());
      }
    });
    /* If not search not present in else append li saying not present */
    if (newFilter?.length != 0) {
      setSearchResult(newFilter);
      setOptionLength(searchResult?.length);
    } else {
      setSearchResult(options);
      setOptionLength(newFilter.length);
    }
  };
  const handleClick = () => {
    if (!readonly) {
      setOpen(!open);
      if (readonly === true) {
        setOpen(false);
      }
    }
  };
  const outSideClickHandler = () => {
    setOpen(false);
  };

  const handleCreateObject = (val) => {
    if (type == "multiselect") {
      options.push({
        tagId: (searchResult.length + 1).toString(),
        tagName: val.toString(),
      }); //push new values to update options
      setUpdatedOptions(options);
      fetchData_forTag();
    } else {
      options.push({
        id: (searchResult.length + 1).toString(),
        product: val.toString(),
      }); //push new values to update options
      setUpdatedOptions(options);
    }
  };
  /* to show labels*/
  const handleLabel = (label) => {
    setValue(label);
    if (!showLabel.includes(label)) {
      setShowLabel((oldlabel) => oldlabel.concat(label));
      setUpdatedOptions((oldlabel) => oldlabel.concat(label));
    }
  };
  const removeLabel = (remLab) => {
    setValue(
      showLabel.filter(function (f) {
        return f !== remLab;
      })
    );
    setShowLabel(
      showLabel.filter(function (f) {
        return f !== remLab;
      })
    );
    setUpdatedOptions(
      showLabel.filter(function (f) {
        return f !== remLab;
      })
    );
  };

  const renderOption = (liData) => {
    if (type == "multiselect") {
      if (optionLength != 0) {
        return liData?.map((item, index) => (
          <li
            key={index}
            className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize"
            onClick={() => handleLabel(item.tagName || item.courseName)}
          >
            {item.tagName || item.courseName}
          </li>
        ));
      } else {
        return (
          <button
            className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize"
            disabled={forCourses}
            onClick={() => {
              value != ""
                ? (handleCreateObject(value), handleLabel(value))
                : undefined;
            }}
          >
            {!forCourses
              ? `"{Click here to create" ${value != "" && `"${value}"`}}`
              : "No Record found"}
          </button>
        );
      }
    } else if (type == "singleSelect") {
      // if (optionLength != 0) {
      return liData?.map((item, index) => (
        <li
          key={index}
          className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize"
          onClick={() => {
            setValue(
              item.productGroupName ||
                item.categoryName ||
                item.variantName ||
                item.role_name ||
                item.examName
            );
            forUserRoll && setId(item.role_id || item.categoryId);
          }}
        >
          {item.limacode} &nbsp;{" "}
          {item.productGroupName ||
            item.categoryName ||
            item.variantName ||
            item.role_name ||
            item.examName}
        </li>
      ));
    }
  };
  useEffect(() => {
    if (type == "singleSelect") {
      if (valueReadProduct) {
        setValue(valueReadProduct);
      }
    } else {
      if (valueReadCourseTag) {
        setValue(valueReadCourseTag);
        setShowLabel(valueReadCourseTag);
      }
    }
  }, [valueReadProduct, valueReadCourseTag]);

  useEffect(() => {
    if (type === "singleSelect" && setFieldValue) {
      setFieldValue(forUserRoll ? id : value); //setting values so that onsubmitting we can pass the values tht set to frontend
    } else {
      // if (setUpdatedOptions) {
      //   setUpdatedOptions(showLabel); //to send set option values to fontend
      // }
    }
  }, [value, showLabel]);
  return (
    <div className="w-full flex">
      <OutsideClickHandler onOutsideClick={outSideClickHandler}>
        {(type == "singleSelect" || type == "multiselect") && (
          <div className={`${width} relative top-0`}>
            <input
              className={`ml-5 bg-white  rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-full cursor-pointer focus:placeholder-transparent font-semibold placeholder-bold ${
                className ? className : "border-2 border-secondary-300"
              }`}
              type="text"
              placeholder={placeholder}
              value={value}
              onChange={handleInput}
              onClick={handleClick}
              readOnly={
                type === "singleSelect" && readonly === true ? true : false
              }
            />
            <span
              onClick={handleClick}
              className="absolute top-3 opacity-70 -right-2 cursor-pointer"
            >
              {!isIconNotNeeded && (
                <img src={open === true ? ArrowUp : ArrowDown} />
              )}
            </span>
            {open === true && (
              <div className="ml-5">
                <ul
                  className={`h-auto pt-2 w-full border-0 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 shadow-lg absolute z-[1000] top-14 bg-white scrollbar-thin overflow-y-scroll scrollbar-thumb-secondary-700 scrollbar-thumb-rounded  max-h-[10rem] ${
                    type == "multiselect" ? "mb-[2rem]" : "mb-5"
                  }`}
                >
                  {renderOption(searchResult)}
                </ul>
              </div>
            )}
          </div>
        )}
      </OutsideClickHandler>
      {type === "multiselect" && (
        <div className="w-[36rem] ml-9 flex mt-2 flex-wrap">
          {showLabel.map((lab, i) => {
            return (
              <div
                key={i.toString()}
                itemID={i}
                id={i}
                className="relative mr-1 mb-5"
              >
                <span className="bg-gray-100 text-sm text-black rounded-full px-3 pr-6 pt-3 pb-2 capitalize whitespace-nowrap">
                  {lab}
                </span>
                <span
                  className="absolute bottom-[3px] text-xs font-medium cursor-pointer right-2 text-gray-500"
                  onClick={() => removeLabel(lab)}
                >
                  &#10005;
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
DropDown.propTypes = {
  placeholder: propTypes.string,
  options: propTypes.array,
  setUpdatedOptions: propTypes.func,
  type: propTypes.string,
  width: propTypes.string,
  setFieldValue: propTypes.func,
  readonly: propTypes.bool,
  valueReadProduct: propTypes.string,
  valueReadCourseTag: propTypes.array,
  isIconNotNeeded: propTypes.bool,
  className: propTypes.string,
  forUserRoll: propTypes.bool,
  forCourses: propTypes.bool,
};
export default DropDown;
