import React from "react";
import PropTypes from "prop-types";

function Toggle({ activeTab, title, setActiveTab, margin }) {
  return (
    <div className="flex items-center">
      <p className=" text-lg font-normal font-ibm">{title}</p>
      <div className={`flex bg-secondary-200 rounded-2xl ml-${margin}`}>
        <p
          onClick={() => setActiveTab("event")}
          className={`py-3 px-5 rounded-2xl cursor-pointer   ${
            activeTab === "event"
              ? "bg-white shadow-outer font-ibm text-lg mb-0"
              : "font-ibm text-lg mb-0"
          } `}>
          Event
        </p>
        <p
          onClick={() => setActiveTab("update")}
          className={`py-3 px-5 rounded-2xl cursor-pointer ${
            activeTab === "update"
              ? "bg-white shadow-outer font-ibm text-lg mb-0"
              : "font-ibm text-lg mb-0"
          } `}>
          Update
        </p>
      </div>
    </div>
  );
}

Toggle.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  title: PropTypes.string,
  margin: PropTypes.number,
};

export default Toggle;
