import propTypes from "prop-types";
import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";
import ArrowDown from "../../../assets/icons/arrow-down.svg";
import ArrowUp from "../../../assets/icons/arrow-up.svg";

function SearchCentreDropDown({
  placeholder,
  options, //removed default empty array since the data isn't showing properly
  setUpdatedOptions,
  type,
  width,
  readonly,
  isIconNotNeeded,
  className,
}) {
  const [open, setOpen] =
    useState(false); /* State to  show and hide dropdown box*/
  const [value, setValue] = useState("");
  const [searchResult, setSearchResult] =
    useState(options); /* To store Array of search result and array init*/

  useEffect(() => {
    setSearchResult(options);
  }, [JSON.stringify(options)]);

  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  const [optionLength, setOptionLength] = useState(
    searchResult?.length
  ); /* state to store search result length if optionLength == 0 show li saying "result not found!"*/
  var [showLabel, setShowLabel] =
    useState(options); /*state to store clicked multiselected values */

  const searchCentre = (value) => {
    return apirequest({
      url: `${endpoint.getCentres.addr}`,
      method: "post",
      data: {
        // adminimspin: "IMS0000394697",
        adminimspin: login_Details.userImsPin,
        keyword: value,
      },
    });
  };
  const { mutate } = useMutation({
    mutationKey: [endpoint.getCentres.key],
    mutationFn: searchCentre,
    onSuccess: (data) => {
      //   alert(JSON.stringify(data?.data.data.centresList));
      setSearchResult(data?.data.data.centresList);
      setOptionLength(data?.data.data.centresList);
    },
    onError: (data) => {
      if (data.status === 404) {
        setSearchResult([]);
        setOptionLength(0);
      }
    },
  });

  const handleInput = (e) => {
    setValue(e.target.value);
    if (e.target.value.length > 2) {
      mutate(e.target.value);
    }
  };
  const handleClick = () => {
    if (!readonly) {
      setOpen(!open);
      if (readonly === true) {
        setOpen(false);
      }
    }
  };
  const outSideClickHandler = () => {
    setOpen(false);
  };

  /* to show labels*/
  const handleLabel = (label, id) => {
    setValue(label);
    if (showLabel.find((lab) => lab.id === id)) {
      return;
    }
    // if (
    // !showLabel.includes(label) &&
    // showLabel.find((lab) => lab.id === label)
    // ) {
    setShowLabel((oldlabel) => oldlabel.concat({ id, label }));
    setUpdatedOptions((oldlabel) => oldlabel.concat({ id, label }));
    // }
  };
  const removeLabel = (remLab) => {
    // setValue((prev) =>
    //   prev.filter(function (f) {
    //     return f.id !== remLab.id || f?.centre_id !== remLab?.centre_id;
    //   })
    // );
    setShowLabel((prev) =>
      prev.filter(function (f) {
        return f.id !== remLab.id || f?.centre_id !== remLab?.centre_id;
      })
    );
    setUpdatedOptions((prev) =>
      prev.filter(function (f) {
        return f.id !== remLab.id || f?.centre_id !== remLab?.centre_id;
      })
    );
  };

  const renderOption = (liData) => {
    if (type == "multiselect") {
      if (optionLength != 0) {
        return liData?.map((item, index) => (
          <li
            key={index}
            className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize"
            onClick={() => handleLabel(item.centre_name, item.centre_id)}
          >
            {item.centre_name}
          </li>
        ));
      } else if (optionLength === 0 && value === "") {
        return (
          <button className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize">
            {/* ? `"{Click here to create" ${value != "" && `"${value}"`}}` */}
            Type three characters to search.
          </button>
        );
      } else {
        return (
          <button className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize">
            {/* ? `"{Click here to create" ${value != "" && `"${value}"`}}` */}
            No Record found
          </button>
        );
      }
    }
  };
  useEffect(() => {
    if (setUpdatedOptions) {
      setUpdatedOptions(showLabel); //to send set option values to fontend
    }
  }, [value, showLabel]);
  return (
    <div className="w-full flex">
      <OutsideClickHandler onOutsideClick={outSideClickHandler}>
        {(type == "singleSelect" || type == "multiselect") && (
          <div className={`${width} relative top-0`}>
            <input
              className={`ml-5 bg-white  rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-full cursor-pointer focus:placeholder-transparent font-semibold placeholder-bold ${
                className ? className : "border-2 border-secondary-300"
              }`}
              type="text"
              placeholder={placeholder}
              value={value}
              onChange={handleInput}
              onClick={handleClick}
              readOnly={
                type === "singleSelect" && readonly === true ? true : false
              }
            />
            <span
              onClick={handleClick}
              className="absolute top-3 opacity-70 -right-2 cursor-pointer"
            >
              {!isIconNotNeeded && (
                <img src={open === true ? ArrowUp : ArrowDown} />
              )}
            </span>
            {open === true && (
              <div className="ml-5">
                <ul
                  className={`h-auto pt-2 w-full border-0 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 shadow-lg absolute z-[1000] top-14 bg-white scrollbar-thin overflow-y-scroll scrollbar-thumb-secondary-700 scrollbar-thumb-rounded  max-h-[10rem] ${
                    type == "multiselect" ? "mb-[2rem]" : "mb-5"
                  }`}
                >
                  {renderOption(searchResult)}
                </ul>
              </div>
            )}
          </div>
        )}
      </OutsideClickHandler>
      {type === "multiselect" && (
        <div className="w-[36rem] ml-9 flex mt-2 flex-wrap">
          {showLabel.map((lab, i) => {
            return (
              <div
                key={i.toString()}
                itemID={i}
                id={i}
                className="relative mr-1 mb-5"
              >
                <span className="bg-gray-100 text-sm text-black rounded-full px-3 pr-6 pt-3 pb-2 capitalize whitespace-nowrap">
                  {lab.label || lab.centre_name}
                </span>
                <span
                  className="absolute bottom-[3px] text-xs font-medium cursor-pointer right-2 text-gray-500"
                  onClick={() => removeLabel(lab)}
                >
                  &#10005;
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
SearchCentreDropDown.propTypes = {
  placeholder: propTypes.string,
  options: propTypes.array,
  setUpdatedOptions: propTypes.func,
  type: propTypes.string,
  width: propTypes.string,
  readonly: propTypes.bool,
  valueReadProduct: propTypes.string,
  valueReadCourseTag: propTypes.array,
  isIconNotNeeded: propTypes.bool,
  className: propTypes.string,
};
export default SearchCentreDropDown;
