import React, { useState } from "react";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config";
import { apirequest } from "../../../API/api";
import SchoolDropdown from "../SchoolDropdown";
import propTypes from "prop-types";
import { UploadFileToAWS } from "../../../AWS/uploadFile";

function CriteriaForm({endPoints, downloadEndpoints}){

  const login_Details = useSelector((state) => state?.loginData?.LoginDetails); //get the login details stored in redux while login
  const [selectedCourse  , setSelectedCourse] = useState("");

  const [dataForPayload , setdataForPayload] = useState("");
  const [browseLink , setBrowseLinks] = useState("");
 
  // Api call for Courses data 
  const { data: coursesData } = useQuery(endpoint.getSchoolCoursesApi.key, () =>
    apirequest({
      method: "post",
      url: endpoint.getSchoolCoursesApi.addr,
      data: {
        adminImsPin: login_Details.userImsPin,
      },
    }),{
    select : (res)=>res.data.data
  }
  );

  const {refetch} = useQuery(endPoints.key ,() =>apirequest({
    method : "Post",
    url : endPoints.addr,
    data : {
      course : selectedCourse ,
      createdBy: login_Details.userImsPin ,
      csvPath : browseLink,
      parametersToBeDisplayed : dataForPayload
    }
  }),{
    enabled  : false
  });

  const {refetch : downloadCSV} = useQuery(downloadEndpoints.key , ()=>apirequest({
    method :"post",
    url : downloadEndpoints.addr,
    data : {
      course : selectedCourse
    }
  }),{
    enabled : false ,
    select : (res)=>res.data.data ,
    onSuccess : (res)=>{
      const fileContent = res[0]["RecentCSVPath"];

      const a = document.createElement("a");
      a.href = fileContent;
      a.download = "recent_CSV_file.csv";

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  });
  
  function handleChange(e){
    setdataForPayload (e.target.value.split(","));

  }

  const handleBrowse = (event,setBrowseLink , path) => {
    event.preventDefault();
    setBrowseLink( event.target?.files[0].name);   //setting the selected CSV file 
    const fileDate = new Date()
      .toLocaleString({ hour12: true })
      .toString()
      .split("/");

    if (event.target?.files[0]) {
      const val = UploadFileToAWS({
        key: "addSlideShowUpload",
        file: event.target?.files[0],
        path:
          path +
          fileDate[2].toString().split(",")[0] +
          "/" +
          event.target?.files[0].name.toString().split(".")[0] +
          "_" +
          login_Details.userImsPin +
          "_" +
          fileDate[2].toString().split(/[, ]+/)[1] +
          "." +
          event.target?.files[0].name.toString().split(".")[1],
      });
      {
        val.then((item) => setBrowseLink(item));
      }
    } else {
      alert("No files available!");
    }
  };

  return (
    <div>
      <div className="flex justify-between">
        <BreadCrumb />
        <div>
          <div className="grid grid-cols-2 gap-2 w-[500px] h-[50px] pt-2">
            <button
              onClick = {()=>selectedCourse ==="" ? alert("Please Select Course !") : downloadCSV()}
              className="px-8 py-3 bg-primary-300 text-white  h-[50px] rounded-2xl shadow-outer cursor-pointer"
            >
              Download recent CSV
            </button>
          </div>
        </div>
      </div>
    
      <div className="mb-4 pl-10 flex gap-10 pt-5">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Course Name
        </label>
        <div className="ml-[80px]">
          <SchoolDropdown
            dropdownData={coursesData}
            filterId="courseId"
            filterValue="courseName"
            selectedValue={selectedCourse}
            setSelectedValue={setSelectedCourse}
            forSingleSelect={true}
          />
        </div>
      </div>
      <div className="mb-4 pl-10 flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Parameters to be displayed <br />
        </label>
        <input
          className="ml-20 bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL "
          type="text"
          name="parametersToBeDisplayed"
          placeholder="Type the  value and press Enter"
          onChange={handleChange}
          required
          autoComplete="off"
        />
      </div>
      <div className="mb-4 pl-10 flex gap-10">
        <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3 gap-5 text-black">
          Upload CSV (First Call)
        </label>
        <input
          className="ml-20 relative bg-white text-black border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
          type="text"
          name="UploadCSV"
          placeholder="No File Selected"
          value={browseLink}
          required
          autoComplete="off"
          readOnly
        />
        <label className=" px-3 bg-primary-300 text-white h-10  text-sm rounded-2xl flex text-center items-center font-semibold  shadow-outer cursor-pointer">
          <input
            className="hidden"
            type="file"
            multiple
            onChange={(e) =>
              handleBrowse(
                e,
                setBrowseLinks,
                `School/${endPoints.keys}/uploadCSV`
              )
            }
            accept=".csv"
          />
          Choose File
        </label>
      </div>

      <div className="flex justify-center mt-5 space-x-5">
        <button className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer cursor-pointer" onClick = {refetch}>
          Save
        </button>
        <button className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer cursor-pointer">
          Exit
        </button>
      </div>
    </div>
  );
}

CriteriaForm.propTypes = {
  endPoints : propTypes.object,
  downloadEndpoints : propTypes.object
};
export default CriteriaForm;
