/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from "react";
import BreadCrumb from "../global/breadcrumbs/BreadCrumb";
import Header from "../header/Header";
import DropDown from "../../components/global/fields/DropDown";
import ToggleSchedule from "../../components/home/slideshow/ToggleSchedule";
import CalendarModalTrigger from "../../components/home/blogs/calendar/CalendarModalTrigger";
import Toggle from "../../components/global/fields/Toggle";
import DropDownSearch from "../../components/global/fields/CommunicationDropdown";
import DropDownForDuration from "../../components/home/blogs/Dropdown";
import { apirequest, apirequestIMSLIVE } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import TextEditor from "../global/editor/TextEditor";
import { useHistory } from "react-router-dom";
import { UploadFileToAWS } from "../../AWS/uploadFile";
import { useSelector } from "react-redux";
import WhiteSpace from "../global/regEx/WhiteSpaceValidator";

// import FilterFunctionlity from "../filterFunctionality/FilterFunctionlity";
// import WhiteSpace from "../global/regEx/WhiteSpaceValidator";
// import { useSelector} from "react-redux";
const AddCommunication = () => {
  const history = useHistory();
  // const courseDetails = useSelector(state => state);
  const [activeTabForPublish, setActiveTabforPublish] = useState("now");
  const [communicationType, setCommunicationType] = useState(null);
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [hour, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [variantStatus, setVariantStatus] = useState("active");
  const [browseLink, setBrowseLink] = useState("");

  const [communicationData, setCommunicationData] = useState({
    communicationName: "",
    description: "",
    startDate: "",
    startTime: "0:0:0",
    endDate: "",
    link: "",
  });
  const [editorDescription, setEditorDescription] = useState("");
  var startTime =
    hour || minutes || seconds
      ? (hour === "" ? 0 : hour) +
        ":" +
        (minutes === "" ? 0 : minutes) +
        ":" +
        (seconds === "" ? 0 : seconds)
      : "0:0:0";

  let isActive = variantStatus === "active" ? "1" : "0";

  // States for getting the unique items for city , centre=========>so on
  const [getStateCode, setGetStateCode] = useState([]);
  const [getCityCode, setGetCityCode] = useState([]);
  const [getBranchId, setGetBranchId] = useState([]);
  const [getCourseId, setGetCourseId] = useState([]);
  const [getVariantId, setGetVariantId] = useState([]);
  const [getBatchId, setGetBatchIdd] = useState([]);

  const AssetType = [
    { id: 1, productGroupName: "Email" },
    { id: 2, productGroupName: "WhatsApp" },
    { id: 3, productGroupName: "Push Notification" },
    { id: 4, productGroupName: "Maintenance Message" },
  ];

  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  // api for getting state details ===>
  const { data: getStatesData } = useQuery(
    `${endpoint.getStatesData.key}`,
    () =>
      apirequest({
        url: `${endpoint.getStatesData.addr}`,
      })
  );

  // api to call the cities Data ==========>
  const {data: citiesData} = useQuery([endpoint.getCitiesData.key ,getStateCode], ()=>apirequest({
    url  : `${endpoint.getCitiesData.addr}`,
    method :"POST",
    data: {
      stateId : getStateCode
    } 
  }),{
    enabled : (!!JSON.stringify(getStateCode)),
    select : (res)=>res.data.data
  });

  // api call for getting centers ======>
  const { data: centerData, refetch: centreRefetch } = useQuery(
    [endpoint.get_Centers_For_addCommunication.key , getCityCode],
    () =>
      apirequest({
        url: `${endpoint.get_Centers_For_addCommunication.addr}`,
        method: "POST",
        data: {
          cityId: getCityCode
        },
      }),
    {
      enabled : (!!JSON.stringify(getCityCode)),
      select: (res) => res.data.data,
    }
  );
  // api for getting courses names =====>
  const { data: coursesData, refetch: coursesRefetch } = useQuery(
    [endpoint.get_Courses_For_addCommunication.key , getBranchId],
    () =>
      apirequest({
        url: `${endpoint.get_Courses_For_addCommunication.addr}`,
        method: "post",
        data: {
          branchId: getBranchId,
          imsPin : login_Details.userImsPin
        },
      }),
    {
      enabled : (!!JSON.stringify(getBranchId)),
      select: (res) => res.data.data,
    }
  );

  // api call for getting variants ======>
  const { data: variantsData, refetch: variantRefetch } = useQuery(
    [endpoint.get_Variants_For_addCommunication.key , getCourseId],
    () =>
      apirequestIMSLIVE({
        url: `${endpoint.get_Variants_For_addCommunication.addr}`,
        method: "post",
        data: {
          courseId:  getCourseId 
        },
      }),
    {
      enabled : (!!JSON.stringify(getCourseId)) ,
      select: (res) => res.data.data,
    }
  );
  // api call for getting Batches ======>
  const { data: batchesData, refetch: batchRefetch } = useQuery(
    [endpoint.get_Batches_For_addCommunication.key , getVariantId],
    () =>
      apirequestIMSLIVE({
        url: `${endpoint.get_Batches_For_addCommunication.addr}`,
        method: "post",
        data: {
          variantId: getVariantId,
          centreId : getBranchId
        },
      }),
    {
      enabled : (!!JSON.stringify(getVariantId)),
      select: (res) => res.data.data,
    }
  );

  useEffect(() => {
    if (activeTabForPublish != "now") {
      setCommunicationData({ ...communicationData, startDate: startdate });
    } else {
      setCommunicationData({ ...communicationData, startDate: "" });
    }
  }, [startdate, activeTabForPublish]);
  useEffect(() => {
    if (editorDescription != "") {
      setCommunicationData({
        ...communicationData,
        ["description"]: editorDescription,
      });
    }
  }, [editorDescription]);
  const handleChange = (e) => {
    setCommunicationData({
      ...communicationData,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    // setPostAddCommunication(false);
    if (communicationType != "") {
      setCommunicationData({
        ...communicationData,
        type: communicationType,
        description: "",
        link: "",
        startDate: "",
        startTime: "0:0:0",
        endDate: "",
      });
    }
  }, [communicationType]);

  // api call to post communicationData ======>
  const fetchCommunicationRequest = () => {
    return apirequest({
      url: endpoint.post_addCommunication.addr,
      method: "POST",
      data: {
        communicationName: communicationData?.communicationName,
        type: communicationType,
        description: communicationData?.description,
        link: communicationData?.link,
        startDate: communicationData?.startDate,
        startTime: startTime != "" ? startTime : "0:0:0",
        endDate: enddate ? enddate : "",
        isActive: isActive,
        createdBy: login_Details.userImsPin,
        stateId:getStateCode,
        cityId:  getCityCode,
        branchId: getBranchId,
        courseId: getCourseId,
        variantId: getVariantId,
        batchId: getBatchId,
      },
    });
  };
  const { refetch: fetchCommunication } = useQuery(
    `${endpoint.post_addCommunication.key}`,
    fetchCommunicationRequest,
    {
      enabled: false,
      onSuccess: (data) => {
        alert(data.data.message);
        window.location.reload(false);
      },
      onError: (err) => {
        alert(err.data.message);
      },
    }
  );
  const validationText = (obj) => {
    let result = Object.keys(obj).filter((item) => {
      if (obj[item] === "") {
        return item;
      }
    });
    if (result.length > 0) {
      alert(`Please Enter valid ${result.toString()}`);
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (WhiteSpace(communicationData.communicationName)) {
      alert("Please enter Valid Communication Name");
    }
    else if(getBatchId.length === 0){
      alert("All Dropdown Fields are Mandatory");
    }
    else if (
      communicationType === "Email" ||
      communicationType === "WhatsApp"
    ) {
      if (communicationType === "WhatsApp") {
        browseLink === "" && alert("Please Select Thumbnail link");
        WhiteSpace(communicationData.description) &&
          alert("Please Enter Valid Description");
        if (activeTabForPublish === "schedule") {
          if (startdate === "") {
            alert("Please enter Start date");
          } else {
            fetchCommunication();
          }
        }else fetchCommunication();
      } else if (communicationType === "Email") {
        browseLink === "" && alert("Please Select Thumbnail link");
        editorDescription.length === 0 &&
          alert("Please Enter Valid Description");
        if (activeTabForPublish === "schedule") {
          if (startdate === "") {
            alert("Please enter Start date");
          } else {
            fetchCommunication();
          }
        }else fetchCommunication();
      }
    } else if (
      communicationType === "Push Notification" ||
      communicationType === "Maintenance Message"
    ) {
      if (WhiteSpace(communicationData.description)) {
        alert("Please Enter Valid Description");
      } else if(communicationData?.link ?.trim()===""){
        alert("Enter Valid Redirect URL");
      }
      else if (activeTabForPublish === "schedule") {
        let obj = { startdate, hour, enddate };
        validationText(obj);
        if (
          Number(startdate.replace(/-/g, "")) >
          Number(enddate.replace(/-/g, ""))
        ) {
          alert("please select End Date after Start Date");
        } else if (enddate === "") {
          alert("Please Select End date");
        } else {
          fetchCommunication();
        }
      } else if (enddate === "") {
        alert("Please Select End date");
      } else {
        fetchCommunication();
      }
    }
  };

  const handleBrowse = (event) => {
    event.preventDefault();
    setBrowseLink(event.target?.files[0].name);
    const fileDate = new Date()
      .toLocaleString({ hour12: true })
      .toString()
      .split("/");
    if (event.target?.files[0]) {
      const val = UploadFileToAWS({
        key: "addCommunicationUpload",
        file: event.target?.files[0],
        path:
          "Asset/AddCommunication/" +
          fileDate[2].toString().split(",")[0] +
          "/" +
          event.target?.files[0].name.toString().split(".")[0] +
          "_" +
          login_Details.userImsPin +
          "_" +
          fileDate[2].toString().split(/[, ]+/)[1] +
          "." +
          event.target?.files[0].name.toString().split(".")[1],
      });
      {
        val.then((item) =>
          setCommunicationData({ ...communicationData, link: item })
        );
      }
    } else {
      alert("No files available!");
    }
  };

  return (
    <div className="pb-9">
      <Header />
      <div className="mt-6 ml-2">
        <BreadCrumb />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="font-ibm flex justify-center mt-7">
          <div className="space-y-5 w-[93%] shadow-outer rounded-2xl p-10">
            <div className="space-x-8">
              <label className="font-normal text-lg">Communication Title</label>
              <input
                placeholder="Enter Communication title"
                className="bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                name="communicationName"
                value={communicationData?.communicationName}
                onChange={handleChange}
                autoComplete="off"
                required
              />
            </div>
            <div className="flex justify-between mr-20">
              <div className="space-x-[155px] flex">
                <label className="font-normal text-lg leading-[40px]">
                  State
                </label>
                <DropDownSearch
                  setId={setGetStateCode}
                  getId={getStateCode}
                  dropdownData={getStatesData?.data?.data.stateDetails ?? []}
                  filterText="State"
                  filterId="stateId"
                  filterValue="stateName"
                  val = {[]}
                />
              </div>
              <div className="flex flex-row space-x-5">
                <label className="font-normal text-lg mr-6 leading-[40px]">
                  City
                </label>
                <DropDownSearch
                  setId={setGetCityCode}
                  getId={getCityCode}
                  dropdownData={citiesData ?? []}
                  filterText="City"
                  filterId="cityId"
                  filterValue="cityName"
                  // callRefetch={true}
                  refetch = {centreRefetch}
                  val= {[]}
                />
              </div>
            </div>

            <div className="flex justify-between mr-20">
              <div className="space-x-[145px] flex">
                <label className="font-normal text-lg leading-[40px]">
                  Center
                </label>
                <div className="flex flex-row align-middle">
                  <DropDownSearch
                    setId={setGetBranchId}
                    getId={getBranchId}
                    dropdownData={centerData ?? []}
                    filterText="Center"
                    filterId="branchId"
                    filterValue="branchName"
                    refetch={coursesRefetch}
                    // callRefetch = {true}
                    val= {[]}
                  />
                </div>
              </div>
              <div className="flex space-x-5">
                <label className="font-normal text-lg mt-2 leading-[40px]">
                  Courses
                </label>
                <DropDownSearch
                  setId={setGetCourseId}
                  getId={getCourseId}
                  dropdownData={coursesData ?? []}
                  filterText="Courses"
                  filterId="courseId"
                  filterValue="courseName"
                  refetch={variantRefetch}
                  // callRefetch = {true}
                  val= {[]}
                />
              </div>
            </div>

            <div className="flex justify-between mr-[77px]">
              <div className="space-x-[143px] flex">
                <label className="font-normal text-lg leading-[40px]">
                  Variant
                </label>
                <div className="flex flex-row align-middle">
                  <DropDownSearch
                    setId={setGetVariantId}
                    getId={getVariantId}
                    dropdownData={variantsData ?? []}
                    filterText="Variants"
                    filterValue="variantName"
                    filterId="variantId"
                    refetch={batchRefetch}
                    // callRefetch ={true}
                    val= {[]}
                  />
                </div>
              </div>
              <div className="flex flex-row space-x-5">
                <label className="font-normal text-lg mr-6 leading-[40px]">
                  Batch
                </label>
                <DropDownSearch
                  setId={setGetBatchIdd}
                  getId={getBatchId}
                  dropdownData={batchesData ?? []}
                  filterText="Batches"
                  filterId="batchId"
                  filterValue="batchName"
                  callRefetch = {false}
                  val= {[]}
                />
              </div>
            </div>

            <div className="flex mb-4 space-x-6">
              <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
                Communication Type{" "}
              </label>
              <DropDown
                placeholder={"Enter Communication type"}
                options={AssetType}
                setUpdatedOptions={setCommunicationType}
                type={"singleSelect"}
                width={"w-[22rem]"}
                setFieldValue={setCommunicationType}
              />
            </div>

            {/* Push Notification Form Starts here =============> */}

            {communicationType === "Push Notification" && (
              <React.Fragment>
                <div className="flex mb-4 space-x-11">
                  <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
                    Communication Body
                  </label>
                  <textarea
                    className="ml-5 bg-white border-2 border-secondary-300 h-36 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                    placeholder="Enter Description "
                    name="description"
                    onChange={handleChange || " "}
                    value={communicationData.description}
                    required
                  />
                </div>
                <div className="flex mb-4 space-x-10">
                  <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
                    Redirect URL
                  </label>
                  <input
                    className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                    placeholder="Paste URL"
                    name="link"
                    required
                    onChange={handleChange || " "}
                  />
                </div>
                <div className="flex space-x-16 mb-4">
                  <ToggleSchedule
                    title="Publish"
                    activeTab={activeTabForPublish}
                    setActiveTab={setActiveTabforPublish}
                    margin="28"
                  />
                  {activeTabForPublish === "schedule" && (
                    <div className="flex  space-x-5">
                      <label className="font-normal text-lg mt-2">
                        Start Date
                      </label>
                      <aside className="flex">
                        <input
                          placeholder="select Start Date"
                          value={startdate}
                          readOnly
                          className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"
                        />
                        <div className="relative top-4 right-8">
                          <CalendarModalTrigger setDate={setStartDate} />
                        </div>
                      </aside>
                    </div>
                  )}
                </div>
                {activeTabForPublish === "schedule" && (
                  <div className="flex mb-4">
                    <label className=" font-normal text-lg font-ibm -mr-4">
                      Start time
                    </label>
                    <div className="ml-28">
                      <DropDownForDuration
                        minOffset={0}
                        maxOffset={23}
                        text={"Hrs"}
                        setSelectedValue={setHour}
                      />
                    </div>
                    <div className="ml-2">
                      <DropDownForDuration
                        minOffset={0}
                        maxOffset={59}
                        text={"Mins"}
                        setSelectedValue={setMinutes}
                      />
                    </div>
                    <div className="ml-2">
                      <DropDownForDuration
                        minOffset={0}
                        maxOffset={59}
                        text={"Sec"}
                        setSelectedValue={setSeconds}
                      />
                    </div>
                  </div>
                )}
                <div className="flex  space-x-[100px] mb-4">
                  <label className="font-normal text-lg mt-2">End Date</label>
                  <div className="flex">
                    <input
                      placeholder="select End Date"
                      value={enddate}
                      readOnly
                      className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"
                    />
                    <div className="relative top-4 right-8">
                      <CalendarModalTrigger setDate={setEndDate} />
                    </div>
                  </div>
                </div>
                <Toggle
                  title="Status"
                  activeTab={variantStatus}
                  setActiveTab={setVariantStatus}
                  margin="16"
                />
              </React.Fragment>
            )}
            {/* Email form starts here ============> */}

            {communicationType === "Email" && (
              <React.Fragment>
                <div className="flex mb-4 space-x-11">
                  <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
                    Communication Body
                  </label>
                  <TextEditor
                    customStyle={
                      "border-2 border-secondary-300 border-solid rounded-sm h-60 max-w-3xl"
                    }
                    setGetEditorValue={setEditorDescription}
                    setFieldValue={null}
                  />
                </div>
                <div className="flex mb-4 space-x-8">
                  <label className="inline-block w-[11%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
                    Thumbnail
                  </label>
                  <input
                    className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-6/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                    placeholder="Paste Thumbnail Link"
                    name="link"
                    value={browseLink}
                    readOnly={true}
                  />
                  <label className="bg-primary-200 px-10 ml-2 py-3 rounded-xl text-white text-sm font-semibold cursor-pointer font-ibm">
                    <input
                      className="hidden "
                      type="file"
                      multiple
                      onChange={handleBrowse}
                    />
                    Browse
                  </label>
                </div>
                <div className="flex space-x-16 mb-4">
                  <ToggleSchedule
                    title="Publish"
                    activeTab={activeTabForPublish}
                    setActiveTab={setActiveTabforPublish}
                    margin="32"
                  />
                  {activeTabForPublish === "schedule" && (
                    <div className="flex  space-x-5">
                      <label className="font-normal text-lg mt-2">
                        Start Date
                      </label>
                      <aside className="flex">
                        <input
                          placeholder="Select Start Date"
                          value={startdate}
                          readOnly
                          className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"
                        />
                        <div className="relative top-4 right-8">
                          <CalendarModalTrigger setDate={setStartDate} />
                        </div>
                      </aside>
                    </div>
                  )}
                </div>
                {activeTabForPublish === "schedule" && (
                  <div className="flex mb-4 space-x-[126px]">
                    <label className=" font-normal text-lg font-ibm -mr-4">
                      Start time
                    </label>
                    <div className="ml-28">
                      <DropDownForDuration
                        minOffset={0}
                        maxOffset={24}
                        text={"Hrs"}
                        setSelectedValue={setHour}
                      />
                    </div>
                    <div className="ml-2">
                      <DropDownForDuration
                        minOffset={0}
                        maxOffset={60}
                        text={"Mins"}
                        setSelectedValue={setMinutes}
                      />
                    </div>
                    <div className="ml-2">
                      <DropDownForDuration
                        minOffset={0}
                        maxOffset={60}
                        text={"Sec"}
                        setSelectedValue={setSeconds}
                      />
                    </div>
                  </div>
                )}
                <Toggle
                  title="Status"
                  activeTab={variantStatus}
                  setActiveTab={setVariantStatus}
                  margin="20"
                />
              </React.Fragment>
            )}
            {/* Whats App form starts here ==========> */}
            {communicationType === "WhatsApp" && (
              <React.Fragment>
                <div className="flex mb-4 space-x-11">
                  <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
                    Communication Body
                  </label>
                  <textarea
                    className="ml-5 bg-white border-2 border-secondary-300 h-36 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                    placeholder="Enter Communication type"
                    name="description"
                    onChange={handleChange}
                    required
                    value={communicationData.description}
                  />
                </div>
                <div className="flex mb-4 space-x-8">
                  <label className="inline-block w-[11%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
                    Thumbnail
                  </label>
                  <input
                    className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-6/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                    placeholder="Paste Thumbnail Link"
                    name="link"
                    value={browseLink}
                    readOnly={true}
                  />
                  <label className="bg-primary-200 px-10 ml-2 py-3 rounded-xl text-white text-sm font-semibold cursor-pointer font-ibm">
                    <input
                      className="hidden "
                      type="file"
                      multiple
                      onChange={handleBrowse}
                      accept=".webp"
                      defaultValue=""
                    />
                    Browse
                  </label>
                </div>
                <div className="flex space-x-16 mb-4">
                  <ToggleSchedule
                    title="Publish"
                    activeTab={activeTabForPublish}
                    setActiveTab={setActiveTabforPublish}
                    margin="32"
                  />
                  {activeTabForPublish === "schedule" && (
                    <div className="flex  space-x-5">
                      <label className="font-normal text-lg mt-2">
                        Start Date
                      </label>
                      <aside className="flex">
                        <input
                          placeholder="Select Start Date"
                          value={startdate}
                          readOnly
                          className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"
                        />
                        <div className="relative top-4 right-8">
                          <CalendarModalTrigger setDate={setStartDate} />
                        </div>
                      </aside>
                    </div>
                  )}
                </div>
                {activeTabForPublish === "schedule" && (
                  <div className="flex mb-4 space-x-[126px]">
                    <label className=" font-normal text-lg font-ibm -mr-4">
                      Start time
                    </label>
                    <div className="ml-28">
                      <DropDownForDuration
                        minOffset={0}
                        maxOffset={23}
                        text={"Hrs"}
                        setSelectedValue={setHour}
                      />
                    </div>
                    <div className="ml-2">
                      <DropDownForDuration
                        minOffset={0}
                        maxOffset={59}
                        text={"Mins"}
                        setSelectedValue={setMinutes}
                      />
                    </div>
                    <div className="ml-2">
                      <DropDownForDuration
                        minOffset={0}
                        maxOffset={59}
                        text={"Sec"}
                        setSelectedValue={setSeconds}
                      />
                    </div>
                  </div>
                )}
                <Toggle
                  title="Status"
                  activeTab={variantStatus}
                  setActiveTab={setVariantStatus}
                  margin="20"
                />
              </React.Fragment>
            )}

            {/* Maintanence message form Starts here ============> */}
            {communicationType === "Maintenance Message" && (
              <React.Fragment>
                <div className="flex mb-4 space-x-11">
                  <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
                    Communication Body
                  </label>
                  <textarea
                    className="ml-5 bg-white border-2 border-secondary-300 h-36 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                    placeholder="Enter Communication type"
                    name="description"
                    onChange={handleChange}
                    required
                    value={communicationData.description}
                  />
                </div>
                <div className="flex mb-4 space-x-10">
                  <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
                    Redirect URL
                  </label>
                  <input
                    className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                    placeholder="Paste URL"
                    name="link"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="flex space-x-16 mb-4">
                  <ToggleSchedule
                    title="Publish"
                    activeTab={activeTabForPublish}
                    setActiveTab={setActiveTabforPublish}
                    margin="32"
                  />
                  {activeTabForPublish === "schedule" && (
                    <div className="flex  space-x-5">
                      <label className="font-normal text-lg mt-2">
                        Start Date
                      </label>
                      <aside className="flex">
                        <input
                          placeholder="Select Start Date"
                          value={startdate}
                          readOnly
                          className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"
                        />
                        <div className="relative top-4 right-8">
                          <CalendarModalTrigger setDate={setStartDate} />
                        </div>
                      </aside>
                    </div>
                  )}
                </div>
                {activeTabForPublish === "schedule" && (
                  <div className="flex mb-4 space-x-[126px]">
                    <label className=" font-normal text-lg font-ibm -mr-4">
                      Start time
                    </label>
                    <div className="ml-28">
                      <DropDownForDuration
                        minOffset={0}
                        maxOffset={23}
                        text={"Hrs"}
                        setSelectedValue={setHour}
                      />
                    </div>
                    <div className="ml-2">
                      <DropDownForDuration
                        minOffset={0}
                        maxOffset={59}
                        text={"Mins"}
                        setSelectedValue={setMinutes}
                      />
                    </div>
                    <div className="ml-2">
                      <DropDownForDuration
                        minOffset={0}
                        maxOffset={59}
                        text={"Sec"}
                        setSelectedValue={setSeconds}
                      />
                    </div>
                  </div>
                )}
                <div className="flex  space-x-[115px] mb-4">
                  <label className="font-normal text-lg mt-2">End Date</label>
                  <div className="flex">
                    <input
                      placeholder="Select End Date"
                      value={enddate}
                      readOnly
                      className="bg-white border-2 w-[100%] cursor-default border-secondary-300 rounded-2xl pl-4 pr-5 py-2 border-solid  px-2 focus:outline-none placeholder-bold font-semibold text-xl focus:placeholder-transparent"
                    />
                    <div className="relative top-4 right-8">
                      <CalendarModalTrigger setDate={setEndDate} />
                    </div>
                  </div>
                </div>
                <Toggle
                  title="Status"
                  activeTab={variantStatus}
                  setActiveTab={setVariantStatus}
                  margin="20"
                />
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="flex justify-center mt-5 space-x-5 mb-5">
          <button className="px-10 py-3 bg-primary-200 text-white rounded-2xl shadow-outer">
            Save
          </button>
          <p
            className="px-10 py-3 cursor-pointer font-medium text-base rounded-2xl shadow-outer"
            onClick={history.goBack}
          >
            Exit
          </p>
        </div>
      </form>
    </div>
  );
};

export default AddCommunication;
