import React, { useState } from "react";
import Header from "../../components/header/Header";
import { Link } from "react-router-dom";
import SlideshowTable from "../../components/home/slideshow/SlideshowTable";
import TablePagination from "../../components/table/TablePagination";
// import TableSearch from "../../components/table/TableSearch";
import DocumentPlusIcon from "../../assets/icons/document.svg";
import BreadCrumb from "../../components/global/breadcrumbs/BreadCrumb";
import Loader from "../../components/global/loader/Loader";
import { useSelector } from "react-redux";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";

const BatchListing = () => {
  const adminimspin = useSelector(state => state.loginData.LoginDetails.userImsPin);

  // const [getBatchesData , setBatchesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const column = [
    { col: "1", width: "5%" },
    { col: "2", width: "40%" },
    { col: "3", width: "" },
    { col: "4", width: "5%" },
  ];

  // API's for Batch Listing
  const CategoriesListing = () => {
    return apirequest({
      url: `${endpoint.get_batches.addr}`,
      method: "post",
      data: {
        // adminimspin:adminimspin,
        adminimspin:adminimspin,
      }
    });
  };
  
  const {data :  get_batchData, error,isSuccess :is_batch_success,status } = useQuery(
    endpoint.get_batches.key,
    CategoriesListing,{
      // onSuccess : res=>setBatchesData(res),
      refetchOnMount : true
    }
  );

  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };

  return (
    <div id="courses">
      <Header />
      {status === "loading" ? (
        <Loader />
      ) : !is_batch_success && error?.status !== 404? (
        <span>{error?.message}</span>
      ) : (
        <div className="courses">
          <div className="courseTable mt-9">
            <div className="courseTable__head flex justify-between  md:mt-[2%] md-mb-[5%] md:mx-9 pl-[2%]">
              <div className="flex">
                <BreadCrumb />
              </div>

              <div className="ml-4 flex">
                {/* <TableSearch 
                  defaultData ={centres_DefaultData}
                  setDefaultData ={setCentresData}
                  dataKey = "centreName"
                  dataForFilter={centres_DefaultData?.data.data}
                  name = "Courseware"
                  subModuleName= "Master"
                  setCurrentPage={setCurrentPage}
                  childName = "Centres"
                  childModuleName=""
                  placeholder="Search by Centre"
                /> */}
                  
                <Link to="/courseware/master/batch">
                  <button className="ml-6 bg-primary-200 text-white font-medium px-6 rounded-xl hover:opacity-75 shadow-outer font-ibm w-40 h-[56px]">
                    Add New Batch
                  </button>
                </Link>
              </div>
            </div>
            <div className="courseTable__body md:ml-[4%] md:mr-[34px] mt-4 shadow-outer pl-[2%] pr-[5%] rounded-xl bg-white pb-[2%]">
              <SlideshowTable
                icon={DocumentPlusIcon}
                column={column}
                currentData={get_batchData?.data?.data?.slice(
                  indexOfFirstItem,
                  indexOfLastItem,
                )}
                type={"course"}
              />
            </div>
            <div className="courseTable__footer mx-auto mb-7">
              <TablePagination
                data={get_batchData?.data?.data}
                dataPerPage={dataPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BatchListing;
