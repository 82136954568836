import React from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ReactComponent as DownArrow } from "../../../assets/icons/arrow-down.svg";
import PropTypes from "prop-types";

const NewDropDown = ({
  dropDownOptions,
  optionId,
  optionName,
  selected,
  setSelected,
}) => {
  const [selectedOption, setSelectedOption] = React.useState(selected);

  const handleClick = (id, name) => {
    setSelectedOption(name);
    setSelected(id, name);
  };
  return (
    <>
      <Listbox
        as="div"
        value={selectedOption}
        onChange={setSelectedOption}
        className="relative"
      >
        <Listbox.Button className="w-full ">
          <div className=" flex items-center justify-between w-full border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3">
            <p className={`font-normal text-sm `}>{selected}</p>
            <DownArrow />
          </div>
        </Listbox.Button>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute w-full bg-secondary-100 mt-2 rounded-2xl py-4 pl-4 shadow-outer z-30 outline-none max-h-[400px] overflow-auto">
            {dropDownOptions &&
              dropDownOptions.map((option) => (
                <Listbox.Option
                  as="p"
                  value={option[optionName]}
                  key={option[optionId]}
                >
                  {({ selected, active }) => (
                    <p
                      className={`${
                        selected || active ? "font-semibold" : "font-normal"
                      } mb-4 text-sm`}
                      onClick={() =>
                        handleClick(option[optionId], option[optionName])
                      }
                    >
                      {option[optionName]}
                    </p>
                  )}
                </Listbox.Option>
              ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </>
  );
};
NewDropDown.propTypes = {
  dropDownOptions: PropTypes.any,
  optionId: PropTypes.string,
  optionName: PropTypes.string,
  selected: PropTypes.any,
  setSelected: PropTypes.any,
};

export default NewDropDown;
