import React, { useState, useEffect } from "react";
import Header from "../../header/Header";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import "../blogs/multiselect.css";
import Dropdown from "../blogs/Dropdown";
import DropDownTags from "../../global/fields/DropDown";
import propTypes from "prop-types";
import DropDown from "../../global/fields/CourseDropDown";
import ToggleYesNo from "../../global/fields/ToggleYesNo";
import CalendarModalTrigger from "../blogs/calendar/CalendarModalTrigger";
import Recurring from "./Recurring";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import WhiteSpace from "../../global/regEx/WhiteSpaceValidator";

const AddNotice = () => {

  let history = useHistory();
  const [toggleState, setToggleState] = useState(true);
  const [EventName, setEventName] = useState("");
  const [Venue, setVenue] = useState("");
  const [TypeEvent, setTypeEvent] = useState("");
  const [CTAName, setCTAName] = useState("");
  const [URLtoCTA, setURLtoCTA] = useState("");
  const [TexttoDisplay, setTexttoDisplay] = useState("");
  const [AuthorName, setAuthorName] = useState("");
  const [courseTags, setCourseTags] = useState([]);
  const [date, setDate] = useState("Select Date");
  const [StartHour, setStartHour] = useState(0);
  const [StartMinute, setStartMinute] = useState(0);
  const [StartSecond, setStartSecond] = useState(0);
  const [EndHour, setEndHour] = useState(0);
  const [course, setCourse] = useState([]);
  const [EndSecond, setEndSecond] = useState(0);
  const [PublishHour, setPublishHour] = useState(0);
  const [PublishMinute, setPublishMinute] = useState(0);
  const [PublishSecond, setPublishSecond] = useState(0);
  const [enddate, setEndDate] = useState("Select End Date");
  const [countdownDate, setCountdownDate] = useState("Select Date");
  // state to store selected course values and passed as prop
  const [showLabel, setShowLabel] = useState([]);
  const [value, setValue] = useState("");
  // state to be passed for reccuring  as prop
  const [repeat, setRepeat] = useState(1);
  const [repeatValue, setRepeatValue] = useState("Week");
  const [Endsvalue, setEndsValue] = useState(1);
  const [type, setType] = useState("Never");
  const [recurringDate, setrecurringDate] = useState("Select Date");
  const [filters, handleFilters] = useState([]);
  const [EndMinute, setEndMinute] = useState(Number(StartHour)===Number(EndHour) ? 1 : 0);
  const [Checked, setChecked] = useState([]);
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  const { data: getCourseData } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method : "post",
      data : {
        "adminimspin":login_Details.userImsPin
      }
    }), 
  );
  // Query for tags data,eg: ["CAT", "XAT"]==========>
  const { data: tagsData } = useQuery(
    `${endpoint.tags_data.key}`,
    () =>
      apirequest({
        url: `${endpoint.tags_data.addr}`,
      }),
  );

  const fetchAddEvent = () => {
    return apirequest({
      url: `${endpoint.add_calendarEvent.addr}`,
      method: "post",
      data: {
        calendarEventName: EventName,
        startDate: date,
        startTime: `${StartHour}:${StartMinute}:${StartSecond}`,
        endDate: enddate==="Select End Date"?"":enddate,
        endTime: `${EndHour}:${EndMinute}:${EndSecond}`,
        meetingRecurrance: toggleState == true ? "1" : "0",
        repeatCount: toggleState == true?repeat:"",
        repeatType: toggleState == true?repeatValue:"",
        repeatOn: filters === [] ? [""] : filters,
        endsNever: toggleState == true ?type == "Never" ? "1" : "0":"",
        // if type=on, then show the ends On Date
        endsOn: type == "On"?recurringDate==="Select Date"?"":recurringDate:"",
        endsAfter:  type == "After"?Endsvalue:"",
        venue: Venue,
        eventType: TypeEvent,
        ctaName: CTAName,
        link: URLtoCTA,
        publishTime: `${PublishHour}:${PublishMinute}:${PublishSecond}`,
        countDownDate: countdownDate,
        description: TexttoDisplay,
        authorName: AuthorName,
        isActive: "1",
        createdBy: login_Details.userImsPin,
        courseName: showLabel,
        tagName: courseTags,
      },
    });
  };
  //API request to add new blogs data
  const {refetch: addEventRefetch } = useQuery(
    endpoint.add_calendarEvent.key,
    fetchAddEvent,
    {
      enabled: false,
      onSuccess :(res)=>{alert(res.data.message),window.location.reload(false);},
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );

  const onsave = e => {
    if(enddate!=="Select End Date" && toggleState=== true){
      alert("please select meeting recurring as No or don't select End Date");
    }
    else if(enddate==="Select End Date" && toggleState=== false){
      alert("please select End Date");
    }
    else if((Number(date.replace(/-/g, "")))>Number(enddate.replace(/-/g, ""))){
      alert("please select End Date after Start Date");
    }
    else if(WhiteSpace(EventName)===true ||WhiteSpace(Venue)===true ||WhiteSpace(TypeEvent)===true ||WhiteSpace(CTAName)===true ||WhiteSpace(URLtoCTA)===true ||WhiteSpace(TexttoDisplay)===true ||WhiteSpace(AuthorName)===true){
      alert("Required Fields can't be blank spaces");
    }
    else if((Number(countdownDate.replace(/-/g, "")))>Number(enddate.replace(/-/g, ""))){
      alert("Countdown Data should be before end Date");
    }
    // to make course selection mandatory
    else if(showLabel.length===0){
      alert("Please Select Course");
    }
    else{
      addEventRefetch();
    }
    e.preventDefault();
  };

  useEffect(() => {
    if(enddate === "Select End Date"){
      setToggleState(false);
    }
  }, [enddate]);
  useEffect(() => {
    setEndHour(StartHour);
  }, [StartHour]);

  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />

      <div className="mt-2 mx-12 px-8 py-2 ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
          onSubmit={onsave}>
          <div className="h-full space-y-4  px-12 py-6 relative">
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Event Title
                <input
                  type="text"
                  name="eventName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={EventName}
                  onChange={e => setEventName(e.target.value)}
                />
              </label>
            </div>
            <div className="flex space-x-28">
              <label className=" font-normal text-lg font-ibm ">Course/s</label>
              {/* {isSuccess && */}
              <DropDown
                placeholder="Search Course(s)"
                options={getCourseData?.data?.data?.courseList}
                setUpdatedOptions={setCourse}
                type={"multiselect"}
                width={"w-[22rem]"}
                courseTags={course}
                showLabel={showLabel}
                setShowLabel={setShowLabel}
                value={value}
                setValue={setValue}
                forCourses = {true}
              />
            </div>
            <div className="flex space-x-36">
              <label className=" font-normal text-lg font-ibm ">Tag/s</label>
              {/* {tags_data_status && ( */}
              <DropDownTags
                placeholder={"Search exisiting or Create new tags"}
                options={tagsData?.data.data}
                setUpdatedOptions={setCourseTags}
                type={"multiselect"}
                width={"w-[22rem]"}
                courseTags={courseTags}
              />
            </div>
            <div className="flex space-x-32">
              <label className=" font-normal text-lg font-ibm ">
                Start Date
              </label>

              <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    date
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"
                  }`}>
                  {date && date}
                </p>
                <CalendarModalTrigger setDate={setDate} />
              </div>
            </div>
            <div className="flex">
              <>
                <label className=" font-normal text-lg font-ibm ">
                  Start time
                </label>
                <div className="ml-32">
                  <Dropdown
                    minOffset={0}
                    maxOffset={23}
                    text={"Hrs"}
                    selectedValue={StartHour}
                    setSelectedValue={setStartHour}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Mins"}
                    selectedValue={StartMinute}
                    setSelectedValue={setStartMinute}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Sec"}
                    selectedValue={StartSecond}
                    setSelectedValue={setStartSecond}
                  />
                </div>
              </>
            </div>
            <div className="flex space-x-32">
              <label className=" font-normal text-lg font-ibm ">End Date</label>
              <div className="relative w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                {enddate!=="Select End Date" &&
                <span  className="absolute bottom-[5px] text-xs font-medium cursor-pointer left-1/2 text-gray-500" onClick={()=> setEndDate("Select End Date")}>&#10005;</span>}
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    enddate
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"

                  }`}>
                  {enddate && enddate}
                </p>
                <CalendarModalTrigger setDate={setEndDate} />
              </div>
            </div>
            <div className="flex">
              <label className=" font-normal text-lg font-ibm ">End time</label>
              <div className="ml-32">
                <Dropdown
                  minOffset={StartHour}
                  maxOffset={23}
                  text={"Hrs"}
                  selectedValue={EndHour}
                  setSelectedValue={setEndHour}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={Number(StartHour)===Number(EndHour) ? Number(StartMinute)+1 : 0}
                  maxOffset={59}
                  text={"Mins"}
                  selectedValue={EndMinute}
                  setSelectedValue={setEndMinute}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={0}
                  maxOffset={59}
                  text={"Sec"}
                  selectedValue={EndSecond}
                  setSelectedValue={setEndSecond}
                />
              </div>
            </div>
            <ToggleYesNo
              title={"Is the meeting recurring?"}
              setToggleState={setToggleState}
              toggleState={toggleState}
              disable={enddate === "Select End Date"?false:true}
              mg={28}
            />
            {toggleState && (
              <Recurring
                date={recurringDate}
                setDate={setrecurringDate}
                type={type}
                setType={setType}
                Endsvalue={Endsvalue}
                setEndsValue={setEndsValue}
                filters={filters}
                handleFilters={handleFilters}
                repeat={repeat}
                setRepeat={setRepeat}
                repeatValue={repeatValue}
                setRepeatValue={setRepeatValue}
                startDate={date}
                Checked={Checked}
                setChecked={setChecked}
              />
            )}
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Venue
                <input
                  type="text"
                  name="eventName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={Venue}
                  onChange={e => setVenue(e.target.value)}
                />
              </label>
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Type of Event
                <input
                  type="text"
                  name="eventName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={TypeEvent}
                  onChange={e => setTypeEvent(e.target.value)}
                />
              </label>
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                CTA Name
                <input
                  type="text"
                  name="eventName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={CTAName}
                  onChange={e => setCTAName(e.target.value)}
                />
              </label>
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                URL to CTA
                <input
                  name="eventName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={URLtoCTA}
                  onChange={e => setURLtoCTA(e.target.value)}
                />
              </label>
            </div>
            <div className="flex">
              <>
                <label className=" font-normal text-lg font-ibm ">
                  Publish time
                </label>
                <div className="ml-32">
                  <Dropdown
                    minOffset={0}
                    maxOffset={23}
                    text={"Hrs"}
                    selectedValue={PublishHour}
                    setSelectedValue={setPublishHour}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Mins"}
                    selectedValue={PublishMinute}
                    setSelectedValue={setPublishMinute}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Sec"}
                    selectedValue={PublishSecond}
                    setSelectedValue={setPublishSecond}
                  />
                </div>
              </>
            </div>
            <div className="flex space-x-32">
              <label className=" font-normal text-lg font-ibm ">
                Countdown Date
              </label>

              <div className="relative w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                {countdownDate !== "Select Date" && (
                  <span
                    className="absolute bottom-[5px] text-xs font-medium cursor-pointer left-1/2 text-gray-500"
                    onClick={() => setCountdownDate("Select Date")}
                  >
                    &#10005;
                  </span>
                )}
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    date
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"
                  }`}>
                  {countdownDate && countdownDate}
                </p>
                <CalendarModalTrigger setDate={setCountdownDate} />
              </div>
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Text to Display
                <input
                  type="text"
                  name="eventName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={TexttoDisplay}
                  onChange={e => setTexttoDisplay(e.target.value)}
                />
              </label>
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Author Name
                <input
                  type="text"
                  name="eventName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={AuthorName}
                  onChange={e => setAuthorName(e.target.value)}
                />
              </label>
            </div>
          </div>
          <div className=" space-x-6 absolute left-[40%] -bottom-16">
            <button
              className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              type="submit">
              SAVE
            </button>
            <button onClick={history.goBack} className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
              EXIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
AddNotice.propTypes = {
  closeModal: propTypes.func,
  setIsCalendarOpen: propTypes.func,
};

export default AddNotice;
