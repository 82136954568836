import React,{ useState} from "react";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import Header from "../../header/Header";
import Toggle from "../../global/fields/Toggle";
import {  useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useSelector } from "react-redux";
const AddCategory = () => {
  const history = useHistory();
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  const [activeTab,setActiveTab]=useState("active");
  let isActive = activeTab ==="active" ? "1" : "0";
  const [categoryInfo, setCategoryInfo] = useState({});

  const handleChange = (e) => {
    let variable  = e.target.value ;
    if(variable[variable?.length-2] === " "){
      setCategoryInfo({...categoryInfo,[e.target.name] : variable.trim()});
    }else{
      setCategoryInfo({...categoryInfo,[e.target.name] : variable});
    }
  };

  const fetch_addCategory_API = () => {
    return apirequest({
      url : `${endpoint.add_category.addr}`,
      method : "post",
      data : {
        "categoryName": categoryInfo.categoryName,
        "description": categoryInfo.categoryDescription,
        "isActive": isActive,
        "createdBy": login_Details.userImsPin,
      }
    });
  };
  const {refetch:refetch_addCategory,error} = useQuery(`${endpoint.add_category.key}`,fetch_addCategory_API,{
    enabled:false,
    onSuccess: (data) => {
      alert(data?.data.message);
      window.location.reload(false);
    },
    onError: () => {
      alert(error);
    },
  });
  // console.log(Object.keys(categoryInfo).length> 0);
  const handleSubmit = (e) => {
    e.preventDefault();
    if((Object.keys(categoryInfo).length> 0 && ((categoryInfo["categoryName"]  === (" " || "") || categoryInfo["categoryDescription"] === (" " || ""))))){
      alert("All Fields are Mandatory");
    }else{
      refetch_addCategory();
    }
  };
  return (
    <div>
      <Header />
      <div className="mt-6 ml-16">
        <BreadCrumb />
      </div>
      <div className="pb-[5rem]">
        <div className="courseTable__body md:ml-[6%] md:mr-[12%] mt-4 shadow-outer py-12 px-8 rounded-xl bg-white pb-[2%]">
          <form className="flex flex-col justify-start font-ibm" id="categoryForm" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="inline-block w-[10%] text-left whitespace-nowrap mr-14 font-normal font-ibm text-lg mt-3">Category Name </label>
              <input className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                type="text" 
                name= "categoryName"
                placeholder="Type category name"
                value = {categoryInfo?.categoryName ?? ""}
                onChange={handleChange} 
                required/>        
            </div>
            <div className="flex mb-4">
              <label className="inline-block w-[10%] text-left whitespace-nowrap mr-14 font-normal font-ibm text-lg mt-3">Category Description </label>
              <textarea className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none resize-none w-7/12 focus:placeholder-transparent placeholder-bold font-semibold" 
                rows="4" cols="50" 
                name="categoryDescription"
                onChange={handleChange}
                placeholder="Enter a short description about the Category" 
                value = {categoryInfo?.categoryDescription ?? ""}
                required/>        
            </div>
            <Toggle activeTab={activeTab} title={"This Course is"} setActiveTab={setActiveTab}/>
          </form>
        </div>
        <div className="flex items-center justify-center mt-7 pr-20">
          <button type="submit" form="categoryForm" className="bg-primary-200 text-white font-ibm px-12 rounded-xl hover:opacity-75 shadow-outer py-[10px] text-base font-medium mr-10 cursor-pointer">Save</button>
          <button  className="text-black boxShadow-forButton font-ibm px-10 rounded-xl hover:opacity-75 shadow-outer text-base font-medium py-[10px] cursor-pointer" onClick={history.goBack}>Exit</button>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;