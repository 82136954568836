import React from "react";
import propTypes from "prop-types";

export const SearchDropBox = ({name,setInputValue}) => {
  const handleSelect =(val)=>{
    setInputValue(val);
  };
  return (
    <li
      className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize"
      onClick={() => handleSelect(name)}
    >
      {name}
    </li>
  );
};
SearchDropBox.propTypes = {
  inputValue: propTypes.string,
  name : propTypes.string,
  setInputValue : propTypes.func,
  data : propTypes.array,
  index : propTypes.number
};
