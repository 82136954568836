import React, { useState } from "react";
import BreadCrumb from "../global/breadcrumbs/BreadCrumb";
import Header from "../header/Header";
import Toggle from "../../components/global/fields/ToggleYesNo";
import DropDown from "../../components/global/fields/CourseDropDown";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CourseDropDown from "../global/fields/CourseDropDownId";

const UpdateCentre = () => {
  const CentreData = useSelector(state => state.slideshowData.slideShowDetails);
  let history = useHistory();
  const [courseTags, setCourseTags] = useState([]);
  const [showLabel, setShowLabel] = useState(CentreData.courses ? CentreData.courses.map(a => a.courseName) : [],);
  const [courses, setCourses] = useState(CentreData.courses ? CentreData.courses: [],);
  const [courseId, setCourseId] = useState(
    CentreData.courses ? CentreData.courses.map(a => a.courseId) : [],
  );
  const [showLabelwithId,setShowLabelwithId]=useState(CentreData.courses ? CentreData.courses: [],);
  const [centreData, setCentreData] = useState({
    centreName: CentreData.centreName,
    centreCode: CentreData.centreCode,
    zipCode: CentreData.zipCode,
    mobile: CentreData.mobileNumber,
    emailId: CentreData.emailId,
    address: CentreData.address,
    description: CentreData.description,
    // salesforceId:CentreData.sfId,
  });

  const stateId = useSelector(state => state.CourseId.stateCode);
  const login_Details = useSelector(state => state?.loginData?.LoginDetails);
  const CityId = useSelector(state => state.CourseId.cityId);
  const [activeTabForPublish, setActiveTabforPublish] = useState(CentreData.isActive==="1"?true:false);
  // States for getting the unique items for city , centre=========>so on
  const [valueCountry, setValueCountry] = useState(CentreData?.country[0]?.countryName);
  const [value, setValue] = useState(CentreData?.state[0]?.stateName);
  const [valueCity, setValueCity] = useState(CentreData?.city[0]?.cityName);
  //API request to get course data
  const { data: getCourseData,isSuccess} = useQuery(endpoint.course_data.key, ()=>apirequest({
    url : `${endpoint.course_data.addr}`,
    method : "post",
    data :{
      "adminimspin":login_Details.userImsPin
    }
  }),{
    refetchOnMount : true
  });
  // api for getting country details ===>
  const { data: getCountryData } = useQuery(`${endpoint.get_country.key}`, () =>
    apirequest({
      url: `${endpoint.get_country.addr}`,
    }),
  );
  // api for getting state details ===>
  const { data: getStatesData } = useQuery(
    `${endpoint.getStatesData.key}`,
    () =>
      apirequest({
        url: `${endpoint.getStatesData.addr}`,
      }),
  );
  // api for updating centre
  const fetchUpdateCentre = () => {
    return apirequest({
      url: `${endpoint.update_centre.addr}`,
      method: "put",
      data: {
        centreName: centreData.centreName,
        centreCode: centreData.centreCode,
        countryId: "1",
        stateId: stateId===""?CentreData.state[0].stateId:stateId,
        cityId: CityId===""?CentreData.city[0].cityId:CityId,
        zipCode: centreData.zipCode,
        emailId: centreData.emailId,
        mobileNumber: centreData.mobile,
        address: centreData.address,
        description: centreData.description,
        isActive: activeTabForPublish == true ? "1" : "0",
        modifiedBy: login_Details.userImsPin,
        centreId : CentreData.centreId,
        // sfId: centreData.salesforceId,
        courseId: showLabelwithId.map(x=>x.courseId),
      },
    });
  };

  //API request to update centre data
  const { refetch: addCategoryRefetch } = useQuery(
    endpoint.update_centre.key,
    fetchUpdateCentre,
    {
      enabled: false,
      onSuccess :(res)=>alert(res.data.message),
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );

  const handleSubmit = event => {
    event.preventDefault();
    addCategoryRefetch();
  };
  const handleChange = e => {
    setCentreData({
      ...centreData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="pb-9">
      <Header />
      <div className="mt-6 ml-2">
        <BreadCrumb />
      </div>
      <form>
        <div className="font-ibm flex justify-center mt-7">
          <div className="space-y-5 w-[93%] shadow-outer rounded-2xl p-10">
            <div className="flex space-x-5 mr-20">
              <div className="space-x-20 flex">
                <label className="font-normal text-lg leading-[40px]">
                  Centre Name
                </label>
                <input
                  className="border-2 h-10 border-secondary-300   w-80 rounded-lg focus:outline-none"
                  name="centreName"
                  value={centreData.centreName}
                  onChange={handleChange}
                />
              </div>
              <div className="flex space-x-5">
                <label className="font-normal text-lg mr-6 leading-[40px]">
                  Centre Code
                </label>
                <input
                  className="border-2 h-10 border-secondary-300   w-80 rounded-lg focus:outline-none"
                  name="centreCode"
                  value={centreData.centreCode}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex space-x-12 mr-20">
              <div className="space-x-[100px] flex">
                <label className="font-normal text-lg leading-[40px]">
                  Country
                </label>
                {getCountryData && getCountryData.data.data && (
                  <DropDown
                    placeholder={"Search or Create new Group"}
                    options={getCountryData.data.data}
                    type={"singleSelect"}
                    width={"w-[20rem]"}
                    readonly={false}
                    value={valueCountry}
                    setValue={setValueCountry}
                  />
                )}
              </div>
              <div className="flex  space-x-[50px]">
                <label className="font-normal text-lg mr-6 leading-[40px]">
                  State
                </label>
                {getStatesData && getStatesData.data.data && (
                  <DropDown
                    placeholder={"Search or Create new Group"}
                    options={getStatesData.data.data.stateDetails}
                    type={"singleSelect"}
                    width={"w-[20rem]"}
                    readonly={false}
                    value={value}
                    setValue={setValue}
                  />
                )}
              </div>
            </div>
            <div className="flex space-x-12 mr-20">
              <div className="space-x-[130px] flex">
                <label className="font-normal text-lg leading-[40px]">
                  City
                </label>
                {getStatesData && getStatesData.data.data && (
                  <DropDown
                    placeholder={"Search or Create new Group"}
                    options={
                      stateId !== ""
                        ? getStatesData?.data.data.cityDetails
                          .filter(state => state.stateId === stateId)
                          .reduce((acc, val) => acc.concat(val), [])
                        : getStatesData?.data.data.cityDetails
                    }
                    type={"singleSelect"}
                    width={"w-[20rem]"}
                    readonly={false}
                    value={valueCity}
                    setValue={setValueCity}
                  />
                )}
              </div>
              <div className="flex flex-row space-x-12">
                <label className="font-normal text-lg mr-6 leading-[40px]">
                  Zipcode
                </label>
                <input
                  className="border-2 h-10 border-secondary-300   w-80 rounded-lg focus:outline-none"
                  name="zipCode"
                  value={centreData.zipCode}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex space-x-9">
              <div className="space-x-28 flex">
                <label className="font-normal text-lg leading-[40px]">
                  Email Id
                </label>
                <input
                  className="border-2 h-10 border-secondary-300   w-80 rounded-lg focus:outline-none"
                  name="emailId"
                  value={centreData.emailId}
                  onChange={handleChange}
                />
              </div>
              <div className="flex space-x-20">
                <label className="font-normal text-lg mt-2 leading-[40px]">
                  Mobile
                </label>
                <input
                  className="border-2 h-10 border-secondary-300   w-80 rounded-lg focus:outline-none"
                  name="mobile"
                  value={centreData.mobile}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex space-x-5 mr-[77px]">
              <div className="space-x-[120px] flex">
                <label className="font-normal text-lg leading-[40px]">
                  Address
                </label>
                <textarea
                  className="ml-5 bg-white border-2 border-secondary-300 h-36 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-80 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                  name="address"
                  onChange={handleChange}
                  value={centreData.address}
                />
              </div>
              <div className="flex flex-row space-x-6">
                <label className="font-normal text-lg mr-6 leading-[40px]">
                  Description
                </label>
                <textarea
                  className="ml-5 bg-white border-2 border-secondary-300 h-36 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-80 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                  name="description"
                  onChange={handleChange}
                  value={centreData.description}
                />
              </div>
            </div>
            <div className="flex space-x-44 mr-[77px]">
              <Toggle
                title="Branch Active"
                toggleState={activeTabForPublish}
                setToggleState={setActiveTabforPublish}
                mg="mr-[100px]"
              />
              {/*As Backend team asked not to show and send SFId in payload  */}

              {/*<div className="flex space-x-6 py-2">
                <label className=" font-normal text-lg font-ibm ">
                Sales Force ID
                </label>
                <input
                  required
                  name="salesforceId"
                  className="border-2 h-10 border-secondary-300 rounded-lg focus:outline-none w-80"
                  value={centreData.salesforceId}
                  onChange={handleChange}
                />
              
                  </div>*/}
            </div>
            <div className="flex py-5 space-x-14">
              <label className=" font-normal text-lg font-ibm ">Course</label>
              {isSuccess &&
              <CourseDropDown
                placeholder={"Search Course(s)"}
                options={getCourseData?.data?.data?.courseList}
                setUpdatedOptions={setCourseTags}
                type={"multiselect"}
                width={"w-[22rem]"}
                courseTags={courseTags}
                showLabel={showLabel}
                setShowLabel={setShowLabel}
                value={courses}
                setValue={setCourses}
                courseId={courseId}
                setCourseId={setCourseId}
                showLabelwithId={showLabelwithId}
                setShowLabelwithId={setShowLabelwithId}
              />}
            </div>
          </div>
        </div>
      </form>
      <div className="flex justify-center mt-5 space-x-5 mb-5">
        <button
          className="px-10 py-3 bg-primary-200 text-white rounded-2xl shadow-outer"
          onClick={handleSubmit}>
          Save
        </button>
        <button
          className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer"
          onClick={history.goBack}>
          Exit
        </button>
      </div>
    </div>
  );
};

export default UpdateCentre;
