import propTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import arrowDown from "../../assets/icons/arrow-down.svg";
import arrowUp from "../../assets/icons/arrow-up.svg";
import tickMarkimg from "../../assets/icons/tick-circle-active.svg";
import Icon from "../global/icons/Icon";
import CentreCheckBoxItem from "./CentreCheckbox";

const CityCentreTreeNode = ({ data, forProfileModule, newArr, setNewArr }) => {
  const [isChildVisible, setIsChildVisible] = useState(false);
  let hasChild = data.centres?.length > 0;

  // since the course_id and product group are diff .. have get the data from redux and access in line 57
  const centreValues = useSelector(
    (state) =>
      state?.accessManagementUserData.access_management_UserData.centreAccess
  );
  return (
    <div className="px-7 ">
      <div className="flex justify-between">
        <aside className="flex space-x-5 space-y-5 text-center ">
          {hasChild && (
            <div onClick={() => setIsChildVisible(!isChildVisible)}>
              {" "}
              <Icon
                src={isChildVisible ? arrowUp : arrowDown}
                className="rounded-full shadow-outer cursor-pointer  w-10 h-10"
              />
            </div>
          )}
          <div
            className={`font-normal text-lg align-middle ${
              hasChild ? "relative bottom-3" : "ml-[58px] mb-4"
            }`}
          >
            {data.city_name}
          </div>
        </aside>
        {/* <CheckBox id= "2" /> */}
        {/* <div className="flex space-x-11">
          {!forProfileModule ? (
            <>
              <CheckBoxItem
                item={data}
                id="1"
                newArr={newArr}
                key={data.city_id}
                // forEditUser={forEditUser}
                setNewArr={setNewArr}
              />
            </>
          ) : (
            <div className="flex space-x-12 mt-1">
              {
                <img
                  className="w-6 h-6"
                  src={
                    access_FOR_city_centre?.viewAccess === "1"
                      ? tickMarkimg
                      : null
                  }
                />
              }
              {
                <img
                  className="w-6 h-6"
                  src={
                    access_FOR_city_centre?.editAccess === "1"
                      ? tickMarkimg
                      : null
                  }
                />
              }
              {
                <img
                  className="w-6 h-6"
                  src={
                    access_FOR_city_centre?.deleteAccess === "1"
                      ? tickMarkimg
                      : null
                  }
                />
              }
            </div>
          )}
        </div> */}
      </div>
      {hasChild && isChildVisible && (
        <div className="mb-3 space-y-3 ">
          {data?.centres.map((item, index) => {
            const arrayToObject = (arr) => {
              if (forProfileModule) {
                const res = {};
                for (let i = 0; i < arr?.length; i++) {
                  const key = arr[i]["centre_id"];
                  res[key] = arr[i];
                }
                return res;
              }
            };
            let userAccess = forProfileModule && arrayToObject(centreValues);

            let access_For_Centre =
              forProfileModule && userAccess[item.centre_id];

            return (
              <div key={item.centre_id} className="flex justify-between">
                <div
                  key={item.centre_id}
                  className="font-normal text-lg ml-20 "
                >
                  {item.centre_name}
                </div>
                <div className="flex space-x-11 mb-1" key={index}>
                  {!forProfileModule ? (
                    <>
                      <CentreCheckBoxItem
                        item={item}
                        id="2"
                        key={index}
                        newArr={newArr}
                        setNewArr={setNewArr}
                      />
                    </>
                  ) : (
                    <div className="flex space-x-12 mt-1">
                      {
                        <img
                          className="w-6 h-6"
                          src={
                            access_For_Centre?.viewAccess === "1" && tickMarkimg
                          }
                        />
                      }
                      {
                        <img
                          className="w-6 h-6"
                          src={
                            access_For_Centre?.editAccess === "1" && tickMarkimg
                          }
                        />
                      }
                      {
                        <img
                          className="w-6 h-6"
                          src={
                            access_For_Centre?.deleteAccess === "1" &&
                            tickMarkimg
                          }
                        />
                      }
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

CityCentreTreeNode.propTypes = {
  data: propTypes.any,
  forProfileModule: propTypes.bool,
  accessDetails: propTypes.object,
  newArr: propTypes.array,
  setNewArr: propTypes.func,
  forEditUser: propTypes.bool,
};

export default CityCentreTreeNode;
