import React from "react";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import Header from "../../header/Header";
import FilterFunctionlity from "../../filterFunctionality/FilterFunctionlity";
import TableSearch from "../../table/TableSearch";

import GreyPill from "../../global/Tags/GreyPill";
import TablePagination from "../../table/TablePagination";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config";
import { apirequest } from "../../../API/api";
import editIcon from "../../../assets/icons/submitted-no-bg.svg";
import { useDispatch, useSelector } from "react-redux";
// import { setarticlesData } from "../../../redux/features/article/articleSlice";
import { seteventSchoolData } from "../../../redux/features/eventsForSchool/eventSchoolSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import deleteIcon from "../../../assets/icons/delete-inactive.svg";
import { addEditEventApiCall } from "../apiCalls/reusableApiCalls";
import { useEffect } from "react";
import Loader from "../../global/loader/Loader";

const Events = () => {
  const [eventsData, setEventsData] = useState({});
  const dispatch = useDispatch(); 
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);   //selector to get the login_Details from redux
  const history = useHistory();
  const { isSuccess, isError , isLoading ,data: eventDefaultData } = useQuery(  //api call to fetch the events  
    endpoint.getEventsDataforSchools.key,
    () =>
      apirequest({
        method: "post",
        url: endpoint.getEventsDataforSchools.addr,
        data : {
          adminimspin : login_Details.userImsPin
        }
      }),
    {
      onSuccess: (res) => setEventsData(res),
      refetchOnMount : true
    }
  );

  const [currentPage, setCurrentPage] = useState(1);  
  //logic for pagination functionality and show the data length as per figma   
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;

  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };

  const handleClick = (item) => {
    dispatch(seteventSchoolData(item));  //dispatch to store the value to use in Edit Page
    history.push("/School/EditEvents");
  };


  const [payloadData, setPayloadObj] = useState({});

  const { refetch } = addEditEventApiCall({ payloadData });  //api call to delete the particular data

  const handleDelete = (item) => {
    const {schools , courses} = item;
    setPayloadObj({
      ...item,
      isActive: "0",
      modifiedBy: login_Details.userImsPin,
      requestType : "Delete",
      schoolId : schools.map(item=>item.schoolId).flat(),
      courseId : courses.map(item=>item.courseId).flat()
    });
  };

  useEffect(() => {
    Object.keys(payloadData).length ? refetch() : null;
  }, [payloadData]);

  return (
    <div className="">
      <Header />
      {isLoading && <Loader />}
      {isError && (
        <span className="flex justify-center text-red-700">
          No Record found
        </span>
      )}
      <div>
        <div className="p-5 space-y-5 font-ibm">
          <header className="flex justify-between">
            <BreadCrumb />
            <Link
              to="/School/AddEvents"
              className="px-8 py-2 text-white bg-primary-300 rounded-2xl cursor-pointer shadow-outer"
            >
              Add
            </Link>
          </header>

          <section className="flex justify-between">
            <FilterFunctionlity />
            <TableSearch 
              defaultData={eventDefaultData}
              setDefaultData={setEventsData}
              dataKey="eventTitle"
              dataForFilter={eventDefaultData?.data.data}
              name = "School"
              subModuleName="Events"
              childModuleName=""
              placeholder="Search by Events"
              setCurrentPage={setCurrentPage}
            />
          </section>
          {isSuccess && (
            <>
              <section className="p-8">
                {eventsData?.data?.data?.filter(item=>item.isActive !=0).length >0 ? eventsData ?.data?.data?.filter(item=>item.isActive !=0)
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((item) => (
                    <section
                      key={item.id}
                      className="flex items-center justify-between mb-3 space-x-3"
                    >
                      <div className="flex justify-start w-[80%] space-x-3">
                        <img src={item.thumbnailLink} className="w-28 h-24"/>
                        <div className="space-y-4">
                          <p className="font-normal text-lg">{item.eventTitle}</p>
                          <div className="flex space-x-3">
                            <GreyPill tagName={item.date} />
                            <GreyPill tagName={item.tags.join(", ")} />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end space-x-4 items-center">
                        <img
                          className=" cursor-pointer"
                          src={editIcon}
                          onClick={() => handleClick(item)}
                        />
                        <img
                          className="cursor-pointer "
                          src={deleteIcon}
                          onClick={() => handleDelete(item)}
                        />
                      </div>
                    </section>
                  )) : <p className="text-base text-red-600 flex justify-center">No Record Found</p>}
              </section>
              <footer>
                <TablePagination
                  data={eventsData?.data?.data}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  paginate={paginate}
                  dataPerPage={dataPerPage}
                />
              </footer>
            </>
          )}
          
        </div>
        
      </div>
    </div>
  );
};

export default Events;
