import React, { useState } from "react";
import Header from "../../header/Header";
import ToggleSchedule from "./ToggleSchedule";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import Dropdown from "../blogs/Dropdown";
import Toggle from "../../global/Toggle";
import CalendarModalTrigger from "../blogs/calendar/CalendarModalTrigger";
import propTypes from "prop-types";
import DropDown from "../../global/fields/CourseDropDown";
import VideoDropdown from "../../global/fields/CourseDropDown";
import { apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UploadFileToAWS } from "../../../AWS/uploadFile";
import WhiteSpace from "../../global/regEx/WhiteSpaceValidator";

const options = [
  {
    courseId: "1",
    courseName: "Vimeo",
  },
  {
    courseId: "2",
    courseName: "YouTube",
  },
  {
    courseId: "3",
    courseName: "S3",
  },
];

const EditSlideShow = () => {
  let history = useHistory();
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  const slideshowdetails = useSelector(
    (state) => state.slideshowData.slideShowDetails
  );
  let setTime = slideshowdetails?.startTime?.split(":");
  let endTime = slideshowdetails?.endTime?.split(":");
  const [SlideShowdata, setSlideShowdata] = useState({
    slideShowName: slideshowdetails.slideShowName,
    imageUrl: slideshowdetails.imageUrl,
    hyperLink: slideshowdetails.hyperLink,
    videoLink: slideshowdetails.videoLink,
  });
  const [Videovalue, setVideoValue] = useState(
    slideshowdetails.videoType !== "" ? slideshowdetails.videoType : ""
  );
  const [type, setType] = useState(slideshowdetails.type);
  const [date, setDate] = useState(slideshowdetails.startDate);
  const [enddate, setEndDate] = useState(slideshowdetails.endDate);
  const [activeTabPublish, setActiveTabPublish] = useState(
    slideshowdetails.publishStatus == "Published" ? "now" : "schedule"
  );
  const [activeTab, setActiveTab] = useState(
    slideshowdetails.isActive == "0" ? "inactive" : "active"
  );
  const [showLabel, setShowLabel] = useState(
    slideshowdetails.courseNames ? slideshowdetails.courseNames : []
  );
  const [courseTags, setCourseTags] = useState(
    slideshowdetails.courseNames ? slideshowdetails.courseNames : []
  );
  const [browseLink, setBrowseLink] = useState(slideshowdetails.imageUrl);
  const onValueChange = (event) => {
    setType(event.target.value);
  };
  const [courses, setCourses] = useState(
    slideshowdetails.courseNames ? slideshowdetails.courseNames : ""
  );
  const [videoType, setVideoType] = useState(
    slideshowdetails.videoType ? slideshowdetails.videoType : ""
  );
  const [StartHour, setStartHour] = useState(setTime[0] ?? 0);
  const [StartMinute, setStartMinute] = useState(setTime[1].replace(/^0/, ""));
  const [StartSecond, setStartSecond] = useState(setTime[2].replace(/^0/, ""));
  const [EndHour, setEndHour] = useState(endTime[0] ?? 0);
  const [EndMinute, setEndMinute] = useState(endTime[1].replace(/^0/, ""));
  const [EndSecond, setEndSecond] = useState(endTime[2].replace(/^0/, ""));
  // const timeStart=StartHour+StartMinute+StartSecond;
  // const timeEnd=EndHour+EndMinute+EndSecond;

  //to get the current date
  // let today = new Date();
  // let dd = String(today.getDate()).padStart(2, "0");
  // let mm = String(today.getMonth() + 1).padStart(2, "0");
  // let yyyy = today.getFullYear();
  // today = yyyy + "-" + mm + "-" + dd ;
  // // to get the current time
  // let todayDate = new Date();
  // const currentHour = todayDate.getHours()  ;
  // const currentMinute = todayDate.getMinutes();

  // api to get courses data
  const { data: getCourseData } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method: "post",
      data: {
        adminimspin: login_Details.userImsPin,
      },
    })
  );
  // api to update slideshow

  const fetchUpdateSlideShow = () => {
    return apirequest({
      url: `${endpoint.update_slideshow.addr}`,
      method: "put",
      data: {
        slideShowName: SlideShowdata.slideShowName,
        type: type,
        imageUrl: browseLink,
        hyperLink: SlideShowdata.hyperLink,
        videoType: type === "Video" ? Videovalue : "",
        videoLink: type === "Video" ? SlideShowdata.videoLink : "",
        isActive: activeTab == "active" ? "1" : "0",
        startDate: activeTabPublish == "now" ? "" : date,
        startTime:
          activeTabPublish == "now"
            ? ""
            : `${StartHour}:${StartMinute}:${StartSecond}`,
        endDate: enddate,
        endTime: `${EndHour}:${EndMinute}:${EndSecond}`,
        modifiedBy: login_Details.userImsPin,
        courseName: showLabel,
        slideShowId: slideshowdetails.slideShowId,
      },
    });
  };

  //API request to update slideshow data
  const { refetch: updateSlideShowRefetch } = useQuery(
    endpoint.update_slideshow.key,
    fetchUpdateSlideShow,
    {
      enabled: false,
      onSuccess: (res) => alert(res.data.message),
      retry: 0,
      onError: (err) => {
        alert(err.data.message);
      },
    }
  );
  const HandleChange = (e) => {
    setSlideShowdata({ ...SlideShowdata, [e.target.name]: e.target.value });
  };
  const onsave = (e) => {
    if (activeTabPublish === "schedule") {
      // if((Number(today.replace(/-/g, "")))>Number(date.replace(/-/g, ""))){
      //   alert("Publish Date can't be Past days");
      // }
      if (Number(date.replace(/-/g, "")) > Number(enddate.replace(/-/g, ""))) {
        alert("please select End Date after Start Date");
      } else if (date === "Select Date") {
        alert("Please select start date");
      } else if (enddate === "Select Date") {
        {
          alert("Please select end date");
        }
      } else if (browseLink === "") {
        alert("Please select browse link to Upload Image");
      }
      // to show the alert message if start date is today and start time is before current system's timings
      // else if((Number(date.replace(/-/g, "")))===Number(today.replace(/-/g, ""))){
      //   if(Number(StartHour)<currentHour){
      //     alert("please select start time after current time");
      //   }
      //   else if(Number(StartHour)===currentHour){
      //     if(Number(StartMinute)<currentMinute)
      //       alert("please select start time after current time");
      //     else{
      //       updateSlideShowRefetch();
      //     }
      //   }
      //   else{
      //     updateSlideShowRefetch();
      //   }
      // }
      else if (WhiteSpace(SlideShowdata.slideShowName) === true) {
        alert("Please enter a Valid Slideshow Name");
      }
      // to make course selection mandatory
      else if (showLabel.length === 0) {
        alert("Please Select Course");
      } else if (type === "Image") {
        // if(WhiteSpace(SlideShowdata.hyperLink)===true){
        // alert("Please enter a Valid Hyperlink");
        // }
        // else
        updateSlideShowRefetch();
      } else if (type === "Video") {
        if (WhiteSpace(SlideShowdata.videoLink) === true) {
          alert("Please enter a Valid videolink");
        } else updateSlideShowRefetch();
      } else {
        updateSlideShowRefetch();
      }
    }
    // to show the alert message if end date is today and end time is before current system's timings
    // else if((Number(enddate.replace(/-/g, "")))===Number(today.replace(/-/g, ""))){
    //   if(Number(EndHour)<currentHour){
    //     alert("please select end time after current time");
    //   }
    //   else if(Number(EndHour)===currentHour){
    //     if(Number(EndMinute)<currentMinute)
    //       alert("please select end time after current time");
    //     else{
    //       updateSlideShowRefetch();
    //     }
    //   }
    //   else{
    //     updateSlideShowRefetch();
    //   }
    // }
    else if (browseLink === "") {
      alert("Please select browse link to Upload Image");
    } else if (enddate === "Select Date") {
      alert("Please select end date");
    } else if (WhiteSpace(SlideShowdata.slideShowName) === true) {
      alert("Please enter a Valid Slideshow Name");
    }
    // to make course selection mandatory
    else if (showLabel.length === 0) {
      alert("Please Select Course");
    } else if (type === "Image") {
      // if (WhiteSpace(SlideShowdata.hyperLink) === true) {
      // alert("Please enter a Valid Hyperlink");
      // } else
      updateSlideShowRefetch();
    } else if (type === "Video") {
      if (WhiteSpace(SlideShowdata.videoLink) === true) {
        alert("Please enter a Valid videolink");
      } else updateSlideShowRefetch();
    } else {
      updateSlideShowRefetch();
    }
    e.preventDefault();
  };
  const handleBrowse = (event) => {
    event.preventDefault();
    setBrowseLink(event.target?.files[0].name);
    const fileDate = new Date()
      .toLocaleString({ hour12: true })
      .toString()
      .split("/");
    if (event.target?.files[0]) {
      const val = UploadFileToAWS({
        key: "editSlideShowUpload",
        file: event.target?.files[0],
        path:
          "Asset/EditSlideShow/" +
          fileDate[2].toString().split(",")[0] +
          "/" +
          event.target?.files[0].name.toString().split(".")[0] +
          "_" +
          login_Details.userImsPin +
          "_" +
          fileDate[2].toString().split(/[, ]+/)[1] +
          "." +
          event.target?.files[0].name.toString().split(".")[1],
      });
      {
        val.then((item) => setBrowseLink(item));
      }
    } else {
      alert("No files available!");
    }
  };
  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />

      <div className="mt-2 mx-12  px-8 py-2 ">
        <form className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer">
          <div className="h-full space-y-6  px-12 py-6 relative">
            <div className="flex  py-5 space-x-10">
              <label className=" font-normal text-lg font-ibm ">
                SlideShow Name
              </label>
              <input
                type="text"
                name="slideShowName"
                className="border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg focus:outline-none"
                value={SlideShowdata.slideShowName}
                onChange={HandleChange}
              />
            </div>
            <div className="flex space-x-12">
              <label className="font-normal text-lg font-ibm">
                Slide Show Type
              </label>
              <div className="flex">
                <div className="radio">
                  <label className="font-normal text-lg font-ibm">
                    <input
                      type="radio"
                      value="Image"
                      checked={type === "Image"}
                      onChange={onValueChange}
                    />
                    Image
                  </label>
                </div>
                {/* Todo : for Video Radio Button */}
                {/* <div className="">
                  <label className="font-normal text-lg font-ibm">
                    <input
                      type="radio"
                      value="Video"
                      checked={type === "Video"}
                      onChange={onValueChange}
                    />
                    Video
                  </label>
                </div> */}
              </div>
            </div>
            {type === "Video" && (
              <div className="flex space-x-20 pt-6">
                <label className="font-normal text-lg font-ibm">
                  Video Type
                </label>
                {/* to handle the videoDropdown value */}
                <VideoDropdown
                  isVideoDropDown={true}
                  value={videoType}
                  setValue={setVideoType}
                  placeholder={"select video type"}
                  options={options}
                  setUpdatedOptions={setVideoValue}
                  type={"singleSelect"}
                  width={"w-[22rem]"}
                  setFieldValue={setVideoValue}
                />
              </div>
            )}

            <div className="flex space-x-10">
              {type === "Image" && (
                <>
                  <label className=" font-normal text-lg font-ibm py-5">
                    Upload Image
                  </label>
                  <input
                    type="text"
                    name="imageUrl"
                    className="focus:outline-none border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg top-48"
                    // Paste the image URL to Upload Image URL
                    placeholder=" Upload Image URL"
                    value={browseLink}
                    readOnly={true}
                  />

                  <label className="absolute right-36 top-44 bg-primary-200 px-10 ml-2 py-3 rounded-xl text-white text-sm font-semibold cursor-pointer font-ibm">
                    <input
                      className="hidden "
                      type="file"
                      multiple
                      onChange={handleBrowse}
                      accept=".webp"
                    />
                    Browse
                  </label>
                </>
              )}
            </div>
            <div className="flex  space-x-10 py-5">
              {type === "Image" && (
                <>
                  <label className=" font-normal text-lg font-ibm ">
                    Hyper Link <span className="text-gray-400">(optional)</span>
                  </label>
                  <input
                    type="text"
                    name="hyperLink"
                    className="focus:outline-none border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg"
                    value={SlideShowdata.hyperLink}
                    onChange={HandleChange}
                  />
                </>
              )}
              {type === "Video" && (
                <>
                  <label className=" font-normal text-lg font-ibm ">
                    Video Link
                  </label>
                  <input
                    type="text"
                    name="videoLink"
                    className="focus:outline-none border-2 h-10 border-secondary-300 absolute left-48 w-3/6 rounded-lg"
                    value={SlideShowdata.videoLink}
                    onChange={HandleChange}
                  />
                </>
              )}
            </div>
            <div className="flex py-2 space-x-24">
              <label className=" font-normal text-lg font-ibm ">Courses</label>
              <DropDown
                placeholder={"Search Course(s)"}
                options={getCourseData?.data?.data?.courseList}
                setUpdatedOptions={setCourseTags}
                type={"multiselect"}
                width={"w-[22rem]"}
                courseTags={courseTags}
                showLabel={showLabel}
                setShowLabel={setShowLabel}
                value={courses}
                setValue={setCourses}
                forCourses={true}
              />
            </div>
            <div className="space-x-20 ml">
              <Toggle
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                margin={32}
                title="Status"
              />
            </div>

            <div className="flex">
              <ToggleSchedule
                activeTab={activeTabPublish}
                setActiveTab={setActiveTabPublish}
                title="Publish"
                margin={32}
              />
              {activeTabPublish !== "now" && (
                <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                  <p
                    className={`w-full outline-none boder border-gray-200 ${
                      date
                        ? "text-base text-secondary-400  font-normal"
                        : "text-sm  text-primary-700"
                    }`}
                  >
                    {date && date}
                  </p>
                  <CalendarModalTrigger setDate={setDate} />
                </div>
              )}
            </div>
            {activeTabPublish !== "now" && (
              <div className="flex">
                <label className=" font-normal text-lg font-ibm ">
                  Start time
                </label>
                <div className="ml-28">
                  <Dropdown
                    minOffset={0}
                    maxOffset={23}
                    text={"Hrs"}
                    selectedValue={StartHour}
                    setSelectedValue={setStartHour}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Mins"}
                    selectedValue={StartMinute}
                    setSelectedValue={setStartMinute}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Sec"}
                    selectedValue={StartSecond}
                    setSelectedValue={setStartSecond}
                  />
                </div>
              </div>
            )}
            <div className="flex space-x-28">
              <label className=" font-normal text-lg font-ibm ">End Date</label>
              <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    enddate
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"
                  }`}
                >
                  {enddate && enddate}
                </p>
                <CalendarModalTrigger setDate={setEndDate} />
              </div>
            </div>
            <div className="flex">
              <label className=" font-normal text-lg font-ibm ">End time</label>
              <div className="ml-28">
                <Dropdown
                  minOffset={0}
                  maxOffset={23}
                  text={"Hrs"}
                  selectedValue={EndHour}
                  setSelectedValue={setEndHour}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={0}
                  maxOffset={59}
                  text={"Mins"}
                  selectedValue={EndMinute}
                  setSelectedValue={setEndMinute}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={0}
                  maxOffset={59}
                  text={"Sec"}
                  selectedValue={EndSecond}
                  setSelectedValue={setEndSecond}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="flex justify-center space-x-6 mt-2">
          <button
            className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            onClick={onsave}
            type="submit"
          >
            SAVE
          </button>
          <button
            onClick={history.goBack}
            className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          >
            EXIT
          </button>
        </div>
      </div>
    </div>
  );
};
EditSlideShow.propTypes = {
  closeModal: propTypes.func,
  setIsCalendarOpen: propTypes.func,
};

export default EditSlideShow;
