import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  slideShowName: "",
  slideShowId: "",
  slideShowDetails: "",
};

export const slideshowSlice = createSlice({
  name: "slideshowData",
  initialState,
  reducers: {
    getSlideshowDetails: (state, action) => {
      state.slideShowName = action.payload.slideShowName;
      state.slideShowId = action.payload.slideShowId;
      state.slideShowDetails = action.payload;
    },
  },
});

export const { getSlideshowDetails } = slideshowSlice.actions;

export const selectSlideshow = state => state.slideshowData.slideShowName;

export default slideshowSlice.reducer;
