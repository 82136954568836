import { useQuery } from "react-query";
import { apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";

export const addEditEventApiCall = ({ payloadData }) => {
  return useQuery(
    endpoint.addDeleteEditEvent.key,
    () =>
      apirequest({
        url: `${endpoint.addDeleteEditEvent.addr}`,
        method: "post",
        data: payloadData,
      }),
    {
      enabled: false,
      onSuccess: (res) => {
        alert(res.data.message);
      },
      onError: (err) => alert(err.data.message),
      retry: 1,
    }
  );
};

export const articlesApiCalls = ({ payloadData }) => {
  return useQuery(
    endpoint.addEditApiForArticles.key,
    () =>
      apirequest({
        url: `${endpoint.addEditApiForArticles.addr}`,
        method: "post",
        data: payloadData,
      }), 
    {
      enabled: false,
      onSuccess: (res) => {
        alert(res.data.message);
      },
      onError: (err) => alert(err.data.message),
      retry: 1,
    }
  );
};
