import React from "react";
import TreeDraggable from "../../../global/tree/TreeDraggable";
import propTypes from "prop-types";

const AddFolderTree = ({ setDisableSave, data }) => {
  
  return (
    <TreeDraggable
      data={data}
      count={1}
      depth={1}
      treeIndexes={[0]}
      setDisableSave={setDisableSave}
    />
  );
};
AddFolderTree.propTypes = {
  setDisableSave: propTypes.func,
  data: propTypes.array,
};
export default AddFolderTree;
