import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  schoolId: "",
  isEditing:false,
};

export const schoolsSlice = createSlice({
  name: "noticeData",
  initialState,
  reducers: {
    setSchoolId: (state, action) => {
      state.schoolId = action.payload;
    },
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    }
  },
});

export const { setSchoolId, setIsEditing } = schoolsSlice.actions;

export default schoolsSlice.reducer;
