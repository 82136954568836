import React, { useState } from "react";
import { EditorState, convertToRaw ,ContentState} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import propTypes from "prop-types";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
const TextEditor = ({customStyle,setGetEditorValue,setFieldValue}) => {
  const [editorState, setEditorState] = useState(
    () => {
      if (setFieldValue != null) {
        const blocksFromHTML = htmlToDraft(setFieldValue);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        return EditorState.createWithContent(contentState);  
      }else{
        return EditorState.createEmpty(); 
      }
    }
  );
  const editorStyle = {
    height:"10rem",
    padding:"1rem",
  };
  const handlechange = () => {
    setGetEditorValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  const uploadImageCallBack = (file) => {
    return new Promise(
      (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "https://api.imgur.com/3/image");
        xhr.setRequestHeader("Authorization", "Client-ID XXXXX");
        const data = new FormData();
        data.append("image", file);
        xhr.send(data);
        xhr.addEventListener("load", () => {
          const response = JSON.parse(xhr.responseText);
          resolve(response);
        });
        xhr.addEventListener("error", () => {
          const error = JSON.parse(xhr.responseText);
          reject(error);
        });
      }
    );
  };  
  return (
    <div className={customStyle && customStyle}>
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}  
        onChange={handlechange}  
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        editorStyle={editorStyle}  
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
        }}
      />
    </div>
  );
};
TextEditor.propTypes = {
  customStyle:propTypes.string,
  setGetEditorValue:propTypes.func,
  setFieldValue:propTypes.string,
};
export default TextEditor;