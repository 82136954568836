import React, { useState } from "react";
import propTypes from "prop-types";
import arrowUp from "../../assets/icons/arrow-up.svg";
import arrowDown from "../../assets/icons/arrow-down.svg";
import Icon from "../global/icons/Icon";
import tickMarkimg from "../../assets/icons/tick-circle-active.svg";
import { useSelector } from "react-redux";
import CheckBoxItem from "./CheckBoxItem";

const TreeNode = ({
  data,
  forProfileModule,
  accessDetails,
  newArr,
  setNewArr,
}) => {
  const [isChildVisible, setIsChildVisible] = useState(false);
  let hasChild = (data.subModules || data.productCourses)?.length > 0;

  let access_FOR_product_Group =
    forProfileModule && accessDetails[data.product_group_id];
  let access_FOR_product_Module =
    forProfileModule && accessDetails[data.product_module_id];

  // since the course_id and product group are diff .. have get the data from redux and access in line 57
  const courseValues = useSelector(
    (state) =>
      state?.accessManagementUserData.access_management_UserData
        .productGroupAccess
  );

  return (
    <div className="px-7 ">
      <div className="flex justify-between">
        <aside className="flex space-x-5 space-y-5 text-center ">
          {hasChild && (
            <div onClick={() => setIsChildVisible(!isChildVisible)}>
              {" "}
              <Icon
                src={isChildVisible ? arrowUp : arrowDown}
                className="rounded-full shadow-outer cursor-pointer  w-10 h-10"
              />
            </div>
          )}
          <div
            className={`font-normal text-lg align-middle ${
              hasChild ? "relative bottom-3" : "ml-[58px] mb-4"
            }`}
          >
            {data.product_group_name || data.module_name}
          </div>
        </aside>
        {/* <CheckBox id= "2" /> */}
        <div className="flex space-x-11">
          {!forProfileModule ? (
            <>
              <CheckBoxItem
                item={data}
                id="1"
                newArr={newArr}
                key={data.product_group_id || data.product_module_id}
                // forEditUser={forEditUser}
                setNewArr={setNewArr}
              />
            </>
          ) : (
            <div className="flex space-x-12 mt-1">
              {
                <img
                  className="w-6 h-6"
                  src={
                    (access_FOR_product_Group
                      ? access_FOR_product_Group
                      : access_FOR_product_Module
                    )?.viewAccess === "1"
                      ? tickMarkimg
                      : null
                  }
                />
              }
              {
                <img
                  className="w-6 h-6"
                  src={
                    (access_FOR_product_Group
                      ? access_FOR_product_Group
                      : access_FOR_product_Module
                    )?.editAccess === "1"
                      ? tickMarkimg
                      : null
                  }
                />
              }
              {
                <img
                  className="w-6 h-6"
                  src={
                    (access_FOR_product_Group
                      ? access_FOR_product_Group
                      : access_FOR_product_Module
                    )?.deleteAccess === "1"
                      ? tickMarkimg
                      : null
                  }
                />
              }
            </div>
          )}
        </div>
      </div>
      {hasChild && isChildVisible && (
        <div className="mb-3 space-y-3 ">
          {(data?.subModules || data?.productCourses).map((item, index) => {
            const arrayToObject = (arr) => {
              if (forProfileModule) {
                const res = {};
                for (let i = 0; i < arr?.length; i++) {
                  const key = arr[i]["courseId"];
                  res[key] = arr[i];
                }
                return res;
              }
            };
            let userAccess = forProfileModule && arrayToObject(courseValues);

            let access_For_Courses =
              forProfileModule && userAccess[item.course_id];
            let access_for_Module_child =
              forProfileModule && accessDetails[item.product_module_id];
            return (
              <div
                key={item.product_module_id || item.course_id}
                className="flex justify-between"
              >
                <div
                  key={item.product_module_id || item.course_id}
                  className="font-normal text-lg ml-20 "
                >
                  {item.module_name || item.courseName}
                </div>
                <div className="flex space-x-11 mb-1" key={index}>
                  {!forProfileModule ? (
                    <>
                      <CheckBoxItem
                        item={item}
                        id="2"
                        key={index}
                        newArr={newArr}
                        setNewArr={setNewArr}
                      />
                    </>
                  ) : (
                    <div className="flex space-x-12 mt-1">
                      {
                        <img
                          className="w-6 h-6"
                          src={
                            (access_FOR_product_Group
                              ? access_For_Courses
                              : access_for_Module_child
                            )?.viewAccess === "1" && tickMarkimg
                          }
                        />
                      }
                      {
                        <img
                          className="w-6 h-6"
                          src={
                            (access_FOR_product_Group
                              ? access_For_Courses
                              : access_for_Module_child
                            )?.editAccess === "1" && tickMarkimg
                          }
                        />
                      }
                      {
                        <img
                          className="w-6 h-6"
                          src={
                            (access_FOR_product_Group
                              ? access_For_Courses
                              : access_for_Module_child
                            )?.deleteAccess === "1" && tickMarkimg
                          }
                        />
                      }
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

TreeNode.propTypes = {
  data: propTypes.any,
  forProfileModule: propTypes.bool,
  accessDetails: propTypes.object,
  newArr: propTypes.array,
  setNewArr: propTypes.func,
  forEditUser: propTypes.bool,
};

export default TreeNode;
