import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import calendar from "../../assets/icons/calendar_inactive.svg";
import { ReactComponent as ClearIcon } from "../../assets/icons/cancel.svg";
import SearchIcon from "../../assets/icons/search-no-bg.svg";
import Loader from "../../components/global/loader/Loader";
import Header from "../../components/header/Header";
import TablePagination from "../../components/table/TablePagination";
const SlotManagementPage = () => {
  const [filterKeyword, setFilterKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("ASC");

  const [tests, setTests] = useState([]);
  const adminimspin = useSelector(
    (state) => state.loginData.LoginDetails.userImsPin
  );

  const thClassName = "text-lg font-semibold font-ibm";

  const getProctoredTests = () => {
    return apirequest({
      url: `${endpoint.getProctoredTests.addr}`,
      method: "post",
      data: {
        adminimspin: adminimspin,
      },
    });
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: ["Proctored Tests"],
    queryFn: getProctoredTests,
    select: (data) => data.data.data,
    onSuccess: (data) => {
      setTests(data);
    },
  });

  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;

  const handleSearch = (value) => {
    if (value === "") {
      setTests(data);
    }
    setTests(() =>
      data.filter(
        (asset) =>
          asset.asset_name.toLowerCase().includes(value.toLowerCase()) ||
          asset.asset_name.toLowerCase() === value.toLowerCase()
      )
    );
  };

  const handleSort = (col) => {
    if (order == "ASC") {
      setOrder("DESC");
      setTests((prev) => prev.sort((a, b) => (a[col] > b[col] ? 1 : -1)));
    } else if (order == "DESC") {
      setOrder("ASC");
      setTests((prev) => prev.sort((a, b) => (a[col] < b[col] ? 1 : -1)));
    }
  };

  return (
    <div>
      <Header />
      <div className="p-5">
        <div className="flex justify-between pt-5 px-5">
          <div
            className={`z-50 ${"min-w-[26rem] "} rounded-2xl shadow-outer flex p-[1rem]  relative bg-white `}
          >
            <div className={`${"w-full"} flex  items-center`}>
              <input
                type="text"
                name="search"
                value={filterKeyword}
                placeholder={"Search by test name"}
                onChange={(e) => {
                  setFilterKeyword(e.target.value);
                  handleSearch(e.target.value);
                }}
                autoFocus
                className="w-full text-secondary-600 font-normal font-ibm focus:outline-none"
                autoComplete="off"
              />
              <ClearIcon
                className="mr-5 cursor-pointer"
                onClick={() => {
                  setFilterKeyword("");
                  handleSearch("");
                }}
              />
            </div>
            <div className="h-5 w-5 flex items-center justify-end">
              <img
                src={SearchIcon}
                className="cursor-pointer"
                alt="search"
                onClick={() => handleSearch(filterKeyword)}
              />
            </div>
          </div>
        </div>

        {isLoading ? (
          <Loader />
        ) : isError ? (
          <h3 className="text-bold text-red-500 text-lg">
            Error Loading tests List...
          </h3>
        ) : (
          <>
            <table className={`border-separate w-full `}>
              <thead className="shadow-outer w-full h-[4rem] top-0 z-50 text-left">
                <tr className="w-full">
                  <th
                    className={thClassName + " pl-5"}
                    // width={column[0]["width"]}
                  >
                    Name
                    <span
                      className="ml-1 cursor-pointer font-medium relative"
                      onClick={() => handleSort("modified_ts")}
                    >
                      <span className="absolute left-0 opacity-40">
                        &#8593;
                      </span>
                      <span className="absolute left-2 opacity-40">
                        &#8595;
                      </span>
                    </span>
                  </th>
                  <th
                    className={thClassName}
                    // width={column[1]["width"]}
                  >
                    Product
                  </th>
                  <th
                    className={thClassName + ""}
                    // width={column[2]["width"]}
                  >
                    Proctored Window Period
                  </th>
                  <th
                    className={thClassName + ""}
                    //  width={column[2]["width"]}
                  >
                    Slot Booking Period
                  </th>
                  <th
                    className={thClassName}
                    // width={column[0]["width"]}
                  ></th>
                </tr>
              </thead>
              <tbody className="table-spacing">
                {tests && tests?.length == 0 ? (
                  <tr>
                    <td
                      colSpan={5}
                      className="py-20 px-10 text-center text-xl font-ibm opacity-80"
                    >
                      No Data Found!
                    </td>
                  </tr>
                ) : (
                  <>
                    {tests
                      ?.slice(indexOfFirstItem, indexOfLastItem)
                      .map((key, index) => (
                        <tr key={index}>
                          <td
                            className="align-top pt-4 pl-3"
                            // width={column[0]["width"]}
                          >
                            <div className={`flex `}>
                              <div
                                className={`align-top ${
                                  "userId" in key && "cursor-pointer"
                                }`}
                                // onClick={() => "userId" in key && onclickHandler(key)}
                              >
                                <p className={"text-xl mb-0 "}>
                                  {key.asset_name}
                                </p>
                                <div className="flex mt-3 ml-3">
                                  <img width={16} src={calendar} />
                                  <p className={"text-sm mb-0 ml-1"}>
                                    Modified on{" "}
                                    {moment(key.modified_ts).format(
                                      "MMM DD YYYY hh:mm a"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>

                          <td
                            className="align-top pt-4"
                            // width={column[1]["width"]}
                          >
                            <p className={"text-xl mb-0 opacity-100"}>
                              {key.product_group_name}
                            </p>
                          </td>
                          <td
                            className="align-top pt-4"
                            // width={column[1]["width"]}
                          >
                            <p className={"text-xl mb-0 opacity-100"}>
                              {moment(key.release_date).format("MMM DD YYYY")} -{" "}
                              {moment(key.end_date).format("MMM DD YYYY")}
                            </p>
                          </td>
                          <td
                            className="align-top pt-4"
                            // width={column[1]["width"]}
                          >
                            <p className={"text-xl mb-0 opacity-100"}>
                              {moment(key.slot_start_date).format(
                                "MMM DD YYYY"
                              )}{" "}
                              -{" "}
                              {moment(key.slot_end_date).format("MMM DD YYYY")}
                            </p>
                          </td>
                          <td
                            className="align-top pt-4"
                            // width={column[1]["width"]}
                          >
                            {moment(key.end_date).isAfter(moment.now()) && (
                              <Link
                                to={`/manage-slots/${key.asset_id}`}
                                className={
                                  "text-primary-200 cursor-pointer text-sm underline mb-0 opacity-100 "
                                }
                              >
                                Manage Slot
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </tbody>
            </table>
            {tests.length !== 0 && (
              <TablePagination
                data={tests}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
                dataPerPage={dataPerPage}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SlotManagementPage;
