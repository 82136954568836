import React, { useState, useEffect } from "react";
import Header from "../../header/Header";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import Sequence from "../noticeboard/sequencing/Sequencing";
import DropDown from "../../global/fields/CourseDropDown";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function SlideShowSequencing() {

  let history = useHistory();
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  const [value, setValue] = useState("");
  const [users, setUsers] = useState([]);
  const [productGroup, setProductGroup] = useState("");
  const [courseid,setCourseid]=useState("");
  // api to get courses
  const { data: getCourseData,isSuccess} = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method : "post",
      data : {
        "adminimspin":login_Details.userImsPin
      }
    }));
  // end
  // const courseid = useSelector(state => state.CourseId.courseId);
  //API request to course_notice
  const fetchNotice = () => {
    return apirequest({
      url: `${endpoint.get_course_slideshow.addr}`,
      method: "post",
      data: {
        courseId: courseid,
      },
    });
  };

  const { data: getSlideShowData, refetch: RefetchNotice } = useQuery(
    endpoint.get_course_slideshow.key,
    fetchNotice,
    {
      enabled: false,
      onSuccess: res => {
        setUsers(res.data.data);
      },
      retry: 0,
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );
  useEffect(() => {
    courseid === "" ? "" : RefetchNotice();
  }, [courseid]);
  // end
  // changed value of sequence to be sent in api for updates
  const value_sequence = [];
  getSlideShowData &&
    users.map((item, index) => {
      value_sequence.push({
        slideShowId: item.slideshowId,
        sequenceOrder: index,
      });
    });

  // api for update sequencing
  const UpdateSequence = () => {
    return apirequest({
      url: `${endpoint.slideShow_sequence.addr}`,
      method: "put",
      data: {
        courseId: courseid,
        data: value_sequence,
      },
    });
  };

  const { refetch: updatesequence } = useQuery(
    endpoint.slideShow_sequence.key,
    UpdateSequence,
    {
      enabled: false,
      onSuccess :(res)=>alert(res.data.message),
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );
  // end
  const onsave = e => {
    console.log({ e });
    e.preventDefault();
    updatesequence();
    console.log({ productGroup, courseid });
  };
  useEffect(() => {
    setUsers([]);
  
  }, [courseid]);
  

  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />
      <div className="mt-2 mx-12  px-8 py-2  ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
          onSubmit={onsave}>
          <div className="h-full space-y-6  px-12 py-6 relative">
            <div className="flex mb-4">
              <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg">
                Courses
              </label>
              {isSuccess &&
              <DropDown
                placeholder={"Select a Course"}
                options={getCourseData?.data?.data?.courseList}
                type={"singleSelect"}
                width={"w-[22rem]"}
                setFieldValue={setProductGroup}
                readonly={false}
                value={value}
                setValue={setValue}
                setValueId={setCourseid}
                isSequencing={true}
              />}
            </div>
            {getSlideShowData && getSlideShowData.data.data && (
              <Sequence users={users} setUsers={setUsers} />
            )}
          </div>
          <div className=" space-x-6 absolute left-[40%] -bottom-16">
            <button
              className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              type="submit">
              SAVE
            </button>
            <button onClick={history.goBack} className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
              EXIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
