import React from "react";
import SearchMentorModal from "./SearchMentorModal";
import propTypes from "prop-types";


function ViewAnswerModalTrigger() {
  const Trigger = () => {
    return (
      <div className="flex cursor-pointer">
        <div
          className={`-mt-[4px] font-ibm font-medium text-lg  ${
            "text-secondary-600"
          }`}>
            Add Mentor
        </div>   
      </div>
    );
  };
  return <SearchMentorModal Trigger={Trigger}  /> ;
}
ViewAnswerModalTrigger.propTypes={ 
  modalButtonFunctions: propTypes.array,
  isOpen:propTypes.bool
};

export default ViewAnswerModalTrigger;
