import { useSelector } from "react-redux";
export const globalFilter = ({ name, subModuleName,childName }) => {
  const loginDetails = useSelector((state) => state?.loginData?.LoginDetails);
  let result;
  loginDetails?.productModuleAccessData.map((data) => {
    if (data["module_name"] === name) {
      return data.subModules.map((subData) => {
        if(subData.subModules.length === 0){
          if (subData["module_name"] === subModuleName) {
            return (result = {
              id: subData.product_module_id,
              name: subData.module_name.toLowerCase(),
              imsPin: loginDetails.userImsPin,
            });
          }
        }else{
          subData.subModules.map((child) => {
            if (child["module_name"] === childName) {
              return (result = {
                id: child.product_module_id,
                name: child.module_name.toLowerCase(),
                imsPin: loginDetails.userImsPin,
              });
            }
          });
        }
      });
    }
  });
  return result;
};
