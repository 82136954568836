import React from "react";
import propTypes from "prop-types";
import course from "../../../../assets/icons/test.svg";
import variantIcon from "../../../../assets/icons/myTest.svg";
import video from "../../../../assets/icons/video.svg";
import pdf from "../../../../assets/icons/quiz.svg";
import test from "../../../../assets/icons/test.svg";
import attach from "../../../../assets/icons/attach.svg";
import { useDispatch } from "react-redux";
import {
  setClipBoardData,
  setImportLearningPathData,
} from "../../../../redux/features/learningPath/importLearningPathSlice";
import Loader from "../../../global/loader/Loader";
function Browse({
  data,
  setData,
  currentScreen,
  setCurrentScreen,
  variant,
  setCourse,
  setVariant,
  error,
  status,
}) {
  const dispatch = useDispatch();
  console.log("Current Screen : ", currentScreen);
  function handleClick(item) {
    if (currentScreen === 1) {
      setCurrentScreen(2);
      setCourse(item);
    } else if (currentScreen === 2) {
      setCurrentScreen(3);
      setVariant(item);
    } else if (currentScreen > 2) {
      if (item.children && item.children.length > 0) {
        setCurrentScreen(currentScreen + 1);
        let x = currentScreen + 1;
        setData(prevData => ({
          ...prevData,
          [x]: item.children,
        }));
      } else {
        alert("No subtiers found");
      }
    }
  }

  function returnIcon(item) {
    if ("assetId" in item) {
      let assetType = item.assetType.toLowerCase();
      if (assetType === "pdf" || assetType === "html") return pdf;
      if (assetType === "video") return video;
      else return test;
    }
    if ("learningPathId" in item) return null;
    if ("courseId" in item) return course;
    if ("variantId" in item) return variantIcon;
    else return null;
  }

  function handleCopyLPObject(LPobject) {
    dispatch(setImportLearningPathData(LPobject));
    const clipBoardData = variant.variantName + " > " + LPobject.name;
    dispatch(setClipBoardData(clipBoardData));
    setCurrentScreen(0);
  }

  return (
    <>
      {status === "loading" ? (
        <Loader />
      ) : error ? (
        <div className="h-[250px] w-full flex items-center justify-center ">
          {error}
        </div>
      ) : (
        <div className="grid gap-4 mb-3 grid-cols-3 ">
          {data &&
            data.map(item => {
              return (
                <div
                  key={
                    item.learningPathId ||
                    item.courseId ||
                    item.variantId ||
                    item.assetId
                  }
                  className="flex flex-row border  border-slate-400 rounded-lg px-2 py-2 break-all">
                  {!("assetPath" in item) && (
                    <img className="mr-4 h-5 " src={returnIcon(item)} alt="" />
                  )}
                  <div className="flex w-full justify-between">
                    <p
                      className="text-sm cursor-pointer "
                      onClick={() => handleClick(item)}>
                      {item.name ||
                        item.courseName +
                          (item.productGroupName &&
                            " - " + item.productGroupName) ||
                        item.variantName ||
                        item.assetName}
                    </p>
                    {"assetPath" in item && (
                      <img
                        className="cursor-pointer"
                        src={attach}
                        alt=""
                        onClick={() => handleCopyLPObject(item)}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
}

Browse.propTypes = {
  data: propTypes.array,
  currentScreen: propTypes.number,
  setData: propTypes.func,
  setCurrentScreen: propTypes.func,
  fetchCourses: propTypes.func,
  setCourse: propTypes.func,
  variant: propTypes.object,
  setVariant: propTypes.func,
  error: propTypes.string,
  status: propTypes.string,
};
export default Browse;
