import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import calendar from "../../assets/icons/calendar.svg";
import { ReactComponent as ClearIcon } from "../../assets/icons/cancel.svg";
import SearchIcon from "../../assets/icons/search-no-bg.svg";
import submitted from "../../assets/icons/submitted-no-bg.svg";
import Header from "../../components/header/Header";
import TablePagination from "../../components/table/TablePagination";
import { setVenueDetails } from "../../redux/features/venue/venueSlice";
import Loader from "../../components/global/loader/Loader";

const VenueManagementPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filterKeyword, setFilterKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const adminimspin = useSelector(
    (state) => state.loginData.LoginDetails.userImsPin
  );
  const [venues, setVenues] = useState([]);
  const [order, setOrder] = useState("ASC");

  const thClassName = "text-lg font-semibold font-ibm";

  const getVenues = () => {
    return apirequest({
      url: `${endpoint.getAllVenues.addr}`,
      method: "post",
      data: {
        adminimspin: adminimspin,
      },
    });
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: [endpoint.getAllVenues.key],
    queryFn: getVenues,
    select: (data) => data.data.data.venueList,
    onSuccess: (data) => {
      setVenues(data);
    },
  });

  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;

  const handleSearch = (value) => {
    if (value === "") {
      setVenues(data);
    }
    setVenues(() =>
      data.filter(
        (venue) =>
          venue.venueName.toLowerCase().includes(value.toLowerCase()) ||
          venue.venueName.toLowerCase() === value.toLowerCase()
      )
    );
  };

  const handleSort = (col) => {
    if (order == "ASC") {
      setOrder("DESC");
      setVenues((prev) => prev.sort((a, b) => (a[col] > b[col] ? 1 : -1)));
    } else if (order == "DESC") {
      setOrder("ASC");
      setVenues((prev) => prev.sort((a, b) => (a[col] < b[col] ? 1 : -1)));
    }
  };

  return (
    <div>
      <Header />
      <div className="flex justify-between pt-5 px-5">
        <div
          className={`z-50 ${"min-w-[26rem] "} rounded-2xl shadow-outer flex p-[1rem]  relative bg-white `}
        >
          <div className={`${"w-full"} flex  items-center`}>
            <input
              type="text"
              name="search"
              value={filterKeyword}
              placeholder={"Search by venue name"}
              onChange={(e) => {
                setFilterKeyword(e.target.value);
                handleSearch(e.target.value);
              }}
              autoFocus
              className="w-full text-secondary-600 font-normal font-ibm focus:outline-none"
              autoComplete="off"
            />
            <ClearIcon
              className="mr-5 cursor-pointer"
              onClick={() => {
                setFilterKeyword("");
                handleSearch("");
              }}
            />
          </div>
          <div className="h-5 w-5 flex items-center justify-end">
            <img
              src={SearchIcon}
              className="cursor-pointer"
              alt="search"
              onClick={() => handleSearch(filterKeyword)}
            />
          </div>
        </div>
        <div className="ml-6 bg-primary-200 text-white font-normal px-6 rounded-xl hover:opacity-75 shadow-outer text-lg pt-[10px] mr-4 text-center">
          <Link to="/add-venue" className="text-white hover:text-white">
            Add Venue
          </Link>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <h3 className="text-bold text-red-500 text-lg">
          Error Loading Venue List...
        </h3>
      ) : (
        <div className="p-5">
          <table className={`border-separate w-full `}>
            <thead className="shadow-outer w-full h-[4rem] top-0 z-50 text-left">
              <tr className="w-full">
                <th className={thClassName + " pl-5"}>Venue Name & Address</th>
                <th
                  className={thClassName}
                  onClick={() => handleSort("modifiedTs")}
                  // width={column[1]["width"]}
                >
                  Modified On
                  <span className="ml-1 cursor-pointer font-medium relative">
                    <span className="absolute left-0 opacity-40">&#8593;</span>
                    <span className="absolute left-2 opacity-40">&#8595;</span>
                  </span>
                </th>
                <th className={thClassName + ""}>Modified By</th>
                <th width={500} className={thClassName + ""}>
                  Centres
                </th>
                <th className={thClassName}></th>
              </tr>
            </thead>
            <tbody className="table-spacing">
              {venues && venues?.length == 0 ? (
                <tr>
                  <td
                    colSpan={4}
                    className="py-20 px-10 text-center text-xl font-ibm opacity-80"
                  >
                    No Data Found!
                  </td>
                </tr>
              ) : (
                <>
                  {venues
                    ?.slice(indexOfFirstItem, indexOfLastItem)
                    .map((venue, index) => (
                      <tr key={index}>
                        <td
                          className="align-top pt-4 pl-5"
                          // width={column[0]["width"]}
                        >
                          <div className={`flex `}>
                            <div
                              className={`align-top ${
                                "userId" in venue && "cursor-pointer"
                              }`}
                            >
                              <p className={"text-lg mb-0 "}>
                                {venue.venueName}
                                {/* - {venue.venueAddress} */}
                              </p>
                            </div>
                          </div>
                        </td>

                        <td
                          className="align-top pt-4 flex items-center gap-2"
                          // width={column[1]["width"]}
                        >
                          <img width={16} src={calendar} />
                          <p className={"text-sm mb-0 opacity-100"}>
                            {moment(venue.modifiedTs)
                              .local()
                              .format("MMM DD YYYY hh:mm a")}
                          </p>
                        </td>
                        <td
                          className="align-top pt-[22px]"
                          // width={column[1]["width"]}
                        >
                          <p className={"text-sm mb-0 opacity-100"}>
                            {venue.modifiedBy}
                          </p>
                        </td>
                        <td
                          className="align-top pt-4 flex items-center gap-2 flex-wrap"
                          // width={column[1]["width"]}
                        >
                          {venue.centres.map((centre) => (
                            <div
                              key={centre.centre_id}
                              className="mr-2 bg-gray-100 px-3 py-2 rounded-2xl text-sm capitalize max-w-fit"
                            >
                              {centre.centre_name}
                            </div>
                          ))}
                        </td>
                        <td
                          className="align-top pt-4"
                          // width={column[1]["width"]}
                        >
                          <button
                            onClick={() => {
                              dispatch(setVenueDetails(venue));
                              history.push(
                                `/edit-venue/${venue.venueName}?id=${venue.venueId}`
                              );
                            }}
                          >
                            <img
                              className="h-6 cursor-pointer"
                              src={submitted}
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </table>
          {venues.length !== 0 && (
            <TablePagination
              data={venues}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              paginate={paginate}
              dataPerPage={dataPerPage}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default VenueManagementPage;
