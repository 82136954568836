import React from "react";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import AddEditArticle from "./AddEditArticle";
import Header from "../../header/Header";
import { useSelector } from "react-redux";

const EditArticles = () => {

  const data = useSelector(state=>state.articleDetails.articlesData);
  console.log(data);
  return (
    <div>
      <Header/>
      <BreadCrumb />
      <AddEditArticle eventsdata ={data} requestType="Update"/>
    </div>
  );
};

export default EditArticles;