import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import Drag from "../../../assets/icons/drag.svg";
import ArrowDown from "../../../assets/icons/arrow-down.svg";
import ArrowUp from "../../../assets/icons/arrow-up.svg";
import testIcon from "../../../assets/icons/test.svg";
import video from "../../../assets/icons/video.svg";
import quiz from "../../../assets/icons/quiz.svg";
import deleteinactive from "../../../assets/icons/delete-inactive.svg";
import EditTierFuncButton from "../../course/navigationPath/TriggerEditTierFolder";
import { setImportLearningPathData } from "../../../redux/features/learningPath/importLearningPathSlice";
import { useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddTierFuncButton from "../../../components/course/navigationPath/TriggerAddTierFolder";
import { useSelector } from "react-redux";
import MapAssetModal from "../../../components/course/learningPath/MapAssetModal";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import {
  setDeletedTiers,
  setFolderOptions,
  setOnDragAsset,
} from "../../../redux/features/learningPath/learningPathSlice";
import { deleteAsset } from "../../../redux/features/learningPath/learningPathSlice";
import { endpoint } from "../../../API/config";
import { apirequest } from "../../../API/api";
import { useQuery } from "react-query";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const AssetTree = ({
  data,
  count,
  depth,
  treeIndexes,
  setDisableSave,
  parentId,
}) => {
  const [items, setItems] = useState([...data]);
  const dispatch = useDispatch();
  let _parentIndex = treeIndexes;
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#00ABFB" : "lightgrey",
  });

  const onDragEnd = res => {
    if (!res.destination || res.source?.index == res.destination?.index) return;
    setItems(items => {
      const reorderedItems = reorder(
        items,
        res.source.index,
        res.destination.index,
      );
      const payload = {
        parent_id : parentId,
        reorderedItems,
      };
      dispatch(setOnDragAsset(payload));
      return reorderedItems;
    });
    setDisableSave(false);
  };

  const onDelete = (index, item) => {
    setItems(items => {
      const reorderedItems = JSON.parse(JSON.stringify(items));
      reorderedItems.splice(index, 1);
      return reorderedItems;
    });
    let action_payload = {
      item,
      parent_id : parentId,
    };
    dispatch(deleteAsset(action_payload));
    if ("learningPathId" in item) dispatch(setDeletedTiers(item));
    setDisableSave(false);
  };

  useEffect(() => {
    setItems([...data]);
  }, [data]);

  return (
    <>
      <DragDropContext onDragEnd={(...e) => onDragEnd(...e, depth)}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}>
              {items.length > 0 ? (
                items.map((asset, index) => {
                  if ("assetId" in asset || asset.assetPath === "1") {
                    return (
                      <TreeNode
                        item={asset}
                        index={index}
                        key={index}
                        count={count}
                        depth={depth}
                        treeIndexes={[..._parentIndex, index]}
                        onDelete={onDelete}
                        parentId={parentId}
                        setDisableSave={setDisableSave}
                      />
                    );
                  }
                })
              ) : (
                <p className="text-center bg-white px-[10px] font-ibm font-normal text-lg py-5 mb-0">
                  No Asset Available
                </p>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const TreeNode = ({
  item,
  index,
  count,
  depth,
  treeIndexes,
  onDelete,
  parentId,
  setDisableSave,
}) => {
  const [childVisible, setChildVisiblity] = useState(false);
  const showAssetsOptions = useSelector(state => state.learningPathData.folderOptions);
  const dispatch = useDispatch();
  const variantData = useSelector(
    state => state.variantsByCourseData.variantsByCourse,
  );
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    boxShadow: isDragging ? "0 0 .4rem #666" : "none",
    background: "white",
    ...draggableStyle,
  });
  const hasAsstsObject =
    (item.assetPath === "1" && item.children.length) > 0 ? true : false;

  /*Map Asset Modal Triggers */
  const Trigger = () => {
    return (
      <div className="whitespace-nowrap text-black hover:text-black">
        Map Asset
      </div>
    );
  };

  const closeModal = () => {
    dispatch(setFolderOptions(null));
  };

  const handleEditClick = () => {
    setDisableSave(false);
  };

  /* Copy Path Link Function  */
  function copyToClipboard(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }
  const getCopyLearningPathObject = obj => {
    dispatch(setImportLearningPathData(obj));
    copyToClipboard(
      variantData.variantName + " > " + JSON.parse(JSON.stringify(obj)).name,
    )
      .then(() => alert("Path Link Copied!"))
      .catch(() => alert("error"));
  };

  function handleShowOptions(lpId) {
    dispatch(setFolderOptions(lpId));
  }

  const deleteAssetFromLP = async () => {
    return apirequest({
      url: `${endpoint.delete_LearningPath_asset.addr}`,
      method: "post",
      data: {
        learningPathId: parentId,
        assetId: item.assetId,
      },
    });
  };

  const { refetch: deleteLPasset } = 
  useQuery(
    [endpoint.delete_LearningPath_asset.key],
    deleteAssetFromLP,
    {
      enabled: false,
    },
  );

  function handleDeleteAsset(index, item) {
    deleteLPasset();
    onDelete(index, item);
  }

  return (
    <>
      <Draggable
        key={"learningPathId" in item ? item.learningPathId : item.assetId}
        draggableId={
          "learningPathId" in item ? item.learningPathId : item.assetId
        }
        index={index}>
        {(provided, snapshot) => (
          <div
            className="px-5 py-1 bg-gray-400"
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}>
            <div className="flex justify-between">
              <div className="flex">
                <img
                  className="mr-3 h-4 mt-3"
                  {...provided.dragHandleProps}
                  src={Drag}
                />
                {item.assetType && (
                  <div className="mt-2 mr-4">
                    <img
                      className={`h-5 ${
                        item.assetType == "Pdf" || item.assetType == "HTML"
                          ? "ml-[2px]"
                          : ""
                      }`}
                      src={
                        item.assetType == "Video"
                          ? video
                          : item.assetType == "Pdf" || item.assetType == "HTML"
                            ? quiz
                            : testIcon
                      }
                    />
                  </div>
                )}
                {hasAsstsObject && (
                  <div
                    className="rounded-full shadow-dark flex px-[6px] py-[4px] bg-white mr-2 mt-[2px]"
                    onClick={() => setChildVisiblity(v => !v)}>
                    <img
                      className="h-5 w-[17px]"
                      src={childVisible ? ArrowUp : ArrowDown}
                    />
                  </div>
                )}
                <div
                  className={`contentFont mt-2 mr-[2rem] text-black text-sm ${
                    hasAsstsObject ? "font-medium" : "font-normal"
                  } text-base`}
                  style={{ opacity: count }}>
                  {"name" in item ? item.name : item.assetName}
                </div>
              </div>
              {item.assetId ? (
                <img
                  className="mr-3 h-4 mt-3 cursor-pointer"
                  onClick={() => handleDeleteAsset(index, item)}
                  src={deleteinactive}
                />
              ) : (
                <div className="relative inline-block cursor-pointer">
                  <span
                    className={`mr-3 h-4 m-auto text-base font-bold cursor-pointer ${
                      showAssetsOptions === item.learningPathId
                        ? "text-black"
                        : "text-gray-300"
                    } `}
                    onClick={() => handleShowOptions(item.learningPathId)}>
                    ⋯
                  </span>

                  {showAssetsOptions === item.learningPathId && (
                    <div className="py-2 border-0 border-secondary-300 rounded-2xl px-4 pl-6 pr-3 border-solid shadow-lg absolute z-50 top-5 bg-white mb-5 -right-2">
                      <Cancel
                        className="cursor-pointer mr-0 float-right -mt-1"
                        onClick={closeModal}
                      />
                      <div className="py-1 font-ibm">
                        <EditTierFuncButton
                          assetPath="1"
                          editing={true}
                          parentData={item}
                          handleEditClick={handleEditClick}
                        />
                      </div>
                      <div className="py-1 font-ibm">
                        <span
                          className="whitespace-nowrap text-black hover:text-black"
                          onClick={() => onDelete(index, item)}>
                          Delete
                        </span>
                      </div>
                      {item.assetId === "0" && (
                        <AddTierFuncButton
                          assetPath="1"
                          parentId={item.learningPathId}
                          setDisableSave={setDisableSave}
                        />
                      )}
                      <div className="py-1 font-ibm">
                        <span
                          className="whitespace-nowrap text-black hover:text-black"
                          onClick={() => getCopyLearningPathObject(item)}>
                          Copy Path Link
                        </span>
                      </div>
                      <MapAssetModal
                        Trigger={Trigger}
                        setDisableSave={setDisableSave}
                        learningPathId={item.learningPathId}
                        addingToTier={true}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {hasAsstsObject &&
              childVisible &&
              parentId === item.parentId &&
              item.assetPath === "1" && (
              <AssetTree
                data={item.children}
                index={index}
                count={count.toFixed(1) == 0.2 ? 1 : count.toFixed(1) - 0.2}
                depth={depth + 1}
                treeIndexes={treeIndexes}
                setDisableSave={setDisableSave}
                parentId={item.learningPathId}
              />
            )}
          </div>
        )}
      </Draggable>
    </>
  );
};
TreeNode.propTypes = {
  item: propTypes.object,
  index: propTypes.number,
  count: propTypes.number,
  depth: propTypes.number,
  treeIndexes: propTypes.array,
  onDelete: propTypes.func,
  parentId: propTypes.string,
  setDisableSave: propTypes.func,
};
AssetTree.propTypes = {
  data: propTypes.array,
  count: propTypes.number,
  depth: propTypes.number,
  treeIndexes: propTypes.array,
  setDisableSave: propTypes.func,
  parentId: propTypes.string,
};
export default AssetTree;
