import React, { useState } from "react";
import WithModal from "../../global/modal/WithModal/WithModal";
import propTypes from "prop-types";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { getmentorDetails } from "../../../redux/features/mentor/mentorSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

function MentorSearchModal({closeModal}) {
  const [detail, setDetail] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const mapAsset = async () => {
    return apirequest({
      url: `${endpoint.SearchMentorByNameOrPin.addr}`,
      method: "POST",
      data: {
        "emailId": detail.includes("@")?detail:"",
        "imsPin": detail.includes("@")?"":detail
      },
    });
  };

  const {refetch } = useQuery(
    endpoint.SearchMentorByNameOrPin.key,
    mapAsset,
    {
      enabled : false,
      onSuccess: res => {
        dispatch(getmentorDetails(res.data.data));
        closeModal();
        history.push("/add-mentor");
      },
      onError:(err)=>{alert(err.data.message);}
    }
  );
  const  handleClick=()=>{
    refetch(); 
  };
  return (
    <div className="flex flex-col  w-[750px] h-96 space-y-8 mt-9">
      <div className="flex ml-44 justify-between">
        <p className="font-ibm text-2xl text-secondary-600 font-medium">
        Add Mentor
        </p>
        <Cancel className="cursor-pointer -mt-4 mr-6" onClick={closeModal} />
      </div>
      <p className="font-ibm text-sm text-secondary-600 font-normal ml-44">
        Search by Email/IMS Pin
      </p>
      <input className="w-96 h-12 border-2 rounded-xl ml-44 focus:outline-none" value={detail} onChange={(e)=>setDetail(e.target.value)} placeholder="Email id / IMS Pin"/>

      <button
        className="w-96 h-14 ml-44 px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-secondary-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
        onClick={handleClick}
        type="submit">
          Search
      </button>
      
    </div>

  );
}

MentorSearchModal.propTypes = {
  modalButtonFunctions: propTypes.array,
  closeModal:propTypes.bool,
};
export default WithModal(MentorSearchModal);
