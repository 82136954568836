import React,{useState} from "react";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import submitted from "../../assets/icons/submitted.svg";
import { useDispatch } from "react-redux";
import { getnoticeDetails } from "../../redux/features/notice/noticeSlice";

const MentorTable = ({ column,currentData,tableHead }) => {
  const thClassName = "text-lg font-semibold font-ibm";
  const [order, setOrder] = useState("ASC");
  const dispatch = useDispatch();

  const handleSort = col => {
    if (order == "ASC") {
      setOrder("DESC");
      currentData.sort((a, b) => (a[col] > b[col] ? 1 : -1));
    } else if (order == "DESC") {
      setOrder("ASC");
      currentData.sort((a, b) => (a[col] < b[col] ? 1 : -1));
    }
  };
  return (
    <>
      <table
        className={`border-separate w-full ${tableHead == true ? "" : ""}`}>
        {tableHead && (
          <thead className="shadow-outer w-full h-[4rem] top-0 z-50 text-left">
            {tableHead == true && (
              <tr className="w-full">
                <th
                  className={thClassName + " pl-8"}
                  width={column[0]["width"]}>
                  Mentor Name
                  <span
                    className="ml-1 cursor-pointer font-medium relative"
                    onClick={() => handleSort("mentorName")}>
                    <span className="absolute left-0 opacity-40">&#8593;</span>
                    <span className="absolute left-2 opacity-40">&#8595;</span>
                  </span>
                </th>
                <th className={thClassName} width={column[5]["width"]}>Courses</th>
                <th className={thClassName} width={column[3]["width"]}>
                  Centers
                </th>
                <th
                  className={thClassName + " pl-3"}
                  width={column[4]["width"]}>
                  Status
                </th>
                <th
                  className={thClassName + " pr-10"}
                  width={column[4]["width"]}></th>
              </tr>
            )}
          </thead>
        )}
        <tbody className="table-spacing">
          {currentData?.length == 0 ? (
            <tr>
              <td>
                <p className="py-20 px-10 text-center text-xl font-ibm opacity-80">
                  No Data Found!
                </p>
              </td>
            </tr>
          ) : (
            <>
              {currentData?.map((key, index) => (
                <tr key={index}>
                  <td
                    className="align-top pt-4 pl-8 "
                    width={column[0]["width"]}>
                    <div className="flex">
                      <div className="align-top">
                        <p
                          className={
                            "text-xl mb-0 " +
                            (key.isActive == 1 ? "opacity-100" : "opacity-60")
                          }>
                          {key.mentorName}
                        </p>
                        <div className="flex mt-3">
                          <p
                            className={
                              "text-base mb-0 " +
                              (key.isActive == 1 ? "opacity-100" : "opacity-60")
                            }>
                            {key.mentorEmail}
                          </p>
                        </div>
                        <div className="flex mt-3">
                          <p
                            className={
                              "text-base mb-0 " +
                              (key.isActive == 1 ? "opacity-100" : "opacity-60")
                            }>
                            {key.mentorImsPin}
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="align-top pt-4 " width={column[1]["width"]}>
                    <p
                      className={
                        "text-xl mb-0 " +
                        (key.isActive == 1
                          ? "opacity-100 "
                          : "opacity-60 ")
                      }>
                      {key.courseNames.join(",")}
                    </p>
                  </td>
                  {tableHead == true && (
                    <td className="align-top pt-4" width={column[4]["width"]}>
                      <p
                        className={
                          "text-xl mb-0 " +
                          (key.isActive == 1 ? "opacity-100" : "opacity-60")
                        }>
                        {key.branchNames.join(",")}
                      </p>
                    </td>
                  )}
                  {tableHead == true && (
                    <td className="align-top pt-4 pl-3" width={column[1]["width"]}>
                      <p
                        className={
                          "text-xl mb-0" +
                          (key.isActive == 1 ? "opacity-100" : "opacity-60")
                        }>
                        {key.isActive == 1?"Active":"Inactive"}
                      </p>
                    </td>
                  )}
                  <td width={column[1]["width"]} className="align-top">
                    <div key={index} className="flex cursor-pointer items-center space-x-5">
                      <Link key={index} to="/update-mentor">
                        
                        <img
                          className="h-7 mt-4"
                          src={submitted}
                          onClick={() => {
                            dispatch(getnoticeDetails(key));
                          }}>
                        </img>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};
MentorTable.propTypes = {
  column: propTypes.array, //for adding width for table
  currentData: propTypes.array, //for get data from parent component
  colIcon: propTypes.string, //for icon in the first column
  tableHead: propTypes.bool, //for adding header on table
  setFilteredData: propTypes.func,
  isforAddConentRepo: propTypes.bool,
  setRenderChildPage: propTypes.func,
  refetch: propTypes.func,
};
export default MentorTable;
