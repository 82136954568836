import React, { useState } from "react";
import Header from "../../../components/header/Header";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import { Link } from "react-router-dom";
import PostTable from "./PostTable";
import TablePagination from "../../../components/table/TablePagination";
// import ArrowUp from "../../../assets/icons/arrow-up.svg";
import TableSearch from "../../table/TableSearch";
import { apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import Loader from "../../../components/global/loader/Loader";
import { globalFilter } from "../../global/globalFilter/GlobalFilter";
import { useSelector } from "react-redux";
import FilterFunctionlity from "../../filterFunctionality/FilterFunctionlity";

const Courses = () => {
  const modulesData = globalFilter({
    name: "Home Page",
    subModuleName: "Blog Management",
  });

  const [getPostData, setPostData] = useState([]);
  const blogId = useSelector((state) => state.blogsData.blogDetails.blogId);
  const [currentPage, setCurrentPage] = useState(1);
  const [courseId, setCourseId] = useState([]);

  const column = [
    { col: "1", width: "" },
    { col: "2", width: "40%" },
    { col: "3", width: "49%" },
    { col: "4", width: "5%" },
  ];

  const PostListing = () => {
    return apirequest({
      url: `${endpoint.posts_data.addr}`,
      method: "post",
      data: {
        adminimspin: modulesData.imsPin,
        blogId: blogId,
      },
    });
  };

  const {
    data: posts_DefaultData,
    error,
    isSuccess: is_post_success,
    status,
  } = useQuery(endpoint.posts_data.key, PostListing, {
    onSuccess: (res) => setPostData(res),
    refetchOnMount: true,
    retry: 0,
  });

  // API Call for course_data filter
  const { data: getCourseData } = useQuery(
    `${endpoint.course_data.key}`,
    () =>
      apirequest({
        url: `${endpoint.course_data.addr}`,
        method: "post",
        data: {
          adminimspin: modulesData.imsPin,
          courseId: "",
        },
      }),
    {
      select: (res) => res.data.data.courseList,
    }
  );

  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };

  return (
    <div id="courses">
      <Header />
      <div className="mt-8">
        <BreadCrumb />
      </div>
      {status === "loading" ? (
        <Loader />
      ) : !is_post_success && error?.status !== 404 ? (
        <span>{error?.message}</span>
      ) : (
        <div className="courses">
          <div className="courseTable ml-16">
            <div className="courseTable__head flex justify-between  md:mt-[2%] md-mb-[5%] md:mx-9 pl-[2%]">
              {/* Filter Function */}
              <FilterFunctionlity
                filterText=" Course"
                setFunction={setPostData}
                dropdownData={getCourseData}
                url={endpoint.posts_data.addr}
                payload={courseId}
                setPayload={setCourseId}
                setCurrentPage={setCurrentPage}
                payloadData={{
                  adminimspin: modulesData.imsPin,
                  courseId: courseId,
                  blogId: blogId,
                }}
                filterId="courseId"
                filterValue="courseName"
              />

              <div className="ml-4 flex">
                <TableSearch
                  data={getPostData?.data?.data?.postList}
                  defaultData={posts_DefaultData}
                  setDefaultData={setPostData}
                  dataKey="postName"
                  dataForFilter={posts_DefaultData?.data.data?.postList}
                  name="Home Page"
                  subModuleName="Blog Management"
                  setCurrentPage={setCurrentPage}
                  childModuleName="posts"
                  blogId={blogId}
                  placeholder="Search by Post"
                />
                {/* // </TableSearch> */}
                {/* } */}
                {((is_post_success &&
                  getPostData?.data?.data?.global_add_access === "1") ||
                  error?.data.message === "No Record found") && (
                  <Link to="/HomePage/BlogManagement/PostManagement/AddPost">
                    <button className="ml-6 bg-primary-200 text-white font-medium px-6 rounded-xl hover:opacity-75 shadow-outer font-ibm w-40 h-[56px]">
                      Add Post
                    </button>
                  </Link>
                )}
              </div>
            </div>
            {/* to handle the scenario if there is no data found */}
            <div className="courseTable__body md:ml-[4%] md:mr-[34px] mt-4 shadow-outer pl-[2%] pr-[5%] rounded-xl bg-white pb-[2%]">
              {is_post_success ? (
                <PostTable
                  column={column}
                  currentData={getPostData?.data?.data?.postList.slice(
                    indexOfFirstItem,
                    indexOfLastItem
                  )}
                  type={"course"}
                />
              ) : (
                <p className="py-20 px-10 text-center text-xl font-ibm opacity-80">
                  {error?.data.message}
                </p>
              )}
            </div>
            <div className="courseTable__footer mx-auto mb-7">
              {is_post_success && (
                <TablePagination
                  data={getPostData?.data?.data?.postList}
                  dataPerPage={dataPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  paginate={paginate}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Courses;
