import React from "react";
import closeActive from "../../assets/icons/close-active.svg";


const SchoolModal = () => {
  return (
    <div className="shadow-outer rounded-2xl p-4 mx-36 mt-10 -mb-14 ">
      <div className=" flex flex-row-reverse -mt-8">
        {" "}
        <img src={closeActive} />
      </div>
      <div className="flex flex-row text-2xl font-medium m-6">
        Import Schools
      </div>
      <div className="grid grid-cols-6 gap-5 m-4 ml-6 ">
        <p className=" col-span-2 text-xl font-medium">
          Selected Course to import from
        </p>
        <div className="col-span-3">
          <div className="w-[425px] flex border-2 border-gray-200 rounded-xl px-4 py-2">
            <p>IMS 2</p>
          </div>
        </div>

        <p className=" col-span-2 text-xl font-medium">Selected Schools</p>
        <div className="col-span-3">
          <div className="w-[425px] flex border-2 border-gray-200 rounded-xl px-4 py-2">
            <p>IMS 2</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center m-4 p-4 ">
        <button className="px-10 py-3 shadow-outer rounded-2xl text-lg bg-primary-200 float-right mt-8 mr-4 text-white cursor-pointer">
          save
        </button>
        <button className="px-10 py-3 shadow-outer rounded-2xl text-lg float-right mt-8 mr-4 cursor-pointer">
          Exit
        </button>
      </div>
    </div>
  );
};

export default SchoolModal;
