import React from "react";
import propTypes from "prop-types";

function ShowMessage({ folder }) {
  let msg1 = folder
    ? "Start by adding a Tier using \"Add Tier\" button"
    : "Assets mapped to TIER will appear here.";

  let msg2 = folder
    ? "Import learning path from another variant using the \"Import Learning Path\" button"
    : "You can map assets to TIER using \"Map Assets\" button";

  return (
    <div
      className="text-center px-[85px] font-ibm font-normal text-lg py-10"
      id="tierStartContent">
      <p className="mb-4">{msg1}</p>
      {folder && <p className="mb-4">OR</p>}
      <p className={`${!folder && "mb-4"}`}>{msg2}</p>
    </div>
  );
}

ShowMessage.propTypes = {
  folder: propTypes.bool,
  msg1: propTypes.string,
  msg2: propTypes.string,
};

export default ShowMessage;
