import React, { useState, useEffect } from "react";
import propTypes from "prop-types";

const Tab = ({ children, className, activeTab, handleTabChange }) => {
  const [tabData, setTabData] = useState([]);

  useEffect(() => {
    let data = [];
    React.Children.forEach(children, element => {
      if (!React.isValidElement(element)) return;
      const {
        props: { tab, children },
      } = element;
      data.push({ tab, children });
    });
    setTabData(data);
  }, [children]);

  return (
    <div className="w-100">
      <ul className={`nav nav-tabs flex ${className ? className : "px-28"}`}>
        {tabData.map(({ tab }, idx) => (
          <li
            className={`nav-item ${
              idx === activeTab ? "bg-primary-200 text-white" : "text-black"
            } mr-10 capitalize py-3 px-8 text-base rounded-xl font-ibm shadow-forButton`}
            key={idx}>
            <a
              className={`nav-link ${
                idx === activeTab
                  ? "active text-white hover:text-white"
                  : "text-black hover:text-black"
              }`}
              href="#"
              onClick={() => handleTabChange(idx)}>
              {tab}
            </a>
          </li>
        ))}
      </ul>

      <div className="tab-content p-3">
        {tabData[activeTab] && tabData[activeTab].children}
      </div>
    </div>
  );
};

const TabPane = ({ children }) => {
  return { children };
};
Tab.TabPane = TabPane;
Tab.propTypes = {
  children: propTypes.array,
  activeTab: propTypes.number,
  className: propTypes.string,
  handleTabChange: propTypes.func,
};
export default Tab;
