import React from "react";
import arrowUp from "../../assets/icons/arrow-up.svg";
import { useState } from "react";
import arrowDown from "../../assets/icons/arrow-down.svg";
import propTypes from "prop-types";
import { useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
// import CrossIcon from "../../assets/icons/delete-inactive.svg";

const SchoolDropdown = ({
  dropdownData, 
  setSelectedValue, 
  filterValue , 
  filterId  , 
  selectedValue ,
  forSingleSelect,
  placeholder
}) => {

  const  [showDropdown, setShowDropdown] = useState(false);

  const [filteredDropdowndata  , setDropdownData] = useState(dropdownData);
  const  [value , setValue] = useState([]);

  useEffect(()=>{
    setDropdownData(dropdownData);
  },[dropdownData]);

  const handleChange =(e)=>{
    let searchValue = e.target.value;
    setValue(e.target.value);
    let spliitedValue =  searchValue.split(" ");
    searchValue.length>0 ? setShowDropdown(true) : null;
    const filterData = dropdownData?.filter((item)=>{
      return spliitedValue.some((data)=>{
        if(item?.[filterValue].toLowerCase().includes(data.toLowerCase())){
          return item;
        }
      });
    });
    setDropdownData(filterData);
  };
  const handleSelect =(item)=>{
    const setData = new Set(selectedValue);
    if(forSingleSelect){
      setSelectedValue(item[filterId]);
      setValue(item[filterValue]);
      return ;
    }
    if (setData.has(item) ){
      return;
    }else {
      setSelectedValue((prev)=>[...prev , item]);
      setValue(()=>[ item[filterValue]]);
      return ;
    }
  };
  const handleRemove =(item)=>{
    setSelectedValue(()=>selectedValue.filter((val)=>val[filterId] !=item[filterId]));
  };
  return (
    <OutsideClickHandler onOutsideClick={()=>setShowDropdown(false)}>
      <div className="z-50">
        <div className="flex ">
          <div className="w-96 flex items-center" onClick={()=>setShowDropdown(!showDropdown)}>
            <input 
              className="bg-white  rounded-2xl pl-4 pr-5 py-3 border-solid border-2 border-secondary-300 px-2 focus:outline-none w-full cursor-pointer focus:placeholder-transparent font-semibold placeholder-bold" 
              placeholder={placeholder}
              value ={value}
              onChange={(e)=>handleChange(e)}
            />
            <img className="-ml-8 w-7 h-7" src={showDropdown? arrowUp : arrowDown}  />
          </div>
          <div className="grid grid-cols-4">
            {!forSingleSelect && selectedValue?.map(item=>(
              <div key={item[filterId]} onClick={()=>handleRemove(item)} className="bg-gray-100 px-2 py-1 m-1 rounded-2xl flex space-x-3 items-center">
                <span> {item[filterValue]}</span>
                <p className="cursor-pointer">X</p>
              </div>
            ))}
          </div>
        </div>
        <div className="z-50">
          {showDropdown &&<div className="w-96 h-auto absolute z-20 border-0 border-secondary-300 rounded-2xl  p-8 border-solid  px-2 shadow-lg bg-white scrollbar-thin overflow-y-scroll scrollbar-thumb-secondary-700 scrollbar-thumb-rounded  max-h-[10rem] ">
            {filteredDropdowndata?.map(item=>(
              <div key={item[filterId]} className="pb-2">
                <p className="cursor-pointer hover:bg-gray-100 text-sm font-normal" onClick={()=>handleSelect(item)}>{item[filterValue]}</p>
              </div>
            ))}
          </div>}
        </div>
      </div>
    </OutsideClickHandler >
  );
};

SchoolDropdown.propTypes={
  dropdownData : propTypes.array,
  selectedValue : propTypes.any,
  setSelectedValue : propTypes.func,
  filterValue : propTypes.string,
  filterId : propTypes.string,
  forSingleSelect : propTypes.bool,
  placeholder:propTypes.string
};

export default SchoolDropdown;