import React from "react";
import propTypes from "prop-types";

const Icon = ({ src ,className}) => {
  return (
    <div className={`flex justify-center items-center  rounded-full shadow-outer bg-white ${ className ? className : " relative left-16 w-12 h-12"}`}>
      <img src={src} alt="img" />
    </div>
  );
};
Icon.propTypes = {
  src: propTypes.string,
  className : propTypes.string
};

export default Icon;
