import React, { useState } from "react";
import DropDown from "../../global/fields/DropDown";
import Toggle from "../../global/fields/Toggle";
import { apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import WhiteSpace from "../../global/regEx/WhiteSpaceValidator";

const CourseDetailForm = () => {
  const history = useHistory();
  const courseDetails = useSelector(state => state?.courseData?.courseDetails);
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);

  const [value, setValue] = useState({
    courseName: courseDetails ? courseDetails.courseName : "",
    courseDescription: courseDetails ? courseDetails.courseDescription : "",
  });
  const [activeTab, setActiveTab] = useState(
    courseDetails.isActive == "0" ? "inactive" : "active",
  );
  let isActive = activeTab === "active" ? "1" : "0";


  /*Product Group Api */
  const [productGroup, setProductGroup] = useState("");
  const { data: product_groupName, isSuccess: productGroupStatus } = useQuery(
    endpoint.product_group.key,
    () =>
      apirequest({
        url: `${endpoint.product_group.addr}`,
      }),
  );
  /*  End Product Group Api */


  const [courseTags, setCourseTags] = useState([]);
  /* CourseTag Api*/
  const { data: tagsData, isSuccess: tags_data_status } = useQuery(
    `${endpoint.tags_data.key}`,
    () =>
      apirequest({
        url: `${endpoint.tags_data.addr}`,
      }),
  );

  /* End CourseTag Api*/
  const handleChange = e => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  /* End Form Submit */

  /* Fetch Update Course API */
  const fetchUpdateRequest = () => {
    return apirequest({
      url: endpoint.update_course.addr,
      method: "put",
      data: {
        requestType: "update",
        courseName: value.courseName,
        courseDescription: value.courseDescription,
        productGroupName: productGroup,
        isActive: isActive,
        modifiedBy: login_Details.userImsPin,
        tagName: courseTags,
        courseId: courseDetails.courseId,
      },
    });
  };
  const { refetch: UpdateData } = useQuery(
    `${endpoint.update_course.key}`,
    fetchUpdateRequest,
    {
      enabled: false,
      onSuccess: data => {
        alert(data.data.message);
      },
      onError: err => {
        alert(err.data.message);
      },
    },
  );

  /* Form Submit */
  const handleSubmit = e => {
    e.preventDefault();
    let flag = true;
    let isFormValidated = () => {
      if (value.courseName === ""|| WhiteSpace(value.courseName)===true) {
        flag = false;
        alert("Please Enter Course Name!");
        return;
      }
      if (productGroup === "") {
        flag = false;
        alert("Please Select Product Group!");
        return;
      }
      if (value.courseDescription === ""|| WhiteSpace(value.courseDescription)===true) {
        flag = false;
        alert("Please Enter Course Description!");
        return;
      }
      if (courseTags.length === 0) {
        flag = false;
        alert("Please Select Course Tags!");
        return;
      }
    };
    isFormValidated();
    if (flag) UpdateData();
  };
  /* End Fetch Update Course API */

  
  return (
    <div className="pb-[5rem]">
      <div className="courseTable__body md:ml-[5%] md:mr-[12%] mt-4 shadow-outer py-12 px-8 rounded-xl bg-white pb-[2%]">
        <form
          className="flex flex-col justify-start font-ibm"
          id="editCourse"
          onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
              Course Name{" "}
            </label>
            <input
              className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
              type="text"
              placeholder="Type course name"
              name="courseName"
              value={value.courseName}
              onChange={handleChange}
              autoComplete="off"
            />
          </div>
          <div className="flex mb-4">
            <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
              Product Group
            </label>
            <DropDown
              placeholder={"Search or Create new Group"}
              options={productGroupStatus ? product_groupName.data.data : []}
              type={"singleSelect"}
              width={"w-[22rem]"}
              setFieldValue={setProductGroup}
              readonly={false}
              valueReadProduct={courseDetails.productGroupName}
            />
          </div>
          <div className="flex mb-4">
            <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
              Course Description{" "}
            </label>
            <textarea
              className=" text-left ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none resize-none w-7/12 focus:placeholder-transparent placeholder-bold font-semibold"
              rows="4"
              cols="50"
              placeholder="Enter a short description about the course"
              name="courseDescription"
              value={value.courseDescription.trim()}
              onChange={handleChange}
            />
          </div>
          <div className="flex mb-4">
            <label className="inline-block w-[10%] text-left whitespace-nowrap mr-10 font-normal font-ibm text-lg mt-3">
              Course Tags{" "}
            </label>
            <DropDown
              placeholder={"Search exisiting or Create new tags"}
              options={tags_data_status ? tagsData.data.data : []}
              setUpdatedOptions={setCourseTags}
              type={"multiselect"}
              width={"w-[22rem]"}
              courseTags={courseTags}
              valueReadCourseTag={courseDetails.tagNames}
            />
          </div>
          <Toggle
            activeTab={activeTab}
            title={"This Course is"}
            setActiveTab={setActiveTab}
          />
        </form>
      </div>
      <div className="flex items-center justify-center mt-7 pr-20">
        <button
          type="submit"
          form="editCourse"
          className="bg-primary-200 text-white font-ibm px-12 rounded-xl hover:opacity-75 shadow-outer py-[10px] text-base font-medium mr-10 cursor-pointer">
          Save
        </button>
        <button
          className="text-black boxShadow-forButton font-ibm px-10 rounded-xl hover:opacity-75 shadow-outer text-base font-medium py-[10px] cursor-pointer"
          onClick={history.goBack}>
          Exit
        </button>
      </div>
    </div>
  );
};

export default CourseDetailForm;
