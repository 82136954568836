import React from "react";
import propTypes from "prop-types";
import TreeNode from "./TreeNode";
import CityCentreTreeNode from "./CityCentreTreeNode";

const AccessManagementTable = ({
  forCenter,
  forProfileModule,
  tableData,
  accessDetail,
  newArr,
  setNewArr,
  forEditUser,
  title,
}) => {
  
  return (
    <div className="m-5">
      <header className="flex justify-between shadow-outer rounded-t-xl px-7 pt-4 pb-2 font-bold">
        <div>
          <h3>{title}</h3>
        </div>
        <div className="flex space-x-10">
          <h3>View</h3>
          <h3>Edit</h3>
          <h3>Delete</h3>
        </div>
      </header>
      <div className="shadow-outer rounded-b-xl mt-0.5 pt-4">
        {tableData &&
          tableData.map((item, index) => {
            return forCenter ? (
              <CityCentreTreeNode
                data={item}
                key={index}
                newArr={newArr}
                setNewArr={setNewArr}
                accessDetails={accessDetail}
                forProfileModule={forProfileModule}
                forEditUser={forEditUser}
              />
            ) : (
              <TreeNode
                data={item}
                key={index}
                newArr={newArr}
                setNewArr={setNewArr}
                accessDetails={accessDetail}
                forProfileModule={forProfileModule}
                forEditUser={forEditUser}
              />
            );
          })}
      </div>
    </div>
  );
};

AccessManagementTable.propTypes = {
  forCenter: propTypes.bool,
  forProfileModule: propTypes.bool,
  tableData: propTypes.array,
  accessDetail: propTypes.object,
  newArr: propTypes.array,
  setNewArr: propTypes.func,
  forEditUser: propTypes.bool,
  title: propTypes.string,
};

export default AccessManagementTable;
