import moment from "moment";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg";
import NewDropDown from "../global/fields/NewDropDown";
import Loader from "../global/loader/Loader";
import WithModal from "../global/modal/WithModal/WithModal";
import CalendarModalTrigger from "../home/blogs/calendar/CalendarModalTrigger";
import propTypes from "prop-types";

const SlotModal = ({ closeModal, isEdit = false, slotToEdit }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const adminimspin = useSelector(
    (state) => state.loginData.LoginDetails.userImsPin
  );
  // alert(JSON.stringify(slotToEdit));
  const [slot, setSlot] = useState(
    !isEdit
      ? {
          num_terminals_available: null,
          venue_id: null,
          venue_name: null,
          centre_id: null,
          asset_id: id,
          date: null,
          slot_request_list: [],
        }
      : {
          ...slotToEdit,
          date: slotToEdit.slot_date,
          slot_request_list: isEdit
            ? [
                {
                  num_terminals_available: slotToEdit.num_terminals_available,
                  start_time: slotToEdit.slot_time_start,
                  end_time: slotToEdit.slot_time_end,
                },
              ]
            : [],
        }
  );
  const [timeSlot, setTimeSlot] = useState(
    isEdit
      ? {
          num_terminals_available: slotToEdit.num_terminals_available,
          start_time: slotToEdit.slot_time_start,
          end_time: slotToEdit.slot_time_end,
        }
      : {
          num_terminals_available: null,
          start_time: null,
          end_time: null,
        }
  );

  const getVenues = () => {
    return apirequest({
      url: `${endpoint.getVenues.addr}`,
      method: "post",
      data: {
        adminimspin: adminimspin,
      },
    });
  };
  const addSlot = () => {
    return apirequest({
      url: `${endpoint.addSlot.addr}`,
      method: "post",
      data: {
        adminimspin: adminimspin,
        venue_id: Number(slot.venue_id),
        centre_id: Number(slot.centre_id),
        asset_id: Number(id),
        slot_request_list: slot.slot_request_list,
      },
    });
  };
  const editSlot = () => {
    return apirequest({
      url: `${endpoint.editSlot.addr}`,
      method: "post",
      data: {
        adminimspin: adminimspin,
        venue_id: Number(slot.venue_id),
        centre_id: Number(slot.centre_id),
        asset_id: Number(id),
        slot_request_list: slot.slot_request_list.map((s) => ({
          ...s,
          slot_id: slotToEdit.slot_id,
          num_terminals_available: Number(slot.num_terminals_available),
        })),
      },
    });
  };

  const { mutate, isLoading: isAdding } = useMutation({
    mutationKey: [endpoint.addSlot.key],
    mutationFn: isEdit ? editSlot : addSlot,
    onSuccess: (data) => {
      if (isEdit) {
        if (data.data.success) {
          alert(`Slot ${isEdit ? "updated" : "added"} successfully.`);
          queryClient.invalidateQueries({ queryKey: [endpoint.getSlots.key] });
          closeModal();
          return;
        }
        alert(`Failed to ${isEdit ? "update" : "add"} slot.`);
        return;
      }
      alert(`Slot ${isEdit ? "updated" : "added"} successfully.`);
      queryClient.invalidateQueries({ queryKey: [endpoint.getSlots.key] });
      closeModal();
    },
    onError: () => {
      alert(`Failed to ${isEdit ? "update" : "add"} slot.`);
    },
  });

  const { data: venues, isLoading } = useQuery({
    queryKey: [endpoint.getVenues.key],
    queryFn: getVenues,
    select: (data) => data.data.data.venueList,
  });
  if (isLoading) return <Loader />;
  return (
    <>
      <div className="p-5 flex flex-col gap-5" style={{ width: 500 }}>
        <div className="flex justify-between items-center gap-5">
          <h1 className="font-ibm text-lg font-medium">
            {isEdit ? "Update" : "Add"} Slot
          </h1>
          <Cancel className="cursor-pointer" onClick={closeModal} />
        </div>
        <div className="flex flex-col gap-2">
          <p>Venue</p>
          {isEdit ? (
            <div className=" flex items-center justify-between w-full border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3">
              <p className={`font-normal text-sm `}>{slot.venue_name}</p>
            </div>
          ) : (
            <NewDropDown
              selected={slot.venue_name}
              setSelected={(id, name) => {
                setSlot((prev) => ({
                  ...prev,
                  venue_id: id,
                  venue_name: name,
                }));
              }}
              dropDownOptions={venues}
              optionId={"venueId"}
              optionName={"venueName"}
            />
          )}
          {slot.venue_id && venues && (
            <>
              <p>Centre</p>
              {isEdit ? (
                <div className=" flex items-center justify-between w-full border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3">
                  <p className={`font-normal text-sm `}>{slot.centre_name}</p>
                </div>
              ) : (
                <NewDropDown
                  selected={slot.centre_name}
                  setSelected={(id, name) => {
                    setSlot((prev) => ({
                      ...prev,
                      centre_id: id,
                      centre_name: name,
                    }));
                  }}
                  dropDownOptions={
                    venues.find((venue) => venue.venueId === slot.venue_id)
                      ?.centres || []
                  }
                  optionId={"centre_id"}
                  optionName={"centre_name"}
                />
              )}
            </>
          )}
          <p>Date</p>
          <div className="w-full flex border border-secondary-300 rounded-xl px-4 py-4 ">
            <input
              placeholder="Select Date"
              className={`w-full outline-none ${
                slot.date
                  ? "text-base text-secondary-600  font-medium"
                  : "text-sm  text-primary-400"
              }`}
              value={slot.date}
            />
            {!slotToEdit?.booked_terminals > 0 && (
              <CalendarModalTrigger
                setDate={(date) => {
                  if (!isEdit) {
                    setSlot((prev) => ({ ...prev, date: date }));
                  } else {
                    setSlot((prev) => ({
                      ...prev,
                      date: date,
                      slot_request_list: [
                        {
                          num_terminals_available: Number(
                            prev.num_terminals_available
                          ),
                          start_time: `${date} ${
                            prev.slot_request_list[0].start_time.split(" ")[1]
                          }`,
                          end_time: `${date} ${
                            prev.slot_request_list[0].end_time.split(" ")[1]
                          }`,
                        },
                      ],
                    }));
                  }
                }}
              />
            )}
          </div>
          <p>Terminals Available</p>
          <div className="w-full flex border border-secondary-300 rounded-xl px-4 py-4 ">
            <input
              onChange={(e) =>
                setSlot((prev) => ({
                  ...prev,
                  num_terminals_available: e.target.value,
                }))
              }
              value={slot.num_terminals_available}
              type="number"
              className="w-full outline-none text-base text-secondary-600  font-medium"
            />
          </div>
          <p>Time Slot</p>
          <div className="flex items-center gap-2 ">
            <div className="grid grid-cols-2 gap-2 w-full">
              <div>
                <p className="text-sm">From:</p>
                <div className="w-full flex border border-secondary-300 rounded-xl px-4 py-4 ">
                  <input
                    disabled={isEdit && slotToEdit?.booked_terminals > 0}
                    onChange={(e) => {
                      if (!slot.date) {
                        alert("Please select date before time slot.");
                        setTimeSlot((prev) => ({
                          ...prev,
                          start_time: null,
                        }));
                        return;
                      }
                      if (slot.date) {
                        setTimeSlot((prev) => ({
                          ...prev,
                          start_time: `${slot.date} ${e.target.value}:00`,
                          num_terminals_available: Number(
                            slot.num_terminals_available
                          ),
                        }));
                        if (isEdit) {
                          setSlot((prev) => ({
                            ...prev,
                            slot_request_list: [
                              {
                                num_terminals_available: Number(
                                  slot.num_terminals_available
                                ),
                                start_time: `${slot.date} ${e.target.value}:00`,
                                end_time: `${slot.date} ${
                                  timeSlot?.start_time?.split(" ")[1]
                                }`,
                              },
                            ],
                          }));
                        }
                      }
                    }}
                    value={timeSlot?.start_time?.split(" ")[1]}
                    className={`w-full outline-none text-base text-secondary-600  font-medium disabled:text-secondary-400`}
                    type="time"
                  />
                </div>
              </div>
              <div>
                <p className="text-sm">To:</p>
                <div className="w-full flex border border-secondary-300 rounded-xl px-4 py-4 ">
                  <input
                    disabled={isEdit && slotToEdit?.booked_terminals > 0}
                    onChange={(e) => {
                      if (!slot.date) {
                        alert("Please select date before time slot.");
                        setTimeSlot((prev) => ({
                          ...prev,
                          end_time: null,
                        }));
                        return;
                      }
                      if (slot.date) {
                        setTimeSlot((prev) => ({
                          ...prev,
                          end_time: `${slot.date} ${e.target.value}:00`,
                          num_terminals_available: Number(
                            slot.num_terminals_available
                          ),
                        }));
                        if (isEdit) {
                          setSlot((prev) => ({
                            ...prev,
                            slot_request_list: [
                              {
                                num_terminals_available: Number(
                                  slot.num_terminals_available
                                ),
                                end_time: `${slot.date} ${e.target.value}:00`,
                                start_time: `${slot.date} ${
                                  timeSlot?.start_time?.split(" ")[1]
                                }`,
                              },
                            ],
                          }));
                        }
                      }
                    }}
                    value={timeSlot?.end_time?.split(" ")[1]}
                    className={`w-full outline-none text-base text-secondary-600  font-medium disabled:text-secondary-400`}
                    type="time"
                  />
                </div>
              </div>
            </div>
            {!isEdit && (
              <button
                disabled={isEdit && slotToEdit.booked_terminals > 0}
                onClick={() => {
                  if (!timeSlot.start_time || !timeSlot.end_time) {
                    alert(
                      "Please select start and end time before adding slot."
                    );
                    return;
                  }
                  const start_time = `${slot.date} ${
                    timeSlot?.start_time?.split(" ")[1]
                  }`;
                  const end_time = `${slot.date} ${
                    timeSlot?.end_time?.split(" ")[1]
                  }`;
                  const isNotValid = slot.slot_request_list.find(
                    (request) =>
                      request.end_time === end_time &&
                      request.start_time === start_time
                  );
                  if (isNotValid) return alert("Slot request already added.");
                  setSlot((prev) => ({
                    ...prev,
                    slot_request_list: [
                      ...prev.slot_request_list,
                      {
                        ...timeSlot,
                        end_time,
                        start_time,
                      },
                    ],
                  }));
                }}
                style={{
                  marginTop: "18px",
                }}
                className="p-2 py-4 bg-primary-200 rounded-xl flex items-center justify-center text-white"
              >
                Add
              </button>
            )}
          </div>
          {!isEdit && (
            <div className="flex items-center gap-2 flex-wrap">
              {slot.slot_request_list.map((time_slot, slot_index) => (
                <div
                  key={slot_index}
                  className="mr-2 bg-gray-100 px-3 py-2 rounded-2xl text-sm capitalize flex items-center gap-2"
                >
                  {`${moment(
                    time_slot.start_time.split(" ")[1],
                    "HH:mm"
                  ).format("hh:mm A")} - ${moment(
                    time_slot.end_time.split(" ")[1],
                    "HH:mm"
                  ).format("hh:mm A")}`}
                  <button
                    disabled={slotToEdit && slotToEdit.booked_terminals > 0}
                    className={
                      slotToEdit && slotToEdit.booked_terminals > 0
                        ? "hidden"
                        : "block"
                    }
                    onClick={() => {
                      const newSlots = [...slot.slot_request_list];
                      newSlots.splice(slot_index, 1);
                      console.log(newSlots);
                      setSlot((prev) => {
                        return {
                          ...prev,
                          slot_request_list: newSlots,
                        };
                      });
                    }}
                  >
                    x
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex justify-center mt-5 space-x-5">
          <button
            disabled={isAdding}
            onClick={() => {
              if (!slot.venue_id) {
                alert("Please Select Venue");
                return;
              } else if (!slot.centre_id) {
                alert("Please Select Centre");
                return;
              } else {
                mutate();
              }
            }}
            className="px-10 py-3 bg-primary-300 text-white rounded-2xl shadow-outer"
          >
            {isEdit ? "Update" : "Add"}
          </button>
          <button
            className="px-10 py-3 font-medium text-base rounded-2xl shadow-outer"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

SlotModal.propTypes = {
  closeModal: propTypes.any,
  isEdit: propTypes.bool,
  slotToEdit: propTypes.any,
};
export default WithModal(SlotModal);
