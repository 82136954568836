import React, { useState } from "react";
import Header from "../../components/header/Header";
import { Link } from "react-router-dom";
import CourseTable from "../../components/table/CoursesTable";
import TablePagination from "../../components/table/TablePagination";
import testIcon from "../../assets/icons/test.svg";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import Loader from "../../components/global/loader/Loader";
import TableSearch from "../../components/table/TableSearch";
import { globalFilter } from "../../components/global/globalFilter/GlobalFilter";
import FilterFunctionlity from "../../components/filterFunctionality/FilterFunctionlity";

const Courses = () => {
  const [coursesData, setCoursesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productGroupId , setproductGroupId] = useState([]);

  const modulesData = globalFilter({name : "Courseware", subModuleName : "Courses"});
  const column = [
    { col: "0", width: "45%" },
    { col: "1", width: "50%" },
    { col: "2", width: "" },
  ];
  const {
    data: courseData,
    error,
    isSuccess,
    // isError,
    status,
    refetch,
  } = useQuery(
    `${endpoint.course_data.key}`,
    () =>
      apirequest({
        url: `${endpoint.course_data.addr}`,
        method: "Post",
        data: {
          adminimspin:modulesData.imsPin ,
        },
      }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      onSuccess: (res) => setCoursesData(res),
    }
  );

  const { data: getProductGroupData } = useQuery(`${endpoint.product_group.key}`, () =>
    apirequest({
      url: `${endpoint.product_group.addr}`,
    }),{
    select : res=>res.data.data
  });

  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };

  return (
    <div id="courses">
      <Header />
      {status === "loading" ? (
        <Loader />
      ) : (!isSuccess && error?.status !== 404 )? (
        <span>{error?.message}</span>
      ) : (
        <div className="courses">
          <div className="courseTable mt-9">
            <div className="courseTable__head flex justify-between  md:mt-[4%] md-mb-[5%] md:mx-9 pl-[2%]">
              <div className="flex">
                <FilterFunctionlity
                  filterText="Product Group Name"
                  setFunction={setCoursesData}
                  dropdownData={getProductGroupData}
                  url={endpoint.course_data.addr}
                  payload={productGroupId}
                  setPayload={setproductGroupId}
                  setCurrentPage={setCurrentPage}
                  payloadData={{
                    adminimspin: modulesData.imsPin,
                    productGroupId: productGroupId,
                  }}
                  filterId="productGroupId"
                  filterValue="productGroupName"
                />
              </div>
              <div className="flex">
                <TableSearch
                  defaultData={courseData}
                  setDefaultData={setCoursesData}
                  dataKey="courseName"
                  dataForFilter={courseData?.data?.data?.courseList}
                  name="Courseware"
                  subModuleName="Courses"
                  setCurrentPage={setCurrentPage}
                  childModuleName=""
                  placeholder="Search by Courses"
                />
                {(courseData?.data?.data?.global_add_access === "1" || error?.data.message ==="No Record found") && (
                  <div className="ml-4 flex">
                    <div className="ml-6 bg-primary-200 text-white font-normal px-6 rounded-xl hover:opacity-75 shadow-outer text-lg py-[7px]">
                      <Link
                        className="text-white hover:text-white"
                        to="/Courseware/Courses/add-Course">
                        Add Course
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="courseTable__body md:ml-[4%] md:mr-[34px] mt-4 shadow-outer pt-3 pl-[2%] pr-[3%] rounded-xl bg-white pb-[2%]">
              <CourseTable
                column={column}
                currentData={coursesData?.data?.data?.courseList.slice(
                  indexOfFirstItem,
                  indexOfLastItem,
                )}
                colIcon={testIcon}
                refetch={refetch}
              />
            </div>
            <div className="courseTable__footer mx-auto mb-7">
              <TablePagination
                data={coursesData?.data?.data?.courseList}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
                dataPerPage={dataPerPage}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Courses;
