import React, { useState } from "react";
import Header from "../../header/Header";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import Dropdown from "../blogs/Dropdown";
import propTypes from "prop-types";
import DropDown from "../../global/fields/CourseDropDown";
import DropDownTags from "../../global/fields/DropDown";
import ToggleYesNo from "../../global/fields/ToggleYesNo";
import CalendarModalTrigger from "../blogs/calendar/CalendarModalTrigger";
import Recurring from "./Recurring";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import WhiteSpace from "../../global/regEx/WhiteSpaceValidator";

const AddNotice = () => {

  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  let history = useHistory();
  const [courseTags, setCourseTags] = useState([]);
  const [course, setCourse] = useState([]);

  const eventsData =useSelector(state => state.slideshowData.slideShowDetails);
  let setTime = eventsData?.startTime?.split(":");
  let endTime = eventsData?.endTime?.split(":");

  const [valueCourse, setValueCourse] = useState(eventsData.courseNames);
  const [value, setValue] = useState({
    EventName: eventsData.calendarEventName,
    Venue: eventsData.venue,
    TypeEvent: eventsData.eventType,
    CTAName: eventsData.ctaName,
    URLtoCTA: eventsData.link,
    TexttoDisplay: eventsData.description,
    AuthorName: eventsData.authorName,
  });
  const [toggleState, setToggleState] = useState(
    eventsData.meetingRecurrance === "1" ? true : false,
  );
  const [showLabel, setShowLabel] = useState(eventsData.courseNames);
  const [date, setDate] = useState(eventsData.startDate);
  const [enddate, setEndDate] = useState(eventsData.endDate==="None" ?"Select End Date":eventsData.endDate);
  const [countdownDate, setCountdownDate] = useState(eventsData.countDownDate);
  const [StartHour, setStartHour] = useState(setTime[0] ?? 0);
  const [StartMinute, setStartMinute] = useState(setTime[1].replace(/^0/, ""));
  const [StartSecond, setStartSecond] =useState(setTime[2].replace(/^0/, ""));
  const [EndHour, setEndHour] = useState(endTime[0] ?? 0);
  const [EndMinute, setEndMinute] = useState(endTime[1].replace(/^0/, ""));
  const [EndSecond, setEndSecond] =useState(endTime[2].replace(/^0/, ""));
  const [PublishHour, setPublishHour] = useState(
    eventsData.publishTime.split(":")[0],
  );
  const [PublishMinute, setPublishMinute] = useState(
    eventsData.publishTime.split(":")[1],
  );
  const [PublishSecond, setPublishSecond] = useState(
    eventsData.publishTime.split(":")[2],
  );

  // state to be passed for reccuring  as prop
  const [repeat, setRepeat] = useState(eventsData.meetingRecurrance === "1" ? eventsData.repeatCount : "1");
  const [repeatValue, setRepeatValue] = useState(eventsData.meetingRecurrance === "1" ? eventsData.repeatType : "Day");
  const [Endsvalue, setEndsValue] = useState(eventsData.endsAfter?eventsData.endsAfter:"");
  const [type, setType] = useState(
    eventsData.endsNever === "1"
      ? "Never"
      : eventsData.endsOn ==="None"
        ? "After"
        : "On",
  );
  const [recurringDate, setrecurringDate] = useState(eventsData.endsOn);
  const [filters, handleFilters] = useState(eventsData.repeatOn);
  const [Checked, setChecked] = useState(eventsData.repeatOn);
  const { data: getCourseData,isSuccess } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method : "post",
      data : {
        "adminimspin":login_Details.userImsPin
      }
    }), 
  );
  // Query for tags data,eg: ["CAT", "XAT"]==========>
  const { data: tagsData, isSuccess: tags_data_status } = useQuery(
    `${endpoint.tags_data.key}`,
    () =>
      apirequest({
        url: `${endpoint.tags_data.addr}`,
      }),
  );

  const fetchUpdateEvent = () => {
    return apirequest({
      url: `${endpoint.update_calendarEvent.addr}`,
      method: "put",
      data: {
        calendarEventName: value.EventName,
        startDate: date,
        startTime: `${StartHour}:${StartMinute}:${StartSecond}`,
        endDate: enddate==="Select End Date"?"":enddate,
        endTime: `${EndHour}:${EndMinute}:${EndSecond}`,
        meetingRecurrance: toggleState == true ? "1" : "0",
        repeatCount: toggleState == true ?repeat?repeat:"":"",
        repeatType: toggleState == true ?repeatValue:"",
        repeatOn: repeatValue==="Week"?filters:[],
        endsNever: type == "Never" ? "1" : "0",
        // if type=on, then show the ends On Date
        endsOn:type == "On" ? recurringDate : "",
        endsAfter:type == "After" ? Endsvalue : "",
        venue: value.Venue,
        eventType: value.TypeEvent,
        ctaName: value.CTAName,
        link: value.URLtoCTA,
        publishTime: `${PublishHour}:${PublishMinute}:${PublishSecond}`,
        countDownDate: countdownDate,
        description: value.TexttoDisplay,
        authorName: value.AuthorName,
        isActive: "1",
        modifiedBy: login_Details.userImsPin,
        calendarEventId: eventsData.calendarEventId,
        courseName: showLabel,
        tagName: courseTags,
      },
    });
  };
  //API request to edit events data
  const { refetch: UpdateEventRefetch } = useQuery(
    endpoint.update_calendarEvent.key,
    fetchUpdateEvent,
    {
      enabled: false,
      onSuccess :(res)=>alert(res.data.message),
      onError:(err)=>{
        alert(err.data.message);
      }
    },
  );
  const HandleChange = e => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const onsave = e => {

    if(enddate!=="Select End Date" && toggleState=== true){
      alert("please select meeting recurring as No or don't select End Date");
    }
    else if(enddate==="Select End Date" && toggleState=== false){
      alert("please select End Date");
    }
    else if((Number(date.replace(/-/g, "")))>Number(enddate.replace(/-/g, ""))){
      alert("please select End Date after Start Date");
    }
    else if(WhiteSpace(value.EventName)===true ||WhiteSpace(value.Venue)===true ||WhiteSpace(value.TypeEvent)===true ||WhiteSpace(value.CTAName)===true ||WhiteSpace(value.URLtoCTA)===true ||WhiteSpace(value.TexttoDisplay)===true ||WhiteSpace(value.AuthorName)===true){
      alert("Required Fields can't be blank spaces");
    }
    // to make course selection mandatory
    else if(showLabel.length===0){
      alert("Please Select Course");
    }
    else{
      UpdateEventRefetch();
    }
    e.preventDefault();
  };
  // useEffect(() => {
  //   if(enddate === "Select End Date"){
  //     setToggleState(false);
  //   }
  // }, [enddate]);
  return (
    <div className="flex flex-col ">
      <Header />
      <BreadCrumb />

      <div className="mt-2 mx-12 px-8 py-2 ">
        <form
          className=" flex flex-col relative bg-secondary-100 py-6 shadow-outer"
        >
          <div className="h-full space-y-4  px-12 py-6 relative">
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Event Title
                <input
                  type="text"
                  name="EventName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={value.EventName}
                  onChange={HandleChange}
                />
              </label>
            </div>
            <div className="flex space-x-28">
              <label className=" font-normal text-lg font-ibm ">Course/s</label>
              {isSuccess &&
              <DropDown
                placeholder={"Search Course(s)"}
                options={getCourseData?.data?.data?.courseList}
                setUpdatedOptions={setCourse}
                type={"multiselect"}
                width={"w-[22rem]"}
                courseTags={course}
                showLabel={showLabel}
                setShowLabel={setShowLabel}
                value={valueCourse}
                setValue={setValueCourse}
                forCourses ={true}
              />}
            </div>
            <div className="flex space-x-36">
              <label className=" font-normal text-lg font-ibm ">Tag/s</label>
              {tags_data_status && (
                <DropDownTags
                  placeholder={"Search exisiting or Create new tags"}
                  options={tagsData.data.data}
                  setUpdatedOptions={setCourseTags}
                  type={"multiselect"}
                  width={"w-[22rem]"}
                  courseTags={courseTags}
                  valueReadCourseTag={eventsData.tagNames}
                />
              )}
            </div>
            <div className="flex space-x-32">
              <label className=" font-normal text-lg font-ibm ">
                Start Date
              </label>

              <div className="w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    date
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"
                  }`}>
                  {date && date}
                </p>
                <CalendarModalTrigger setDate={setDate} />
              </div>
            </div>
            <div className="flex">
              <>
                <label className=" font-normal text-lg font-ibm ">
                  Start time
                </label>
                <div className="ml-32">
                  <Dropdown
                    minOffset={0}
                    maxOffset={23}
                    text={"Hrs"}
                    selectedValue={StartHour}
                    setSelectedValue={setStartHour}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Mins"}
                    selectedValue={StartMinute}
                    setSelectedValue={setStartMinute}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Sec"}
                    selectedValue={StartSecond}
                    setSelectedValue={setStartSecond}
                  />
                </div>
              </>
            </div>
            <div className="flex space-x-32">
              <label className=" font-normal text-lg font-ibm ">End Date</label>
              <div className="relative w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                {enddate!=="Select End Date" &&
                <span  className="absolute bottom-[5px] text-xs font-medium cursor-pointer left-1/2 text-gray-500" onClick={()=> setEndDate("Select End Date")}>&#10005;</span>}
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    enddate
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"
                  }`}>
                  {enddate && enddate}
                </p>
                <CalendarModalTrigger setDate={setEndDate} />
              </div>
            </div>
            <div className="flex">
              <label className=" font-normal text-lg font-ibm ">End time</label>
              <div className="ml-32">
                <Dropdown
                  minOffset={StartHour}
                  maxOffset={23}
                  text={"Hrs"}
                  selectedValue={EndHour}
                  setSelectedValue={setEndHour}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={Number(StartHour)===Number(EndHour) ? Number(StartMinute)+1 : 0}
                  maxOffset={59}
                  text={"Mins"}
                  selectedValue={EndMinute}
                  setSelectedValue={setEndMinute}
                />
              </div>
              <div className="ml-2">
                <Dropdown
                  minOffset={0}
                  maxOffset={59}
                  text={"Sec"}
                  selectedValue={EndSecond}
                  setSelectedValue={setEndSecond}
                />
              </div>
            </div>
            <ToggleYesNo
              title={"Is the meeting recurring?"}
              setToggleState={setToggleState}
              toggleState={toggleState}
              disable={enddate === "Select End Date"?false:true}
              mg={28}
            />
            {toggleState && (
              <Recurring
                date={recurringDate}
                setDate={setrecurringDate}
                type={type}
                setType={setType}
                Endsvalue={Endsvalue}
                setEndsValue={setEndsValue}
                filters={filters}
                handleFilters={handleFilters}
                repeat={repeat}
                setRepeat={setRepeat}
                repeatValue={repeatValue}
                setRepeatValue={setRepeatValue}
                Checked={Checked}
                setChecked={setChecked}
                startDate={date}
              />
            )}
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Venue
                <input
                  type="text"
                  name="Venue"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={value.Venue}
                  onChange={HandleChange}
                />
              </label>
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Type of Event
                <input
                  type="text"
                  name="TypeEvent"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={value.TypeEvent}
                  onChange={HandleChange}
                />
              </label>
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                CTA Name
                <input
                  type="text"
                  name="CTAName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={value.CTAName}
                  onChange={HandleChange}
                />
              </label>
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                URL to CTA
                <input
                  name="URLtoCTA"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={value.URLtoCTA}
                  onChange={HandleChange}
                />
              </label>
            </div>
            <div className="flex">
              <>
                <label className=" font-normal text-lg font-ibm ">
                  Publish time
                </label>
                <div className="ml-32">
                  <Dropdown
                    minOffset={0}
                    maxOffset={23}
                    text={"Hrs"}
                    selectedValue={PublishHour}
                    setSelectedValue={setPublishHour}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Mins"}
                    selectedValue={PublishMinute}
                    setSelectedValue={setPublishMinute}
                  />
                </div>
                <div className="ml-2">
                  <Dropdown
                    minOffset={0}
                    maxOffset={59}
                    text={"Sec"}
                    selectedValue={PublishSecond}
                    setSelectedValue={setPublishSecond}
                  />
                </div>
              </>
            </div>
            <div className="flex space-x-32">
              <label className=" font-normal text-lg font-ibm ">
                Countdown Date
              </label>

              <div className="relative w-72 flex border-2 border-gray-200 rounded-xl px-4 pt-2 ml-6 h-10">
                {countdownDate !== "Select Date" && (
                  <span
                    className="absolute bottom-[5px] text-xs font-medium cursor-pointer left-1/2 text-gray-500"
                    onClick={() => setCountdownDate("Select Date")}
                  >
                    &#10005;
                  </span>
                )}
                <p
                  className={`w-full outline-none boder border-gray-200 ${
                    date
                      ? "text-base text-secondary-400  font-normal"
                      : "text-sm  text-primary-700"
                  }`}>
                  {countdownDate && countdownDate}
                </p>
                <CalendarModalTrigger setDate={setCountdownDate} />
              </div>
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Text to Display
                <input
                  type="text"
                  name="TexttoDisplay"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={value.TexttoDisplay}
                  onChange={HandleChange}
                />
              </label>
            </div>
            <div className="flex  justify-between py-5">
              <label className=" font-normal text-lg font-ibm ">
                Author Name
                <input
                  type="text"
                  name="AuthorName"
                  className="border-2 h-10 border-secondary-300 absolute left-64 w-3/6 rounded-lg focus:outline-none"
                  value={value.AuthorName}
                  onChange={HandleChange}
                />
              </label>
            </div>
          </div> 
        </form>
        <div className=" space-x-6 flex justify-center mt-2">
          <button
            onClick={onsave}
            className=" px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            type="submit">
            SAVE
          </button>
          <button onClick={history.goBack} className=" p-3 text-lg font-ibm font-normal   border border-transparent rounded-2xl bg-secondary-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
            EXIT
          </button>
        </div>
      </div>
    </div>
  );
};
AddNotice.propTypes = {
  closeModal: propTypes.func,
  setIsCalendarOpen: propTypes.func,
};

export default AddNotice;
