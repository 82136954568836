import React from "react";
import BreadCrumb from "../../global/breadcrumbs/BreadCrumb";
import Header from "../../header/Header";
import CriteriaForm from "./CutOffForm";

const SelectionCriteria = () => {
  return (
    <div>
      <Header />
      <div className="flex justify-between">
        <BreadCrumb />
        <div>
          {" "}
          <div className="grid grid-cols-2 gap-2 w-[100%] h-[50px] pt-5">
            <button
              className="px-6 py-3 bg-primary-300 text-white h-[50px] rounded-2xl shadow-outer "
              // onClick={handleClick}
            >
              Import Cut-Offs
            </button>
            <button
              className="px-6 py-3 bg-primary-300 text-white  h-[50px] rounded-2xl shadow-outer cursor-pointer"
              //   onClick={handleClick}
            >
              Download recent CSV
            </button>
          </div>
        </div>
      </div>
      <div>
        <CriteriaForm />
      </div>
    </div>
  );
};

export default SelectionCriteria;
