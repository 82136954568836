import React from "react";
import arrowLeft from "../../../../assets/icons/arrow-left-full.svg";
import {ReactComponent as Cancel} from "../../../../assets/icons/cancel.svg";
import propTypes from "prop-types";

function BackNCancelButton({ currentScreen, setCurrentScreen, closeModal, setError, setCourse, setVariant }) {
  return (
    <div
      className={`flex justify-between mb-10  ${
        currentScreen == 0 && "pl-[18rem]"
      }`}>
      {currentScreen != 0 && (
        <div
          className="rounded-full shadow-forButton flex p-[9px] bg-white h-10 cursor-pointer"
          onClick={() => {
            setError(null);
            if(currentScreen === 3)
              setVariant(null);
            if(currentScreen === 2)
              setCourse(null);
            setCurrentScreen(currentScreen - 1);
          }}>
          <img src={arrowLeft} />
        </div>
      )}
      <p className="font-ibm text-lg text-secondary-600 font-medium text-center">
        {currentScreen == 0 ? "Browse Learning Path" : "Import Learning Path"}
      </p>
      <Cancel className="cursor-pointer" onClick={closeModal} />
    </div>
  );
}

BackNCancelButton.propTypes = {
  currentScreen: propTypes.number,
  setCurrentScreen: propTypes.func,
  closeModal: propTypes.func,
  setError: propTypes.func,
  setCourse: propTypes.func,
  setVariant: propTypes.func,
};
export default BackNCancelButton;
