import React, { useState } from "react";
import SearchIcon from "../../assets/icons/search-no-bg.svg";
import { ReactComponent as ClearIcon } from "../../assets/icons/cancel.svg";
import OutsideClickHandler from "react-outside-click-handler";
import propTypes from "prop-types";
import { useFullTextSearchCustomCall } from "../global/fullTextSearch/FullTextSearch";
import { SearchDropBox } from "../global/fields/SearchDropBox";
import { globalFilter } from "../global/globalFilter/GlobalFilter";
import { useSelector } from "react-redux";

function TableSearch({
  setDefaultData,
  defaultData,
  dataKey,
  name ,
  subModuleName,
  dataForFilter,
  setCurrentPage,
  childName,childModuleName,
  courseId,
  blogId,
  contentRepoID,
  placeholder,noSubmodules
}) {
  const modulesData = globalFilter({name : name, subModuleName :  subModuleName , childName : childName});
  const [showInput, setShowInput] = useState(false);
  // const placeholder = "Search by learning assests";
  const[keyCodde , setKeyCode]  = useState("");
  const [searchValue, setSearchValue] = useState("");
  const loginDetails = useSelector((state) => state?.loginData?.LoginDetails);
 
  const outSideClickHandler = () => {
    setShowInput(false);
  };

  let spliitedValue = searchValue?.split(" ");

  const handleChange = (e) =>{
    if(searchValue === "" && keyCodde === 32){
      return ;
    }

    if(spliitedValue?.length>1 && spliitedValue[spliitedValue?.length -1] === ""){
      if(keyCodde === 32){
        return searchValue ;
      }else{setSearchValue(e.target.value);}
    }else {setSearchValue(e.target.value);}
  };
  
  const handleKeyDown = (event)=>{
    setKeyCode(event.keyCode);
    if(event.keyCode === 13){
      refetch();
    }
  };

  const clearTextHandler = () => {
    setSearchValue("");
    setDefaultData(defaultData);
  };

  let payloadData = courseId ? {
    moduleId: modulesData.id ,
    moduleName: modulesData.name,
    childModuleName: childModuleName,
    searchKey: spliitedValue,
    adminimspin: modulesData.imsPin,
    courseId : courseId
  } :blogId ?{

    moduleId: modulesData.id ,
    moduleName: modulesData.name,
    childModuleName: childModuleName,
    searchKey: spliitedValue,
    adminimspin: modulesData.imsPin,    
    blogId : blogId

  }: contentRepoID ? {
    "moduleId" : modulesData.id,
    "moduleName" : "Content Repository",
    "childModuleName" : "AssetListing",
    "contentRepositoryId" :contentRepoID,
    "searchKey" : spliitedValue,
    "adminimspin" : modulesData.imsPin
  } :  noSubmodules ?{
    "moduleId" : "27",
    "moduleName" : "Mentor MyPlan List",
    "childModuleName" : "",
    "searchKey" : spliitedValue,
    "adminimspin" : loginDetails.userImsPin
  }: {
    moduleId: modulesData?.id ,
    moduleName: modulesData?.name,
    childModuleName: childModuleName,
    searchKey: name === "School" ? [searchValue] :  spliitedValue,
    adminimspin: modulesData?.imsPin,
  };

  // calling custom hook for full text search ========>
  const { refetch } = useFullTextSearchCustomCall({
    payloadData,
    enabled: false,
    setFilteredata: setDefaultData,
    setCurrentPage : setCurrentPage,
  });


  const handleSubmit = () => {
    setShowInput(true);

    showInput &&  refetch();
  };

  const dataForSearchBox = dataForFilter?.filter((data)=>{
    return spliitedValue?.some((item)=>{
      if(data[dataKey]?.toLowerCase().includes(item.toLowerCase())){return data;}
    });
  });

  return (
    <OutsideClickHandler onOutsideClick={outSideClickHandler}>
      <div>
        <div
          className={`z-50 ${
            showInput ? "min-w-[26rem] " : ""
          } rounded-2xl shadow-outer flex p-[1rem]  relative bg-white `}
        >
          {showInput && (
            <div className={`${showInput ? "w-full" : ""} flex  items-center`}>
              <input
                type="text"
                name="search"
                value={searchValue}
                placeholder={placeholder}
                onChange={(e) => handleChange(e)}
                autoFocus
                onKeyDown ={handleKeyDown}
                className="w-full text-secondary-600 font-normal font-ibm focus:outline-none"
                autoComplete="off"
              />
              <ClearIcon
                className="mr-5 cursor-pointer"
                onClick={clearTextHandler}
              />
            </div>
          )}
          <div className="h-5 w-5 flex items-center justify-end">
            <img
              src={SearchIcon}
              className="cursor-pointer"
              alt="search"
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
        {(searchValue[0]?.length>0 && showInput) &&
          <div className="ml-5">
            <ul className="h-auto pt-2 w-96 border-2 border-solid border-gray-200 rounded-lg shadow-lg absolute z-50 bg-white overflow-y-scroll scrollbar scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded  max-h-[10rem]">
              {dataForSearchBox?.length>0 ? dataForSearchBox.map((data , ind)=>(
                <SearchDropBox
                  name = {data[dataKey]}
                  setInputValue = {setSearchValue}
                  inputValue = {searchValue}
                  key = {ind}
                  data = {dataForFilter}
                />
              )) : "No Record Found"}
            </ul>
          </div>
        }
      </div>
    </OutsideClickHandler>
  );
}
TableSearch.propTypes = {
  defaultData : propTypes.any,
  setDefaultData : propTypes.func,
  dataForFilter : propTypes.array,
  dataKey :propTypes.string,
  name : propTypes.string,
  subModuleName :propTypes.string,
  setCurrentPage : propTypes.func,
  childName : propTypes.string,
  childModuleName : propTypes.string,courseId : propTypes.string,
  blogId : propTypes.string,
  contentRepoID : propTypes.string,
  placeholder: propTypes.string,
  noSubmodules : propTypes.bool
};
export default TableSearch;
