import React, { useState } from "react";
import arrowDown from "../../../../assets/icons/arrow-down.svg";
import propTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";

function Multiselect({ options, selectedOptions, setOptions, removeOptions }) {
  const [showDropdown, setShowDropdown] = useState(false);
  function handleOptionSelect(option){
    setOptions(option);
  }
  function removeOption(option){
    removeOptions(option);
  }
  return (
    <div className="flex ml-20">
      {/***** Multi select dropDown *****/}
      <div className="w-[231px] p-[14px] flex justify-between border border-[#C7C2C2] relative mr-6 rounded-xl ">
        <p
          className="cursor-pointer"
          onClick={() => setShowDropdown(!showDropdown)}>
          Options
        </p>
        <img
          className="cursor-pointer"
          src={arrowDown}
          alt="down"
          onClick={() => setShowDropdown(!showDropdown)}
        />
        {showDropdown && (
          <OutsideClickHandler
            onOutsideClick={() => setShowDropdown(!showDropdown)}>
            <div className="absolute bg-white top-[70px] left-0 shadow-outer w-full p-5 mb-3 rounded-xl max-h-56 overflow-scroll ">
              {options?.map(option => (
                <p
                  onClick={() => handleOptionSelect(option)}
                  key={option.courseId}
                  className="mb-2 cursor-pointer hover:font-semibold ">
                  {option.courseName}
                </p>
              ))}
            </div>
          </OutsideClickHandler>
        )}
      </div>
      {/***** Selected Options *****/}
      <div className="flex">
        {selectedOptions?.map(option => (
          <div
            key={option.courseId}
            className="bg-[#f4f5f6] flex items-center px-6 relative rounded-xl mr-5 ">
            <p>{option.courseName}</p>
            <p onClick={() => removeOption(option)} className="absolute right-2 top-1 z-50 cursor-pointer text-[#C7CCD2] ">x</p>
          </div>
        ))}
      </div>
    </div>
  );
}

Multiselect.propTypes = {
  options: propTypes.array,
  selectedOptions: propTypes.array,
  setOptions: propTypes.func,
  courses: propTypes.array,
  setCourses: propTypes.func,
  removeOptions: propTypes.func,
};

export default Multiselect;
