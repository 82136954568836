import React from "react";
import AssetTree from "../../../global/tree/AssetTree";
import propTypes from "prop-types";
import { useSelector } from "react-redux";
const AddAssetTree = ({ setDisableSave, parentId }) => {
  const assetTierObject = useSelector(
    state => state.learningPathData.assetTierObject,
  );
  return (
    <AssetTree
      data={assetTierObject}
      count={1}
      depth={1}
      treeIndexes={[0]}
      setDisableSave={setDisableSave}
      parentId={parentId}
    />
  );
};
AddAssetTree.propTypes = {
  setDisableSave: propTypes.func,
  parentId: propTypes.string,
};
export default AddAssetTree;
