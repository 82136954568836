import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MoveIcon from "../../../../assets/icons/move.svg";
import React from "react";
import propTypes from "prop-types";
import { useDispatch } from "react-redux";
import { getBlogIdDetails } from "../../../../redux/features/blogs/postsequencingSlice";
import testIcon from "../../../../assets/icons/test.svg";

export default function Sequencing({
  users,
  setUsers,
  setSelectedCourse,
  selectedCourse,
  blogid,
  setBlogId,
}) {
  const dispatch = useDispatch();

  const handleDragEnd = e => {
    if (!e.destination) return;
    let tempData = Array.from(users);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setUsers(tempData);
  };
  const handleDragEndPosts = e => {
    if (!e.destination) return;
    let tempData = Array.from(selectedCourse);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setSelectedCourse(tempData);
  };

  return (
    <div className="flex ">
      <div className="shadow-outer w-2/5 mt-4  rounded-2xl">
        <DragDropContext onDragEnd={handleDragEnd}>
          <section className=" w-full ">
            <Droppable droppableId="droppable-1">
              {provider => (
                <div ref={provider.innerRef} {...provider.droppableProps}>
                  {users?.map((user, index) => (
                    <Draggable
                      key={user.blogName}
                      
                      draggableId={user.blogName}
                      index={index}>
                      {provider => (
                        <div
                          className="flex space-x-5 space-y-6"
                          {...provider.draggableProps}
                          ref={provider.innerRef}>
                          <div
                            {...provider.dragHandleProps}
                            width="50px"
                            height="60px">
                            <img className="ml-4 mt-6" src={MoveIcon} alt="move" />
                          </div>

                          <div
                            className={`cursor-pointer ${blogid==user.blogId ? "text-primary-200 " :""}`}
                            onClick={() => {
                              setBlogId(user.blogId);
                              dispatch(getBlogIdDetails(user.blogId));
                            }}>
                            {user.blogName}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provider.placeholder}
                </div>
              )}
            </Droppable>
          </section>
        </DragDropContext>
      </div>
      {/* 2nd table */}
      <div className="shadow-outer w-2/5 mt-4 ml-16 rounded-2xl">
        <DragDropContext onDragEnd={handleDragEndPosts}>
          <section className=" w-full ">
            <Droppable droppableId="droppable-1">
              {provider => (
                <div ref={provider.innerRef} {...provider.droppableProps} >
                  {selectedCourse &&
                    selectedCourse.map((user, index) => (
                      <Draggable
                        key={user.postName}
                        draggableId={user.postName}
                        index={index}>
                        {provider => (
                          <div
                            className="flex space-x-5 space-y-6"
                            {...provider.draggableProps}
                            ref={provider.innerRef}>
                            <div
                              {...provider.dragHandleProps}
                              width="30px"
                              height="60px">
                              <img className="ml-4 mt-6" src={MoveIcon} alt="move" />
                            </div>
                            <img src={testIcon} />
                            <div>{user.postName}</div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provider.placeholder}
                </div>
              )}
            </Droppable>
          </section>
        </DragDropContext>
      </div>
    </div>
  );
}
Sequencing.propTypes = {
  data: propTypes.array,
  users: propTypes.array,
  setUsers: propTypes.func,
  selectedCourse: propTypes.any,
  setSelectedCourse: propTypes.func,
  blogid:propTypes.oneOfType([
    propTypes.string,
    propTypes.number]),
  setBlogId:propTypes.func,
};
