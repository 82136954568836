import React, { useState } from "react";
import Header from "../../components/header/Header";
import CreateContentRepo from "../../components/createContentRepo/CreateContentRepo";
import ContentRepositoryComp from "../../components/createContentRepo/ContentRepositoryComp";
import { useQuery } from "react-query";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useLocation } from "react-router-dom";
import Loader from "../../components/global/loader/Loader";
import { useSelector } from "react-redux";
const AddContentRepo = () => {
  const location = useLocation();
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  const dataValues = location?.state?.data; //onclick edit pass its values to create content repo
  const [contentRepoFields, setContentRepFieldValue] = useState({
    inputValue: "",
    productValue: "",
  }); // and array of objects to send set values to form submission
  // const [showComp, setShowComp] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [addContentdata, setAddContentdata] = useState(null);
  const [showAddAssetButton, setShowAddAssetButton] = useState(false);
  const [assetData, setAssetData] = useState([]);

  const { refetch } = useQuery(
    endpoint.add_content_repo.key,
    () =>
      apirequest({
        url: `${endpoint.add_content_repo.addr}`,
        method: "post",
        data: {
          contentRepositoryName: contentRepoFields.inputValue,
          productGroupName: contentRepoFields.productValue,
          isActive: "1",
          createdBy: login_Details.userImsPin,
        },
      }),
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        setAddContentdata(data.data?.data[0]);
        setIsSuccess(true);
        // setShowComp(true);
        alert(data.data.message);
      },
      onError: (err) => {
        alert(err.data.message);
      },
    }
  );

  const fetchGetAssetUsingRepoPayload = () => {
    return apirequest({
      url: `${endpoint.getAssetsUsingContentRepository.addr}`,
      method: "post",
      data: {
        contentRepositoryId: dataValues?.contentRepositoryId 
      },
    });
  };

  const { status: assetStatus  , data :  getAssetdata , isFetching} = useQuery(
    [endpoint.getAssetsUsingContentRepository.key , dataValues?.contentRepositoryId],
    fetchGetAssetUsingRepoPayload,
    {
      onSuccess: (res) => setAssetData(res),
      enabled : !!(dataValues?.contentRepositoryId),
      refetchOnMount : true
    }
  );

  return (
    <>
      <Header />
      <div className="mt-10 mx-6">
        <CreateContentRepo
          contentRepFieldValue={contentRepoFields}
          setContentRepFieldValue={setContentRepFieldValue}
          refetch_ContentRepo_data={refetch}
          isSuccessMsg={isSuccess}
          dataValues={dataValues ?? addContentdata}
          setShowAddAssetButton={setShowAddAssetButton}
        />
        { assetStatus === "loading" || isFetching ? (
          <Loader />
        ) : (
          <ContentRepositoryComp
            isforAddConentRepo={false}
            dataSource={assetData?.data?.data ?? []}
            contentRepoData={dataValues ??  addContentdata}
            status={assetStatus}
            showAddAssetButton={showAddAssetButton}
            setAssetData={setAssetData}
            contentRepoID={dataValues?.contentRepositoryId ?? addContentdata?.contentRepositoryId}
            defaultAssetData = {getAssetdata}
          />
        )}
      </div>
    </>
  );
};
export default AddContentRepo;
