import React, { useState } from "react";
import Header from "../header/Header";
import MentorTable from "./MentorTable";
import TablePagination from "../table/TablePagination";
import documentIcon from "../../assets/icons/quiz.svg";
import Loader from "../../components/global/loader/Loader";
import {apirequest} from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
// import { useSelector } from "react-redux";
// import FilterFunctionlity from "../../filterFunctionality/FilterFunctionlity";
import TableSearch from "../table/TableSearch";
import SearchMentorModalTrigger from "./modalscreen/SearchMentorModalTrigger";


const MentorListing = () => {
 
  const column = [
    { col: "1", width: "25%" },
    { col: "2", width: "15%" },
    { col: "3", width: "15%" },
    { col: "4", width: "30%" },
    { col: "5", width: "10%" },
    { col: "6", width: "20%" },
  ];
  const [mentorData , setMentorData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(true);
  };
  // API's for mentor Listing
  const MentorListing = () => {
    return apirequest({
      url: `${endpoint.get_mentorData.addr}`,
      method: "get",
    });
  };

  const { data: getMentorData,error,isSuccess :is_mentor_success,status,refetch } = useQuery(
    endpoint.get_mentorData.key,
    MentorListing,{
      onSuccess : res =>setMentorData(res),
      retry : 1
    }
  );

  const [currentPage, setCurrentPage] = useState(1);
  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };

  return (
    <div id="courses">
      <Header />
      {status === "loading" ? (
        <Loader />
      ) : !is_mentor_success && error?.status !== 404 ? (
        <span>{error?.message}</span>
      ) : (
        <div className="courses relative">
          <div className="courseTable mt-9">
            <div className="courseTable__head flex justify-between  md:mt-[4%] md-mb-[5%] md:mx-9 pl-[2%]">
              {/* <FilterFunctionlity 
                filterText = "Course"
                setFunction = {setNoticeBoardData}
                dropdownData = {getCourseData}
                url = {endpoint.noticeboard_data.addr}
                payload = {courseId}
                setPayload = {setCourseId}
                setCurrentPage = {setCurrentPage}
                payloadData = {{adminimspin :login_Details.userImsPin, courseId : courseId} }
                filterId = "courseId"
                filterValue="courseName"
              /> */}

              <div className="ml-4 flex space-x-96">
                <TableSearch
                  defaultData ={getMentorData}
                  setDefaultData = {setMentorData}
                  dataKey = "mentorName"
                  dataForFilter = {getMentorData?.data.data}
                  name = "Mentor MyPlan List"
                  subModuleName = ""
                  setCurrentPage = {setCurrentPage}
                  childModuleName = ""
                  placeholder= "Search by mentor Name"
                  noSubmodules = {true}
                />
              
                <div className="w-56 h-14 absolute right-20 top-0 px-7 py-3 text-lg font-ibm font-normal text-secondary-100  border border-transparent rounded-2xl bg-primary-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"><SearchMentorModalTrigger modalButtonFunctions={[handleClick]} isOpen={isOpen}/></div>
                  
              </div>
            </div>
            <div className="courseTable__body   mt-4 shadow-outer mx-16 rounded-xl bg-white pb-[2%]">
              {is_mentor_success && (
                <MentorTable
                  colIcon={documentIcon}
                  column={column}
                  tableHead={true}
                  refetch={refetch}
                  currentData={mentorData?.data?.data.slice(
                    indexOfFirstItem,
                    indexOfLastItem,
                  )}
                />
              )}
            </div>
            <div className="courseTable__footer mx-auto mb-7">
              {is_mentor_success &&
              <TablePagination
                data={mentorData?.data?.data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
                dataPerPage={dataPerPage}
              />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MentorListing;
