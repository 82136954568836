import React, { useState } from "react";
import Header from "../../header/Header";
import { Link } from "react-router-dom";
import NoticeTable from "./NoticeTable";
import TablePagination from "../../table/TablePagination";
// import ArrowUp from "../../../assets/icons/arrow-up.svg";
import documentIcon from "../../../assets/icons/quiz.svg";
import Loader from "../../../components/global/loader/Loader";
import {apirequest} from "../../../API/api";
import { endpoint } from "../../../API/config";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";
import FilterFunctionlity from "../../filterFunctionality/FilterFunctionlity";
import TableSearch from "../../table/TableSearch";

const NoticeboardListing = () => {
  // const adminimspin = useSelector(state => state.loginData.LoginDetails.userImsPin);
  const column = [
    { col: "1", width: "40%" },
    { col: "2", width: "15%" },
    { col: "3", width: "15%" },
    { col: "4", width: "10%" },
    { col: "5", width: "10%" },
    { col: "6", width: "30%" },
  ];
  const login_Details = useSelector(state=>state?.loginData?.LoginDetails);
  const [noticeBoardData , setNoticeBoardData] = useState([]);
  const [courseId , setCourseId] = useState([]);

  // API's for Noticeboard Listing
  const NoticeListing = () => {
    return apirequest({
      url: `${endpoint.noticeboard_data.addr}`,
      method: "post",
      data: {
        // adminimspin:adminimspin,
        adminimspin:login_Details.userImsPin,
        courseId : ""
      }
    
    });
  };

  const { data: getNoticeData,error,isSuccess :is_notice_success,status,refetch } = useQuery(
    endpoint.noticeboard_data.key,
    NoticeListing,{
      onSuccess : res =>setNoticeBoardData(res),
      retry : 1
    }
  );

  const { data: getCourseData } = useQuery(`${endpoint.course_data.key}`, () =>
    apirequest({
      url: `${endpoint.course_data.addr}`,
      method : "post",
      data : {
        "adminimspin":login_Details.userImsPin,      
        "courseId" : ""
      }
    }),{
    select : res=>res.data.data.courseList
  });

  const [currentPage, setCurrentPage] = useState(1);
  /* Limit Data To Show to 10 */
  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };

  return (
    <div id="courses">
      <Header />
      {status === "loading" ? (
        <Loader />
      ) : !is_notice_success && error?.status !== 404 ? (
        <span>{error?.message}</span>
      ) : (
        <div className="courses">
          <div className="courseTable mt-9">
            <div className="courseTable__head flex justify-between  md:mt-[4%] md-mb-[5%] md:mx-9 pl-[2%]">
              <FilterFunctionlity 
                filterText = "Course"
                setFunction = {setNoticeBoardData}
                dropdownData = {getCourseData}
                url = {endpoint.noticeboard_data.addr}
                payload = {courseId}
                setPayload = {setCourseId}
                setCurrentPage = {setCurrentPage}
                payloadData = {{adminimspin :login_Details.userImsPin, courseId : courseId} }
                filterId = "courseId"
                filterValue="courseName"
              />

              <div className="ml-4 flex pr-8">
                {/* noticeBoardName noticeBoardId*/}
                <TableSearch
                  defaultData ={getNoticeData}
                  setDefaultData = {setNoticeBoardData}
                  dataKey = "noticeBoardName"
                  dataForFilter = {getNoticeData?.data.data?.noticeBoardList                  }
                  name = "Home Page"
                  subModuleName = "Notice Board Management"
                  setCurrentPage = {setCurrentPage}
                  childModuleName = ""
                  placeholder= "Search by Noticeboard Name"
                />
                {(is_notice_success && getNoticeData.data.data.global_add_access==="1" || error?.data.message ==="No Record found") &&
                <Link to="/HomePage/NoticeBoardManagement/new-notice">
                  <button className="ml-6 bg-primary-200 text-white font-medium px-6 rounded-xl hover:opacity-75 shadow-outer font-ibm w-40 h-[56px]">
                    Add Notice
                  </button>
                </Link>}
                <Link to="/HomePage/NoticeBoardManagement/noticeboard-sequence">
                  <button className="ml-6 bg-secondary-100 text-ibm font-medium px-6 rounded-xl hover:opacity-75 shadow-outer font-ibm w-auto h-[56px]">
                    Noticeboard Sequence
                  </button>
                </Link>
              </div>
            </div>
            <div className="courseTable__body   mt-4 shadow-outer mx-16 rounded-xl bg-white pb-[2%]">
              {is_notice_success && (
                <NoticeTable
                  colIcon={documentIcon}
                  column={column}
                  tableHead={true}
                  refetch={refetch}
                  currentData={noticeBoardData?.data?.data.noticeBoardList.slice(
                    indexOfFirstItem,
                    indexOfLastItem,
                  )}
                />
              )}
            </div>
            <div className="courseTable__footer mx-auto mb-7">
              {is_notice_success &&
              <TablePagination
                data={noticeBoardData?.data?.data.noticeBoardList}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
                dataPerPage={dataPerPage}
              />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoticeboardListing;
