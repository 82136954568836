import React from "react";

import Header from "../../header/Header";
import CriteriaForm from "../eligibilityCriteria/CriteriaForm";
import { endpoint } from "../../../API/config";

const Deadlines = () => {
  return (
    <div>
      <Header />
      <CriteriaForm
        endPoints={endpoint.addDeadlines} 
        downloadEndpoints={endpoint.downloadDeadlinesCSV}
      />
    </div>
  );
};

export default Deadlines;