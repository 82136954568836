import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const Dropdown = ({
  minOffset,
  maxOffset,
  text,
  selectedValue,
  setSelectedValue,
}) => {
  const [value, setValue] = useState(selectedValue);
  const initialValue = 0;
  const onHandleChange = (evt) => {
    setSelectedValue(evt.target.value);
  };

  const options = [];
  function getlength(number) {
    if (number.toString().length > 2) {
      return Number(number.substring(1));
    } else if (number <= 9 && number.toString().length === 2) {
      return Number(number.toString().substring(1));
    } else {
      return Number(number);
    }
  }

  for (let i = minOffset; i <= maxOffset; i++) {
    const res = initialValue + i;
    options.push(
      <option key={i} value={getlength(res)}>
        {getlength(res)}
      </option>
    );
  }

  useEffect(() => {
    if (selectedValue || selectedValue === 0) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  return (
    <div className="flex">
      <select
        className="w-20 h-6 border-2 border-secondary-300 rounded-lg focus:outline-none"
        value={value}
        onChange={onHandleChange}
      >
        {options}
      </select>
      <span className="font-ibm text-md font-semibold ml-2">{text}</span>
    </div>
  );
};
Dropdown.propTypes = {
  minOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  selectedValue: PropTypes.any,
  setSelectedValue: PropTypes.func,
};

export default Dropdown;
